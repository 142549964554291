export const validation = {
	device: {
		dupe: "A Device with name {{name}} already exists.",
		maxLength: "Device name cannot be longer than {{length}} characters.",
		minLength: "Device name must be longer than {{length}} characters.",
		pattern: "Device name can only use alphanumeric and . _ - : , & ' \" / ( ) ! ? characters.",
	},
	alarmGroup: {
		dupe: "An Alarm Group with name {{name}} already exists.",
		maxLength: "Alarm Group name cannot be longer than {{length}} characters.",
		minLength: "Alarm Group name must be longer than {{length}} characters.",
		pattern: "Alarm Group name can only use alphanumeric and . _ - : , & ' \" / ( ) ! ? characters.",
	},
	scheme: {
		dupe: "A Scheme with name {{name}} already exists.",
		maxLength: "Scheme name cannot be longer than {{length}} characters.",
		minLength: "Scheme name must be longer than {{length}} characters.",
		pattern: "Scheme name can only use alphanumeric and . _ - : , & ' \" / ( ) ! ? characters.",
	},
	zone: {
		dupe: "A Zone with name {{name}} already exists.",
		maxLength: "Zone name cannot be longer than {{length}} characters.",
		minLength: "Zone name must be longer than {{length}} characters.",
		pattern: "Zone name can only use alphanumeric and . _ - : , & ' \" / ( ) ! ? characters.",
	},
	report: {
		name: {
			maxLength: "Report name cannot be longer than {{length}} characters.",
			minLength: "Report name must be longer than {{length}} characters.",
			pattern: "Report name can only use alphanumeric and . _ - : , & ' \" / ( ) ! ? characters.",
		},
		desc: {
			maxLength: "Report description cannot be longer than {{length}} characters.",
			minLength: "Report description must be longer than {{length}} characters.",
			pattern: "Report description can only use alphanumeric and . _ - : , & ' \" / ( ) ! ? characters.",
		},
	},
};
