//Node Modules
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useResetRecoilState, useRecoilState, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router";
import _ from "lodash";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { deleteUserSchemeConfig, deleteUserNotifyConfig, updateZone } from "../graphql/mutations";
import { userSchemeConfigByCognitoUserId, listSchemes } from "../graphql/queries";
import { getUserConfigs, listZonesBasic } from "../graphql/queries-custom";

//BinaryForge Components
import { Note } from "../components/helpers";

//3rd Party Components
import { confirmDialog } from "primereact/confirmdialog";

//Atoms
import { selectedUserSchemeAtom, selectedUserAtom, selectedUserSelector, userSchemeOptionsAtom } from "../atoms/user";
import { toastAtom } from "../atoms/toast";

//Helpers
import { useApiRequest } from "./Api";

//Other
import { nav } from "../config/navigation";

export const useConfirmUserAction = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const apiRequest = useApiRequest();

	const [toast, setToast] = useRecoilState(toastAtom);
	// const selectedUser = useRecoilValue(selectedUserAtom);
	const selectedUser = useRecoilValue(selectedUserAtom);
	const resetSelectedUser = useResetRecoilState(selectedUserSelector);
	const selectedScheme = useRecoilValue(selectedUserSchemeAtom);

	const clearTenantData = async (userSchemeConfig, userId) => {
		const isUserTenant = userSchemeConfig.some((config) => config.role === "TENANT");
		if (isUserTenant) {
			const {
				data: {
					zoneBySchemeId: { items: tenantZones },
				},
			} = await API.graphql(
				graphqlOperation(listZonesBasic, {
					schemeId: selectedScheme.id,
					filter: { tenant: { contains: userId } },
				})
			);
			for await (const zone of tenantZones) {
				const updatedTenants = _.pull(zone.tenant, userId);
				await API.graphql(graphqlOperation(updateZone, { input: { id: zone.id, tenant: updatedTenants } }));
			}
		}
	};

	const doPromoteUser = async (username, userId, email) => {
		try {
			await apiRequest("post", `/user/${username}/promote`, { email: email }, t("admin.user.promote.loading"));

			const {
				data: {
					userSchemeConfigByCognitoUserId: { items: userSchemeConfigs },
				},
			} = await API.graphql(
				graphqlOperation(userSchemeConfigByCognitoUserId, {
					cognitoUserId: userId,
				})
			);

			for await (const config of userSchemeConfigs) {
				await API.graphql(graphqlOperation(deleteUserSchemeConfig, { input: { id: config.id } }));
			}

			await clearTenantData(userSchemeConfigs, userId);

			setToast({
				...toast,
				severity: "success",
				summary: t("admin.user.promote.toast.successSummary"),
				detail: t("admin.user.promote.toast.successDetail", { username: username }),
			});
			resetSelectedUser();
			navigate(`${nav.admin.base}/${nav.admin.user.base}/${nav.admin.user.management}`);
		} catch (err) {
			console.error("Promote Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("admin.user.promote.toast.errorSummary"),
				detail: t("admin.user.promote.toast.errorDetail", { error: err.message }),
			});
		}
	};

	const doDeleteUser = async (userId, isAdmin) => {
		try {
			await apiRequest("del", `/user/${selectedUser.username}`, null, t("user.delete.loading"));

			if (!isAdmin) {
				const {
					data: {
						userSchemeConfigByCognitoUserId: { items: userSchemeConfig },
						userNotifyConfigByCognitoUserId: { items: userNotifyConfig },
					},
				} = await API.graphql(
					graphqlOperation(getUserConfigs, {
						cognitoUserId: userId,
						filterScheme: { schemeId: { eq: selectedScheme.id } },
						filterNotify: { schemeId: { eq: selectedScheme.id } },
					})
				);

				await API.graphql(graphqlOperation(deleteUserSchemeConfig, { input: { id: userSchemeConfig[0].id } }));
				if (userNotifyConfig.length > 0)
					await API.graphql(
						graphqlOperation(deleteUserNotifyConfig, { input: { id: userNotifyConfig[0].id } })
					);

				await clearTenantData(userSchemeConfig, userId);
			}

			setToast({
				...toast,
				severity: "success",
				summary: t("user.delete.toast.successSummary"),
				detail: t("user.delete.toast.successDetail", { user: selectedUser.username }),
			});
			resetSelectedUser();
			navigate(
				isAdmin
					? `${nav.admin.base}/${nav.admin.user.base}/${nav.admin.user.management}`
					: `${nav.user.base}/${nav.user.management}`
			);
		} catch (err) {
			console.error("Delete Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("user.delete.toast.errorSummary"),
				detail: t("user.delete.toast.errorDetail", { error: err.message }),
			});
		}
	};

	const doResendInvite = async () => {
		try {
			await apiRequest("post", `/user/${selectedUser.username}/confirm`, null, t("user.resendInvite.loading"));
			setToast({
				...toast,
				severity: "success",
				summary: t("user.resendInvite.toast.successSummary"),
				detail: t("user.resendInvite.toast.successDetail"),
			});
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t("user.resendInvite.toast.errorSummary"),
				detail: t("user.resendInvite.toast.errorDetail", { error: err.message }),
			});
		}
	};

	const doResetPassword = async () => {
		try {
			await apiRequest(
				"post",
				`/user/${selectedUser.username}/reset_password`,
				null,
				t("user.resetPassword.loading")
			);
			setToast({
				...toast,
				severity: "success",
				summary: t("user.resetPassword.toast.successSummary"),
				detail: t("user.resetPassword.toast.successDetail"),
			});
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t("user.resetPassword.toast.errorSummary"),
				detail: t("user.resetPassword.toast.errorDetail", {
					error: err.response ? err.response.data.message : err.message,
				}),
			});
		}
	};

	const confirmPromoteUser = (username, userId, email) => {
		confirmDialog({
			header: t("admin.user.promote.header"),
			message: (
				<>
					<Note
						messageKey={t("admin.user.promote.message", { user: username, email: email })}
						icon="pi pi-info-circle fontColour-info"
					/>
					<Note
						wrapperStyle="marginTop-small"
						messageKey={t("admin.user.promote.warning")}
						messageStyle="fontSize-small"
						icon="pi pi-exclamation-triangle fontColour-warn"
					/>
				</>
			),
			acceptLabel: t("admin.user.promote.accept"),
			rejectLabel: t("admin.user.promote.reject"),
			acceptIcon: "pi pi-angle-double-up",
			acceptClassName: "success feature",
			accept: () => doPromoteUser(username, userId, email),
			reject: () => {}, //Do Nothing,
		});
	};

	const confirmDeleteUser = (userId, isAdmin) => {
		confirmDialog({
			header: t("user.delete.header"),
			message: (
				<Note
					messageKey={t("user.delete.message", { user: selectedUser.username })}
					icon="pi pi-exclamation-triangle fontColour-warn"
				/>
			),
			acceptLabel: t("user.delete.accept"),
			rejectLabel: t("user.delete.reject"),
			acceptIcon: "pi pi-user-minus",
			acceptClassName: "error feature",
			accept: () => doDeleteUser(userId, isAdmin),
			reject: () => {}, //Do Nothing,
		});
	};

	const confirmResetPassword = () => {
		confirmDialog({
			header: t("user.resetPassword.header"),
			message: (
				<Note
					messageKey={t("user.resetPassword.message", {
						user: selectedUser.username,
						email: selectedUser.email,
					})}
					icon="pi pi-exclamation-triangle fontColour-warn"
				/>
			),
			acceptLabel: t("user.resetPassword.accept"),
			rejectLabel: t("common.boolean.cancel"),
			acceptIcon: "pi pi-lock",
			acceptClassName: "fontColour-success feature",
			accept: () => doResetPassword(),
			reject: () => {}, //Do Nothing,
		});
	};

	const confirmResendInvite = () => {
		confirmDialog({
			header: t("user.resendInvite.header"),
			message: (
				<Note
					messageKey={t("user.resendInvite.message", {
						user: selectedUser.username,
						email: selectedUser.email,
						expires: process.env.REACT_APP_REGISTRATION_EXPIRY,
					})}
					icon="pi pi-exclamation-triangle fontColour-warn"
				/>
			),
			acceptLabel: t("user.resendInvite.accept"),
			rejectLabel: t("common.boolean.cancel"),
			acceptIcon: "pi pi-envelope",
			acceptClassName: "fontColour-success feature",
			accept: () => doResendInvite(),
			reject: () => {}, //Do Nothing,
		});
	};

	return { confirmPromoteUser, confirmDeleteUser, confirmResetPassword, confirmResendInvite };
};

export const useGetUserSchemeConfig = () => {
	//Recoil State
	const setSchemeOptions = useSetRecoilState(userSchemeOptionsAtom);
	const setSelectedScheme = useSetRecoilState(selectedUserSchemeAtom);

	//Global Vars
	let toPage = null;
	let schemeRole = null;

	//Get the user config
	const handleGetUserConfig = useCallback(async (userSub, defaultScheme) => {
		// Fetch a list of schemes from DyanmoDB to which the user is assigned that have a role of OWNER
		// No point fetching schemes with a role of INSTALLER or TENANT as these cannot log into the Portal
		const {
			data: {
				userSchemeConfigByCognitoUserId: { items: userSchemes },
			},
		} = await API.graphql(
			graphqlOperation(userSchemeConfigByCognitoUserId, {
				cognitoUserId: userSub,
				filter: { role: { eq: "OWNER" } },
			})
		);

		if (userSchemes.length === 1) {
			// The user is only assigned to one scheme so use this
			// Set the selected scheme as the only item returned from the graphQL query
			setSelectedScheme({
				id: userSchemes[0].scheme.id,
				name: userSchemes[0].scheme.name,
				role: userSchemes[0].role,
				configId: userSchemes[0].id,
			});
			schemeRole = userSchemes[0].role;
			// The user should be navigated to the devices page
			toPage = `${nav.device.base}/${nav.device.management}`;
		} else if (userSchemes.length !== 0) {
			// The user is assigned to multiple schemes so use this
			// Set the scheme options for the user to choose which scheme to use
			const userSchemeOptions = userSchemes.map((s) => ({
				id: s.scheme.id,
				name: s.scheme.name,
				role: s.role,
				configId: s.id,
			}));
			setSchemeOptions(userSchemeOptions);
			// The user should be navigated to the scheme select page
			toPage = nav.combo.schemeSelect;

			// If the user has a default scheme selected then set this as the selected scheme
			if (defaultScheme) {
				//Find the scheme details from the list of options based on the default scheme returned from Cognito
				const selectDefaultScheme = userSchemes.find((s) => s.scheme.id === defaultScheme);
				setSelectedScheme({
					id: selectDefaultScheme.scheme.id,
					name: selectDefaultScheme.scheme.name,
					role: selectDefaultScheme.role,
					configId: selectDefaultScheme.id,
				});
				schemeRole = selectDefaultScheme.role;
				// The user should be navigated to the devices page
				toPage = `${nav.device.base}/${nav.device.management}`;
			}
		} else {
			// The user had no schemes available for the portal set the options to error code
			setSchemeOptions(-1);
			toPage = nav.combo.schemeSelect;
		}

		return { toUserPage: toPage, schemeRole };
	}, []);

	//Get the admin config
	const handleGetAdminConfig = useCallback(async (defaultScheme) => {
		// Fetch a list of all schemes from DyanmoDB
		// These should not come from user config as admin users are assigned to all schemes
		const {
			data: {
				listSchemes: { items: adminSchemes },
			},
		} = await API.graphql(graphqlOperation(listSchemes));

		if (adminSchemes.length === 1) {
			// The user is only assigned to one scheme so use this
			// Set the selected scheme as the only item returned from the graphQL query
			setSelectedScheme({
				id: adminSchemes[0].id,
				name: adminSchemes[0].name,
				role: "ADMINISTRATOR",
			});
			// The user should be navigated to the devices page
			toPage = `${nav.device.base}/${nav.device.management}`;
		} else {
			// The user is assigned to multiple schemes so use this
			// Set the scheme options for the user to choose which scheme to use
			const userSchemeOptions = adminSchemes.map((s) => ({
				id: s.id,
				name: s.name,
				role: "ADMINISTRATOR",
			}));
			setSchemeOptions(userSchemeOptions);
			// The user should be navigated to the scheme select page
			toPage = nav.combo.schemeSelect;

			// If the user has a default scheme selected then set this as the selected scheme
			if (defaultScheme) {
				//Find the scheme details from the list of options based on the default scheme returned from Cognito
				const selectDefaultScheme = adminSchemes.find((s) => s.id === defaultScheme);
				setSelectedScheme({
					id: selectDefaultScheme.id,
					name: selectDefaultScheme.name,
					role: "ADMINISTRATOR",
				});
				// The user should be navigated to the devices page
				toPage = `${nav.device.base}/${nav.device.management}`;
			}
		}

		return { toUserPage: toPage, schemeRole: "ADMINISTRATOR" };
	}, []);

	return { handleGetUserConfig, handleGetAdminConfig };
};

export const getReturnPage = (type, isAdmin, params) => {
	let pageUrl = null;
	if (isAdmin) {
		type === "create"
			? (pageUrl = `${nav.admin.base}/${nav.admin.user.base}/${nav.user.management}`)
			: (pageUrl = `${nav.admin.base}/${nav.admin.user.base}/${params.username}`);
	} else {
		type === "create"
			? (pageUrl = `${nav.user.base}/${nav.user.management}`)
			: (pageUrl = `${nav.user.base}/${params.username}`);
	}

	return pageUrl;
};
