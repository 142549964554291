/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      id
      name
      reported_state
      desired_state
      desired_state_info
      modbus_state
      alarmGroupsInput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroupsOutput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inputDevice
      outputDevice
      alert {
        id
        sensor_state
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deviceSensorAlertDeviceId
        __typename
      }
      liveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      archiveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneBreadcrumb
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      id
      name
      reported_state
      desired_state
      desired_state_info
      modbus_state
      alarmGroupsInput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroupsOutput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inputDevice
      outputDevice
      alert {
        id
        sensor_state
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deviceSensorAlertDeviceId
        __typename
      }
      liveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      archiveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneBreadcrumb
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      id
      name
      reported_state
      desired_state
      desired_state_info
      modbus_state
      alarmGroupsInput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroupsOutput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inputDevice
      outputDevice
      alert {
        id
        sensor_state
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deviceSensorAlertDeviceId
        __typename
      }
      liveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      archiveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneBreadcrumb
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDeviceProvisioning = /* GraphQL */ `
  mutation CreateDeviceProvisioning(
    $input: CreateDeviceProvisioningInput!
    $condition: ModelDeviceProvisioningConditionInput
  ) {
    createDeviceProvisioning(input: $input, condition: $condition) {
      id
      name
      inputDevice
      outputDevice
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDeviceProvisioning = /* GraphQL */ `
  mutation UpdateDeviceProvisioning(
    $input: UpdateDeviceProvisioningInput!
    $condition: ModelDeviceProvisioningConditionInput
  ) {
    updateDeviceProvisioning(input: $input, condition: $condition) {
      id
      name
      inputDevice
      outputDevice
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDeviceProvisioning = /* GraphQL */ `
  mutation DeleteDeviceProvisioning(
    $input: DeleteDeviceProvisioningInput!
    $condition: ModelDeviceProvisioningConditionInput
  ) {
    deleteDeviceProvisioning(input: $input, condition: $condition) {
      id
      name
      inputDevice
      outputDevice
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlarmGroup = /* GraphQL */ `
  mutation CreateAlarmGroup(
    $input: CreateAlarmGroupInput!
    $condition: ModelAlarmGroupConditionInput
  ) {
    createAlarmGroup(input: $input, condition: $condition) {
      id
      name
      input {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      output {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlarmGroup = /* GraphQL */ `
  mutation UpdateAlarmGroup(
    $input: UpdateAlarmGroupInput!
    $condition: ModelAlarmGroupConditionInput
  ) {
    updateAlarmGroup(input: $input, condition: $condition) {
      id
      name
      input {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      output {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlarmGroup = /* GraphQL */ `
  mutation DeleteAlarmGroup(
    $input: DeleteAlarmGroupInput!
    $condition: ModelAlarmGroupConditionInput
  ) {
    deleteAlarmGroup(input: $input, condition: $condition) {
      id
      name
      input {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      output {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDeviceSensorAlert = /* GraphQL */ `
  mutation CreateDeviceSensorAlert(
    $input: CreateDeviceSensorAlertInput!
    $condition: ModelDeviceSensorAlertConditionInput
  ) {
    createDeviceSensorAlert(input: $input, condition: $condition) {
      id
      sensor_state
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      deviceSensorAlertDeviceId
      __typename
    }
  }
`;
export const updateDeviceSensorAlert = /* GraphQL */ `
  mutation UpdateDeviceSensorAlert(
    $input: UpdateDeviceSensorAlertInput!
    $condition: ModelDeviceSensorAlertConditionInput
  ) {
    updateDeviceSensorAlert(input: $input, condition: $condition) {
      id
      sensor_state
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      deviceSensorAlertDeviceId
      __typename
    }
  }
`;
export const deleteDeviceSensorAlert = /* GraphQL */ `
  mutation DeleteDeviceSensorAlert(
    $input: DeleteDeviceSensorAlertInput!
    $condition: ModelDeviceSensorAlertConditionInput
  ) {
    deleteDeviceSensorAlert(input: $input, condition: $condition) {
      id
      sensor_state
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      deviceSensorAlertDeviceId
      __typename
    }
  }
`;
export const createNotifyToken = /* GraphQL */ `
  mutation CreateNotifyToken(
    $input: CreateNotifyTokenInput!
    $condition: ModelNotifyTokenConditionInput
  ) {
    createNotifyToken(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNotifyToken = /* GraphQL */ `
  mutation UpdateNotifyToken(
    $input: UpdateNotifyTokenInput!
    $condition: ModelNotifyTokenConditionInput
  ) {
    updateNotifyToken(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotifyToken = /* GraphQL */ `
  mutation DeleteNotifyToken(
    $input: DeleteNotifyTokenInput!
    $condition: ModelNotifyTokenConditionInput
  ) {
    deleteNotifyToken(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createScheme = /* GraphQL */ `
  mutation CreateScheme(
    $input: CreateSchemeInput!
    $condition: ModelSchemeConditionInput
  ) {
    createScheme(input: $input, condition: $condition) {
      id
      name
      users {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          isDefaultScheme
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroups {
        items {
          id
          name
          input {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          output {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorAlerts {
        items {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        nextToken
        __typename
      }
      userNotifyConfig {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          config
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contact
      contactEmergency
      zoneConfig
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateScheme = /* GraphQL */ `
  mutation UpdateScheme(
    $input: UpdateSchemeInput!
    $condition: ModelSchemeConditionInput
  ) {
    updateScheme(input: $input, condition: $condition) {
      id
      name
      users {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          isDefaultScheme
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroups {
        items {
          id
          name
          input {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          output {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorAlerts {
        items {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        nextToken
        __typename
      }
      userNotifyConfig {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          config
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contact
      contactEmergency
      zoneConfig
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteScheme = /* GraphQL */ `
  mutation DeleteScheme(
    $input: DeleteSchemeInput!
    $condition: ModelSchemeConditionInput
  ) {
    deleteScheme(input: $input, condition: $condition) {
      id
      name
      users {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          isDefaultScheme
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroups {
        items {
          id
          name
          input {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          output {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorAlerts {
        items {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        nextToken
        __typename
      }
      userNotifyConfig {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          config
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contact
      contactEmergency
      zoneConfig
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserSchemeConfig = /* GraphQL */ `
  mutation CreateUserSchemeConfig(
    $input: CreateUserSchemeConfigInput!
    $condition: ModelUserSchemeConfigConditionInput
  ) {
    createUserSchemeConfig(input: $input, condition: $condition) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      isDefaultScheme
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserSchemeConfig = /* GraphQL */ `
  mutation UpdateUserSchemeConfig(
    $input: UpdateUserSchemeConfigInput!
    $condition: ModelUserSchemeConfigConditionInput
  ) {
    updateUserSchemeConfig(input: $input, condition: $condition) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      isDefaultScheme
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserSchemeConfig = /* GraphQL */ `
  mutation DeleteUserSchemeConfig(
    $input: DeleteUserSchemeConfigInput!
    $condition: ModelUserSchemeConfigConditionInput
  ) {
    deleteUserSchemeConfig(input: $input, condition: $condition) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      isDefaultScheme
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserDashboardConfig = /* GraphQL */ `
  mutation CreateUserDashboardConfig(
    $input: CreateUserDashboardConfigInput!
    $condition: ModelUserDashboardConfigConditionInput
  ) {
    createUserDashboardConfig(input: $input, condition: $condition) {
      id
      cognitoUserId
      schemeId
      dashboardAnalytics
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserDashboardConfig = /* GraphQL */ `
  mutation UpdateUserDashboardConfig(
    $input: UpdateUserDashboardConfigInput!
    $condition: ModelUserDashboardConfigConditionInput
  ) {
    updateUserDashboardConfig(input: $input, condition: $condition) {
      id
      cognitoUserId
      schemeId
      dashboardAnalytics
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserDashboardConfig = /* GraphQL */ `
  mutation DeleteUserDashboardConfig(
    $input: DeleteUserDashboardConfigInput!
    $condition: ModelUserDashboardConfigConditionInput
  ) {
    deleteUserDashboardConfig(input: $input, condition: $condition) {
      id
      cognitoUserId
      schemeId
      dashboardAnalytics
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserNotifyConfig = /* GraphQL */ `
  mutation CreateUserNotifyConfig(
    $input: CreateUserNotifyConfigInput!
    $condition: ModelUserNotifyConfigConditionInput
  ) {
    createUserNotifyConfig(input: $input, condition: $condition) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      config
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserNotifyConfig = /* GraphQL */ `
  mutation UpdateUserNotifyConfig(
    $input: UpdateUserNotifyConfigInput!
    $condition: ModelUserNotifyConfigConditionInput
  ) {
    updateUserNotifyConfig(input: $input, condition: $condition) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      config
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserNotifyConfig = /* GraphQL */ `
  mutation DeleteUserNotifyConfig(
    $input: DeleteUserNotifyConfigInput!
    $condition: ModelUserNotifyConfigConditionInput
  ) {
    deleteUserNotifyConfig(input: $input, condition: $condition) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      config
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createZone = /* GraphQL */ `
  mutation CreateZone(
    $input: CreateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    createZone(input: $input, condition: $condition) {
      id
      schemeId
      label
      type
      parent
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateZone = /* GraphQL */ `
  mutation UpdateZone(
    $input: UpdateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    updateZone(input: $input, condition: $condition) {
      id
      schemeId
      label
      type
      parent
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteZone = /* GraphQL */ `
  mutation DeleteZone(
    $input: DeleteZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    deleteZone(input: $input, condition: $condition) {
      id
      schemeId
      label
      type
      parent
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLiveEvent = /* GraphQL */ `
  mutation CreateLiveEvent(
    $input: CreateLiveEventInput!
    $condition: ModelLiveEventConditionInput
  ) {
    createLiveEvent(input: $input, condition: $condition) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLiveEvent = /* GraphQL */ `
  mutation UpdateLiveEvent(
    $input: UpdateLiveEventInput!
    $condition: ModelLiveEventConditionInput
  ) {
    updateLiveEvent(input: $input, condition: $condition) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLiveEvent = /* GraphQL */ `
  mutation DeleteLiveEvent(
    $input: DeleteLiveEventInput!
    $condition: ModelLiveEventConditionInput
  ) {
    deleteLiveEvent(input: $input, condition: $condition) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createArchiveEvent = /* GraphQL */ `
  mutation CreateArchiveEvent(
    $input: CreateArchiveEventInput!
    $condition: ModelArchiveEventConditionInput
  ) {
    createArchiveEvent(input: $input, condition: $condition) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateArchiveEvent = /* GraphQL */ `
  mutation UpdateArchiveEvent(
    $input: UpdateArchiveEventInput!
    $condition: ModelArchiveEventConditionInput
  ) {
    updateArchiveEvent(input: $input, condition: $condition) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteArchiveEvent = /* GraphQL */ `
  mutation DeleteArchiveEvent(
    $input: DeleteArchiveEventInput!
    $condition: ModelArchiveEventConditionInput
  ) {
    deleteArchiveEvent(input: $input, condition: $condition) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createValidation = /* GraphQL */ `
  mutation CreateValidation(
    $input: CreateValidationInput!
    $condition: ModelValidationConditionInput
  ) {
    createValidation(input: $input, condition: $condition) {
      model
      validation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateValidation = /* GraphQL */ `
  mutation UpdateValidation(
    $input: UpdateValidationInput!
    $condition: ModelValidationConditionInput
  ) {
    updateValidation(input: $input, condition: $condition) {
      model
      validation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteValidation = /* GraphQL */ `
  mutation DeleteValidation(
    $input: DeleteValidationInput!
    $condition: ModelValidationConditionInput
  ) {
    deleteValidation(input: $input, condition: $condition) {
      model
      validation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createModbusConfig = /* GraphQL */ `
  mutation CreateModbusConfig(
    $input: CreateModbusConfigInput!
    $condition: ModelModbusConfigConditionInput
  ) {
    createModbusConfig(input: $input, condition: $condition) {
      deviceType
      modbusTemplate
      statusBits
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateModbusConfig = /* GraphQL */ `
  mutation UpdateModbusConfig(
    $input: UpdateModbusConfigInput!
    $condition: ModelModbusConfigConditionInput
  ) {
    updateModbusConfig(input: $input, condition: $condition) {
      deviceType
      modbusTemplate
      statusBits
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteModbusConfig = /* GraphQL */ `
  mutation DeleteModbusConfig(
    $input: DeleteModbusConfigInput!
    $condition: ModelModbusConfigConditionInput
  ) {
    deleteModbusConfig(input: $input, condition: $condition) {
      deviceType
      modbusTemplate
      statusBits
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      type
      measureName
      allDevices
      devices
      from
      to
      aggregateType
      interval
      relative
      public
      name
      desc
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      type
      measureName
      allDevices
      devices
      from
      to
      aggregateType
      interval
      relative
      public
      name
      desc
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      type
      measureName
      allDevices
      devices
      from
      to
      aggregateType
      interval
      relative
      public
      name
      desc
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCommsTracker = /* GraphQL */ `
  mutation CreateCommsTracker(
    $input: CreateCommsTrackerInput!
    $condition: ModelCommsTrackerConditionInput
  ) {
    createCommsTracker(input: $input, condition: $condition) {
      id
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCommsTracker = /* GraphQL */ `
  mutation UpdateCommsTracker(
    $input: UpdateCommsTrackerInput!
    $condition: ModelCommsTrackerConditionInput
  ) {
    updateCommsTracker(input: $input, condition: $condition) {
      id
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCommsTracker = /* GraphQL */ `
  mutation DeleteCommsTracker(
    $input: DeleteCommsTrackerInput!
    $condition: ModelCommsTrackerConditionInput
  ) {
    deleteCommsTracker(input: $input, condition: $condition) {
      id
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlarmGroupsInput = /* GraphQL */ `
  mutation CreateAlarmGroupsInput(
    $input: CreateAlarmGroupsInputInput!
    $condition: ModelAlarmGroupsInputConditionInput
  ) {
    createAlarmGroupsInput(input: $input, condition: $condition) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlarmGroupsInput = /* GraphQL */ `
  mutation UpdateAlarmGroupsInput(
    $input: UpdateAlarmGroupsInputInput!
    $condition: ModelAlarmGroupsInputConditionInput
  ) {
    updateAlarmGroupsInput(input: $input, condition: $condition) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlarmGroupsInput = /* GraphQL */ `
  mutation DeleteAlarmGroupsInput(
    $input: DeleteAlarmGroupsInputInput!
    $condition: ModelAlarmGroupsInputConditionInput
  ) {
    deleteAlarmGroupsInput(input: $input, condition: $condition) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlarmGroupsOutput = /* GraphQL */ `
  mutation CreateAlarmGroupsOutput(
    $input: CreateAlarmGroupsOutputInput!
    $condition: ModelAlarmGroupsOutputConditionInput
  ) {
    createAlarmGroupsOutput(input: $input, condition: $condition) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlarmGroupsOutput = /* GraphQL */ `
  mutation UpdateAlarmGroupsOutput(
    $input: UpdateAlarmGroupsOutputInput!
    $condition: ModelAlarmGroupsOutputConditionInput
  ) {
    updateAlarmGroupsOutput(input: $input, condition: $condition) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlarmGroupsOutput = /* GraphQL */ `
  mutation DeleteAlarmGroupsOutput(
    $input: DeleteAlarmGroupsOutputInput!
    $condition: ModelAlarmGroupsOutputConditionInput
  ) {
    deleteAlarmGroupsOutput(input: $input, condition: $condition) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
