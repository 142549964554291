//Node Modules
import { useRecoilValueLoadable } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { Loading, Note } from "../helpers";
import { DoughnutChart } from "../report";

//3rd Party Components

//Atoms
import { userSummarySelector } from "../../atoms/dashboard";

//Helpers

//Other

const UserSummaryLoader = () => {
	//Recoil
	const { t } = useTranslation();
	const { state, contents } = useRecoilValueLoadable(userSummarySelector);

	switch (state) {
		case "hasError":
			return (
				<Note
					messageKey={`Error: ${contents.message ? contents.message : contents}`}
					icon="pi-exclamation-circle fontColour-error"
				/>
			);
		case "loading":
			return <Loading />;
		case "hasValue":
			const activeRoles = {
				OWNER: contents.roleCounts.active.OWNER,
				TENANT: contents.roleCounts.active.TENANT,
				INSTALLER: contents.roleCounts.active.INSTALLER,
			};
			const inactiveRoles = {
				OWNER: contents.roleCounts.inactive.OWNER,
				TENANT: contents.roleCounts.inactive.TENANT,
				INSTALLER: contents.roleCounts.inactive.INSTALLER,
			};
			const labels = Object.keys(activeRoles).map((label) => t(`user.role.${label}`));
			return (
				<div className="summaryChart-wrapper">
					<div className="summaryChart-center">
						<div className="flexVert text-center">
							<span>{contents.total.active}</span>
							<span>{contents.total.inactive}</span>
						</div>
					</div>
					<DoughnutChart
						labels={labels}
						chartData={[activeRoles, inactiveRoles]}
						measureName={["Active Count", "Inactive Count"]}
						legendId="userSummaryLegend"
					/>
				</div>
			);
		default:
			return null;
	}
};

export default UserSummaryLoader;
