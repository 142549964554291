//Node Modules
import React, { useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

//Pages

//BinaryForge Components

//3rd Party Components
import { Chart, registerables } from "chart.js";

//Atoms

//Services

//Helpers
import { chartStyleConfig, doughnutChartConfig, htmlLegendPlugin } from "../../config/chart";

//Other

const DoughnutChart = ({ labels, chartData, measureName, legendId, colours = "default" }) => {
	//Hooks
	const { t } = useTranslation();
	const chart = useRef(null);
	Chart.register(...registerables);

	const getUpdatedOptions = useCallback(() => {
		let options = doughnutChartConfig(legendId);
		return options;
	}, [chartData]);

	const createChart = useCallback(() => {
		const ctx = chart.current.getContext("2d");

		return new Chart(ctx, {
			type: "doughnut",
			data: {
				labels: labels,
				datasets: chartData.map((dataset, idx) => {
					return {
						label: measureName[idx],
						data: Object.values(dataset),
						backgroundColor: chartStyleConfig.doughnut.backgroundColours[colours],
						hoverBackgroundColor: chartStyleConfig.doughnut.backgroundColours[colours],
						cutout: chartStyleConfig.doughnut.cutout[idx],
						radius: chartStyleConfig.doughnut.radius[idx],
					};
				}),
			},
			options: getUpdatedOptions(),
			plugins: [htmlLegendPlugin],
		});
	}, [chartData]);

	useEffect(() => {
		let chart;
		if (Object.keys(chartData).length > 0) {
			chart = createChart();
		}

		return () => {
			if (chart) chart.destroy();
		};
	}, [createChart, chartData]);

	return <canvas id="myChart" ref={chart}></canvas>;
};

export default DoughnutChart;
