//Node Modules
import React, { useEffect, useRef, useCallback } from "react";
import { useRecoilValue } from "recoil";

//Pages

//BinaryForge Components

//3rd Party Components
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-luxon";
import zoomPlugin from "chartjs-plugin-zoom";

//Atoms
import { reportParamsAtom } from "../../atoms/report";

//Services

//Helpers
import { chartStyleConfig, historialLineChartConfig } from "../../config/chart";

//Other

const LineChartDate = ({ data, timestamps, measureName }) => {
	const chart = useRef(null);
	Chart.register(...registerables, zoomPlugin);

	const getUpdatedOptions = useCallback(() => {
		let options = historialLineChartConfig(measureName, timestamps);
		return options;
	}, [data]);

	const createChart = useCallback(() => {
		const ctx = chart.current.getContext("2d");

		return new Chart(ctx, {
			type: "line",
			data: {
				datasets: Object.entries(data).map(([k, v], i) => {
					return {
						label: k,
						data: v,
						fill: true,
						borderColor: chartStyleConfig.line[i].borderColour,
						backgroundColor: chartStyleConfig.line[i].backgroundColour,
						tension: 0.25,
						radius: 6,
						pointRadius: 8,
						pointHoverRadius: 10,
					};
				}),
			},
			options: getUpdatedOptions(),
		});
	}, [data, getUpdatedOptions]);

	useEffect(() => {
		// process.env.NODE_ENV === "development" && console.log("Graph Data:", data);
		let lineChart;
		if (Object.keys(data).length > 0) {
			lineChart = createChart();
		}

		return () => {
			if (lineChart) lineChart.destroy();
		};
	}, [createChart, data]);

	return (
		<div className="chartContainer">
			<canvas id="myChart" ref={chart}></canvas>
		</div>
	);
};

export default LineChartDate;
