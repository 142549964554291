//Node Modules
import React, { useEffect, useState, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useRecoilState } from "recoil";
import _ from "lodash";
import { v4 as uuid } from "uuid";

//GraphQL

//BinaryForge Components
import FeatureItem from "./FeatureItem";

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

//Atoms
import { devicesProvisionListAtom } from "../../atoms/devices";
import { toastAtom } from "../../atoms";

//Helpers
import { Username } from "../helpers";
import { exportDate } from "../../helpers/DateUtils";
import { useApiRequest } from "../../helpers/Api";
import { filterApplyTemplate, datetimeRowTemplate, dateFilterTemplate } from "../../config/datatable";
import { inputDeviceOptions, outputDeviceOptions } from "../../config/device";

//Other

const DevicesProvisioningTable = ({ devicesData }) => {
	//Hooks
	const { t } = useTranslation();
	const dt = useRef(null);
	const apiRequest = useApiRequest();

	//Recoil
	const [toast, setToast] = useRecoilState(toastAtom);
	const [devices, setDevices] = useRecoilState(devicesProvisionListAtom);

	// Local State
	const [dtFilters, setDtFilters] = useState(null);
	const [selectedDevice, setSelectedDevice] = useState();

	//On Page Load
	useEffect(() => {
		if (devicesData) {
			setDevices(devicesData);
			initFilters();
		}
	}, [devicesData]);

	//Datatable Functions
	const initFilters = () => {
		setDtFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			id: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			"zone.label": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			inputDevice: { value: null, matchMode: FilterMatchMode.CUSTOM },
			outputDevice: { value: null, matchMode: FilterMatchMode.CUSTOM },
			installerId: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			createdAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
		});
	};

	const exportCSV = (selectionOnly) => {
		dt.current.exportCSV({ selectionOnly });
	};

	const inputRowTemplate = (rowData) => {
		let deviceArray = [];

		if (rowData.inputDevice)
			deviceArray = rowData.inputDevice.map((d) => {
				return <FeatureItem key={uuid()} value={d} type="input" />;
			});

		return <div className="flex gapCol-medium gapRow-small">{deviceArray}</div>;
	};

	const inputFilterItemTemplate = (option) => {
		return <FeatureItem value={option.value} type="input" />;
	};
	const inputFilterSelectedItemTemplate = (option) => {
		if (option !== undefined) return <FeatureItem value={option} type="input" />;
	};

	const inputFilterTemplate = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={inputDeviceOptions}
				itemTemplate={inputFilterItemTemplate}
				selectedItemTemplate={inputFilterSelectedItemTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.form.multiselectPlaceholder")}
			/>
		);
	};

	const deviceFilterFunction = (value, filter) => {
		let include = true;
		if (filter?.length) {
			if (filter.includes(null) && value === null) {
				include = true;
			} else {
				const checkFilter = _.intersection(value, filter);
				if (!checkFilter.length) include = false;
			}
		}
		return include;
	};

	const outputRowTemplate = (rowData) => {
		let deviceArray = [];

		if (rowData.outputDevice)
			deviceArray = rowData.outputDevice.map((d) => {
				return <FeatureItem key={uuid()} value={d} type="output" />;
			});

		return <div className="flex gapCol-medium gapRow-small">{deviceArray}</div>;
	};

	const outputFilterItemTemplate = (option) => {
		return <FeatureItem value={option.value} type="output" />;
	};
	const outputFilterSelectedItemTemplate = (option) => {
		if (option !== undefined) return <FeatureItem value={option} type="output" />;
	};

	const outputFilterTemplate = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={outputDeviceOptions}
				itemTemplate={outputFilterItemTemplate}
				selectedItemTemplate={outputFilterSelectedItemTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.form.multiselectPlaceholder")}
			/>
		);
	};

	const installerRowTemplate = (rowData) => {
		return <Username sub={rowData.installerId} />;
	};

	const confirmRemoveDevice = () => {
		confirmDialog({
			header: t("devices.provisioning.remove.header"),
			message: (
				<>
					<p>
						{
							<Trans
								i18nKey="devices.provisioning.remove.message"
								values={{
									deviceId: selectedDevice.id,
									deviceName: selectedDevice.name,
								}}
								components={{ b: <b /> }}
							/>
						}
					</p>
				</>
			),
			acceptLabel: t("common.action.remove"),
			rejectLabel: t("common.action.cancel"),
			acceptIcon: "pi pi-trash",
			acceptClassName: "error feature",
			rejectClassName: "",
			accept: () => doRemoveDevice(),
			reject: () => {}, //Do Nothing,
		});
	};

	const doRemoveDevice = async () => {
		try {
			await apiRequest("del", `/device/provision/${selectedDevice.id}`, null, null);

			const updatedDeviceList = _.differenceBy(devices, [selectedDevice], "id");
			setDevices(updatedDeviceList);

			setToast({
				...toast,
				severity: "success",
				summary: t("devices.provisioning.remove.toast.successSummary"),
				detail: t("devices.provisioning.remove.toast.successDetail"),
			});
		} catch (err) {
			console.error("Remove Provisioning Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("devices.provisioning.remove.toast.errorSummary"),
				detail: t("devices.provisioning.remove.toast.errorDetail", { err: err }),
			});
		}
	};

	return (
		<>
			<div className="flex jContent-end gap-small">
				<Button onClick={() => exportCSV(false)}>{t("common.table.export")}</Button>
				<Button onClick={() => confirmRemoveDevice()} className="feature error" disabled={!selectedDevice}>
					{t("devices.actions.removeProvisioning")}
				</Button>
			</div>
			<div className="marginTop-large">
				<DataTable
					ref={dt}
					value={devices}
					emptyMessage={t("common.table.noData")}
					selectionMode="single"
					selection={selectedDevice}
					onSelectionChange={(e) => setSelectedDevice(e.value)}
					sortMode="multiple"
					removableSort
					filters={dtFilters}
					filterDisplay="menu"
					autoLayout={true}
					paginator
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
					currentPageReportTemplate={t("common.table.paginatorTemplate")}
					rows={10}
					dataKey="id"
					exportFilename={t("common.table.exportFilename", { filename: "Devices", date: exportDate })}>
					<Column
						field="name"
						header={t("devices.table.name")}
						sortable
						filter
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="id"
						header={t("devices.table.id")}
						sortable
						filter
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="zone.label"
						header={t("devices.table.zone")}
						sortable
						filter
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="inputDevice"
						header={t("devices.table.input")}
						body={inputRowTemplate}
						sortable
						filter
						showFilterMatchModes={false}
						filterElement={inputFilterTemplate}
						filterFunction={deviceFilterFunction}
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="outputDevice"
						header={t("devices.table.output")}
						body={outputRowTemplate}
						sortable
						filter
						showFilterMatchModes={false}
						filterElement={outputFilterTemplate}
						filterFunction={deviceFilterFunction}
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="installerId"
						header={t("devices.table.installer")}
						body={installerRowTemplate}
						sortable
						filter
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="createdAt"
						header={t("common.table.createdAt")}
						body={(rowData) => datetimeRowTemplate(rowData, "createdAt")}
						dataType="date"
						sortable
						filter
						filterApply={filterApplyTemplate}
						filterElement={dateFilterTemplate}
					/>
				</DataTable>
			</div>
		</>
	);
};

export default DevicesProvisioningTable;
