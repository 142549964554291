//Node Modules
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState, useRecoilValue, useRecoilState, useResetRecoilState } from "recoil";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { listSchemes, userSchemeConfigByCognitoUserId } from "../../graphql/queries";

//BinaryForge Components

//3rd Party Components
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

//Atoms
import { userSchemeOptionsAtom, selectedUserSchemeAtom, loggedInUserAtom } from "../../atoms/user";

//Helpers
import { nav } from "../../config/navigation";
import { dialogAtomFamily } from "../../atoms";
import { analyticsParamsAtom } from "../../atoms/dashboard";

//Other

const SchemeSelector = ({ refresh }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	// Recoil State
	const loggedInUser = useRecoilValue(loggedInUserAtom);
	const [schemeOptions, setSchemeOptions] = useRecoilState(userSchemeOptionsAtom);
	const setSelectedScheme = useSetRecoilState(selectedUserSchemeAtom);
	const setShowSchemeSelectDialg = useSetRecoilState(dialogAtomFamily("schemeSelectDialog"));

	// Local State
	const [selectedSchemeValue, setSelectedSchemeValue] = useState(null);

	//On Load
	useEffect(() => {
		if (refresh) {
			updateSchemeOptions();
		}
	}, [refresh]);

	const updateSchemeOptions = async () => {
		if (loggedInUser.role === "ADMINISTRATOR") {
			const {
				data: {
					listSchemes: { items: adminSchemes },
				},
			} = await API.graphql(graphqlOperation(listSchemes));
			const userSchemeOptions = adminSchemes.map((s) => ({
				id: s.id,
				name: s.name,
				role: "ADMINISTRATOR",
			}));
			setSchemeOptions(userSchemeOptions);
		} else {
			const {
				data: {
					userSchemeConfigByCognitoUserId: { items: userSchemes },
				},
			} = await API.graphql(
				graphqlOperation(userSchemeConfigByCognitoUserId, {
					cognitoUserId: loggedInUser.sub,
					filter: { role: { eq: "OWNER" } },
				})
			);
			const userSchemeOptions = userSchemes.map((s) => ({
				id: s.scheme.id,
				name: s.scheme.name,
				role: s.role,
				configId: s.id,
			}));
			setSchemeOptions(userSchemeOptions);
		}
	};

	//Submit the selected scheme
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const schemeData = schemeOptions.find((s) => s.id === selectedSchemeValue);
			setSelectedScheme(schemeData);
			navigate(nav.home);
		} catch (err) {
			console.error(e);
		} finally {
			setShowSchemeSelectDialg(false);
		}
	};

	return (
		schemeOptions && (
			<form onSubmit={handleSubmit}>
				<Dropdown
					value={selectedSchemeValue}
					options={schemeOptions}
					optionLabel="name"
					optionValue="id"
					onChange={(e) => setSelectedSchemeValue(e.value)}
					placeholder={t("schemeSelect.placeholder")}
				/>
				<Button
					type="submit"
					label={t("schemeSelect.submit")}
					className="feature"
					disabled={!selectedSchemeValue}
				/>
			</form>
		)
	);
};

export default SchemeSelector;
