export const device = {
	pageTitle: "{{deviceId}}",
	pageSubtitle: "Device",
	loading: "Fetching Device Data",
	actions: {
		config: "Update Config",
		valveControl: "Switch Valve",
		attributes: "Edit Attributes",
		assignTenant: "Assign Tenants",
		delete: "Delete",
	},
	dialog: {
		toggleRelay: {
			loading: "Sending Relay Toggle Request",
			header: "Confirm Toggle Relay",
			message:
				"Please confirm you would like to toggle the relay for device <b>{{device}}</b> from <b>{{from}}</b> to <b>{{to}}</b>.",
			accept: "Toggle Relay",
		},
		switchValve: {
			loading: "Sending Valve Switch Request",
			header: "Confirm Switch Valve",
			message:
				"Please confirm you would like to switch the valve for device <b>{{device}}</b> from <b>{{from}}</b> to <b>{{to}}</b>.",
			accept: "Switch Valve",
		},
		config: {
			loading: "Requesting Config Update",
			header: "Request Config Update",
			save: "Send Request",
			form: {
				wake: {
					label: "Wake Interval",
					max: "Max value for interval type '$t(device.config.interval.type.{{type}})' is {{value}}",
				},
				keepalive: {
					label: "Keep Alive Interval",
					max: "Max value for interval type '$t(device.config.interval.type.{{type}})' is {{value}}",
				},
				intervalType: {
					label: "Interval Type",
				},
			},
		},
		attributes: {
			loading: "Updating Device Attributes",
			header: "Update Device Attributes",
			save: "Update",
			form: {
				name: {
					label: "Device Name",
					maxLength: "Device Name cannot be longer than {{length}}",
				},
				inputDevice: {
					label: "Input Device",
				},
				outputDevice: {
					label: "Output Device",
				},
			},
			location: "Device Location",
			toast: {
				successSummary: "Device Update Success",
				successDetail: "",
				errorSummary: "Failed to Update Device",
				errorDetail: "{{error}}",
			},
		},
		alarmGroup: {
			header: "Connected $t(device.feature.{{type}}) Alarm Groups",
		},
		tenants: {
			header: "Assigned Tenants",
		},
		delete: {
			header: "Confirm Delete Device",
			message: "Are you sure you want to delete device <b>{{deviceName}}</b>? This cannot be undone.",
			inAlarmWarn: "This device is currently in an alarm state.",
			alarmGroupWarn_one:
				"This device is currently acting as an {{type}} in an Alarm Group. It will be removed from the group on delete.",
			alarmGroupWarn_other:
				"This device is currently acting as an {{type}} in {{count}} Alarm Groups. It will be removed from these groups on delete.",
		},
		deleteDisabled: {
			header: "Delete Disabled",
			message_INPUT: "Cannot delete this device. It is the last input in groups {{groups}}",
			message_OUTPUT: "Cannot delete this device. It is the last output in groups {{groups}}",
		},
	},
	state: {
		header: "Reported State",
		id: "Device Cloud ID",
		location: "Location",
		features: "Connected Features",
		firmware: "Firmware Version",
		hardware: "Hardware Version",
		timestamp: "Device Timestamp",
		relay: "Relay",
		valve: "Valve",
		sensorState: "Sensor State",
		sensorResistance: "Sensor Resistance",
		dcStatus: "DC Power Status",
		dcVoltage: "DC Power Voltage",
		battCharging: "Battery Charging",
		battPercentage: "Battery Percentage",
		alarmGroupsCount_one: "{{count}} Group as $t(device.feature.{{type}})",
		alarmGroupsCount_other: "{{count}} Groups as $t(device.feature.{{type}})",
		tenants: "Tenants",
		tenants_other: "{{count}} Tenants",
		inputDevice: "Input Device",
		outputDevice: "Output Device",
		wifiSsid: "WiFi SSID",
		wifiRssi: "WiFi RSSI",
	},
	config: {
		header: "Config",
		wake: "Wake Interval",
		keep: "Keep Alive Interval",
		batt: "Battery Enabled",
		vdc: "VDC Enabled",
		modbus: "Modbus Enabled",
		interval: {
			wakeInterval: "Wake Interval",
			keepInterval: "Keep Alive Interval",
			type: {
				second: "seconds",
				minute: "minutes",
				hour: "hours",
			},
		},
	},
	modbus: {
		header: "Modbus",
		status: "Status",
		distance: "Distance",
		timestamp: "Timestamp",
		events: "N Events",
	},
	unit: {
		seconds: "seconds",
	},
	sensor: {
		status: {
			on: "On",
			off: "Off",
		},
	},
	relay: {
		on: "On",
		off: "Off",
		open: "Open",
		closed: "Closed",
	},
	dcStatus: {
		true: "Connected",
		false: "Disconnected",
	},
	batteryCharging: {
		true: "Yes",
		false: "No",
	},
	feature: {
		none: "No Connected Devices",
		null: "None",
		input: "Input",
		output: "Output",
		flowstop: "FlowStop",
		flexipad: "Flexi-Pad",
		ranger: "Ranger",
		disabled_one: "Cannot disable $t(device.feature.{{type}}) feature as it is part of {{count}} Alarm Group",
		disabled_other: "Cannot disable $t(device.feature.{{type}}) feature as it is part of {{count}} Alarm Groups",
	},
	pendingUpdate:
		"There is a pending update to <b>{{label}}</b> of value <b>{{value}}</b> requested by {{by}} on <b>{{at}}</b>",
};
