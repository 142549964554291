//Node Modules
import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Auth } from "aws-amplify";

//Pages

//BinaryForge Components
import { AppHeaderGuest } from "../../components/layout";

//3rd Party Components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";

//Atoms
import { toastAtom } from "../../atoms/toast";
import { loggedInUserAtom } from "../../atoms/user";

//Helpers
import { nav } from "../../config/navigation";
import { useLogout } from "../../helpers/Auth";
import { useGetUserSchemeConfig } from "../../helpers/User";

//Other

const Login = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleLogout = useLogout();
	const { handleGetUserConfig, handleGetAdminConfig } = useGetUserSchemeConfig();

	//Recoil Atom States
	const [toast, setToast] = useRecoilState(toastAtom);
	const setLoggedInUser = useSetRecoilState(loggedInUserAtom);

	//React Hook Form
	const defaultValues = { username: "", password: "" };
	const {
		control,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm({
		defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	// Login Form Submit
	const onLogin = async (data) => {
		let toPage = nav.guest.logout;
		try {
			const response = await Auth.signIn(data.username, data.password);

			if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
				setLoggedInUser(data);
				toPage = nav.guest.completeReg;
			} else if (response.challengeName === "SOFTWARE_TOKEN_MFA") {
				setLoggedInUser(data);
				toPage = nav.guest.mfa;
			} else {
				const userPayload = response.signInUserSession.idToken.payload;
				if (userPayload["cognito:groups"]) {
					const isAdmin = userPayload["cognito:groups"].includes("administrator");
					const defaultScheme = userPayload["custom:defaultSchemePortal"];

					const { toUserPage, schemeRole } = isAdmin
						? await handleGetAdminConfig(defaultScheme)
						: await handleGetUserConfig(userPayload["sub"], defaultScheme);

					toPage = toUserPage;

					setLoggedInUser({
						sub: userPayload["sub"],
						username: userPayload["cognito:username"],
						fullname: userPayload["name"],
						role: schemeRole,
					});
				} else {
					throw Error(t("login.error.noGroup"));
				}
			}
		} catch (err) {
			console.error("onSubmit Error:", err);
			let errorMsg = "";
			if (err.message?.includes("PreAuthentication")) {
				errorMsg = t(err.message.substring(36, err.message.length - 1));
			} else {
				errorMsg = err.message;
			}
			await handleLogout();
			setToast({
				...toast,
				severity: "error",
				summary: t("login.toast.loginFail.summary"),
				detail: t("login.toast.loginFail.detail", { error: errorMsg }),
			});
		} finally {
			navigate(toPage);
		}
	};

	return (
		<main className="appMain">
			<div className="appMain-content">
				<AppHeaderGuest />

				<form onSubmit={handleSubmit(onLogin)}>
					<div className="formFieldsWrapper">
						<div className="formField">
							<label htmlFor="username">{t("login.username.label")}</label>
							<Controller
								name="username"
								control={control}
								rules={{ required: t("login.username.required") }}
								render={({ field, fieldState }) => (
									<InputText
										{...field}
										id={field.name}
										autoComplete="username"
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("username")}
						</div>
						<div className="formField">
							<label htmlFor="password">{t("login.password.label")}</label>
							<Controller
								name="password"
								control={control}
								rules={{ required: t("login.password.required") }}
								render={({ field, fieldState }) => (
									<Password
										{...field}
										inputId={field.name}
										feedback={false}
										toggleMask={true}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("password")}
						</div>
					</div>

					<Button
						label={isSubmitting ? t("login.submitting") : t("login.submit")}
						type="submit"
						className="feature marginTop-small"
						disabled={isSubmitting}
					/>
				</form>
				<div className="marginTop-small text-center">
					<Link to={nav.guest.forgotPassword} className="fontSize-small">
						{t("login.forgotPassword")}
					</Link>
				</div>
			</div>
		</main>
	);
};

export default Login;
