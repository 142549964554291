import { DateTime, Duration } from "luxon";

export const now = DateTime.local();
export const today = DateTime.local().startOf("day");
export const weekStart = DateTime.local().startOf("week").minus({ seconds: 1 });
export const weekEnd = DateTime.local().endOf("week");

export const todayStr = DateTime.local().toFormat("EEE d MMM");
export const weekStartStr = DateTime.local().startOf("week").toFormat("EEE d MMM");
export const weekEndStr = DateTime.local().endOf("week").toFormat("EEE d MMM");

export const exportDate = DateTime.local().toFormat("yyyyMMdd_HHmmss");

export const getDate = (date, format = "d/M/yy") => {
	return DateTime.fromFormat(date, format).startOf("day");
};

export const getStartOfDay = (date, format = "d/M/yy HH:mm:ss") => {
	let dt = null;
	if (date instanceof Date) {
		dt = DateTime.fromJSDate(date).setZone("utc").startOf("day");
	} else {
		dt = DateTime.fromFormat(date, format, { zone: "UTC" }).startOf("day");
	}
	return dt;
};

export const getEndOfDay = (date, format = "d/M/yy HH:mm:ss") => {
	let dt = null;
	if (date instanceof Date) {
		dt = DateTime.fromJSDate(date).setZone("utc").endOf("day");
	} else {
		dt = DateTime.fromFormat(date, format, { zone: "UTC" }).endOf("day");
	}
	return dt;
};

export const getDateTime = (date, format = "d/M/yy HH:mm:ss") => {
	let dt = null;
	if (date instanceof Date) {
		dt = DateTime.fromJSDate(date);
	} else {
		dt = DateTime.fromFormat(date, format);
	}
	return dt;
};

export const getUiDate = (date) => {
	let dt = null;
	if (date instanceof Date) {
		dt = date ? DateTime.fromJSDate(date).toFormat("d MMM yyyy") : "No Date Available";
	} else if (typeof date === "number") {
		dt = date ? DateTime.fromMillis(date).toFormat("d MMM yyyy") : "No Date Available";
	} else {
		dt = date ? DateTime.fromISO(date).toFormat("d MMM yyyy") : "No Date Available";
	}

	return dt;
};

export const getUiDateTime = (date, format = "d MMM yyyy, HH:mm") => {
	let dt = null;
	if (date instanceof Date) {
		dt = date ? DateTime.fromJSDate(date).toFormat(format) : "No Date Available";
	} else if (typeof date === "number") {
		const length = date.toString().length;
		dt = date
			? length > 10
				? DateTime.fromMillis(date).toFormat(format)
				: DateTime.fromSeconds(date).toFormat(format)
			: "No Date Available";
	} else {
		dt = date ? DateTime.fromISO(date).toFormat(format) : "No Date Available";
	}

	return dt;
};

export const getUiTime = (time, format = "HH:mm:ss") => {
	let dt = null;
	if (time instanceof Date) {
		dt = DateTime.fromJSDate(time).toFormat("HH:mm");
	} else {
		dt = DateTime.fromFormat(time, format).toFormat("HH:mm");
	}

	return dt;
};

export const formatDateTimeFile = () => {
	return DateTime.local().toFormat("yyyyMMddHHmm");
};

export const createdAtRowTemplate = (rowData) => {
	return <span>{getUiDateTime(rowData.createdAt)}</span>;
};

export const updatedAtRowTemplate = (rowData) => {
	return <span>{getUiDateTime(rowData.updatedAt)}</span>;
};

export const getIntervalHuman = (interval) => {
	let humanInterval;

	if (interval === -1) {
		humanInterval = "All";
	} else if (interval === "0") {
		humanInterval = "Now";
	} else {
		humanInterval = Duration.fromObject({ seconds: interval })
			.rescale()
			.toHuman({ unitDisplay: "long", listStyle: "long" });
	}

	return humanInterval;
};

export const getDurationSecs = (period, periodType) => {
	let secs = 0;

	switch (periodType) {
		case "min":
			secs = period * 60;
			break;
		case "hour":
			secs = period * 60 * 60;
			break;
		case "day":
			secs = period * 60 * 60 * 24;
			break;
		default:
			secs = period;
	}

	return secs;
};
