//Node Modules
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

//Pages

//BinaryForge Components
import { Note } from "../../helpers";

//3rd Party Components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { classNames } from "primereact/utils";

//Atoms
import { toastAtom } from "../../../atoms/toast";
import { loggedInUserAtom, userSchemeOptionsAtom } from "../../../atoms/user";
import { dialogAtomFamily } from "../../../atoms/dialog";

//Helpers
import { useApiRequest } from "../../../helpers/Api";
import { emailValidation, fullnameValidation } from "../../../config/user";
import { useGetCurrentCognitoUser } from "../../../helpers/Auth";

//Other
const appElement = document.getElementsByClassName("appWrapper")[0];

const UserProfileAttributes = ({ currentUser }) => {
	const { t } = useTranslation();
	const apiRequest = useApiRequest();
	const getCognitoUser = useGetCurrentCognitoUser();
	const {
		cognitoUser: { attributes },
	} = currentUser;

	//Recoil State
	const setLoading = useSetRecoilState(dialogAtomFamily("loadingDialog"));
	const [toast, setToast] = useRecoilState(toastAtom);
	const setLoggedInUser = useSetRecoilState(loggedInUserAtom);
	// const users = useRecoilValue(usersAtom);
	// const admins = useRecoilValue(adminUsersAtom);
	const defaultSchemeOptions = useRecoilValue(userSchemeOptionsAtom);

	//Local State
	const [emailWarn, setEmailWarn] = useState(false);
	const [emailCode, setEmailCode] = useState("");

	useEffect(() => {
		if (!attributes.email_verified) {
			setEmailWarn(true);
		}
	}, [attributes]);

	//React Hook Form
	const defaultValues = {
		fullname: attributes.name,
		email: attributes.email,
		defaultScheme: attributes["custom:defaultSchemePortal"],
	};

	const {
		control,
		setValue,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm({
		defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	// Update Attributes
	const handleUpdateAttributes = async (data) => {
		try {
			let { cognitoUser } = currentUser;
			if (!cognitoUser) cognitoUser = await getCognitoUser();
			setLoading({ visible: true, message: t("profile.attributes.loading") });

			if (data.defaultScheme) {
				await Auth.updateUserAttributes(cognitoUser, {
					name: data.fullname,
					"custom:defaultSchemePortal": data.defaultScheme,
				});
			} else {
				await Auth.updateUserAttributes(cognitoUser, {
					name: data.fullname,
				});
				await Auth.deleteUserAttributes(cognitoUser, ["custom:defaultSchemePortal"]);
			}

			if (data.email !== attributes.email) {
				await validateUniqueEmail(data.email);
				setEmailWarn(true);
				await Auth.updateUserAttributes(cognitoUser, { email: data.email });
				setToast({
					...toast,
					severity: "info",
					summary: t("profile.attributes.toast.successSummaryEmail"),
					detail: t("profile.attributes.toast.successDetailEmail"),
				});
			} else {
				setToast({
					...toast,
					severity: "success",
					summary: t("profile.attributes.toast.successSummary"),
					detail: t("profile.attributes.toast.successDetail"),
				});
			}

			setLoggedInUser((prevState) => ({ ...prevState, fullname: data.fullname }));
		} catch (err) {
			console.error("Update User Attr Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("profile.attributes.toast.errorSummary"),
				detail: t("profile.attributes.toast.errorDetail", { error: err.message }),
			});
		} finally {
			setLoading({ visible: false });
		}
	};

	const validateUniqueEmail = async (newEmail) => {
		const existingUsersAndAdmins = await apiRequest("get", "/user?allScheme=true", null, null);
		const existingEmails = existingUsersAndAdmins.map((u) => u.email);
		if (existingEmails.includes(newEmail)) throw Error(t("user.error.emailExists", { user: { email: newEmail } }));
	};

	const handleVerifyEmail = async () => {
		try {
			await Auth.verifyCurrentUserAttributeSubmit("email", emailCode);
			await Auth.currentAuthenticatedUser({ bypassCache: true });

			const session = await Auth.currentSession();
			setEmailWarn(false);
			setValue("email", session.idToken.payload.email);

			setToast({
				...toast,
				severity: "success",
				summary: t("profile.attributes.email.toast.successSummary"),
				detail: t("profile.attributes.email.toast.successDetail"),
			});
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t("profile.attributes.email.toast.errorSummary"),
				detail: t("profile.attributes.email.toast.errorDetail", { error: err.message }),
			});
		}
	};

	return (
		<section>
			<div className="card">
				<Tooltip target=".customTooltip" />

				<header>
					<h3>{t("profile.attributes.title")}</h3>
				</header>

				<div className="flex gap-medium marginBottom-small">
					<span className="label">{t("profile.attributes.role.label")}:</span>
					<span>{t(`user.role.${currentUser.role}`)}</span>
				</div>

				<form onSubmit={handleSubmit(handleUpdateAttributes)}>
					<div className="formFieldsWrapper grid columns-2 gapCol-large gapRow-medium">
						<div className="formField">
							<label htmlFor="fullname">{t("profile.attributes.fullname.label")}</label>
							<Controller
								name="fullname"
								control={control}
								rules={{
									required: t("common.form.required"),
									pattern: {
										value: fullnameValidation,
										message: t("user.validation.fullname.pattern"),
									},
								}}
								render={({ field, fieldState }) => (
									<InputText
										{...field}
										id={field.name}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("fullname")}
						</div>

						<div>
							<div className="formField">
								<label htmlFor="email">{t("profile.attributes.email.label")}</label>
								<Controller
									name="email"
									control={control}
									rules={{
										required: t("common.form.required"),
										pattern: {
											value: emailValidation,
											message: t("profile.attributes.email.pattern"),
										},
									}}
									render={({ field, fieldState }) => (
										<InputText
											{...field}
											id={field.name}
											className={classNames({ "p-error": fieldState.error })}
											readOnly={emailWarn}
										/>
									)}
								/>
								{getFormErrorMessage("email")}
							</div>

							{emailWarn && (
								<div className="flexVert gap-small marginTop-small">
									<div className="flex gap-medium">
										<input
											type="text"
											value={emailCode}
											placeholder={t("profile.attributes.email.verifyPlaceholder")}
											onChange={(e) => setEmailCode(e.target.value)}
										/>
										<button type="button" onClick={handleVerifyEmail}>
											{t("profile.attributes.email.verifySubmit")}
										</button>
									</div>
									<Note
										messageKey={t("profile.attributes.email.verifyWarning")}
										messageStyle="fontSize-small"
										icon="pi-exclamation-triangle fontColour-warn"
									/>
									<Note
										messageKey={t("profile.attributes.email.verifyInfo")}
										messageStyle="fontSize-small"
										icon="pi-info-circle fontColour-info"
									/>
								</div>
							)}
						</div>

						{defaultSchemeOptions && (
							<div className="formField">
								<div className="flex gap-small">
									<label htmlFor="defaultScheme">{t("profile.attributes.defaultScheme.label")}</label>
									<i
										className="pi pi-info-circle fontColour-info customTooltip"
										data-pr-tooltip={t("profile.attributes.defaultScheme.tooltip")}
									/>
								</div>
								<div className="grid columns-2-FreeAuto gapCol-small">
									<Controller
										name="defaultScheme"
										control={control}
										rules={{ required: false }}
										render={({ field: { ref, ...newField }, fieldState }) => (
											<Dropdown
												appendTo={appElement}
												{...newField}
												inputRef={ref}
												options={defaultSchemeOptions}
												optionValue="id"
												optionLabel="name"
												placeholder={t("profile.attributes.defaultScheme.placeholder")}
												onChange={(e) => newField.onChange(e.value)}
												className={classNames({ "p-error": fieldState.error })}
											/>
										)}
									/>
									<Button
										type="button"
										icon="icon-erase"
										className="error"
										onClick={() => setValue("defaultScheme", null)}
										tooltip={t("profile.attributes.defaultScheme.clear")}
									/>
								</div>
								{getFormErrorMessage("defaultScheme")}
							</div>
						)}
					</div>
					<Button
						type="submit"
						label={t("profile.attributes.submit")}
						className="feature aSelf-start"
						disabled={isSubmitting}
					/>
				</form>
			</div>
		</section>
	);
};

export default UserProfileAttributes;
