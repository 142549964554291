//Node Modules
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useForm, Controller } from "react-hook-form";
import { Storage } from "aws-amplify";

//BinaryForge Components
import { BfDialog } from "../../helpers";

//3rd Party Components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";

//Atoms
import { dialogAtomFamily, toastAtom } from "../../../atoms";
import { validationAtomFamily } from "../../../atoms/validation";

//Helpers
import { otaTypeOptions, targetTypeOptions } from "../../../config/ota";
import { useApiRequest } from "../../../helpers/Api";

//Other
import amplifyconfig from "../../../aws-exports";

const OtaJobCreateDialog = () => {
	//Hooks
	const { t } = useTranslation();
	const apiRequest = useApiRequest();

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("otaJobCreateDialog"));
	const [toast, setToast] = useRecoilState(toastAtom);
	const otaValidation = useRecoilValue(validationAtomFamily("ota"));

	//Local State
	const [fileOptions, setFileOptions] = useState([]);
	const [targetOptions, setTargetOptions] = useState([]);

	//React Hook Form
	const defaultValues = {
		type: null,
		name: "",
		desc: "",
		// version: "",
		downgradable: false,
		file: fileOptions[0],
		targetType: null,
		target: null,
	};

	// Form Init
	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
		watch,
		setValue,
	} = useForm({ defaultValues: defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	//Form Vars
	const otaType = watch("type");
	const descLength = watch("desc").length;
	const otaTargetType = watch("targetType");

	const getFileOptions = async () => {
		const { results } = await Storage.list(`ota`, { pageSize: 100 });
		const files = results.map((result) => ({ name: result.key.substring(4), key: result.key }));
		setFileOptions(files);
	};

	const getTargetOptions = async () => {
		setValue("target", null);
		const targets = await apiRequest(
			"get",
			`/ota/target?type=${otaTargetType}`,
			null,
			t("admin.ota.job.create.target.loading")
		);

		setTargetOptions(targets);
	};

	useEffect(() => {
		getFileOptions();
	}, []);

	useEffect(() => {
		if (otaTargetType) getTargetOptions();
	}, [otaTargetType]);

	useEffect(() => {
		if (otaType === "PUBLIC") {
			setValue("targetType", "GROUP", { isTouched: true, isDirty: true });
		}
	}, [otaType]);

	useEffect(() => {
		if (targetOptions.length > 0 && otaTargetType === "GROUP" && otaType === "PUBLIC") {
			const publicTarget = targetOptions.find((t) => t.label === "public").arn;
			setValue("target", [publicTarget], { isTouched: true, isDirty: true });
		}
	}, [targetOptions]);

	const handleClose = () => {
		setShow(false);
		reset();
	};

	const handleCreateOta = async (data) => {
		const version = data.file.name.split("_")[1].slice(1, -4);

		try {
			await apiRequest(
				"post",
				"/ota",
				{ ...data, version, bucket: amplifyconfig["aws_user_files_s3_bucket"] },
				t("admin.ota.job.create.loading")
			);

			handleClose();

			setToast({
				...toast,
				life: 6000,
				severity: "success",
				summary: t("admin.ota.job.create.toast.successSummary"),
				detail: t("admin.ota.job.create.toast.successDetail"),
			});
		} catch (err) {
			console.error(err);
			setToast({
				...toast,
				life: 6000,
				severity: "error",
				summary: t("admin.ota.job.create.toast.errorSummary"),
				detail: t("admin.ota.job.create.toast.errorSummary", { error: err.message }),
			});
		}
	};

	const footer = (
		<>
			<Button label={t("common.action.cancel")} onClick={() => handleClose(false)} />
			<Button
				label={t("common.action.create")}
				icon="pi pi-save"
				className="feature"
				// disabled={!isValid}
				onClick={() => handleSubmit(handleCreateOta)()}
			/>
		</>
	);

	return (
		<BfDialog id="otaJobCreateDialog" header={t("admin.ota.job.create.header")} footer={footer}>
			<form>
				<div className="grid columns-2 gap-medium">
					<div className="formField">
						<label htmlFor="type">{t("admin.ota.job.create.type.label")}</label>
						<Controller
							name="type"
							control={control}
							rules={{
								required: t("common.form.required"),
							}}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<Dropdown
									{...myField}
									inputRef={ref}
									options={otaTypeOptions}
									onChange={(e) => myField.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("type")}
					</div>

					<div className="formField">
						<label htmlFor="file">{t("admin.ota.job.create.file.label")}</label>
						<Controller
							name="file"
							control={control}
							rules={{
								required: t("common.form.required"),
							}}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<Dropdown
									{...myField}
									inputRef={ref}
									options={fileOptions}
									optionLabel="name"
									// optionValue="key"
									onChange={(e) => myField.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("file")}
					</div>

					<div className="formField span-2">
						<label htmlFor="name">{t("admin.ota.job.create.name.label")}</label>
						<Controller
							name="name"
							control={control}
							rules={{
								required: t("common.form.required"),
								pattern: {
									value: RegExp(otaValidation.name.pattern),
									message: t("admin.ota.job.create.name.pattern"),
								},
							}}
							render={({ field, fieldState }) => (
								<InputText {...field} className={classNames({ "p-error": fieldState.error })} />
							)}
						/>
						{getFormErrorMessage("name")}
					</div>

					<div className="formField span-2">
						<div className="flex jContent-spaceBetween">
							<label htmlFor="comment">{t("admin.ota.job.create.desc.label")}</label>
							<span className="fontColour-light fontSize-small">
								{descLength}/{otaValidation.desc.maxLength}
							</span>
						</div>
						<Controller
							name="desc"
							control={control}
							rules={{
								required: t("common.form.required"),
								maxLength: {
									value: otaValidation.desc.maxLength,
									message: t("admin.ota.job.create.desc.maxLength", {
										length: otaValidation.desc.maxLength,
									}),
								},
								pattern: {
									value: RegExp(otaValidation.desc.pattern),
									message: t("admin.ota.job.create.desc.pattern"),
								},
							}}
							render={({ field, fieldState }) => (
								<InputTextarea
									{...field}
									rows={3}
									autoResize={true}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("desc")}
					</div>

					{/* <div className="formField">
						<label htmlFor="version">{t("admin.ota.job.create.version.label")}</label>
						<Controller
							name="version"
							control={control}
							rules={{
								required: t("common.form.required"),
								pattern: {
									value: RegExp(otaValidation.version.pattern),
									message: t("admin.ota.job.create.version.pattern"),
								},
							}}
							render={({ field, fieldState }) => (
								<InputText {...field} className={classNames({ "p-error": fieldState.error })} />
							)}
						/>
						{getFormErrorMessage("version")}
					</div> */}

					<div className="formField">
						<label htmlFor="targetType">{t("admin.ota.job.create.targetType.label")}</label>
						<Controller
							name="targetType"
							control={control}
							rules={{
								required: t("common.form.required"),
							}}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<Dropdown
									{...myField}
									inputRef={ref}
									options={targetTypeOptions}
									disabled={otaType === "PUBLIC"}
									onChange={(e) => myField.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("targetType")}
					</div>
					<div className="formField">
						<label htmlFor="target">{t("admin.ota.job.create.target.label")}</label>
						<Controller
							name="target"
							control={control}
							rules={{
								required: t("common.form.required"),
							}}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<MultiSelect
									{...myField}
									inputRef={ref}
									options={targetOptions}
									optionLabel="label"
									optionValue="arn"
									selectionLimit={otaTargetType === "GROUP" ? 1 : 99999}
									filter={true}
									disabled={!watch("targetType") || otaType === "PUBLIC"}
									onChange={(e) => myField.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("target")}
					</div>
				</div>

				<div className="formField">
					<label htmlFor="downgradable">{t("admin.ota.job.create.downgradable.label")}</label>
					<Controller
						name="downgradable"
						control={control}
						render={({ field, fieldState }) => (
							<InputSwitch
								{...field}
								checked={field.value}
								onChange={(e) => field.onChange(e.value)}
								className={classNames({ "p-error": fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("downgradable")}
				</div>
			</form>
		</BfDialog>
	);
};

export default OtaJobCreateDialog;
