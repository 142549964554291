export const nav = {
	back: "Back",
	backLong: "Back to Previous Page",
	home: "Dashboard",
	alarms: "Alarms ({{count}})",
	alarmGroups: "Alarm Groups",
	events: "Events",
	devices: "Devices",
	groups: "Groups",
	reports: "Analytics",
	zones: "Estate",
	users: "Users",
	profile: "Profile",
	adminUsers: "Admin Management",
	adminScheme: "Scheme Management",
	adminOta: "OTA",
	switchScheme: "Switch Scheme",
	logout: "Logout",
	theme: "Toggle Theme",
};
