//Node Modules
import React from "react";

//BinaryForgeComponenets

//3rd Party Components
import { ProgressSpinner } from "primereact/progressspinner";

//Atoms

//Helpers

//Other

const Loading = () => {
	return (
		<div className="flexVert aItems-center loading">
			<ProgressSpinner strokeWidth="4" style={{ width: "2rem", height: "2rem" }} />
			<p className="fontColour-light fontSize-small marginTop-xsmall">
				Loading
				<span className="loadingDot">.</span>
				<span className="loadingDot">.</span>
				<span className="loadingDot">.</span>
			</p>
		</div>
	);
};

export default Loading;
