import { API } from "aws-amplify";
import { atom, selector } from "recoil";

const restApi = "andelRestApi";

export const adminUsersAtom = atom({
	key: "adminUsersAtom",
	default: null,
});

export const adminUsersSelector = selector({
	key: "adminUsersSelector",
	get: async () => {
		const users = await API.get(restApi, "/user", {
			headers: {
				"X-Scheme-Id": "administrator",
			},
		});
		return users;
	},
	set: ({ set }, newValue) => {
		set(adminUsersAtom, newValue);
	},
});
