//Node Modules
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { listZonesBasic, getSchemeZoneConfig, getZoneBasic } from "../../../graphql/queries-custom";

//BinaryForge Components

//3rd Party Components
import { Accordion, AccordionTab } from "primereact/accordion";

//Atoms
import { zoneNodesAtom } from "../../../atoms/zones";
import { selectedUserSchemeAtom } from "../../../atoms/user";

//Helpers
import { nav } from "../../../config/navigation";
import { zoneConfig } from "../../../config/zones";
import { findTreeNodeByKey, getAllChildZones } from "../../../helpers/Zones";

const TenantDevices = ({ tenantId }) => {
	//Hooks
	const { t } = useTranslation();

	//Recoil
	const selectedUserScheme = useRecoilValue(selectedUserSchemeAtom);
	const [nodes, setNodes] = useRecoilState(zoneNodesAtom);

	//Local State
	const [tenantZonesDevices, setTenantZoneDevices] = useState([]);

	useEffect(() => {
		const getTenantDevices = async () => {
			const tenantZoneData = [];
			let nodeTree = nodes;
			if (nodes.length <= 0) {
				const {
					data: {
						getScheme: { zoneConfig },
					},
				} = await API.graphql(graphqlOperation(getSchemeZoneConfig, { id: selectedUserScheme.id }));
				setNodes(JSON.parse(zoneConfig));

				nodeTree = JSON.parse(zoneConfig);
			}

			const {
				data: {
					zoneBySchemeId: { items: tenantZones },
				},
			} = await API.graphql(
				graphqlOperation(listZonesBasic, {
					schemeId: selectedUserScheme.id,
					filter: { tenant: { contains: tenantId } },
				})
			);

			for await (const tenantZone of tenantZones) {
				const treeNode = await findTreeNodeByKey(nodeTree, tenantZone.id);
				const childZones = [];
				await getAllChildZones(treeNode, childZones);

				const zoneDeviceArray = [];
				for await (const zone of childZones) {
					const {
						data: { getZone: zoneResp },
					} = await API.graphql(graphqlOperation(getZoneBasic, { id: zone }));
					const zoneDevices = zoneResp.devices?.items;
					if (zoneDevices.length)
						zoneDeviceArray.push({
							zoneName: zoneResp.label,
							zoneType: zoneResp.type,
							zoneDevices: zoneDevices,
						});
				}

				tenantZoneData.push({
					zone: tenantZone,
					devices: zoneDeviceArray,
				});
			}

			setTenantZoneDevices(tenantZoneData);
		};

		if (tenantId) getTenantDevices();
	}, [tenantId]);

	return (
		tenantZonesDevices.length > 0 && (
			<div className="card">
				<header>
					<h3>{t("user.details.tenant.zones.header")}</h3>
				</header>

				<Accordion activeIndex={0} multiple className="marginTop-medium">
					{tenantZonesDevices.map((tenantZone) => (
						<AccordionTab
							key={`accord-${tenantZone.id}`}
							header={
								<div className="flex gapCol-xsmall">
									<i className={`${zoneConfig[tenantZone.zone.type].icon}`} />
									<span>{tenantZone.zone.label}</span>
								</div>
							}>
							<div className="flexVert gapRow-xsmall">
								{tenantZone?.zone?.devices?.items?.map((device) => (
									<Link key={device.id} to={`${nav.device.base}/${device.id}`}>
										{device.name}
									</Link>
								))}
							</div>

							{tenantZone?.devices?.length > 0 && (
								<div className="marginTop-medium">
									<h3 className="marginBottom-small withDivider">
										{t("user.details.tenant.zones.childHeader")}
									</h3>
									<div className="flexVert gapRow-xsmall">
										{tenantZone?.devices?.map((childZone) => (
											<div key={childZone.zoneName} className="flexVert">
												<span className="flex aItems-center gap-xsmall fontWeight-bold">
													<i className={`${zoneConfig[childZone.zoneType].icon}`} />
													{childZone.zoneName}
												</span>
												{childZone.zoneDevices.map((device) => (
													<Link
														key={`${childZone.id}${device.id}`}
														to={`${nav.device.base}/${device.id}`}>
														{device.name}
													</Link>
												))}
											</div>
										))}
									</div>
								</div>
							)}
						</AccordionTab>
					))}
				</Accordion>
			</div>
		)
	);
};

export default TenantDevices;
