//Node Modules
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState, useRecoilValueLoadable } from "recoil";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { userDashboardConfigBySchemeId } from "../../graphql/queries";
import { createUserDashboardConfig, updateUserDashboardConfig } from "../../graphql/mutations";

//BinaryForge Components
import { Field } from "../";
import { BfDialog, Note, PillItem } from "../helpers";

//3rd Party Components
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

//Atoms
import { dialogAtomFamily, loggedInUserAtom } from "../../atoms";
import { analyticsListSelector, analyticsParamsAtom } from "../../atoms/dashboard";
import { selectedUserSchemeAtom } from "../../atoms/user";

//Helpers
import { getIntervalHuman, getUiDateTime } from "../../helpers/DateUtils";

const AnalyticsSelector = () => {
	const { t } = useTranslation();

	//Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("analyticsSelectorDialog"));
	const { state, contents } = useRecoilValueLoadable(analyticsListSelector);
	const setAnalyticsParams = useSetRecoilState(analyticsParamsAtom);
	const selectedScheme = useRecoilValue(selectedUserSchemeAtom);
	const loggedInUser = useRecoilValue(loggedInUserAtom);

	//Local State
	const [selectedReport, setSelectedReport] = useState();

	const getTimestamp = (timeString) => {
		if (timeString) {
			if (timeString.startsWith("from")) {
				const iso = timeString.split("'")[1];
				return getUiDateTime(iso);
			} else {
				const seconds = timeString.substring(timeString.indexOf("(") + 1, timeString.lastIndexOf("s"));
				return getIntervalHuman(seconds);
			}
		}
	};

	const setAnalyticsDefault = async () => {
		setAnalyticsParams({ ...selectedReport, devices: JSON.parse(selectedReport.devices), generate: true });

		const {
			data: {
				userDashboardConfigBySchemeId: { items: userDashboardConfig },
			},
		} = await API.graphql(
			graphqlOperation(userDashboardConfigBySchemeId, {
				cognitoUserId: { eq: loggedInUser.sub },
				schemeId: selectedScheme.id,
			})
		);

		const { id, createdBy, createdAt, updatedAt, __typename, ...analytics } = selectedReport;

		if (userDashboardConfig.length > 0) {
			await API.graphql(
				graphqlOperation(updateUserDashboardConfig, {
					input: {
						id: userDashboardConfig[0].id,
						dashboardAnalytics: JSON.stringify({
							...analytics,
							devices: JSON.parse(selectedReport.devices),
							generate: true,
						}),
					},
				})
			);
		} else {
			await API.graphql(
				graphqlOperation(createUserDashboardConfig, {
					input: {
						cognitoUserId: loggedInUser.sub,
						schemeId: selectedScheme.id,
						dashboardAnalytics: JSON.stringify({
							...analytics,
							devices: JSON.parse(selectedReport.devices),
							generate: true,
						}),
					},
				})
			);
		}

		setShow(false);
	};

	// Footer
	const footer = (
		<>
			<Button label={t("common.action.cancel")} onClick={() => setShow(false)} />
			<Button
				label={t("dashboard.analytics.default.set")}
				className="feature"
				onClick={() => setAnalyticsDefault()}
			/>
		</>
	);

	const DropdownSelector = () => {
		switch (state) {
			case "hasError":
				console.error(contents);
				return (
					<Note
						messageKey={`Error: ${contents.message ? contents.message : contents}`}
						icon="pi-exclamation-circle fontColour-error"
					/>
				);
			case "loading":
				return <>Loading...</>;
			case "hasValue":
				return (
					<Dropdown
						value={selectedReport}
						onChange={(e) => setSelectedReport(e.value)}
						options={contents}
						optionLabel="name"
						placeholder="Select a Report"
					/>
				);
			default:
				return null;
		}
	};

	return (
		<BfDialog id="analyticsSelectorDialog" header={t("dashboard.analytics.default.header")} footer={footer}>
			<DropdownSelector />

			{selectedReport && (
				<section className="marginBlock-medium">
					<header className="withDivider">
						<h3>Report Details</h3>
					</header>

					<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall aItems-center">
						<Field label={t("report.preset.table.desc")} response={selectedReport?.desc} />
						<Field
							label={t("report.form.measure.label")}
							response={t(`report.measure.${selectedReport?.measureName}`)}
						/>
						<Field
							label={t("report.form.devices.label")}
							labelClass="aSelf-start"
							response={
								selectedReport.allDevices ? (
									<PillItem key="all" type="text" value="All Devices" wrapperStyle="blue1" />
								) : (
									<div className="flex gap-xsmall">
										{JSON.parse(selectedReport?.devices).map((d) => (
											<PillItem key={d.id} type="text" value={d.name} wrapperStyle="blue1" />
										))}
									</div>
								)
							}
						/>
						<Field
							label={t("report.form.aggregate.label")}
							response={t(`report.aggregate.${selectedReport?.aggregateType}`)}
						/>

						<Field
							label={t("report.form.interval.label")}
							response={getIntervalHuman(selectedReport?.interval)}
						/>

						<Field label={t("report.form.from.label")} response={getTimestamp(selectedReport?.from)} />

						<Field label={t("report.form.to.label")} response={getTimestamp(selectedReport?.to)} />
						<Field
							label={t("report.preset.table.relative")}
							response={
								<PillItem
									type="text"
									value={t(`common.boolean.${selectedReport?.relative ? "yes" : "no"}`)}
									wrapperStyle={selectedReport?.relative ? "success" : "error"}
								/>
							}
						/>
						<Field
							label={t("report.preset.table.public")}
							response={
								<PillItem
									type="text"
									value={t(`common.boolean.${selectedReport?.public ? "yes" : "no"}`)}
									wrapperStyle={selectedReport?.public ? "success" : "error"}
								/>
							}
						/>
					</div>
				</section>
			)}
		</BfDialog>
	);
};

export default AnalyticsSelector;
