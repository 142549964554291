import { useTranslation } from "react-i18next";
import { PillItem } from "../../helpers";
import { getUiDateTime } from "../../../helpers/DateUtils";
import Field from "../../Field";

const wrapperStyle = {
	QUEUED: "blue1",
	IN_PROGRESS: "blue3",
	SUCCEEDED: "green",
	FAILED: "error",
	TIMED_OUT: "blue2",
	REJECTED: "blue2",
	REMOVED: "warn",
	CANCELED: "blue4",
};

const ExectuionSummary = ({ data }) => {
	//Hooks
	const { t } = useTranslation();

	return (
		<div className="flex aItems-center jContent-spaceBetween listSeparator paddingTop-small">
			<div className="flexVert gapRow-xsmall">
				<span className="fontWeight-bold">{data.thingArn.split("/").pop()}</span>
				<PillItem
					value={t(`admin.ota.job.details.execution.status.${data.jobExecutionSummary.status}`)}
					wrapperStyle={wrapperStyle[data.jobExecutionSummary.status]}
					type="text"
				/>
			</div>
			<div className="flexVert fontSize-small">
				<div className="flex gap-xsmall">
					<Field
						label={t("admin.ota.job.details.execution.queuedAt")}
						response={getUiDateTime(data.jobExecutionSummary.queuedAt)}
					/>
				</div>
				<div className="flex gap-xsmall">
					<Field
						label={t("common.updatedAt")}
						response={getUiDateTime(data.jobExecutionSummary.lastUpdatedAt)}
					/>
				</div>
			</div>
			{}
		</div>
	);
};

export default ExectuionSummary;
