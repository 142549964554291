//Node Modules
import { useRef, useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import { Trans, useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { ReportResultTable, ReportResultsChart } from "./";
import { DatatableSkeleton } from "../../helpers/skeleton";
import { Note } from "../../helpers";

//3rd Party Components
import { Button } from "primereact/button";
import { ToggleButton } from "primereact/togglebutton";

//Atoms
import { reportInstantParamsSelector } from "../../../atoms/report";

//Helpers

//Other

const ReportResultsLoader = ({ preset }) => {
	const tableRef = useRef();

	//Local State
	const [viewType, setViewType] = useState(true);

	//Recoil
	const { t } = useTranslation();
	const { state, contents } = useRecoilValueLoadable(reportInstantParamsSelector);

	const Content = () => {
		switch (state) {
			case "hasError":
				return (
					<Note
						messageKey={`Error: ${contents.message ? contents.message : contents}`}
						icon="pi-exclamation-circle fontColour-error"
					/>
				);
			case "loading":
				return (
					<DatatableSkeleton columns={[t("report.table.device"), "measure", t("report.table.timestamp")]} />
				);
			case "hasValue":
				if (contents.errors) {
					const key = preset ? "report.preset.dataExceeded" : "report.table.dataExceeded";
					return (
						<Note
							messageKey={
								<Trans i18nKey={key} components={{ ul: <ul />, li: <li /> }} context="INSTANT" />
							}
							icon="pi-exclamation-triangle fontColour-warn"
						/>
					);
				} else {
					return viewType ? (
						<ReportResultTable reportResultsData={contents.table} />
					) : (
						<ReportResultsChart reportResultsData={contents.chart} />
					);
				}
			default:
				return null;
		}
	};

	return (
		<section className="marginTop-large">
			<div className="flex jContent-spaceBetween">
				<header>
					<h3>Report Results</h3>
				</header>
				<div className="flex gapCol-small">
					<Button onClick={() => tableRef.current.exportCSV(false)} visible={viewType && !contents.errors}>
						{t("common.table.export")}
					</Button>
					<ToggleButton
						onLabel={t("report.view.chart")}
						offLabel={t("report.view.table")}
						onIcon="pi pi-chart-line"
						offIcon="pi pi-table"
						checked={viewType}
						onChange={(e) => setViewType(e.value)}
						style={{ display: contents.errors ? "none" : "inline-flex" }}
					/>
				</div>
			</div>
			<Content />
		</section>
	);
};

export default ReportResultsLoader;
