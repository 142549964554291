//Node Modules
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DateTime } from "luxon";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { listDevicesTable } from "../../../graphql/queries-custom";

//BinaryForge Components
import { ReportResultsLoader } from "./";

//3rd Party Components
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { classNames } from "primereact/utils";

//Atoms
import { reportInstantParamsAtom, reportTypeAtom } from "../../../atoms/report";
import { devicesListAtom } from "../../../atoms/devices";
import { selectedUserSchemeAtom } from "../../../atoms/user";
import { dialogAtomFamily } from "../../../atoms";

//Helpers
import { now } from "../../../helpers/DateUtils";

//Other
import { measureOptionsInstant } from "../../../config/report";
const appElement = document.getElementsByClassName("app")[0];

const ReportForm = () => {
	//Hooks
	const { t } = useTranslation();

	//Recoil State
	const [reportParams, setReportParams] = useRecoilState(reportInstantParamsAtom);
	const [deviceOptions, setDeviceOptions] = useRecoilState(devicesListAtom);
	const { id: schemeId } = useRecoilValue(selectedUserSchemeAtom);
	const setSaveReportDialogShow = useSetRecoilState(dialogAtomFamily("reportSaveDialog"));
	const setReportType = useSetRecoilState(reportTypeAtom);

	//On Page Load
	useEffect(() => {
		if (!deviceOptions || deviceOptions?.length <= 0) {
			getDeviceOptions();
		}
	}, []);

	const getDeviceOptions = async () => {
		const {
			data: {
				deviceByScheme: { items: devicesData },
			},
		} = await API.graphql(
			graphqlOperation(listDevicesTable, {
				schemeId: schemeId,
			})
		);

		setDeviceOptions(devicesData);
	};

	// Form Default Values
	const defaultValues = {
		allDevices: false,
		devices: [],
		to: new Date(),
		measureName: measureOptionsInstant[0].value,
	};

	// Form Init
	const {
		control,
		formState: { errors, isValid, isDirty },
		handleSubmit,
		getValues,
		watch,
		trigger,
	} = useForm({ defaultValues: defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	const allDevicesOpt = watch("allDevices");
	useEffect(() => {
		if (isDirty) trigger("devices");
	}, [allDevicesOpt]);

	const buildReportParams = (data, generate) => {
		const deviceValue = data.allDevices ? { id: schemeId, name: "All" } : data.devices;
		const aggType = measureOptionsInstant.find((opt) => opt.value === data.measureName).type;
		const toString = `from_iso8601_timestamp('${DateTime.fromJSDate(data.to)
			.set({ second: 0, millisecond: 0 })
			.toISO()}')`;
		const params = {
			allDevices: data.allDevices,
			devices: deviceValue,
			to: toString,
			measureName: data.measureName,
			aggregateType: aggType,
			generate: generate,
		};

		setReportParams(params);
	};

	//Submit the Form
	const onSubmit = async (data) => {
		buildReportParams(data, true);
	};

	const handleShowPresetDialog = () => {
		buildReportParams(getValues(), false);
		setReportType("instant");
		setSaveReportDialogShow(true);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className="card">
				<div className="grid columns-2 gapCol-large gapRow-medium">
					<div className="formField">
						<label htmlFor="measureName">{t("report.form.measure.label")}</label>
						<Controller
							name="measureName"
							control={control}
							rules={{ required: t("common.form.required") }}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<Dropdown
									appendTo={appElement}
									{...myField}
									inputRef={ref}
									options={measureOptionsInstant}
									placeholder={t("report.form.measure.placeholder")}
									onChange={(e) => myField.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("measureName")}
					</div>
					<div className="grid columns-2-AutoFree gapCol-medium">
						<div className="formField">
							<label htmlFor="allDevices">{t("report.form.allDevices.label")}</label>
							<Controller
								name="allDevices"
								control={control}
								render={({ field, fieldState }) => (
									<InputSwitch
										{...field}
										checked={field.value}
										onChange={(e) => field.onChange(e.value)}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("allDevices")}
						</div>
						<div className="formField">
							<label htmlFor="devices">{t("report.form.devices.label")}</label>
							<Controller
								name="devices"
								control={control}
								rules={{ required: { value: !allDevicesOpt, message: t("common.form.required") } }}
								render={({ field: { ref, ...myField }, fieldState }) => (
									<MultiSelect
										appendTo={appElement}
										{...myField}
										inputRef={ref}
										options={deviceOptions}
										// optionValue="id"
										optionLabel="name"
										maxSelectedLabels={1}
										disabled={allDevicesOpt}
										filter
										placeholder={t("report.form.devices.placeholder")}
										onChange={(e) => myField.onChange(e.value)}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("devices")}
						</div>
					</div>
					<div className="formField">
						<label htmlFor="to">{t("report.form.to.label")}</label>
						<Controller
							name="to"
							control={control}
							rules={{ required: t("common.form.required") }}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<Calendar
									{...myField}
									inputRef={ref}
									readOnlyInput
									maxDate={now.toJSDate()}
									dateFormat="d M yy"
									showTime
									selectOtherMonths={true}
								/>
							)}
						/>
						{getFormErrorMessage("to")}
					</div>
				</div>
				<div className="grid columns-2 gapCol-large gapRow-medium">
					<Button type="submit" label={t("report.form.submit")} className="marginTop-medium feature" />
					<Button
						type="button"
						label={t("report.form.save")}
						onClick={() => handleShowPresetDialog()}
						disabled={!isValid}
						className="marginTop-medium"
					/>
				</div>
			</form>

			{reportParams?.generate && <ReportResultsLoader />}
		</>
	);
};

export default ReportForm;
