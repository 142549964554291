//Node Modules
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

//BinaryForge Components
import { LineChartDate } from "../report";

//3rd Party Components

//Atoms
import { analyticsParamsAtom } from "../../atoms/dashboard";
import { Note } from "../helpers";

//Helpers

//Other

const AnalyticsChart = ({ analyticsData, timestamps }) => {
	//Hooks
	const { t } = useTranslation();

	//Recoil
	const analyticsParams = useRecoilValue(analyticsParamsAtom);

	return (
		<>
			<p className="marginBottom-small">{analyticsParams?.desc}</p>
			{Object.entries(analyticsData).length > 0 ? (
				<LineChartDate
					data={analyticsData}
					timestamps={timestamps}
					measureName={t(`report.measure.${analyticsParams.measureName}`)}
				/>
			) : (
				<Note messageKey={t("dashboard.analytics.noData")} icon="pi pi-info-circle fontColour-info" />
			)}
		</>
	);
};

export default AnalyticsChart;
