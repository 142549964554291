//Node Modules
import { useRecoilValue } from "recoil";

//Pages

//BinaryForge Components
import { AppNav } from "./";
import { ReactComponent as Logo } from "../../assets/img/logo/logo2023.svg";

//3rd Party Components

//Atoms
import { loggedInUserAtom } from "../../atoms";

//Helpers

//Other

const AppHeader = () => {
	const loggedInUser = useRecoilValue(loggedInUserAtom);

	return (
		<header className="appHeader" role="banner">
			<div className="flex jContent-spaceBetween aItems-center appHeader-content">
				<div className="logo-wrapper">
					<Logo />
					{/* <span>{t("common.appName")}</span> */}
				</div>
				{loggedInUser && <AppNav />}
			</div>
		</header>
	);
};

export default AppHeader;
