//Node Modules
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

//Pages

//BinaryForge Components

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";

//Atoms
import { alarmGroupListAtom, selectedAlarmGroupAtom } from "../../../atoms/alarm";

//Helpers
import { filterApplyTemplate } from "../../../config/datatable";
import { PillItem } from "../../helpers";

//Other

const AlarmGroupTable = ({ alarmGroupData }) => {
	//Hooks
	const { t } = useTranslation();
	const dt = useRef(null);

	//Recoil
	const [groups, setGroups] = useRecoilState(alarmGroupListAtom);
	const [selectedGroup, setSelectedGroup] = useRecoilState(selectedAlarmGroupAtom);

	// Local State
	const [dtFilters, setDtFilters] = useState(null);

	//On Page Load
	useEffect(() => {
		if (alarmGroupData) {
			setGroups(alarmGroupData);
			initFilters();
		}
	}, [alarmGroupData]);

	//Datatable Functions
	const initFilters = () => {
		setDtFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			inputCount: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			outputCount: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			espComplete: {
				value: null,
				matchMode: FilterMatchMode.CUSTOM,
			},
		});
	};

	const espCompleteColTemplate = (rowData) => {
		const background = rowData.espComplete ? "success" : "error";
		return (
			<PillItem
				type="text"
				value={t(`alarm.group.management.table.espComplete.${rowData.espComplete.toString()}`)}
				wrapperStyle={background}
			/>
		);
	};

	const espCompleteFilterTemplate = (options) => {
		return (
			<Dropdown
				showClear
				value={options.value}
				options={[
					{ value: true, label: t(`alarm.group.management.table.espComplete.true`) },
					{ value: false, label: t(`alarm.group.management.table.espComplete.false`) },
				]}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.form.multiselectPlaceholder")}
			/>
		);
	};

	const espCompleteFilterFunction = (value, filter) => {
		let include = true;
		if (filter != null) {
			include = value === filter;
		}
		return include;
	};

	return (
		<div>
			<DataTable
				ref={dt}
				value={groups}
				emptyMessage={t("common.table.noData")}
				selectionMode="single"
				selection={selectedGroup}
				onSelectionChange={(e) => setSelectedGroup(e.value)}
				sortMode="multiple"
				removableSort
				filters={dtFilters}
				filterDisplay="menu"
				autoLayout={true}
				paginator
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
				currentPageReportTemplate={t("common.table.paginatorTemplate")}
				rows={10}
				dataKey="id">
				<Column
					field="name"
					header={t("alarm.group.common.name")}
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="inputCount"
					header={t("alarm.group.common.inputCount")}
					dataType="numeric"
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="outputCount"
					header={t("alarm.group.common.outputCount")}
					dataType="numeric"
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="espComplete"
					header={t("alarm.group.common.espComplete")}
					body={espCompleteColTemplate}
					sortable
					filter
					showFilterMatchModes={false}
					filterElement={espCompleteFilterTemplate}
					filterFunction={espCompleteFilterFunction}
					filterApply={filterApplyTemplate}
				/>
			</DataTable>
		</div>
	);
};

export default AlarmGroupTable;
