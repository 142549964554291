export const zones = {
	type: {
		BUILDING: "Building",
		FLOOR: "Floor",
		ROOM: "Room",
		AREA: "Area",
	},
	management: {
		pageTitle: "Estate Management",
		title: "Zones",
		actions: {
			create: "Create",
			edit: "Edit",
			delete: "Delete",
		},
		drag: {
			error: {
				parentTenant: "A zone in the target branch is already assigned the same tenant(s) as {{currentZone}}",
			},
			toast: {
				successSummary: "Successfully moved Zone",
				successDetail: "",
				errorSummary: "Failed to move Zone",
				errorDetail: "{{error}}",
			},
		},
	},
	detail: {
		title: "Zone Details",
		name: {
			label: "Name",
		},
		type: {
			label: "Type",
		},
		devices: {
			title: "Zone Devices ({{count}})",
		},
		childDevices: {
			title: "Child Zones Devices ({{count}})",
		},
		tenants: {
			title: "Assigned Tenants ({{count}})",
		},
	},
	create: {
		loading: "Creating Zone",
		dialog: {
			header: "Create Zone",
		},
		error: {
			nameUnique: "Zone name must be unique within the current branch.",
		},
		toast: {
			successSummary: "Successfully Created Zone",
			successDetail: "",
			errorSummary: "Failed to Create Zone",
			errorDetail: "{{error}}",
		},
	},
	edit: {
		loading: "Updating Zone",
		dialog: {
			header: "Edit Zone",
		},
		toast: {
			successSummary: "Successfully Updated Zone",
			successDetail: "",
			errorSummary: "Failed to Update Zone",
			errorDetail: "{{error}}",
		},
	},
	delete: {
		header: "Confirm Delete Zone",
		loading: "Deleting Zone",
		accept: "Delete",
		reject: "Cancel",
		message:
			"Are you sure you want to delete the zone - this will also delete any child zones? This cannot be undone and any devices assigned to this zone and it's children will no longer have a physical location assigned.",
		toast: {
			successSummary: "Successfully Deleted Zone",
			successDetail: "",
			errorSummary: "Failed to Delete Zone",
			errorDetail: "{{error}}",
		},
	},
	tenant: {
		loading: "Assigning Tenants",
		header: "Assign Tenants",
		message: "Select which tenant(s) have access to devices in zone: {{zone}}",
		submit: "Assign",
		form: {
			tenants: {
				label: "Tenants",
				placeholder: "Select Tenants",
			},
		},
	},
	form: {
		name: {
			label: "Zone Name",
		},
		type: {
			label: "Zone Type",
			placeholder: "Select a zone type",
		},
		devices: {
			label: "Zone Devices",
			placeholder: "Select Devices",
		},
		tenants: {
			label: "Tenants",
			placeholder: "Select Tenants",
			note: "Tenants can only be assigned to one Zone in the current branch. If you do not see an expected Tenant, check if they are already assigned to another Zone in the current branch.",
		},
	},
};
