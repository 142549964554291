import i18n from "../i18n";

export const eventTypeOptions = [
	{ value: "BATTERY_LOW", label: i18n.t("events.eventType.eventType", { context: "BATTERY_LOW" }) },
	{ value: "OUT_OF_COMMS", label: i18n.t("events.eventType.eventType", { context: "OUT_OF_COMMS" }) },
	{ value: "MAINS_POWER_FAIL", label: i18n.t("events.eventType.eventType", { context: "MAINS_POWER_FAIL" }) },
	{
		value: "SENSOR_INPUT_FLEXIPAD",
		label: i18n.t("events.eventType.eventType", { context: "SENSOR_INPUT_FLEXIPAD" }),
	},
	{ value: "SENSOR_INPUT_RANGER", label: i18n.t("events.eventType.eventType", { context: "SENSOR_INPUT_RANGER" }) },
	{
		value: "SENSOR_INPUT_FLOWSTOP",
		label: i18n.t("events.eventType.eventType", { context: "SENSOR_INPUT_FLOWSTOP" }),
	},
	{ value: "RELAY", label: i18n.t("events.eventType.eventType", { context: "RELAY" }) },
];
