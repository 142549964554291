//Node Modules
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { useTranslation } from "react-i18next";

//Binary Forge Components
import { Note } from "../helpers";

//3rd Party Components
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

//Atoms
import { selectedUserSchemeAtom, usernameSelectorFamily } from "../../atoms/user";
import { selectedSchemeAtom } from "../../atoms/scheme";

//Config
import { nav } from "../../config/navigation";

const UsernameAlt = ({ sub }) => {
	//Hooks
	const { t } = useTranslation();
	const navigate = useNavigate();

	//Recoil
	const { state: usernameState, contents: usernameData } = useRecoilValueLoadable(usernameSelectorFamily(sub));
	const selectedUserScheme = useRecoilValue(selectedUserSchemeAtom);
	const selectedScheme = useRecoilValue(selectedSchemeAtom);

	const handleNavigateToUser = () => {
		if (selectedUserScheme.id === selectedScheme.id) {
			navigate(`${nav.user.base}/${usernameData.username}`);
		} else {
			confirmNavigateToUser();
		}
	};

	const confirmNavigateToUser = () => {
		confirmDialog({
			header: t("admin.scheme.details.users.confirmNavigate.header"),
			message: (
				<Note
					messageKey={t("admin.scheme.details.users.confirmNavigate.message")}
					icon="pi pi-info-circle fontColour-info"
				/>
			),
			acceptLabel: t("admin.scheme.details.users.confirmNavigate.accept"),
			rejectLabel: t("admin.scheme.details.users.confirmNavigate.reject"),
			acceptClassName: "feature",
			accept: () => navigate(`${nav.user.base}/${usernameData.username}?schemeId=${selectedScheme.id}`),
			reject: () => {}, //Do Nothing,
		});
	};

	switch (usernameState) {
		case "hasError":
			//TODO: Show a proper error component here
			console.error("Username Error ::", usernameData);
			return <div>{usernameData.message ? usernameData.message : usernameData}</div>;
		case "loading":
			return <Skeleton width="75%" />;
		case "hasValue":
			return (
				<Button
					type="button"
					label={usernameData.fullname}
					className="p-link aSelf-start"
					onClick={() => handleNavigateToUser()}
				/>
			);
		default:
			return null;
	}
};

export default UsernameAlt;
