//Node Modules
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { Link, useSearchParams } from "react-router-dom";
import { Duration } from "luxon";
import { v4 as uuid } from "uuid";
import _ from "lodash";

//Pages

//BinaryForge Components

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Tooltip } from "primereact/tooltip";

//Atoms
import { eventTableFiltersAtom, selectedEventAtom } from "../../atoms/events";

//Helpers
import { filterApplyTemplate, numberMatchModeOptions, timePeriodTypeOptions } from "../../config/datatable";
import { getUiDateTime, getDurationSecs } from "../../helpers/DateUtils";
import { nav } from "../../config/navigation";
import { eventTypeOptions } from "../../config/event";

//Other

const EventsTable = ({ eventsData, context }) => {
	//Hooks
	const { t } = useTranslation();
	const dt = useRef(null);
	const [searchParams] = useSearchParams();

	//Recoil
	// const [devices, setDevices] = useRecoilState(devicesListAtom);
	const [selectedEvent, setSelectedEvent] = useRecoilState(selectedEventAtom);
	const [eventTableFilters, setEventTableFilters] = useRecoilState(eventTableFiltersAtom);

	// Local State

	//On Page Load
	useEffect(() => {
		if (eventsData) {
			const preFilter_device = searchParams.get("device");
			const preFilter_eventType = searchParams.get("eventType");
			if (!eventTableFilters) initFilters(preFilter_device, preFilter_eventType);
		}
	}, [eventsData]);

	//Datatable Functions
	const initFilters = (device, eventType) => {
		setEventTableFilters({
			eventType: { value: null, matchMode: FilterMatchMode.CUSTOM },
			nameFallback: {
				operator: FilterOperator.AND,
				constraints: [{ value: device, matchMode: FilterMatchMode.CONTAINS }],
			},
			"device.zoneBreadcrumb.label": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			createdAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			updatedAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			duration: { value: null, matchMode: FilterMatchMode.CUSTOM },
		});
	};

	const createdRowTemplate = (rowData) => {
		return getUiDateTime(rowData.createdAt);
	};

	const updatedRowTemplate = (rowData) => {
		return getUiDateTime(rowData.updatedAt);
	};

	const deviceRowTemplate = (rowData) => {
		return <Link to={`${nav.device.base}/${rowData.deviceId}`}>{rowData.nameFallback}</Link>;
	};

	const durationRowTemplate = (rowData) => {
		let duration = rowData.duration ? rowData.duration : 0;
		let durationObj = Duration.fromObject({ seconds: duration })
			.rescale()
			.toHuman({ unitDisplay: "narrow", listStyle: "narrow" });

		return durationObj ? durationObj : "---";
	};

	const typeRowTemplate = (rowData) => {
		if (context === "live" && rowData.eventType.includes("SENSOR_INPUT"))
			return (
				<Link to={`${nav.alarm.base}/${nav.alarm.alerts}`}>
					{t("events.eventType.eventType", { context: rowData.eventType })}
				</Link>
			);
		else return t("events.eventType.eventType", { context: rowData.eventType });
	};

	const typeFilterItemTemplate = (option) => {
		return t("events.eventType.eventType", { context: option.value });
	};
	const typeFilterSelectedItemTemplate = (option) => {
		if (option !== undefined) return t("events.eventType.eventType", { context: option });
	};

	const typeFilterTemplate = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={eventTypeOptions}
				itemTemplate={typeFilterItemTemplate}
				selectedItemTemplate={typeFilterSelectedItemTemplate}
				maxSelectedLabels={2}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.form.multiselectPlaceholder")}
			/>
		);
	};

	const locationRowTemplate = (rowData) => {
		const unique = uuid();
		const locationBreadcrumb = rowData.device?.zoneBreadcrumb?.label?.replaceAll("|", " > ");

		return (
			<>
				<Tooltip target={`.customTooltip-${unique}`} />
				<span
					className={`customTooltip-${unique}`}
					data-pr-tooltip={locationBreadcrumb}
					data-pr-at="right+10 center">
					{rowData.device?.zone?.label}
				</span>
			</>
		);
	};

	const eventFilterFunction = (value, filter) => {
		let include = true;
		if (filter?.length) {
			if (filter.includes(null) && value === null) {
				include = true;
			} else {
				const checkFilter = _.intersection([value], filter);
				if (!checkFilter.length) include = false;
			}
		}
		return include;
	};

	const dateFilterTemplate = (options) => {
		return (
			<Calendar
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder={t("common.table.dateSelect")}
				dateFormat="dd M yy"
				readOnlyInput
				showButtonBar
				showTime
			/>
		);
	};

	const durationFilterTemplate = (options) => {
		const [type, period, periodType] = options.value ?? [
			numberMatchModeOptions[0].value,
			1,
			timePeriodTypeOptions[0].value,
		];
		return (
			<div className="flexVert gapRow-small">
				<div className="formField">
					<label htmlFor="versionFilterType">Match Type</label>
					<Dropdown
						name="versionFilterType"
						value={type}
						options={numberMatchModeOptions}
						onChange={(e) => options.filterCallback([e.value, period, periodType])}
					/>
				</div>
				<div className="formField">
					<label htmlFor="period">Duration</label>
					<InputNumber
						name="period"
						value={period}
						min={1}
						max={365}
						showButtons={true}
						onChange={(e) => options.filterCallback([type, e.value, periodType])}
					/>
				</div>
				<div className="formField">
					<label htmlFor="timePeriodType">Duration type</label>
					<Dropdown
						name="timePeriodType"
						value={periodType}
						options={timePeriodTypeOptions}
						onChange={(e) => options.filterCallback([type, period, e.value])}
					/>
				</div>
			</div>
		);
	};

	const durationFilterFunction = (value, filter) => {
		const [type, period, periodType] = filter ?? [null, null, null];
		let include = true;
		if (type && period && periodType) {
			const durationSecs = getDurationSecs(period, periodType);

			if (type === "eq" && value !== durationSecs) include = false;
			if (type === "neq" && value === durationSecs) include = false;
			if (type === "gt" && value <= durationSecs) include = false;
			if (type === "gte" && value < durationSecs) include = false;
			if (type === "lt" && value >= durationSecs) include = false;
			if (type === "lte" && value > durationSecs) include = false;
		}

		return include;
	};

	return (
		<div>
			<DataTable
				ref={dt}
				value={eventsData}
				emptyMessage={t("common.table.noData")}
				selectionMode="single"
				selection={selectedEvent}
				onSelectionChange={(e) => setSelectedEvent(e.value)}
				onFilter={(e) => setEventTableFilters(e.filters)}
				sortMode="multiple"
				removableSort
				filters={eventTableFilters}
				filterDisplay="menu"
				autoLayout={true}
				paginator
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
				currentPageReportTemplate={t("common.table.paginatorTemplate")}
				rows={10}
				dataKey="id">
				<Column
					field="eventType"
					header={t("events.management.table.type")}
					body={typeRowTemplate}
					sortable
					filter
					showFilterMatchModes={false}
					filterElement={typeFilterTemplate}
					filterFunction={eventFilterFunction}
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="nameFallback"
					header={t("events.management.table.deviceName")}
					body={deviceRowTemplate}
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="device.zone.label"
					header={t("events.management.table.location")}
					body={locationRowTemplate}
					sortable
					filter
					filterField="device.zoneBreadcrumb.label"
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="createdAt"
					header={t("common.table.createdAt")}
					body={createdRowTemplate}
					dataType="date"
					sortable
					filter
					filterApply={filterApplyTemplate}
					filterElement={dateFilterTemplate}
				/>
				<Column
					field="updatedAt"
					header={t("common.table.updatedAt")}
					body={updatedRowTemplate}
					dataType="date"
					sortable
					filter
					filterApply={filterApplyTemplate}
					filterElement={dateFilterTemplate}
				/>
				<Column
					field="duration"
					header={t("events.management.table.duration")}
					body={durationRowTemplate}
					dataType="numeric"
					sortable
					filter
					showFilterMatchModes={false}
					filterApply={filterApplyTemplate}
					filterElement={durationFilterTemplate}
					filterFunction={durationFilterFunction}
				/>
			</DataTable>
		</div>
	);
};

export default EventsTable;
