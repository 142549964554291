//Node Modules
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { listDevicesBasic } from "../../graphql/queries-custom";

//BinaryForge Components
import { ZoneForm } from "./";
import { BfDialog } from "../helpers";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { dialogAtomFamily } from "../../atoms";
import { zoneNodesAtom } from "../../atoms/zones";
import { selectedUserSchemeAtom } from "../../atoms/user";

//Helpers

//Other

const ZoneCreateDialog = () => {
	//Hooks
	const { t } = useTranslation();
	const formRef = useRef();

	//Local State
	const [availableDevices, setAvailableDevices] = useState([]);

	//Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("zoneCreateDialog"));
	const zoneNodes = useRecoilValue(zoneNodesAtom);
	const selectedUserScheme = useRecoilValue(selectedUserSchemeAtom);

	//On Open Dialog
	useEffect(() => {
		const getFreeDevices = async () => {
			const {
				data: {
					deviceByScheme: { items: freeDevices },
				},
			} = await API.graphql(
				graphqlOperation(listDevicesBasic, {
					schemeId: selectedUserScheme.id,
					filter: { zoneId: { attributeExists: false } },
				})
			);

			setAvailableDevices(freeDevices);
		};

		getFreeDevices();
	}, [zoneNodes]);

	const footer = (
		<>
			<Button label={t("common.action.cancel")} onClick={() => setShow(false)} />
			<Button
				label={t("common.action.create")}
				icon="pi pi-save"
				className="feature"
				// disabled={!isValid}
				onClick={() => formRef.current.submitZoneForm()}
			/>
		</>
	);

	return (
		<BfDialog id="zoneCreateDialog" header={t("zones.create.dialog.header")} footer={footer}>
			<ZoneForm type="create" availableDevices={availableDevices} ref={formRef} />
		</BfDialog>
	);
};

export default ZoneCreateDialog;
