export const common = {
	appName: "Cloud Connect Portal",
	appNameShort: "Cloud Connect",
	company: "Andel Ltd.",
	metaDesc: "App Meta Description",
	createdAt: "Created At",
	updatedAt: "Updated At",
	createdBy: "Created By",
	updatedBy: "Updated By",
	unit: {
		secs: "secs",
		seconds: "seconds",
	},
	action: {
		back: "Back",
		next: "Next",
		save: "Save",
		create: "Create",
		update: "Update",
		search: "Search",
		cancel: "Cancel",
		remove: "Remove",
		delete: "Delete",
		disable: "Disable",
		reset: "Reset",
		ok: "Ok",
	},
	option: {
		yes: "Yes",
		no: "No",
	},
	form: {
		password:
			"Password must be at least 8 characters, contain one uppercase character, one lowercase character, one number and one special character (@ $ ! % * ? & ( ) : ; # \" ^ _ + - { } [ ] ' ~ | < > , . / = ` \\)",
		dropdownPlaceholder: "Select Item",
		multiselectPlaceholder: "Select Items",
		required: "This field is required.",
	},
	table: {
		export: "Export",
		exportFilename: "$t(common.appName) Export - {{tableName}} - {{date}}",
		id: "ID",
		noData: "No data to display",
		createdBy: "Created By",
		createdAt: "Created",
		addedAt: "Added",
		updatedBy: "Updated By",
		updatedAt: "Updated",
		paginatorTemplate: "Showing {first} to {last} of {totalRecords} entries",
		dateRange: "Date Range",
		dateSelect: "Select Date",
	},
	boolean: {
		yes: "Yes",
		no: "No",
		active: "Active",
		inactive: "Inactive",
		accept: "Accept",
		cancel: "Cancel",
	},
	copy: {
		successSummary: "Copied Successfully",
		successDetail: "",
	},
};
