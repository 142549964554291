//Node Modules
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../components/layout";

//3rd Party Components

//Atoms
import { alarmGroupListSelector, selectedAlarmGroupAtom } from "../../../atoms/alarm";

//Helpers

//Other
import { nav } from "../../../config/navigation";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { AlarmGroupLoader } from "../../../components/alarm/groups";

const AlarmGroupManagement = () => {
	//Hooks
	const { t } = useTranslation();

	//Recoil
	const refreshCache = useRecoilRefresher_UNSTABLE(alarmGroupListSelector);
	const selectedAlarmGroup = useRecoilValue(selectedAlarmGroupAtom);

	//On Page Load
	useEffect(() => {
		refreshCache();
	}, []);

	return (
		<>
			<PageHeader title={t("alarm.group.management.pageTitle")} />
			<main className="appMain">
				<div className="appMain-content">
					<div className="actions flex jContent-end gap-small">
						<Link
							to={`${nav.alarm.base}/${nav.alarm.group.base}/${nav.alarm.group.create}`}
							className="button">
							{t("alarm.group.management.actions.create")}
						</Link>
						<Link
							to={`${nav.alarm.base}/${nav.alarm.group.base}/${nav.alarm.group.edit}/${selectedAlarmGroup?.id}`}
							className="button feature"
							disabled={!selectedAlarmGroup}>
							{t("alarm.group.details.actions.edit")}
						</Link>
						{/* <Link
							to={`${nav.alarm.base}/${nav.alarm.group.base}/${selectedAlarmGroup?.id}`}
							className="button feature"
							disabled={!selectedAlarmGroup}>
							{t("alarm.group.management.actions.details")}
						</Link> */}
					</div>
					<AlarmGroupLoader />
				</div>
			</main>
		</>
	);
};

export default AlarmGroupManagement;
