export const listDevicesBasic = /* GraphQL */ `
	query DeviceByScheme(
		$schemeId: ID!
		$sortDirection: ModelSortDirection
		$filter: ModelDeviceFilterInput
		$limit: Int
		$nextToken: String
	) {
		deviceByScheme(
			schemeId: $schemeId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				name
			}
		}
	}
`;

export const listDevicesTable = /* GraphQL */ `
	query DeviceByScheme(
		$schemeId: ID!
		$sortDirection: ModelSortDirection
		$filter: ModelDeviceFilterInput
		$limit: Int
		$nextToken: String
	) {
		deviceByScheme(
			schemeId: $schemeId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				name
				zone {
					id
					label
				}
				zoneBreadcrumb
				inputDevice
				outputDevice
			}
			nextToken
		}
	}
`;

export const listDeviceProvisioningsTable = /* GraphQL */ `
	query DeviceProvisioningByScheme(
		$schemeId: ID!
		$sortDirection: ModelSortDirection
		$filter: ModelDeviceProvisioningFilterInput
		$limit: Int
		$nextToken: String
	) {
		deviceProvisioningByScheme(
			schemeId: $schemeId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				name
				inputDevice
				outputDevice
				zone {
					label
				}
				createdAt
				installerId
			}
		}
	}
`;

export const listDevicesAlarmGroup = /* GraphQL */ `
	query DeviceByScheme(
		$schemeId: ID!
		$sortDirection: ModelSortDirection
		$filter: ModelDeviceFilterInput
		$limit: Int
		$nextToken: String
	) {
		deviceByScheme(
			schemeId: $schemeId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				name
				zone {
					label
				}
				inputDevice
				outputDevice
				reported_state
				alert {
					sensor_state
				}
			}
		}
	}
`;

export const listAlarmGroupsTable = /* GraphQL */ `
	query ListAlarmGroups($filter: ModelAlarmGroupFilterInput, $limit: Int, $nextToken: String) {
		listAlarmGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				input {
					items {
						id
						device {
							id
							name
							reported_state
							__typename
						}
						__typename
					}
					nextToken
					__typename
				}
				output {
					items {
						id
						device {
							id
							name
							reported_state
							__typename
						}
						__typename
					}
					nextToken
					__typename
				}
				schemeId
				createdAt
				updatedAt
				__typename
			}
			nextToken
			__typename
		}
	}
`;

export const listAlarmGroupsInputIds = /* GraphQL */ `
	query ListAlarmGroupsInputs($filter: ModelAlarmGroupsInputFilterInput, $limit: Int, $nextToken: String) {
		listAlarmGroupsInputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
			}
		}
	}
`;

export const listAlarmGroupsOutputIds = /* GraphQL */ `
	query ListAlarmGroupsOutputs($filter: ModelAlarmGroupsOutputFilterInput, $limit: Int, $nextToken: String) {
		listAlarmGroupsOutputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
			}
		}
	}
`;

export const validateDeviceName = /* GraphQL */ `
	query DeviceByName(
		$name: String!
		$schemeId: ModelIDKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelDeviceFilterInput
		$limit: Int
		$nextToken: String
	) {
		deviceByName(
			name: $name
			schemeId: $schemeId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				name
			}
		}
	}
`;

export const validateSchemeName = /* GraphQL */ `
	query SchemeByName(
		$name: String!
		$sortDirection: ModelSortDirection
		$filter: ModelSchemeFilterInput
		$limit: Int
		$nextToken: String
	) {
		schemeByName(
			name: $name
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				name
			}
		}
	}
`;

export const validateZoneName = /* GraphQL */ `
	query ZoneByLabel(
		$label: String!
		$schemeId: ModelIDKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelZoneFilterInput
		$limit: Int
		$nextToken: String
	) {
		zoneByLabel(
			label: $label
			schemeId: $schemeId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				label
			}
		}
	}
`;

export const listAlarmAlerts = /* GraphQL */ `
	query DeviceSensorAlertBySensorState(
		$sensor_state: Int!
		$sortDirection: ModelSortDirection
		$filter: ModelDeviceSensorAlertFilterInput
		$limit: Int
		$nextToken: String
	) {
		deviceSensorAlertBySensorState(
			sensor_state: $sensor_state
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				updatedAt
				id
				device {
					id
					name
					inputDevice
					zone {
						label
					}
					zoneBreadcrumb
					alarmGroupsInput {
						items {
							alarmGroup {
								output {
									items {
										device {
											id
											name
											reported_state
											alarmGroupsOutput {
												items {
													alarmGroup {
														name
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const getUserConfigs = /* GraphQL */ `
	query UserSchemeConfigByCognitoUserId(
		$cognitoUserId: String!
		$sortDirection: ModelSortDirection
		$filterScheme: ModelUserSchemeConfigFilterInput
		$filterNotify: ModelUserNotifyConfigFilterInput
		$limit: Int
		$nextToken: String
	) {
		userSchemeConfigByCognitoUserId(
			cognitoUserId: $cognitoUserId
			sortDirection: $sortDirection
			filter: $filterScheme
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				role
			}
		}
		userNotifyConfigByCognitoUserId(
			cognitoUserId: $cognitoUserId
			sortDirection: $sortDirection
			filter: $filterNotify
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
			}
		}
	}
`;

export const listTenantsByScheme = /* GraphQL */ `
	query UserSchemeConfigBySchemeId(
		$schemeId: ID!
		$cognitoUserId: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelUserSchemeConfigFilterInput
		$limit: Int
		$nextToken: String
	) {
		userSchemeConfigBySchemeId(
			schemeId: $schemeId
			cognitoUserId: $cognitoUserId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id: cognitoUserId
			}
		}
	}
`;

export const getSchemeZoneConfig = /* GraphQL */ `
	query GetScheme($id: ID!) {
		getScheme(id: $id) {
			zoneConfig
		}
	}
`;

export const listZonesBasic = /* GraphQL */ `
	query ZoneBySchemeId(
		$schemeId: ID!
		$sortDirection: ModelSortDirection
		$filter: ModelZoneFilterInput
		$limit: Int
		$nextToken: String
	) {
		zoneBySchemeId(
			schemeId: $schemeId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				label
				type
				parent
				tenant
				devices {
					items {
						id
						name
					}
				}
			}
		}
	}
`;

export const getZoneBasic = /* GraphQL */ `
	query GetZone($id: ID!) {
		getZone(id: $id) {
			id
			label
			type
			devices {
				items {
					id
					name
					inputDevice
					outputDevice
				}
			}
			parent
			tenant
			createdBy
			updatedBy
			createdAt
			updatedAt
		}
	}
`;

export const liveEventBySchemeBasic = /* GraphQL */ `
	query LiveEventByScheme(
		$schemeId: ID!
		$updatedAtCreatedAt: ModelLiveEventBySchemeCompositeKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelLiveEventFilterInput
		$limit: Int
		$nextToken: String
	) {
		liveEventByScheme(
			schemeId: $schemeId
			updatedAtCreatedAt: $updatedAtCreatedAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				schemeId
				deviceId
				device {
					id
					name
					zone {
						label
					}
					zoneBreadcrumb
				}
				eventType
				duration
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;

export const archiveEventBySchemeBasic = /* GraphQL */ `
	query ArchiveEventByScheme(
		$schemeId: ID!
		$updatedAtCreatedAt: ModelArchiveEventBySchemeCompositeKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelArchiveEventFilterInput
		$limit: Int
		$nextToken: String
	) {
		archiveEventByScheme(
			schemeId: $schemeId
			updatedAtCreatedAt: $updatedAtCreatedAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				schemeId
				deviceId
				device {
					id
					name
					zone {
						label
					}
					zoneBreadcrumb
				}
				eventType
				duration
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;

export const LiveEventBySchemeSummary = /* GraphQL */ `
	query LiveEventByScheme(
		$schemeId: ID!
		$updatedAtCreatedAt: ModelLiveEventBySchemeCompositeKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelLiveEventFilterInput
		$limit: Int
		$nextToken: String
	) {
		liveEventByScheme(
			schemeId: $schemeId
			updatedAtCreatedAt: $updatedAtCreatedAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				eventType
			}
			nextToken
		}
	}
`;

export const ArchiveEventBySchemeSummary = /* GraphQL */ `
	query ArchiveEventByScheme(
		$schemeId: ID!
		$updatedAtCreatedAt: ModelArchiveEventBySchemeCompositeKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelArchiveEventFilterInput
		$limit: Int
		$nextToken: String
	) {
		archiveEventByScheme(
			schemeId: $schemeId
			updatedAtCreatedAt: $updatedAtCreatedAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				eventType
			}
			nextToken
		}
	}
`;

export const getAlarmGroupInputOutputs = /* GraphQL */ `
	query GetDevice($id: ID!) {
		getDevice(id: $id) {
			id
			alarmGroupsInput {
				items {
					alarmGroup {
						id
						output {
							items {
								deviceID
								device {
									reported_state
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const getAlarmGroupOutputInputs = /* GraphQL */ `
	query GetDevice($id: ID!) {
		getDevice(id: $id) {
			id
			alarmGroupsOutput {
				items {
					alarmGroup {
						id
						input {
							items {
								deviceID
								device {
									reported_state
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const listUserSchemeConfigsBasic = /* GraphQL */ `
	query ListUserSchemeConfigs($filter: ModelUserSchemeConfigFilterInput, $limit: Int, $nextToken: String) {
		listUserSchemeConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				cognitoUserId
				role
			}
		}
	}
`;
