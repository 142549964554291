//Node Modules
import { useRecoilValueLoadable } from "recoil";
import { Trans, useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { AnalyticsChart } from "./";
import { Loading, Note } from "../helpers";

//3rd Party Components

//Atoms
import { analyticsParamsSelector } from "../../atoms/dashboard";

//Helpers

//Other

const AnalyticsLoader = () => {
	//Recoil
	const { t } = useTranslation();
	const { state, contents } = useRecoilValueLoadable(analyticsParamsSelector);

	switch (state) {
		case "hasError":
			return (
				<Note
					messageKey={`Error: ${contents.message ? contents.message : contents}`}
					icon="pi-exclamation-circle fontColour-error"
				/>
			);
		case "loading":
			return <Loading />;
		case "hasValue":
			if (contents.errors) {
				return (
					<Note
						messageKey={
							<Trans
								i18nKey="report.preset.dataExceeded"
								components={{ ul: <ul />, li: <li /> }}
								context="HISTORICAL"
							/>
						}
						icon="pi-exclamation-triangle fontColour-warn"
					/>
				);
			} else {
				return <AnalyticsChart analyticsData={contents.chart} timestamps={contents.timestamp} />;
			}
		default:
			return null;
	}
};

export default AnalyticsLoader;
