//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

//Pages

//BinaryForge Components
import { AppHeaderGuest } from "../../components/layout";

//3rd Party Components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

//Atoms
import { loggedInUserAtom } from "../../atoms/user";
import { toastAtom } from "../../atoms/toast";

//Helpers
import { nav } from "../../config/navigation";
import { useLogout } from "../../helpers/Auth";
import { useGetUserSchemeConfig } from "../../helpers/User";

//Other

const MfaChallenge = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleLogout = useLogout();
	const { handleGetUserConfig, handleGetAdminConfig } = useGetUserSchemeConfig();

	// Recoil State
	const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserAtom);
	const [toast, setToast] = useRecoilState(toastAtom);

	//React Hook Form
	const defaultValues = {
		mfaCode: "",
	};
	const {
		control,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm({
		defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	// Login Form Submit
	const onMfaLogin = async (data) => {
		let toPage = nav.guest.logout;
		try {
			const cognitoUser = await Auth.signIn(loggedInUser.username, loggedInUser.password);
			const response = await Auth.confirmSignIn(cognitoUser, data.mfaCode, "SOFTWARE_TOKEN_MFA");
			const userPayload = response.signInUserSession.idToken.payload;

			if (userPayload["cognito:groups"]) {
				const isAdmin = userPayload["cognito:groups"].includes("administrator");
				const { toUserPage, schemeRole } = isAdmin
					? await handleGetAdminConfig()
					: await handleGetUserConfig(userPayload["sub"]);

				toPage = toUserPage;

				setLoggedInUser({
					sub: userPayload["sub"],
					username: userPayload["cognito:username"],
					fullname: userPayload["name"],
					role: schemeRole,
				});
			} else {
				throw Error(t("login.error.noGroup"));
			}
		} catch (err) {
			console.error("onSubmit Error:", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("login.toast.loginFail.summary"),
				detail: t("login.toast.loginFail.detail", { error: err.message }),
			});
			handleLogout();
		} finally {
			navigate(toPage);
		}
	};

	return (
		<main className="appMain">
			<div className="appMain-content">
				<AppHeaderGuest />

				<form onSubmit={handleSubmit(onMfaLogin)}>
					<div className="formFieldsWrapper">
						<div className="formField">
							<label htmlFor="mfaCode">{t("login.mfaCode.label")}</label>
							<Controller
								name="mfaCode"
								control={control}
								rules={{ required: t("common.form.required") }}
								render={({ field, fieldState }) => (
									<InputText
										{...field}
										id={field.name}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("mfaCode")}
						</div>
					</div>

					<Button
						label={isSubmitting ? t("login.submitting") : t("login.submit")}
						type="submit"
						className="feature"
						disabled={isSubmitting}
					/>
				</form>
				<div className="marginTop-small text-center">
					<Link to={nav.guest.login} className="fontSize-small">
						{t("forgotPassword.back")}
					</Link>
				</div>
			</div>
		</main>
	);
};

export default MfaChallenge;
