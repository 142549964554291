export const user = {
	common: {
		deleted: "Deleted User",
		username: "Username",
		fullname: "Fullname",
		email: "Email",
		role: "Role",
		active: "Active",
		password: "Password",
	},
	role: {
		ADMINISTRATOR: "Administrator",
		INSTALLER: "Installer",
		OWNER: "Owner",
		TENANT: "Tenant",
		none: "No Role",
	},
	validation: {
		username: {
			pattern: "Username can only contain letters, numbers, aspersand (@), period (.) and underscore (_)",
		},
		fullname: {
			pattern: "Fullname can only contain letters, spaces and hyphens (-)",
		},
		email: {
			pattern: "Please enter a valid email address",
		},
		emailConfirm: {
			pattern: "Please enter a valid email address",
			match: "Value must match the email entered previously",
		},
	},
	management: {
		pageTitle: "User Management",
		actions: {
			details: "View Details",
			create: "Create User",
		},
	},
	details: {
		pageTitle: "User Details",
		pageSubtitle: "Username: {{username}}",
		actions: {
			edit: "Edit User",
			delete: "Delete User",
			reset: "Reset Password",
			resend: "Resend Invite",
		},
		form: {
			status: "Status",
			mfa: "MFA",
			login: {
				last: "Last Login Attempt",
				lastSuccess: "Last Successful Login",
			},
		},
		status: {
			CONFIRMED: "Registration Complete",
			FORCE_CHANGE_PASSWORD: "Registration Incomplete",
			RESET_REQUIRED: "Password Reset Required",
			EMAIL_UNVERIFIED: "Email Not Verified",
		},
		tenant: {
			zones: {
				header: "Assigned Zones",
				childHeader: "Child Zone Devices",
			},
		},
	},
	create: {
		pageTitle: "Create User",
		loading: "Creating New User",
		actions: {
			submit: "Create User",
		},
		form: {
			username: {
				label: "Username",
				pattern: "Username can only contain letters, numbers, aspersand (@), period (.) and underscore (_)",
			},
			fullname: {
				label: "Fullname",
				pattern: "Fullname can only contain letters, spaces and hyphens (-)",
			},
			email: {
				label: "Email",
				pattern: "Please enter a valid email address",
			},
			emailConfirm: {
				label: "Confirm Email",
				pattern: "Please enter a valid email address",
				match: "Value must match the email entered previously",
			},
			role: {
				label: "Role",
				placeholder: "Select a role",
			},
			active: {
				label: "Active",
			},
		},
		toast: {
			successSummary: "User Create Success",
			successDetail:
				"A welcome email has been sent to {{email}} with registration details for username {{username}}.",
			errorSummary: "User Create Error",
			errorDetail: 'Could not create the user. $t({{error}}, {"user": {{data}} })',
		},
		dupeUser: {
			header: "User Already Exists",
			message:
				"A user with the email {{email}} already exists assigned to a different scheme. Would you like to import this user into your scheme. The user will be imported with their current username and fullname but be assigned a new role for this scheme.",
			warning: "If you choose no, you will not be able to create a new user with this email",
			accept: "Import",
			reject: "No",
			loading: "Importing User to Project",
		},
	},
	edit: {
		pageTitle: "Edit User",
		pageSubtitle: "Username: {{username}}",
		loading: "Updating User Details",
		actions: {
			submit: "Update User",
		},
		toast: {
			successSummary: "User Update Success",
			successDetail: "",
			errorSummary: "User Update Error",
			errorDetail: 'Could not update the user. $t({{error}}, {"user": {{data}} })',
		},
	},
	delete: {
		header: "Confirm Delete User",
		message:
			"Are you sure you want to delete the user {{user}}? The user will no longer be able to login or use features associated with the $t(common.appName).",
		loading: "Deleting User",
		accept: "Delete User",
		reject: "Cancel",
		toast: {
			successSummary: "Delete Success",
			successDetail:
				"{{user}} will no longer be able to login or use the features associated with the $t(common.appName).",
			errorSummary: "Delete Failed",
			errorDetail: "Please refresh the page and try again.",
		},
	},
	error: {
		usernameGroupExists: "A user with username {{user.username}} already exists.",
		emailGroupExists: "A user with email {{user.email}} already exists",
		usernameExists: "A user with username {{user.username}} already exists.",
		emailExists: "A user with email {{user.email}} already exists",
		rolesNotAllowed: "A user assigned the Clinician role cannot be assigned additional roles.",
	},
	mfa: {
		type: {
			SOFTWARE_TOKEN_MFA: "Enabled",
			NONE: "Not Enabled",
		},
	},
	resetPassword: {
		header: "Reset User Password",
		message:
			"Are you sure you want to reset the password for user {{user}}? This will send a reset code to {{email}} in order for the user to reset their password.",
		loading: "Resetting User Password",
		accept: "Reset Password",
		toast: {
			successSummary: "Reset Code Sent",
			successDetail: "",
			errorSummary: "Password Reset Error",
			errorDetail: "Could not reset the users password. {{error}}",
		},
	},
	resendInvite: {
		header: "Resend Registration Email",
		message:
			"This will resend an email to {{email}} for user {{user}} to complete registration. The user will have {{expires}} to complete registration before the link expires.",
		loading: "Sending Registration",
		accept: "Resend Invite",
		toast: {
			successSummary: "Registration Email Sent",
			successDetail: "",
			errorSummary: "Resend Invite Failed",
			errorDetail: "Could not resend an registraion email to the user. {{error}}",
		},
	},
};
