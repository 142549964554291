// Node Modules
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//Binary Forge Components
import { AppFooter, AppHeaderGuest } from "../../components/layout";

//3rd Party Components

//Atoms

//Helpers

//Other
import { nav } from "../../config/navigation";

const ErrorBoundary = () => {
	const { t } = useTranslation();

	return (
		<div className="app combo">
			<main className="appMain">
				<div className="appMain-content">
					<AppHeaderGuest />

					<h1>{t("error.pageTitle")}</h1>
					<div className="marginTop-medium">
						<p>{t("error.errorMsg")}</p>
						<p>{t("error.supportMsg")}</p>
					</div>
					<div className="flexVert gap-xsmall marginTop-large">
						<Link to={0}>{t("error.prevLink")}</Link>
						<Link to={`${nav.device.base}/${nav.device.management}`} reloadDocument>
							{t("error.homeLink")}
						</Link>
					</div>
				</div>
			</main>
			<AppFooter />
		</div>
	);
};

export default ErrorBoundary;
