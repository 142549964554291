//Node Modules
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

//GraphQL

//BinaryForge Components
import { BfDialog } from "../../helpers";
import { Note } from "../../helpers";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { dialogAtomFamily } from "../../../atoms";

//Helpers

//Other

const DisableMoveDialog = () => {
	//Hooks
	const { t } = useTranslation();

	//Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("disableMoveDialog"));

	const footer = (
		<>
			<Button label={t("common.action.ok")} onClick={() => setShow(false)} />
		</>
	);

	return (
		<BfDialog id="disableMoveDialog" header={t("alarm.group.common.disabled_move.header")} footer={footer}>
			<Note icon="pi pi-info-circle fontColour-info" messageKey={t("alarm.group.common.disabled_move.message")} />
		</BfDialog>
	);
};

export default DisableMoveDialog;
