import { useTranslation } from "react-i18next";
import { PillItem, HighlightItem } from "../helpers";
import { getUiDateTime } from "../../helpers/DateUtils";

const UserDetails = ({ userData }) => {
	const { t } = useTranslation();

	return (
		<>
			<div className="grid columns-3 gapCol-medium">
				<div className="grid columns-label gapCol-medium gapRow-xsmall aItems-center aContent-start">
					<span>{t("user.common.fullname")}:</span>
					<span>{userData.fullname}</span>
					<span>{t("user.common.email")}:</span>
					<span>
						{userData.email_verified === "true" ? (
							userData.email
						) : (
							<PillItem value={userData.email} type="text" wrapperStyle="error" />
						)}
					</span>
					<span>{t("user.common.role")}:</span>
					<span>{t(`user.role.${userData.role}`)}</span>
				</div>
				<div className="grid columns-label gapCol-medium gapRow-xsmall aItems-center aContent-start">
					<span>{t("user.common.active")}:</span>
					<span>
						<PillItem
							type="full"
							value={userData.active ? t("common.boolean.active") : t("common.boolean.inactive")}
							icon={userData.active ? "pi-check-circle" : "pi-times-circle"}
							wrapperStyle={userData.active ? "success" : "error"}
						/>
					</span>
					<span>{t("user.details.form.status")}:</span>
					<span>
						{userData.email_verified === "true" ? (
							<PillItem
								type="text"
								value={t(`user.details.status.${userData.status}`)}
								wrapperStyle={userData.status === "CONFIRMED" ? "success" : "error"}
							/>
						) : (
							<PillItem
								value={t("user.details.status.EMAIL_UNVERIFIED")}
								type="text"
								wrapperStyle="error"
							/>
						)}
					</span>
					<span>{t("user.details.form.mfa")}:</span>
					<span>
						<PillItem
							type="full"
							value={userData.mfa ? t(`user.mfa.type.${userData.mfa}`) : t(`user.mfa.type.NONE`)}
							icon={userData.mfa ? "pi-lock" : "pi-lock-open"}
							wrapperStyle={userData.mfa ? "success" : "error"}
						/>
					</span>
				</div>
				<div className="grid columns-label gapCol-medium gapRow-xsmall aItems-center aContent-start">
					<span>{t("common.table.createdAt")}:</span>
					<span>{getUiDateTime(userData.created)}</span>
					<span>{t("common.table.updatedAt")}:</span>
					<span>{getUiDateTime(userData.updated)}</span>
				</div>
			</div>
			{userData.authEvents && (
				<div className="grid columns-2-Auto jContent-start gap-large marginTop-medium">
					<div className="grid columns-label gapCol-medium gapRow-small aItems-center aContent-start">
						<span>{t("user.details.form.login.last")}:</span>
						<span>
							<HighlightItem
								type="full"
								value={getUiDateTime(userData.authEvents.lastLogin.date)}
								icon={
									userData.authEvents.lastLogin.response === "Pass"
										? "pi-check-circle"
										: "pi-times-circle"
								}
								wrapperStyle={
									userData.authEvents.lastLogin.response === "Pass"
										? "fontColour-success"
										: "fontColour-error"
								}
							/>
						</span>
					</div>
					<div className="grid columns-label gapCol-medium gapRow-small aItems-center aContent-start">
						<span>{t("user.details.form.login.lastSuccess")}:</span>
						<span>
							{userData.authEvents.lastSuccess ? (
								<HighlightItem
									type="full"
									value={getUiDateTime(userData.authEvents.lastSuccess.date)}
									icon={
										userData.authEvents.lastSuccess.response === "Pass"
											? "pi-check-circle"
											: "pi-times-circle"
									}
									wrapperStyle={
										userData.authEvents.lastSuccess.response === "Pass"
											? "fontColour-success"
											: "fontColour-error"
									}
								/>
							) : (
								t("user.login.noLastSuccess")
							)}
						</span>
					</div>
				</div>
			)}
		</>
	);
};

export default UserDetails;
