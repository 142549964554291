import i18n from "../i18n";

export const otaTypeOptions = [
	{ value: "PUBLIC", label: i18n.t("admin.ota.type.PUBLIC") },
	{ value: "INTERNAL", label: i18n.t("admin.ota.type.INTERNAL") },
];
export const otaType = [...otaTypeOptions, { value: "RETRY", label: i18n.t("admin.ota.type.RETRY") }];

export const targetTypeOptions = [
	{ value: "GROUP", label: i18n.t("admin.ota.targetType.GROUP") },
	{ value: "DEVICE", label: i18n.t("admin.ota.targetType.DEVICE") },
];
