//Node Modules
import { useTranslation } from "react-i18next";

//GraphQL

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { AlarmGroupForm } from "../../../components/alarm/groups";

//3rd Party Components

//Atoms

//Helpers

//Other

const AlarmGroupCreate = () => {
	//Hooks
	const { t } = useTranslation();

	return (
		<>
			<PageHeader title={t("alarm.group.create.pageTitle")} />
			<main className="appMain">
				<div className="appMain-content">
					<AlarmGroupForm type="create" />
				</div>
			</main>
		</>
	);
};

export default AlarmGroupCreate;
