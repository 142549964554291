//Node Modules
import { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { updateDevice, updateScheme, deleteZone, updateZone } from "../../../graphql/mutations";
import { getSchemeZoneConfig, getZoneBasic } from "../../../graphql/queries-custom";

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { PillItem, Note } from "../../../components/helpers";
import { ZoneCreateDialog, ZoneEditDialog, ZoneLoader } from "../../../components/zones";

//3rd Party Components
import { Tree } from "primereact/tree";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

//Atoms
import { dialogAtomFamily, toastAtom } from "../../../atoms";
import { selectedUserSchemeAtom } from "../../../atoms/user";
import { selectedZoneAtom, selectedZoneKeyAtom, zoneNodesAtom } from "../../../atoms/zones";

//Helpers
import { zoneConfig } from "../../../config/zones";
import {
	expandLocation,
	findTreeNodeByKey,
	getAllChildZones,
	getAllParentZoneProperty,
	getAllChildZonesFull,
} from "../../../helpers/Zones";
import { useGetFullLocation } from "../../../helpers/Device";

//Other

const ZoneManagement = () => {
	//Hooks
	const { t } = useTranslation();
	const getFullLocation = useGetFullLocation();
	const [searchParams] = useSearchParams();

	//Local State
	const [expandedKeys, setExpandedKeys] = useState();

	//Recoil State
	const setLoading = useSetRecoilState(dialogAtomFamily("loadingDialog"));
	const [toast, setToast] = useRecoilState(toastAtom);
	const selectedUserScheme = useRecoilValue(selectedUserSchemeAtom);
	const [nodes, setNodes] = useRecoilState(zoneNodesAtom);
	const [selectedNodeKey, setSelectedNodeKey] = useRecoilState(selectedZoneKeyAtom);
	const selectedZone = useRecoilValue(selectedZoneAtom);
	const setShowCreateDialog = useSetRecoilState(dialogAtomFamily("zoneCreateDialog"));
	const setShowEditDialog = useSetRecoilState(dialogAtomFamily("zoneEditDialog"));

	//On Page Load
	useEffect(() => {
		const loadInitialConfig = async () => {
			const {
				data: {
					getScheme: { zoneConfig },
				},
			} = await API.graphql(graphqlOperation(getSchemeZoneConfig, { id: selectedUserScheme.id }));
			setNodes(JSON.parse(zoneConfig));

			// console.log("Zones ::", JSON.parse(zoneConfig));
		};

		loadInitialConfig();
	}, []);

	useEffect(() => {
		const expandTree = async () => {
			const zoneId = searchParams.get("zoneId");

			const treeNode = await findTreeNodeByKey(nodes, zoneId);
			const zoneBreadcrumb = await getFullLocation(treeNode);
			const expandedLocation = expandLocation(zoneBreadcrumb.breadcrumbKey.split("|"));

			setExpandedKeys(expandedLocation);
			setSelectedNodeKey(zoneId);
		};

		if (searchParams.get("zoneId") && nodes.length > 0) expandTree();
	}, [searchParams, nodes]);

	//Actions
	const updateNodes = async (updatedNodes) => {
		setNodes(updatedNodes);
		const {
			data: {
				updateScheme: { zoneConfig },
			},
		} = await API.graphql(
			graphqlOperation(updateScheme, {
				input: { id: selectedUserScheme.id, zoneConfig: JSON.stringify(updatedNodes) },
			})
		);

		return JSON.parse(zoneConfig);
	};

	const handleDragDrop = async (e) => {
		const { dragNode, dropNode } = e;
		let dropZone = { id: null };
		let zoneBreadcrumb = null;

		try {
			setLoading({ visible: true, message: t("zones.edit.loading") });
			const {
				data: { getZone: dragZone },
			} = await API.graphql(graphqlOperation(getZoneBasic, { id: dragNode.key }));

			if (dropNode) {
				const {
					data: { getZone },
				} = await API.graphql(graphqlOperation(getZoneBasic, { id: dropNode.key }));
				dropZone = getZone;
			}

			//Validate to make sure we allow this move based on tenants
			if (dragZone.tenant) {
				const parentTenants = _.flattenDeep(await getAllParentZoneProperty(dropZone, "tenant"));
				const dropTenants = dropZone.tenant ? dropZone.tenant : [];

				if (_.intersection([...parentTenants, ...dropTenants], dragZone.tenant).length > 0)
					throw Error(t("zones.management.drag.error.parentTenant", { currentZone: dragZone.label }));
			}

			await updateItemParent(e.value, dragZone.id, dropZone.id);
			const updatedZoneConfig = await updateNodes(e.value);

			if (dragZone.devices?.items) {
				const selectedZone = await findTreeNodeByKey(updatedZoneConfig, dragZone.id);
				const { breadcrumbLabel, breadcrumbKey } = await getFullLocation(selectedZone);
				zoneBreadcrumb = { label: breadcrumbLabel, key: breadcrumbKey };
			}

			// Update the breadcrumbs for devices in the dragged zone
			for await (const zoneDevice of dragZone.devices.items) {
				await API.graphql(
					graphqlOperation(updateDevice, {
						input: { id: zoneDevice.id, zoneBreadcrumb: JSON.stringify(zoneBreadcrumb) },
					})
				);
			}

			const childZones = await getAllChildZonesFull(dragNode);
			for await (const childZone of childZones) {
				const {
					data: { getZone: zone },
				} = await API.graphql(graphqlOperation(getZoneBasic, { id: childZone.key }));

				if (zone.devices?.items) {
					for await (const zoneDevice of zone.devices.items) {
						const selectedZone = await findTreeNodeByKey(updatedZoneConfig, zone.id);
						const { breadcrumbLabel, breadcrumbKey } = await getFullLocation(selectedZone);
						const deviceZoneBreadcrumb = { label: breadcrumbLabel, key: breadcrumbKey };

						await API.graphql(
							graphqlOperation(updateDevice, {
								input: { id: zoneDevice.id, zoneBreadcrumb: JSON.stringify(deviceZoneBreadcrumb) },
							})
						);
					}
				}
			}

			setToast({
				...toast,
				severity: "success",
				summary: t(`zones.management.drag.toast.successSummary`),
				detail: t(`zones.management.drag.toast.successDetail`),
			});
		} catch (err) {
			console.error("Drag Error ::", err.message);
			setToast({
				...toast,
				severity: "error",
				summary: t(`zones.management.drag.toast.errorSummary`),
				detail: t(`zones.management.drag.toast.errorDetail`, { error: err.message }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const findTreeNode = async (nodes, findKey) => {
		let nodeResult = null;
		for await (const node of nodes) {
			if (node.key === findKey) {
				return node;
			}

			if (node.children) {
				const result = await findTreeNode(node.children, findKey);
				if (result) {
					nodeResult = result;
					break;
				}
			}
		}

		return nodeResult;
	};

	const deleteFromNodeTree = async (nodes, deleteKey) => {
		_.remove(nodes, (node) => {
			return node.key === deleteKey;
		});

		_.forEach(nodes, (node) => {
			if (node.children) {
				deleteFromNodeTree(node.children, deleteKey);
			}
		});
	};

	const updateItemParent = async (items, key, parentKey) => {
		if (!items) {
			return;
		}

		for await (const item of items) {
			// Test current object
			if (item.key === key) {
				item.parent = parentKey ? parentKey : null;
				await API.graphql(graphqlOperation(updateZone, { input: { id: item.key, parent: parentKey } }));
			}

			// Test children recursively
			const child = await updateItemParent(item.children, key, parentKey);
			if (child) return child;
		}
	};

	const nodeTemplate = (node, options) => {
		let label = <span>{node.label}</span>;
		let type = (
			<PillItem
				type="full"
				value={t(`zones.type.${node.type}`)}
				icon={zoneConfig[node.type].icon}
				wrapperStyle={zoneConfig[node.type].colour}
			/>
		);

		return (
			<div className={`flex gapCol-xsmall aItems-center ${options.className}`}>
				{type}
				{label}
			</div>
		);
	};

	const confirmDelete = () => {
		confirmDialog({
			header: t("zones.delete.header"),
			message: <Note messageKey={t("zones.delete.message")} icon="pi pi-exclamation-triangle fontColour-warn" />,
			acceptLabel: t("zones.delete.accept"),
			rejectLabel: t("zones.delete.reject"),
			acceptIcon: "pi pi-trash",
			acceptClassName: "error feature",
			accept: () => handleDelete(),
			reject: () => {}, //Do Nothing,
		});
	};

	const handleDelete = async () => {
		try {
			setLoading({ visible: true, message: t("zones.delete.loading") });

			const treeNode = await findTreeNode(nodes, selectedNodeKey);

			//Check if the zone has any children...
			if (treeNode.children && treeNode.children.length > 0) {
				const childZones = [];
				await getAllChildZones(treeNode, childZones);

				for await (const zone of childZones) {
					//Remove these zones from each childrens devices
					const zoneData = await API.graphql(graphqlOperation(getZoneBasic, { id: zone }));
					const zoneDevices = zoneData.data.getZone.devices?.items;
					for await (const zoneDevice of zoneDevices) {
						// console.log("Set Zone to NULL for Child Device ::", zoneDevice.id);
						await API.graphql(
							graphqlOperation(updateDevice, {
								input: { id: zoneDevice.id, zoneId: null, zoneBreadcrumb: null },
							})
						);
					}
					//Delete the child zone from dynamodb
					// console.log("Delete the child zone ::", zone);
					await API.graphql(graphqlOperation(deleteZone, { input: { id: zone } }));
				}
			}

			// Remove the selected zone from each dynamodb device in the zone
			const deviceIds = selectedZone.devices.items.map((device) => device.id);
			for await (const deviceId of deviceIds) {
				// console.log("Set Zone to NULL for Device ::", deviceId);
				await API.graphql(
					graphqlOperation(updateDevice, { input: { id: deviceId, zoneId: null, zoneBreadcrumb: null } })
				);
			}

			// Delete the selected zone from dynamodb
			await API.graphql(graphqlOperation(deleteZone, { input: { id: selectedNodeKey } }));

			//Update the scheme nodes
			const cloneNodes = _.cloneDeep(nodes);
			await deleteFromNodeTree(cloneNodes, selectedNodeKey);
			await updateNodes(cloneNodes);

			//Set the selected zone to null
			setSelectedNodeKey(null);

			setToast({
				...toast,
				severity: "success",
				summary: t(`zones.delete.toast.successSummary`),
				detail: t(`zones.delete.toast.successDetail`),
			});
		} catch (err) {
			console.error("Delete Zone Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t(`zones.delete.toast.errorSummary`),
				detail: t(`zones.delete.toast.errorDetail`, { error: err.message }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const handleSelectedNodeKey = (selectedKey) => {
		if (selectedNodeKey === selectedKey) setSelectedNodeKey(null);
		else setSelectedNodeKey(selectedKey);
	};

	return (
		<>
			<PageHeader title={t("zones.management.pageTitle")} />
			<main className="appMain">
				<div className="appMain-content">
					<div className="actions flex jContent-end gap-small">
						{/* <Link to={`${nav.user.base}/create`} className="button">
							{t("user.management.actions.create")}
						</Link> */}
						{/* <Button onClick={() => setShowAssignTenantDialog(true)} disabled={!selectedZone}>
							{t("device.actions.assignTenant")}
						</Button> */}
						<Button
							label={t("zones.management.actions.delete")}
							disabled={!selectedNodeKey}
							className="error"
							onClick={() => confirmDelete()}
						/>
						<Button
							label={t("zones.management.actions.edit")}
							disabled={!selectedNodeKey}
							onClick={() => setShowEditDialog(true)}
						/>
						<Button
							label={t("zones.management.actions.create")}
							className="feature"
							onClick={() => setShowCreateDialog(true)}
						/>
					</div>
					<div className={`zoneGrid ${selectedNodeKey && "withDetails"}`}>
						<section className="card">
							<header className="marginBottom-medium">
								<h3>{t("zones.management.title")}</h3>
							</header>
							<Tree
								value={nodes}
								nodeTemplate={nodeTemplate}
								selectionMode="single"
								selectionKeys={selectedNodeKey}
								onSelectionChange={(e) => handleSelectedNodeKey(e.value)}
								dragdropScope="zonesDragDrop"
								onDragDrop={(e) => handleDragDrop(e)}
								expandedKeys={expandedKeys}
								onToggle={(e) => setExpandedKeys(e.value)}
							/>
						</section>
						{selectedNodeKey && (
							<section className="card">
								<header className="marginBottom-medium">
									<h3>{t("zones.detail.title")}</h3>
								</header>
								<ZoneLoader />
							</section>
						)}
					</div>
				</div>
			</main>

			<ZoneCreateDialog />
			{selectedNodeKey && <ZoneEditDialog />}
			{/* {nodes && <AssignTenantDialog zone={selectedZone} />} */}
		</>
	);
};

export default ZoneManagement;
