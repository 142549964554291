//Node Modules
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router";
import _ from "lodash";
import { v4 as uuid } from "uuid";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import {
	getAlarmGroupInputOutputs,
	getAlarmGroupOutputInputs,
	listDevicesAlarmGroup,
} from "../../../graphql/queries-custom";
import {
	createAlarmGroup,
	createAlarmGroupsOutput,
	createAlarmGroupsInput,
	updateAlarmGroup,
	deleteAlarmGroupsInput,
	deleteAlarmGroupsOutput,
	deleteAlarmGroup,
} from "../../../graphql/mutations";

//BinaryForge Components
import { Note } from "../../helpers";
import { FeatureItem } from "../../devices";
import DisableMoveDialog from "./DisableMoveDialog";

//3rd Party Components
import { Button } from "primereact/button";
import { PickList } from "primereact/picklist";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { classNames } from "primereact/utils";

//Atoms
import { dialogAtomFamily, toastAtom } from "../../../atoms";
import { selectedUserSchemeAtom } from "../../../atoms/user";
import { validationAtomFamily } from "../../../atoms/validation";

//Helpers
import { useApiRequest } from "../../../helpers/Api";

//Other
import { nav } from "../../../config/navigation";
import { delay } from "../../../helpers/general";
import { useValidateGroup } from "../../../helpers/Validation";

const AlarmGroupsForm = ({ type, initialData, groupId }) => {
	//Hooks
	const { t } = useTranslation();
	const navigate = useNavigate();
	const apiRequest = useApiRequest();
	const validateGroup = useValidateGroup();
	const shouldUpdatePeers = useRef(false);

	//Recoil
	const [toast, setToast] = useRecoilState(toastAtom);
	const selectedUserScheme = useRecoilValue(selectedUserSchemeAtom);
	const setShowDisableMoveDialog = useSetRecoilState(dialogAtomFamily("disableMoveDialog"));
	const setLoading = useSetRecoilState(dialogAtomFamily("loadingDialog"));
	const groupNameValidation = useRecoilValue(validationAtomFamily("alarmGroup"));

	//Local State
	const [isInputDirty, setIsInputDirty] = useState(false);
	const [isOutputDirty, setIsOutputDirty] = useState(false);
	const [inAlarm, setInAlarm] = useState(false);

	const [createDisabled, setCreateDisabled] = useState(false);
	const [outputDevices, setOutputDevices] = useState();
	const [inputDevices, setInputDevices] = useState();

	const [targetInputDevices, setTargetInputDevices] = useState([]);
	const [targetOutputDevices, setTargetOutputDevices] = useState([]);

	//On Page Load
	useEffect(() => {
		const doSetup = async () => {
			await getDevices();

			if (initialData && type === "edit") {
				reset({
					groupName: initialData.name,
				});

				await updateDeviceSelection();
			}
		};

		doSetup();

		return () => reset();
	}, [initialData, type]);

	// Form Default Values
	const defaultValues = {
		groupName: "",
	};

	// Form Init
	const {
		control,
		getValues,
		formState: { errors, isSubmitting, isValid },
		handleSubmit,
		trigger,
		reset,
	} = useForm({ defaultValues: defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	//On Data Update
	useEffect(() => {
		if (
			Object.entries(errors).length > 0 ||
			(isInputDirty && targetInputDevices.length === 0) ||
			(isOutputDirty && targetOutputDevices.length === 0) ||
			inAlarm
		) {
			setCreateDisabled(true);
		} else {
			setCreateDisabled(false);
		}
	}, [errors, targetInputDevices, targetOutputDevices, inAlarm]);

	const validateCreate = () => {
		if (!getValues("groupName") || targetInputDevices.length === 0 || targetOutputDevices.length === 0) {
			trigger("groupName");
			setIsInputDirty(true);
			setIsOutputDirty(true);
			throw Error("");
		}
		return;
	};

	const getDevices = async () => {
		const {
			data: {
				deviceByScheme: { items: devicesData },
			},
		} = await API.graphql(
			graphqlOperation(listDevicesAlarmGroup, {
				schemeId: selectedUserScheme.id,
			})
		);

		const uiOutputDevices = devicesData.filter((device) => device.outputDevice?.length > 0);
		const uiInputDevices = devicesData.filter(
			(device) => device.inputDevice?.length > 0 && !device.inputDevice.includes("FLOWSTOP")
		);
		setOutputDevices(uiOutputDevices);
		setInputDevices(uiInputDevices);
	};

	const updateDeviceSelection = async () => {
		setTargetInputDevices(initialData.inputDevices);
		setTargetOutputDevices(initialData.outputDevices);

		setInputDevices((prevState) => _.differenceBy(prevState, initialData.inputDevices, "id"));
		setOutputDevices((prevState) => _.differenceBy(prevState, initialData.outputDevices, "id"));

		if (initialData.inAlarm.length > 0) setInAlarm(true);
	};

	let allowMove = true;

	const onChangeFlexipad = (event) => {
		if (allowMove) {
			setInputDevices(event.source);
			setTargetInputDevices(event.target);
			setIsInputDirty(true);
		}
		allowMove = true;
	};

	const onChangeFlowstop = (event) => {
		setOutputDevices(event.source);
		setTargetOutputDevices(event.target);
		setIsOutputDirty(true);
	};

	const handleCheckAlarm = (event) => {
		const devicesInAlarm = event.value.filter((d) => d.alert.sensor_state);
		if (devicesInAlarm.length > 0) {
			setShowDisableMoveDialog(true);
			allowMove = false;
		}
	};

	const inputItemTemplate = (item) => {
		const isInAlarm = item.alert.sensor_state;
		const wrapperStyles = [];
		if (isInAlarm) wrapperStyles.push("error");
		return (
			<div className="flexVert">
				<span className="fontWeight-bold">{item.name}</span>
				<div className="flex gapCol-xsmall">
					{item.inputDevice?.map((d) => (
						<FeatureItem key={uuid()} value={d} type="input" wrapperStyles={wrapperStyles} />
					))}
				</div>
			</div>
		);
	};

	const outputItemTemplate = (item) => {
		return (
			<div className="flexVert">
				<span className="fontWeight-bold">{item.name}</span>
				<div className="flex gapCol-xsmall">
					{item.outputDevice?.map((d) => (
						<FeatureItem key={uuid()} value={d} type="input" />
					))}
				</div>
			</div>
		);
	};

	const initSubmit = async () => {
		if (isValid) setLoading({ visible: true, message: "Saving Alarm Group" });

		if (type === "create") {
			await handleSubmit(handleCreateGroup)();
		} else {
			await handleSubmit(handleEditGroup)();
		}

		console.log("Update ::", shouldUpdatePeers.current);

		if (isValid && shouldUpdatePeers.current) updatePeers();
	};

	const updatePeers = async (showLoading = true) => {
		console.log("Updaing ESP-Now Peers...");
		try {
			if (showLoading) setLoading({ visible: true, message: t("alarm.group.peers.loading") });

			//Get a list of outputs we need to send updates to
			let outputs = [...targetOutputDevices];
			if (type === "edit" && initialData.outputDevices !== targetOutputDevices)
				outputs = _.uniqBy([...targetOutputDevices, ...initialData.outputDevices], "id");
			else console.log("No output changes");

			// console.log("All Outputs ::", outputs);

			for (const output of outputs) {
				const {
					data: { getDevice: outputData },
				} = await API.graphql(
					graphqlOperation(getAlarmGroupOutputInputs, {
						id: output.id,
					})
				);

				const peers = _.uniqBy(
					_.flatten(
						outputData.alarmGroupsOutput.items.map((item) =>
							item.alarmGroup.input.items.map((input) => {
								const macAddress = JSON.parse(input.device.reported_state).wifi.mac;
								return { id: macAddress, isReceiver: false };
							})
						)
					),
					"id"
				);

				// console.log("Peers for Output", output.name, "::", peers);
				const enabled = peers.length > 0 ? true : false;

				const desiredState = {
					thingName: output.id,
					desiredState: { ESPNow: { enabled, peers } },
				};

				// console.log("Desired State Outputs ::", desiredState);
				apiRequest("post", "/shadow/desired/update", desiredState, null);
			}

			// await delay(10000);

			let inputs = [...targetInputDevices];
			if (type === "edit" && initialData.inputDevices !== targetInputDevices)
				inputs = _.uniqBy([...inputs, ...initialData.inputDevices], "id");
			else console.log("No input changes");

			// console.log("All inputs ::", inputs);

			for (const input of inputs) {
				const {
					data: { getDevice: inputData },
				} = await API.graphql(
					graphqlOperation(getAlarmGroupInputOutputs, {
						id: input.id,
					})
				);

				const peers = _.uniqBy(
					_.flatten(
						inputData.alarmGroupsInput.items.map((item) =>
							item.alarmGroup.output.items.map((output) => {
								const macAddress = JSON.parse(output.device.reported_state).wifi.mac;
								return { id: macAddress, isReceiver: true };
							})
						)
					),
					"id"
				);

				// console.log("Peers for Input", input.name, "::", peers);
				const enabled = peers.length > 0 ? true : false;

				const desiredState = {
					thingName: input.id,
					desiredState: { ESPNow: { enabled, peers } },
				};

				// console.log("Desired State Inputs ::", desiredState);
				apiRequest("post", "/shadow/desired/update", desiredState, null);
			}
		} catch (err) {
			console.error(err);
		} finally {
			shouldUpdatePeers.current = false;
			if (showLoading) setLoading({ visible: false, message: "" });
		}
	};

	const handleCreateGroup = async (data) => {
		try {
			//Make sure the details are valid
			validateCreate();
			await validateGroup(data.groupName, "");

			//Create the alarm group in Dynamo DB
			const {
				data: { createAlarmGroup: newAlarmGroup },
			} = await API.graphql(
				graphqlOperation(createAlarmGroup, {
					input: {
						name: data.groupName,
						schemeId: selectedUserScheme.id,
					},
				})
			);

			//Using the previously created group add the FlowStop devices relationship
			for await (const flowstop of targetOutputDevices) {
				await API.graphql(
					graphqlOperation(createAlarmGroupsOutput, {
						input: {
							deviceID: flowstop.id,
							alarmGroupID: newAlarmGroup.id,
						},
					})
				);
			}
			//Using the previously created group add the Flexi-Pad devices relationship
			for await (const flexipad of targetInputDevices) {
				await API.graphql(
					graphqlOperation(createAlarmGroupsInput, {
						input: {
							deviceID: flexipad.id,
							alarmGroupID: newAlarmGroup.id,
						},
					})
				);
			}

			setToast({
				...toast,
				severity: "success",
				summary: t("alarm.group.create.toast.successSummary"),
				detail: t("alarm.group.create.toast.successDetail"),
			});

			shouldUpdatePeers.current = true;
			navigate(`${nav.alarm.base}/${nav.alarm.group.base}/${nav.alarm.group.management}`);
		} catch (err) {
			console.error("Create Group Error ::", err);
			let errorMsg = "";
			if (err.errors?.length > 0) errorMsg = err.errors.map((e) => e.message).join(", ");
			else errorMsg = err.message;
			shouldUpdatePeers.current = false;
			setToast({
				...toast,
				severity: "error",
				summary: t("alarm.group.create.toast.errorSummary"),
				detail: t("alarm.group.create.toast.errorDetail", { error: errorMsg }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const handleEditGroup = async (data) => {
		try {
			//Make sure the details are valid
			validateCreate();
			await validateGroup(data.groupName, initialData.id);

			//Get the form data
			if (data.groupName !== initialData.name) {
				await API.graphql(
					graphqlOperation(updateAlarmGroup, {
						input: {
							id: initialData.id,
							name: data.groupName,
						},
					})
				);
			}

			//Get the differences in flowstop
			const addedFlexipad = _.differenceBy(targetInputDevices, initialData.inputDevices, "id");
			const removedFlexipad = _.intersectionBy(initialData.inputDevices, inputDevices, "id");
			const addedFlowstop = _.differenceBy(targetOutputDevices, initialData.outputDevices, "id");
			const removedFlowstop = _.intersectionBy(initialData.outputDevices, outputDevices, "id");

			// console.log("Added Flexi ::", targetInputDevices, data.inputDevices, addedFlexipad);
			// console.log("Removed Flexi ::", removedFlexipad);
			// console.log("Added Flow ::", addedFlowstop);
			// console.log("Added Flow ::", removedFlowstop);

			for await (const flexipad of addedFlexipad) {
				API.graphql(
					graphqlOperation(createAlarmGroupsInput, {
						input: {
							deviceID: flexipad.id,
							alarmGroupID: initialData.id,
						},
					})
				);
			}
			for await (const flowstop of addedFlowstop) {
				API.graphql(
					graphqlOperation(createAlarmGroupsOutput, {
						input: {
							deviceID: flowstop.id,
							alarmGroupID: initialData.id,
						},
					})
				);
			}
			for await (const flexipad of removedFlexipad) {
				API.graphql(
					graphqlOperation(deleteAlarmGroupsInput, {
						input: {
							id: flexipad.relId,
						},
					})
				);
			}
			for await (const flowstop of removedFlowstop) {
				API.graphql(
					graphqlOperation(deleteAlarmGroupsOutput, {
						input: {
							id: flowstop.relId,
						},
					})
				);
			}

			setToast({
				...toast,
				severity: "success",
				summary: t("alarm.group.edit.toast.successSummary"),
				detail: t("alarm.group.edit.toast.successDetail"),
			});

			shouldUpdatePeers.current = true;
			navigate(`${nav.alarm.base}/${nav.alarm.group.base}/${nav.alarm.group.management}`);
		} catch (err) {
			console.error("Edit Group Error ::", err);
			let errorMsg = "";
			if (err.errors?.length > 0) errorMsg = err.errors.map((e) => e.message).join(", ");
			else errorMsg = err.message;
			shouldUpdatePeers.current = false;
			setToast({
				...toast,
				severity: "error",
				summary: t("alarm.group.edit.toast.errorSummary"),
				detail: t("alarm.group.edit.toast.errorDetail", { error: errorMsg }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const confirmDelete = () =>
		confirmDialog({
			header: t("alarm.group.delete.header"),
			message: (
				<Note messageKey={t("alarm.group.delete.message")} icon="pi pi-exclamation-triangle fontColour-warn" />
			),
			acceptLabel: t("alarm.group.delete.delete"),
			rejectLabel: t("alarm.group.delete.keep"),
			acceptIcon: "pi pi-trash",
			acceptClassName: "error feature",
			rejectClassName: "",
			accept: () => doDeleteGroup(),
			reject: () => {}, //Do Nothing,
		});

	const doDeleteGroup = async () => {
		try {
			setLoading({ visible: true, message: t("alarm.group.delete.loading") });

			//Delete all the AlarmGroupsInput
			for await (const flexipad of targetInputDevices) {
				await API.graphql(
					graphqlOperation(deleteAlarmGroupsInput, {
						input: {
							id: flexipad.relId,
						},
					})
				);
			}

			//Delete all the AlarmGroupsOutput
			for await (const flowstop of targetOutputDevices) {
				await API.graphql(
					graphqlOperation(deleteAlarmGroupsOutput, {
						input: {
							id: flowstop.relId,
						},
					})
				);
			}

			await updatePeers(false);

			//Delete the AlarmGroup
			await API.graphql(graphqlOperation(deleteAlarmGroup, { input: { id: groupId } }));

			setToast({
				...toast,
				severity: "success",
				summary: t("alarm.group.delete.toast.successSummary"),
				detail: t("alarm.group.delete.toast.successDetail"),
			});

			navigate(`${nav.alarm.base}/${nav.alarm.group.base}/${nav.alarm.group.management}`);
		} catch (err) {
			console.error("Delete Group Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("alarm.group.delete.toast.errorSummary"),
				detail: t("alarm.group.delete.toast.errorDetail", { error: err.message }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	return (
		<>
			<div className="actions flex jContent-end gap-small">
				{type === "edit" && (
					<Button
						className="error"
						label={t("alarm.group.edit.actions.delete")}
						icon="pi pi-trash"
						onClick={() => confirmDelete()}
						disabled={inAlarm}
					/>
				)}
				<Button
					className="feature"
					label={
						type === "create"
							? t("alarm.group.create.actions.submit")
							: t("alarm.group.edit.actions.submit")
					}
					icon="pi pi-save"
					onClick={() => initSubmit()}
					disabled={createDisabled || isSubmitting}
				/>
			</div>
			<div className="flexVert gapRow-small">
				{isInputDirty && targetInputDevices.length < 1 && (
					<Note
						icon="pi pi-times-circle fontColour-error"
						messageKey={t("alarm.group.common.disabled", { context: type, device: "Input" })}
						messageStyle="fontWeight-bold"
					/>
				)}
				{isOutputDirty && targetOutputDevices.length < 1 && (
					<Note
						icon="pi pi-times-circle fontColour-error"
						messageKey={t("alarm.group.common.disabled", { context: type, device: "Output" })}
						messageStyle="fontWeight-bold"
					/>
				)}
				{inAlarm && (
					<Note
						icon="pi pi-exclamation-triangle fontColour-warn"
						messageKey={t("alarm.group.edit.inAlarm", {
							count: initialData.inAlarm.length,
							inAlarm: initialData.inAlarm.map((a) => a.device.name).join(", "),
						})}
						messageStyle="fontWeight-bold"
					/>
				)}
			</div>
			<div className="card">
				<header>
					<h3>Group Details</h3>
				</header>
				<form>
					<div className="formField">
						<label htmlFor="groupName">{t("alarm.group.create.form.groupName.label")}</label>
						<Controller
							name="groupName"
							control={control}
							rules={{
								required: t("common.form.required"),
								maxLength: {
									value: groupNameValidation["maxLength"],
									message: t("validation.alarmGroup.maxLength", {
										length: groupNameValidation["maxLength"],
									}),
								},
								pattern: {
									value: RegExp(groupNameValidation["pattern"]),
									message: t("validation.alarmGroup.pattern"),
								},
							}}
							render={({ field, fieldState }) => (
								<InputText {...field} className={classNames({ "p-error": fieldState.error })} />
							)}
						/>
						{getFormErrorMessage("groupName")}
					</div>
				</form>
			</div>
			<div className="card">
				<header>
					<h3>Group Input Devices</h3>
				</header>
				<PickList
					source={inputDevices}
					target={targetInputDevices}
					onChange={onChangeFlexipad}
					itemTemplate={inputItemTemplate}
					filter="true"
					filterBy="name"
					breakpoint="1400px"
					sourceHeader="Available"
					targetHeader="Selected"
					showSourceControls={false}
					showTargetControls={false}
					sourceStyle={{ height: "15rem" }}
					targetStyle={{ height: "15rem" }}
					sourceFilterPlaceholder="Search by name"
					targetFilterPlaceholder="Search by name"
					onMoveToTarget={handleCheckAlarm}
					onMoveToSource={handleCheckAlarm}
				/>
			</div>
			<div className="card">
				<header>
					<h3>Group Output Devices</h3>
				</header>
				<PickList
					source={outputDevices}
					target={targetOutputDevices}
					onChange={onChangeFlowstop}
					itemTemplate={outputItemTemplate}
					filter="true"
					filterBy="name"
					breakpoint="1400px"
					sourceHeader="Available"
					targetHeader="Selected"
					showSourceControls={false}
					showTargetControls={false}
					sourceStyle={{ height: "15rem" }}
					targetStyle={{ height: "15rem" }}
					sourceFilterPlaceholder="Search by name"
					targetFilterPlaceholder="Search by name"
				/>
			</div>
			<DisableMoveDialog />
		</>
	);
};

export default AlarmGroupsForm;
