export const dashboard = {
	pageTitle: "Dashboard",
	device: {
		header: "Device Summary",
	},
	event: {
		header: "Event Summary",
	},
	user: {
		header: "User Summary",
	},
	analytics: {
		default: {
			header: "Select Default Chart",
			set: "Set Default",
			open: "Change Default Chart",
			set: "Set Default Chart",
		},
		noConfig:
			"You have not yet configured a default report for your dashboard. Please select one of the preset reports for this scheme to show a chart.",
		noData: "There is currently no data for this report. Make sure your device are communicating and the report is configured correctly.",
	},
};
