import { atom, selector } from "recoil";
import { API, graphqlOperation } from "aws-amplify";
import { DateTime } from "luxon";
import _ from "lodash";
import { listReports } from "../graphql/queries";
import {
	LiveEventBySchemeSummary,
	ArchiveEventBySchemeSummary,
	listDevicesTable,
	listUserSchemeConfigsBasic,
} from "../graphql/queries-custom";
import { loggedInUserAtom, selectedUserSchemeAtom } from "./user";

const restApiName = "andelRestApi";

export const analyticsParamsAtom = atom({
	key: "analyticsParamsAtom",
	default: null,
});

export const analyticsParamsSelector = selector({
	key: "analyticsParamsSelector",
	get: async ({ get }) => {
		const params = get(analyticsParamsAtom);

		if (params.generate) {
			try {
				const resp = await API.post(restApiName, `/analytics/report/get-data`, { body: params });

				if (resp.dataExceeded) return { errors: { dataExceeded: true } };

				return resp;
			} catch (err) {
				console.error(err);
			}
		}
	},
});

export const analyticsListSelector = selector({
	key: "analyticsListSelector",
	get: async ({ get }) => {
		const { sub } = get(loggedInUserAtom);
		const {
			data: {
				listReports: { items: reports },
			},
		} = await API.graphql(
			graphqlOperation(listReports, {
				filter: {
					or: [
						{ and: [{ public: { eq: true } }, { type: { eq: "HISTORICAL" } }] },
						{ and: [{ public: { eq: false }, createdBy: { eq: sub } }, { type: { eq: "HISTORICAL" } }] },
					],
				},
			})
		);

		return reports;
	},
	cachePolicy_UNSTABLE: { eviction: "most-recent" },
});

export const deviceSummarySelector = selector({
	key: "deviceSummarySelector",
	get: async ({ get }) => {
		const { id: schemeId } = get(selectedUserSchemeAtom);

		let data = [];
		let lastNextToken = null;

		do {
			const {
				data: {
					deviceByScheme: { items: devicesData, nextToken },
				},
			} = await API.graphql(
				graphqlOperation(listDevicesTable, {
					schemeId: schemeId,
				})
			);

			lastNextToken = nextToken;
			data.push(...devicesData);
		} while (lastNextToken !== null);

		const total = data.length;
		const inputDevices = _.countBy(data, "inputDevice");
		const outputDevices = _.countBy(data, "outputDevice");
		const none = data.filter((item) => !item.inputDevice && !item.outputDevice).length;

		delete inputDevices["null"];
		delete outputDevices["null"];

		return { total, inputDevices, outputDevices, none };
	},
});

export const eventSummarySelector = selector({
	key: "eventSummarySelector",
	get: async ({ get }) => {
		const { id: schemeId } = get(selectedUserSchemeAtom);

		let liveData = [];
		let lastLiveNextToken = null;

		do {
			const {
				data: {
					liveEventByScheme: { items: liveEventData, nextToken },
				},
			} = await API.graphql(
				graphqlOperation(LiveEventBySchemeSummary, {
					schemeId: schemeId,
					nextToken: lastLiveNextToken,
				})
			);

			lastLiveNextToken = nextToken;
			liveData.push(...liveEventData);
		} while (lastLiveNextToken !== null);

		let archiveData = [];
		let lastArchiveNextToken = null;

		do {
			const {
				data: {
					archiveEventByScheme: { items: archiveEventData, nextToken },
				},
			} = await API.graphql(
				graphqlOperation(ArchiveEventBySchemeSummary, {
					schemeId: schemeId,
					filter: {
						updatedAt: {
							ge: DateTime.now().minus({ months: 1 }).toISO(),
						},
					},
					limit: 500,
					nextToken: lastArchiveNextToken,
				})
			);

			lastArchiveNextToken = nextToken;
			archiveData.push(...archiveEventData);
		} while (lastArchiveNextToken !== null);

		const total = { live: liveData.length, archive: archiveData.length };
		const liveCounts = _.countBy(liveData, "eventType");
		const archiveCounts = _.countBy(archiveData, "eventType");

		return { total, liveCounts, archiveCounts };
	},
});

export const userSummarySelector = selector({
	key: "userSummarySelector",
	get: async ({ get }) => {
		const scheme = get(selectedUserSchemeAtom);
		const cognitoUsers = await API.get(restApiName, `/user`, {
			headers: {
				"X-Scheme-Id": scheme.id,
			},
		});

		const {
			data: {
				listUserSchemeConfigs: { items: schemeUsers },
			},
		} = await API.graphql(
			graphqlOperation(listUserSchemeConfigsBasic, {
				filter: {
					schemeId: { eq: scheme.id },
				},
			})
		);

		cognitoUsers.map((u) => {
			const userRole = schemeUsers.find((su) => su.cognitoUserId === u.id).role;
			u.role = userRole;
			return userRole;
		});

		const activeUsers = cognitoUsers.filter((u) => u.active === true);
		const inactiveUsers = cognitoUsers.filter((u) => u.active === false);

		const total = { active: activeUsers.length, inactive: inactiveUsers.length };
		const roleCounts = { active: _.countBy(activeUsers, "role"), inactive: _.countBy(inactiveUsers, "role") };

		return { total, roleCounts };
	},
});
