//Node Modules
import { useRef, useState, useEffect } from "react";
import { useRecoilValueLoadable, useRecoilValue } from "recoil";
import { Trans, useTranslation } from "react-i18next";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { listDevicesTable } from "../../../graphql/queries-custom";

//BinaryForge Components
import { ReportResultTable, ReportResultsChart } from "./";
import { DatatableSkeleton } from "../../helpers/skeleton";
import { Note } from "../../helpers";

//3rd Party Components
import { Button } from "primereact/button";
import { ToggleButton } from "primereact/togglebutton";

//Atoms
import { reportParamsSelector } from "../../../atoms/report";
import { selectedUserSchemeAtom } from "../../../atoms/user";

//Helpers

//Other

const ReportResultsLoader = ({ allDevices, selectedDevices, preset }) => {
	const tableRef = useRef();

	//Local State
	const [viewType, setViewType] = useState(true);
	const [devices, setDevices] = useState([]);

	//Recoil
	const { t } = useTranslation();
	const { state, contents } = useRecoilValueLoadable(reportParamsSelector);
	const { id: schemeId } = useRecoilValue(selectedUserSchemeAtom);

	useEffect(() => {
		if (allDevices && !selectedDevices.length) {
			getAllDevices();
		} else {
			setDevices(selectedDevices);
		}
	}, [allDevices, selectedDevices]);

	const getAllDevices = async () => {
		const {
			data: {
				deviceByScheme: { items: devicesData },
			},
		} = await API.graphql(
			graphqlOperation(listDevicesTable, {
				schemeId: schemeId,
			})
		);

		setDevices(devicesData);
	};

	const Content = () => {
		switch (state) {
			case "hasError":
				return (
					<Note
						messageKey={`Error: ${contents.message ? contents.message : contents}`}
						icon="pi-exclamation-circle fontColour-error"
					/>
				);
			case "loading":
				return <DatatableSkeleton columns={[t("report.table.timestamp"), "Device"]} />;
			case "hasValue":
				if (contents.errors) {
					const key = preset ? "report.preset.dataExceeded" : "report.table.dataExceeded";
					return (
						<Note
							messageKey={
								<Trans i18nKey={key} components={{ ul: <ul />, li: <li /> }} context="HISTORICAL" />
							}
							icon="pi-exclamation-triangle fontColour-warn"
						/>
					);
				} else {
					return viewType ? (
						<ReportResultTable
							reportResultsData={contents.table}
							selectedDevices={devices}
							ref={tableRef}
						/>
					) : (
						<ReportResultsChart reportResultsData={contents.chart} timestamps={contents.timestamp} />
					);
				}
			default:
				return null;
		}
	};

	return (
		<section className="marginTop-large">
			<div className="flex jContent-spaceBetween">
				<header>
					<h3>Report Results</h3>
				</header>
				<div className="flex gapCol-small">
					<Button onClick={() => tableRef.current.exportCSV(false)} visible={viewType && !contents.errors}>
						{t("common.table.export")}
					</Button>
					<ToggleButton
						onLabel={t("report.view.chart")}
						offLabel={t("report.view.table")}
						onIcon="pi pi-chart-line"
						offIcon="pi pi-table"
						checked={viewType}
						onChange={(e) => setViewType(e.value)}
						style={{ display: contents.errors ? "none" : "inline-flex" }}
					/>
				</div>
			</div>
			{devices && <Content />}
		</section>
	);
};

export default ReportResultsLoader;
