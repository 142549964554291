//Node Modules
import { useEffect } from "react";
import { useRecoilState } from "recoil";

//3rd Party Components
import { Dialog } from "primereact/dialog";

//Atoms
import { dialogAtomFamily } from "../../atoms/dialog";

function DialogCustom({ header, footer, id, children, dialogClass, hideFunction }) {
	const appElement = document.getElementsByClassName("app")[0];

	const [show, setShow] = useRecoilState(dialogAtomFamily(id));

	useEffect(() => {
		return () => setShow(false);
	}, []);

	const handleHide = () => {
		hideFunction();
		setShow(false);
	};

	return (
		<Dialog
			header={header}
			footer={footer}
			visible={show}
			closable={true}
			onHide={() => (hideFunction ? handleHide() : setShow(false))}
			appendTo={appElement}
			maskClassName="mask"
			className={dialogClass}>
			{children}
		</Dialog>
	);
}

export default DialogCustom;
