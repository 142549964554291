//Node Modules
import { useTranslation } from "react-i18next";

//3rd Party Components
import { Skeleton } from "primereact/skeleton";

const ZoneDetailsSkeleton = () => {
	//Hooks
	const { t } = useTranslation();

	return (
		<div>
			<div className="grid columns-label gapCol-small gapRow-xsmall marginBottom-small">
				<span>{t("zones.detail.name.label")}:</span>
				<span>
					<Skeleton width="75%" />
				</span>
				<span>{t("zones.detail.type.label")}:</span>
				<span>
					<Skeleton width="85%" />
				</span>
			</div>
			<div className="grid columns-2 gapCol-medium aItems-start">
				<div className="grid columns-label gapCol-small gapRow-xsmall">
					<span>{t("common.createdBy")}:</span>
					<span>
						<Skeleton width="65%" />
					</span>
					<span>{t("common.createdAt")}:</span>
					<span>
						<Skeleton width="95%" />
					</span>
				</div>
				<div className="grid columns-label gapCol-small gapRow-xsmall">
					<span>{t("common.updatedBy")}:</span>
					<span>
						<Skeleton width="75%" />
					</span>
					<span>{t("common.updatedAt")}:</span>
					<span>
						<Skeleton width="90%" />
					</span>
				</div>
			</div>
			<div>
				<header className="marginTop-medium">
					<h3>{t("zones.detail.devices.title")}</h3>
				</header>
				<div className="flexVert gapRow-xsmall">
					<span>
						<Skeleton width="50%" />
					</span>
					<span>
						<Skeleton width="40%" />
					</span>
					<span>
						<Skeleton width="45%" />
					</span>
				</div>
			</div>
		</div>
	);
};

export default ZoneDetailsSkeleton;
