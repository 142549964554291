import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PillItem } from ".";
import { zoneConfig } from "../../config/zones";
import { nav } from "../../config/navigation";

const Breadcrumb = ({ items, reverse, highlightIndex }) => {
	const [breadcrumbItems, setBreadcrumbItems] = useState([]);

	useEffect(() => {
		if (reverse) setBreadcrumbItems(items.reverse());
		else setBreadcrumbItems(items);
	}, [items]);

	return (
		<div className="flex flex-wrap gapCol-small gapRow-xsmall aItems-center">
			{breadcrumbItems?.map((item, idx) => {
				const counter = idx + 1;

				let resp = [];
				if (counter === highlightIndex) {
					resp.push(
						<Link
							key={`${item.type}-${item.id}`}
							to={`${nav.zones.base}/${nav.zones.management}?zoneId=${item.id}`}>
							<PillItem value={item.label} icon={zoneConfig[item.type].icon} wrapperStyle="blue1" />
						</Link>
					);
				} else {
					resp.push(<span key={`${item.type}-${item.id}`}>{item.label}</span>);
				}
				if (counter !== items.length) {
					resp.push(<span key={`sep-${item.id}`} className="fontSize-small fontColour-lighter">{`>>`}</span>);
				}

				return resp;
			})}
		</div>
	);
};

export default Breadcrumb;
