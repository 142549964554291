/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      name
      reported_state
      desired_state
      desired_state_info
      modbus_state
      alarmGroupsInput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroupsOutput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inputDevice
      outputDevice
      alert {
        id
        sensor_state
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deviceSensorAlertDeviceId
        __typename
      }
      liveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      archiveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneBreadcrumb
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deviceByName = /* GraphQL */ `
  query DeviceByName(
    $name: String!
    $schemeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceByName(
      name: $name
      schemeId: $schemeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deviceByScheme = /* GraphQL */ `
  query DeviceByScheme(
    $schemeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceByScheme(
      schemeId: $schemeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deviceByZone = /* GraphQL */ `
  query DeviceByZone(
    $zoneId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceByZone(
      zoneId: $zoneId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deviceByInstaller = /* GraphQL */ `
  query DeviceByInstaller(
    $installerId: String!
    $schemeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceByInstaller(
      installerId: $installerId
      schemeId: $schemeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDeviceProvisioning = /* GraphQL */ `
  query GetDeviceProvisioning($id: ID!) {
    getDeviceProvisioning(id: $id) {
      id
      name
      inputDevice
      outputDevice
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDeviceProvisionings = /* GraphQL */ `
  query ListDeviceProvisionings(
    $filter: ModelDeviceProvisioningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceProvisionings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        inputDevice
        outputDevice
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        installerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deviceProvisioningByName = /* GraphQL */ `
  query DeviceProvisioningByName(
    $name: String!
    $schemeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceProvisioningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceProvisioningByName(
      name: $name
      schemeId: $schemeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        inputDevice
        outputDevice
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        installerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deviceProvisioningByScheme = /* GraphQL */ `
  query DeviceProvisioningByScheme(
    $schemeId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceProvisioningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceProvisioningByScheme(
      schemeId: $schemeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        inputDevice
        outputDevice
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        installerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deviceProvisioningByZone = /* GraphQL */ `
  query DeviceProvisioningByZone(
    $zoneId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceProvisioningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceProvisioningByZone(
      zoneId: $zoneId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        inputDevice
        outputDevice
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        installerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deviceProvisioningByInstaller = /* GraphQL */ `
  query DeviceProvisioningByInstaller(
    $installerId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceProvisioningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceProvisioningByInstaller(
      installerId: $installerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        inputDevice
        outputDevice
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        installerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlarmGroup = /* GraphQL */ `
  query GetAlarmGroup($id: ID!) {
    getAlarmGroup(id: $id) {
      id
      name
      input {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      output {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlarmGroups = /* GraphQL */ `
  query ListAlarmGroups(
    $filter: ModelAlarmGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlarmGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const alarmGroupByScheme = /* GraphQL */ `
  query AlarmGroupByScheme(
    $schemeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAlarmGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alarmGroupByScheme(
      schemeId: $schemeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDeviceSensorAlert = /* GraphQL */ `
  query GetDeviceSensorAlert($id: ID!) {
    getDeviceSensorAlert(id: $id) {
      id
      sensor_state
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      deviceSensorAlertDeviceId
      __typename
    }
  }
`;
export const listDeviceSensorAlerts = /* GraphQL */ `
  query ListDeviceSensorAlerts(
    $filter: ModelDeviceSensorAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceSensorAlerts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sensor_state
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deviceSensorAlertDeviceId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deviceSensorAlertBySensorState = /* GraphQL */ `
  query DeviceSensorAlertBySensorState(
    $sensor_state: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceSensorAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceSensorAlertBySensorState(
      sensor_state: $sensor_state
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sensor_state
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deviceSensorAlertDeviceId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deviceSensorAlertByScheme = /* GraphQL */ `
  query DeviceSensorAlertByScheme(
    $schemeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceSensorAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceSensorAlertByScheme(
      schemeId: $schemeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sensor_state
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deviceSensorAlertDeviceId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotifyToken = /* GraphQL */ `
  query GetNotifyToken($id: ID!) {
    getNotifyToken(id: $id) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotifyTokens = /* GraphQL */ `
  query ListNotifyTokens(
    $filter: ModelNotifyTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifyTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScheme = /* GraphQL */ `
  query GetScheme($id: ID!) {
    getScheme(id: $id) {
      id
      name
      users {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          isDefaultScheme
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroups {
        items {
          id
          name
          input {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          output {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorAlerts {
        items {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        nextToken
        __typename
      }
      userNotifyConfig {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          config
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contact
      contactEmergency
      zoneConfig
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSchemes = /* GraphQL */ `
  query ListSchemes(
    $filter: ModelSchemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchemes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const schemeByName = /* GraphQL */ `
  query SchemeByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSchemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schemeByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserSchemeConfig = /* GraphQL */ `
  query GetUserSchemeConfig($id: ID!) {
    getUserSchemeConfig(id: $id) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      isDefaultScheme
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserSchemeConfigs = /* GraphQL */ `
  query ListUserSchemeConfigs(
    $filter: ModelUserSchemeConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSchemeConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        isDefaultScheme
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userSchemeConfigByCognitoUserId = /* GraphQL */ `
  query UserSchemeConfigByCognitoUserId(
    $cognitoUserId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserSchemeConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userSchemeConfigByCognitoUserId(
      cognitoUserId: $cognitoUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        isDefaultScheme
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userSchemeConfigBySchemeId = /* GraphQL */ `
  query UserSchemeConfigBySchemeId(
    $schemeId: ID!
    $cognitoUserId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserSchemeConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userSchemeConfigBySchemeId(
      schemeId: $schemeId
      cognitoUserId: $cognitoUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        isDefaultScheme
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserDashboardConfig = /* GraphQL */ `
  query GetUserDashboardConfig($id: ID!) {
    getUserDashboardConfig(id: $id) {
      id
      cognitoUserId
      schemeId
      dashboardAnalytics
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserDashboardConfigs = /* GraphQL */ `
  query ListUserDashboardConfigs(
    $filter: ModelUserDashboardConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDashboardConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        schemeId
        dashboardAnalytics
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userDashboardConfigByCognitoUserId = /* GraphQL */ `
  query UserDashboardConfigByCognitoUserId(
    $cognitoUserId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserDashboardConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userDashboardConfigByCognitoUserId(
      cognitoUserId: $cognitoUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        schemeId
        dashboardAnalytics
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userDashboardConfigBySchemeId = /* GraphQL */ `
  query UserDashboardConfigBySchemeId(
    $schemeId: ID!
    $cognitoUserId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserDashboardConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userDashboardConfigBySchemeId(
      schemeId: $schemeId
      cognitoUserId: $cognitoUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        schemeId
        dashboardAnalytics
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserNotifyConfig = /* GraphQL */ `
  query GetUserNotifyConfig($id: ID!) {
    getUserNotifyConfig(id: $id) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      config
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserNotifyConfigs = /* GraphQL */ `
  query ListUserNotifyConfigs(
    $filter: ModelUserNotifyConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotifyConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        config
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userNotifyConfigByCognitoUserId = /* GraphQL */ `
  query UserNotifyConfigByCognitoUserId(
    $cognitoUserId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserNotifyConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNotifyConfigByCognitoUserId(
      cognitoUserId: $cognitoUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        config
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userNotifyConfigBySchemeId = /* GraphQL */ `
  query UserNotifyConfigBySchemeId(
    $schemeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserNotifyConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNotifyConfigBySchemeId(
      schemeId: $schemeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        config
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getZone = /* GraphQL */ `
  query GetZone($id: ID!) {
    getZone(id: $id) {
      id
      schemeId
      label
      type
      parent
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listZones = /* GraphQL */ `
  query ListZones(
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const zoneBySchemeId = /* GraphQL */ `
  query ZoneBySchemeId(
    $schemeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    zoneBySchemeId(
      schemeId: $schemeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const zoneByLabel = /* GraphQL */ `
  query ZoneByLabel(
    $label: String!
    $schemeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    zoneByLabel(
      label: $label
      schemeId: $schemeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLiveEvent = /* GraphQL */ `
  query GetLiveEvent($id: ID!) {
    getLiveEvent(id: $id) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLiveEvents = /* GraphQL */ `
  query ListLiveEvents(
    $filter: ModelLiveEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLiveEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schemeId
        deviceId
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        eventType
        duration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const liveEventByScheme = /* GraphQL */ `
  query LiveEventByScheme(
    $schemeId: ID!
    $updatedAtCreatedAt: ModelLiveEventBySchemeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLiveEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    liveEventByScheme(
      schemeId: $schemeId
      updatedAtCreatedAt: $updatedAtCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schemeId
        deviceId
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        eventType
        duration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const liveEventByDevice = /* GraphQL */ `
  query LiveEventByDevice(
    $deviceId: ID!
    $updatedAtCreatedAt: ModelLiveEventByDeviceCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLiveEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    liveEventByDevice(
      deviceId: $deviceId
      updatedAtCreatedAt: $updatedAtCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schemeId
        deviceId
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        eventType
        duration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getArchiveEvent = /* GraphQL */ `
  query GetArchiveEvent($id: ID!) {
    getArchiveEvent(id: $id) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listArchiveEvents = /* GraphQL */ `
  query ListArchiveEvents(
    $filter: ModelArchiveEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArchiveEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schemeId
        deviceId
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        eventType
        duration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const archiveEventByScheme = /* GraphQL */ `
  query ArchiveEventByScheme(
    $schemeId: ID!
    $updatedAtCreatedAt: ModelArchiveEventBySchemeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArchiveEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    archiveEventByScheme(
      schemeId: $schemeId
      updatedAtCreatedAt: $updatedAtCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schemeId
        deviceId
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        eventType
        duration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const archiveEventByDevice = /* GraphQL */ `
  query ArchiveEventByDevice(
    $deviceId: ID!
    $updatedAtCreatedAt: ModelArchiveEventByDeviceCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArchiveEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    archiveEventByDevice(
      deviceId: $deviceId
      updatedAtCreatedAt: $updatedAtCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schemeId
        deviceId
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        eventType
        duration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getValidation = /* GraphQL */ `
  query GetValidation($model: String!) {
    getValidation(model: $model) {
      model
      validation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listValidations = /* GraphQL */ `
  query ListValidations(
    $model: String
    $filter: ModelValidationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listValidations(
      model: $model
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        model
        validation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getModbusConfig = /* GraphQL */ `
  query GetModbusConfig($deviceType: DEVICE_TYPE!) {
    getModbusConfig(deviceType: $deviceType) {
      deviceType
      modbusTemplate
      statusBits
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listModbusConfigs = /* GraphQL */ `
  query ListModbusConfigs(
    $deviceType: DEVICE_TYPE
    $filter: ModelModbusConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listModbusConfigs(
      deviceType: $deviceType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        deviceType
        modbusTemplate
        statusBits
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      type
      measureName
      allDevices
      devices
      from
      to
      aggregateType
      interval
      relative
      public
      name
      desc
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        measureName
        allDevices
        devices
        from
        to
        aggregateType
        interval
        relative
        public
        name
        desc
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCommsTracker = /* GraphQL */ `
  query GetCommsTracker($id: ID!) {
    getCommsTracker(id: $id) {
      id
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCommsTrackers = /* GraphQL */ `
  query ListCommsTrackers(
    $filter: ModelCommsTrackerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommsTrackers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlarmGroupsInput = /* GraphQL */ `
  query GetAlarmGroupsInput($id: ID!) {
    getAlarmGroupsInput(id: $id) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlarmGroupsInputs = /* GraphQL */ `
  query ListAlarmGroupsInputs(
    $filter: ModelAlarmGroupsInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlarmGroupsInputs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceID
        alarmGroupID
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        alarmGroup {
          id
          name
          input {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          output {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlarmGroupsOutput = /* GraphQL */ `
  query GetAlarmGroupsOutput($id: ID!) {
    getAlarmGroupsOutput(id: $id) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlarmGroupsOutputs = /* GraphQL */ `
  query ListAlarmGroupsOutputs(
    $filter: ModelAlarmGroupsOutputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlarmGroupsOutputs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceID
        alarmGroupID
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        alarmGroup {
          id
          name
          input {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          output {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
