import { useTranslation } from "react-i18next";

//BinaryForge Components
import { PageHeader } from "../../components/layout";

const colourList = [
	{
		name: "Font",
		values: [
			{ colourName: "colour-font", colourValue: "209deg 34% 36%" },
			{ colourName: "colour-font-light", colourValue: "209deg 34% 52%" },
			{ colourName: "colour-font-lighter", colourValue: "209deg 34% 68%" },
			{ colourName: "colour-font-lightest", colourValue: "209deg 34% 84%" },
			{ colourName: "colour-font-dark", colourValue: "209deg 34% 27%" },
			{ colourName: "colour-font-darker", colourValue: "209deg 34% 18%" },
			{ colourName: "colour-font-darkest", colourValue: "209deg 34% 9%" },
		],
	},
	{
		name: "Andel Blue 1",
		values: [
			{ colourName: "colour-andel-blue1", colourValue: "209deg 34% 59%" },
			{ colourName: "colour-andel-blue1-light", colourValue: "209deg 34% 69%" },
			{ colourName: "colour-andel-blue1-lighter", colourValue: "209deg 34% 79%" },
			{ colourName: "colour-andel-blue1-lightest", colourValue: "209deg 34% 90%" },
			{ colourName: "colour-andel-blue1-dark", colourValue: "209deg 34% 46%" },
			{ colourName: "colour-andel-blue1-darker", colourValue: "209deg 34% 34%" },
			{ colourName: "colour-andel-blue1-darkest", colourValue: "209deg 34% 21%" },
		],
	},
	{
		name: "Andel Blue 2",
		values: [
			{ colourName: "colour-andel-blue2", colourValue: "196deg 66% 75%" },
			{ colourName: "colour-andel-blue2-light", colourValue: "196deg 66% 82%" },
			{ colourName: "colour-andel-blue2-lighter", colourValue: "196deg 66% 88%" },
			{ colourName: "colour-andel-blue2-lightest", colourValue: "196deg 66% 94%" },
			{ colourName: "colour-andel-blue2-dark", colourValue: "196deg 66% 63%" },
			{ colourName: "colour-andel-blue2-darker", colourValue: "196deg 66% 50%" },
			{ colourName: "colour-andel-blue2-darkest", colourValue: "196deg 66% 38%" },
		],
	},
	{
		name: "Andel Blue 3",
		values: [
			{ colourName: "colour-andel-blue3", colourValue: "194deg 73% 60%" },
			{ colourName: "colour-andel-blue3-light", colourValue: "194deg 73% 70%" },
			{ colourName: "colour-andel-blue3-lighter", colourValue: "194deg 73% 80%" },
			{ colourName: "colour-andel-blue3-lightest", colourValue: "194deg 73% 90%" },
			{ colourName: "colour-andel-blue3-dark", colourValue: "194deg 73% 47%" },
			{ colourName: "colour-andel-blue3-darker", colourValue: "194deg 73% 35%" },
			{ colourName: "colour-andel-blue3-darkest", colourValue: "194deg 73% 22%" },
		],
	},
	{
		name: "Andel Blue 4",
		values: [
			{ colourName: "colour-andel-blue4", colourValue: "200deg 90% 88%" },
			{ colourName: "colour-andel-blue4-light", colourValue: "200deg 90% 91%" },
			{ colourName: "colour-andel-blue4-lighter", colourValue: "200deg 90% 94%" },
			{ colourName: "colour-andel-blue4-lightest", colourValue: "200deg 90% 97%" },
			{ colourName: "colour-andel-blue4-dark", colourValue: "200deg 90% 75%" },
			{ colourName: "colour-andel-blue4-darker", colourValue: "200deg 90% 63%" },
			{ colourName: "colour-andel-blue4-darkest", colourValue: "200deg 90% 50%" },
		],
	},
	{
		name: "Andel Green",
		values: [
			{ colourName: "colour-andel-green", colourValue: "68deg 64% 52%" },
			{ colourName: "colour-andel-green-light", colourValue: "68deg 64% 64%" },
			{ colourName: "colour-andel-green-lighter", colourValue: "68deg 64% 76%" },
			{ colourName: "colour-andel-green-lightest", colourValue: "68deg 64% 88%" },
			{ colourName: "colour-andel-green-dark", colourValue: "68deg 64% 39%" },
			{ colourName: "colour-andel-green-darker", colourValue: "68deg 64% 27%" },
			{ colourName: "colour-andel-green-darkest", colourValue: "68deg 64% 14%" },
		],
	},
	{
		name: "Error",
		values: [
			{ colourName: "colour-error", colourValue: "345deg 100% 39%" },
			{ colourName: "colour-error-light", colourValue: "345deg 100% 55%" },
			{ colourName: "colour-error-lighter", colourValue: "345deg 100% 70%" },
			{ colourName: "colour-error-lightest", colourValue: "345deg 100% 85%" },
			{ colourName: "colour-error-dark", colourValue: "345deg 100% 30%" },
			{ colourName: "colour-error-darker", colourValue: "345deg 100% 20%" },
			{ colourName: "colour-error-darkest", colourValue: "345deg 100% 10%" },
		],
	},
	{
		name: "Success",
		values: [
			{ colourName: "colour-success", colourValue: "164deg 100% 29%" },
			{ colourName: "colour-success-light", colourValue: "164deg 100% 47%" },
			{ colourName: "colour-success-lighter", colourValue: "164deg 100% 65%" },
			{ colourName: "colour-success-lightest", colourValue: "164deg 100% 82%" },
			{ colourName: "colour-success-dark", colourValue: "164deg 100% 22%" },
			{ colourName: "colour-success-darker", colourValue: "164deg 100% 15%" },
			{ colourName: "colour-success-darkest", colourValue: "164deg 100% 7%" },
		],
	},
	{
		name: "Info",
		values: [
			{ colourName: "colour-info", colourValue: "204deg 100% 40%" },
			{ colourName: "colour-info-light", colourValue: "204deg 100% 55%" },
			{ colourName: "colour-info-lighter", colourValue: "204deg 100% 70%" },
			{ colourName: "colour-info-lightest", colourValue: "204deg 100% 85%" },
			{ colourName: "colour-info-dark", colourValue: "204deg 100% 30%" },
			{ colourName: "colour-info-darker", colourValue: "204deg 100% 20%" },
			{ colourName: "colour-info-darkest", colourValue: "204deg 100% 10%" },
		],
	},
	{
		name: "Warning",
		values: [
			{ colourName: "colour-warn", colourValue: "44deg 100% 50%" },
			{ colourName: "colour-warn-light", colourValue: "44deg 100% 63%" },
			{ colourName: "colour-warn-lighter", colourValue: "44deg 100% 75%" },
			{ colourName: "colour-warn-lightest", colourValue: "44deg 100% 88%" },
			{ colourName: "colour-warn-dark", colourValue: "44deg 100% 38%" },
			{ colourName: "colour-warn-darker", colourValue: "44deg 100% 25%" },
			{ colourName: "colour-warn-darkest", colourValue: "44deg 100% 13%" },
		],
	},
	{
		name: "Background",
		values: [
			{ colourName: "colour-background", colourValue: "0deg 0% 100%" },
			{ colourName: "colour-background-light", colourValue: "0deg 0% 100%" },
			{ colourName: "colour-background-lighter", colourValue: "0deg 0% 100%" },
			{ colourName: "colour-background-lightest", colourValue: "0deg 0% 100%" },
			{ colourName: "colour-background-dark", colourValue: "0deg 0% 98%" },
			{ colourName: "colour-background-darker", colourValue: "0deg 0% 95%" },
			{ colourName: "colour-background-darkest", colourValue: "0deg 0% 93%" },
		],
	},
];

const StyleGuide = () => {
	const { t } = useTranslation();

	return (
		<>
			<PageHeader title={t("styles.pageTitle")} subtitle={t("styles.pageSubtitle")} />
			<main className="appMain">
				<div className="appMain-content">
					<div>
						<h3 className="marginBottom-xsmall">
							Logos:{" "}
							<a
								href="https://s3.console.aws.amazon.com/s3/buckets/andel-assets?region=eu-west-1&tab=objects"
								target="_blank"
								rel="noreferrer">
								S3 Bucket
							</a>
						</h3>
						<h3>
							Font-Family:{" "}
							<a href="https://fonts.google.com/specimen/Quicksand" target="_blank" rel="noreferrer">
								Quicksand
							</a>
						</h3>
					</div>
					{colourList.map((c) => {
						const main = c.values[0];
						const variants = [...c.values];
						variants.shift();

						return (
							<section className="marginBottom-medium" key={main.colourName}>
								<h2 className="marginBottom-small">{c.name}</h2>
								<div className="colourRow">
									<div className="colourWrapper colourWrapper-main">
										<div className={`colourSquare ${main.colourName}`}></div>
										<p className="colourDetails">
											<span>{main.colourName}</span>
											<br />
											<span>hsl({main.colourValue})</span>
										</p>
									</div>
									{variants.map((v) => (
										<div className="colourWrapper" key={v.colourName}>
											<div className={`colourSquare ${v.colourName}`}></div>
											<p className="colourDetails">
												<span>{v.colourName}</span>
												<br />
												<span>hsl({v.colourValue})</span>
											</p>
										</div>
									))}
								</div>
							</section>
						);
					})}
				</div>
			</main>
		</>
	);
};

export default StyleGuide;
