//Node Modules
import React from "react";
import { Trans } from "react-i18next";

//Pages

//BinaryForge Components

//3rd Party Components

//Atoms

//Helpers
import { getUiDateTime } from "../../helpers/DateUtils";

//Other

const PendingUpdate = ({ label, value, createdBy, createdAt }) => {
	return (
		<div className="pendingUpdate">
			<Trans
				i18nKey="device.pendingUpdate"
				values={{
					label: label,
					value: value,
					by: createdBy,
					at: getUiDateTime(createdAt),
				}}
				components={{ b: <b /> }}
			/>
		</div>
	);
};

export default PendingUpdate;
