//Node Modules
import { useRecoilValueLoadable } from "recoil";

//Pages

//BinaryForge Components
import { ZoneDetails } from "./";
import { ZoneDetailsSkeleton } from "../helpers/skeleton";
import { Note } from "../helpers";

//3rd Party Components

//Atoms
import { selectedZoneSelector } from "../../atoms/zones";

//Helpers

//Other

const ZoneLoader = () => {
	//Recoil
	const { state, contents } = useRecoilValueLoadable(selectedZoneSelector);

	switch (state) {
		case "hasError":
			console.error(contents);
			return (
				<Note
					messageKey={`Error: ${contents.message ? contents.message : contents}`}
					icon="pi-exclamation-circle fontColour-error"
				/>
			);
		case "loading":
			return <ZoneDetailsSkeleton />;
		case "hasValue":
			return <ZoneDetails zoneData={contents} />;
		default:
			return null;
	}
};

export default ZoneLoader;
