const Note = ({ icon, messageKey, messageStyle, wrapperStyle }) => {
	return (
		<div className={`flex gap-small ${wrapperStyle}`}>
			<i className={`pi fontSize-large ${icon}`} />
			<p className={messageStyle}>{messageKey}</p>
		</div>
	);
};

export default Note;
