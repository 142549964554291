export const alarm = {
	group: {
		common: {
			name: "Name",
			inputCount: "Inputs Count",
			outputCount: "Outputs Count",
			espComplete: "Local Config",
			disabled_create: "Unable to create the alarm group. The group must include at least 1 {{device}} device",
			disabled_edit: "Unable to update the alarm group. The group must include at least 1 {{device}} device",
			disabled_move: {
				header: "Cannot Move Device",
				message: "Devices in an alarm state cannot be added or removed from an Alarm Group.",
			},
		},
		management: {
			pageTitle: "Alarm Group Management",
			actions: {
				create: "Create Group",
				details: "View Details",
			},
			table: {
				espComplete: {
					true: "Complete",
					false: "Incomplete",
				},
			},
		},
		details: {
			pageTitle: "{{groupId}}",
			pageSubtitle: "Alarm Group",
			actions: {
				edit: "Edit Group",
			},
		},
		create: {
			pageTitle: "Create Alarm Group",
			actions: {
				submit: "Create Group",
			},
			form: {
				groupName: {
					label: "Group Name",
				},
			},
			toast: {
				successSummary: "Successfully Created Group",
				successDetail: "",
				errorSummary: "Failed to Create Group",
				errorDetail: "{{error}}",
			},
		},
		edit: {
			pageTitle: "{{groupId}}",
			pageSubtitle: "Edit Alarm Group",
			actions: {
				submit: "Update Group",
				delete: "Delete Group",
			},
			toast: {
				successSummary: "Successfully Edited Group",
				successDetail: "",
				errorSummary: "Failed to Edit Group",
				errorDetail: "{{error}}",
			},
			inAlarm_one:
				"Changes to the device configuration for this group cannot be saved whilst there is an active alarm for a Input Device ({{inAlarm}}) in this group.",
			inAlarm_other:
				"Changes to the device configuration for this group cannot be saved whilst there are active alarms for Input Devices ({{inAlarm}}) in this group.",
		},
		delete: {
			header: "Confirm Delete Group",
			loading: "Deleting Group",
			message:
				"Are you sure you want to delete this Alarm Group? All pairing between devices will be removed, unless they are also part of another Alarm Group.",
			keep: "Keep",
			delete: "Delete",
			toast: {
				successSummary: "Delete Group Success",
				successDetail: "",
				errorSummary: "Delete Group Failed",
				errorDetail: "{{error}}",
			},
		},
		peers: {
			loading: "Updating Local Peer Config",
		},
	},
};
