//Node Modules
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import _ from "lodash";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { onUpdateDeviceSensorAlertByScheme } from "../../graphql/subscriptions-custom";
import { listAlarmAlerts } from "../../graphql/queries-custom";

//Pages

//BinaryForge Components

//3rd Party Components

//Atoms
import { deviceSensorAlarmListAtom } from "../../atoms/alarm";
import { selectedUserSchemeAtom } from "../../atoms/user";

//Helpers

//Other

const AlarmSubscriber = () => {
	let updateDeviceSensorAlarmsubscription = null;

	//Recoil State
	const [deviceSensorAlarmList, setDeviceSensorAlarmList] = useRecoilState(deviceSensorAlarmListAtom);
	const selectedUserScheme = useRecoilValue(selectedUserSchemeAtom);

	//Load Initial Data
	useEffect(() => {
		if (selectedUserScheme) {
			getInitialDeviceSensorAlerts();
		}
	}, [selectedUserScheme]);

	//Setup Subscriber
	useEffect(() => {
		if (selectedUserScheme) {
			console.log("Setup the Alarm subscriber for", selectedUserScheme?.name);
			updateDeviceSensorAlarmsubscription = subscribeDeviceSensorAlert();
		}

		return () => {
			if (updateDeviceSensorAlarmsubscription) updateDeviceSensorAlarmsubscription.unsubscribe();
		};
	}, [deviceSensorAlarmList, selectedUserScheme]);

	const getInitialDeviceSensorAlerts = async () => {
		const {
			data: {
				deviceSensorAlertBySensorState: { items: sensorAlarmItems },
			},
		} = await API.graphql(
			graphqlOperation(listAlarmAlerts, {
				sensor_state: 1,
				filter: { schemeId: { eq: selectedUserScheme.id } },
			})
		);

		for await (const sensorAlarmItem of sensorAlarmItems) {
			const outputDevices = [];
			for await (const alarmGroupInput of sensorAlarmItem.device.alarmGroupsInput.items) {
				for await (const outputDevice of alarmGroupInput.alarmGroup.output.items) {
					outputDevices.push(outputDevice.device);
				}
			}
			sensorAlarmItem.outputDevices = _.uniqBy(outputDevices, "id");
		}
		setDeviceSensorAlarmList(sensorAlarmItems);
	};

	//Subscribe to updates
	const subscribeDeviceSensorAlert = () => {
		const sub = API.graphql(
			graphqlOperation(onUpdateDeviceSensorAlertByScheme, { schemeId: selectedUserScheme.id })
		).subscribe({
			next: ({
				value: {
					data: { onUpdateDeviceSensorAlertByScheme: updatedSensorAlert },
				},
			}) => {
				updateAlarmList(updatedSensorAlert);
			},
			error: (error) => console.warn(error),
		});

		return sub;
	};

	const updateAlarmList = (updatedSensorAlert) => {
		let mergedDeviceSensorAlarms;

		const alarmExists = deviceSensorAlarmList.find((alarm) => alarm.id === updatedSensorAlert.id);

		if (updatedSensorAlert.sensor_state && !alarmExists) {
			// console.log("Do Add...");
			const outputDevices = [];
			for (const alarmGroupInput of updatedSensorAlert.device.alarmGroupsInput.items) {
				for (const outputDevice of alarmGroupInput.alarmGroup.output.items) {
					outputDevices.push(outputDevice.device);
				}
			}
			updatedSensorAlert.outputDevices = _.uniqBy(outputDevices, "id");

			mergedDeviceSensorAlarms = _.unionBy([updatedSensorAlert], deviceSensorAlarmList, "id");
		} else if (!updatedSensorAlert.sensor_state && alarmExists) {
			// console.log("Do Remove...");
			mergedDeviceSensorAlarms = _.filter(deviceSensorAlarmList, (a) => {
				return a.id !== updatedSensorAlert.id;
			});
		}

		// console.log("Merged ::", mergedDeviceSensorAlarms);
		if (mergedDeviceSensorAlarms != null) setDeviceSensorAlarmList(mergedDeviceSensorAlarms);
	};

	return null;
};

export default AlarmSubscriber;
