import { API, graphqlOperation } from "aws-amplify";
import { getZone } from "../graphql/queries";
import i18n from "../i18n";
import _ from "lodash";

export const getSensorString = (value) => {
	if (!value) return i18n.t("device.sensor.status.off");
	if (value) return i18n.t("device.sensor.status.on");
};

export const getRelayString = (value) => {
	if (!value) return i18n.t("device.relay.open");
	if (value) return i18n.t("device.relay.closed");
};

export const getValveString = (value) => {
	if (!value) return i18n.t("device.relay.open");
	if (value) return i18n.t("device.relay.closed");
};

export const getDcStatusString = (value) => {
	if (!value) return i18n.t("device.dcStatus.false");
	if (value) return i18n.t("device.dcStatus.true");
};

export const getBatteryChargingString = (value) => {
	if (!value) return i18n.t("device.batteryCharging.false");
	if (value) return i18n.t("device.batteryCharging.true");
};

export const getYesNoString = (value) => {
	if (value === undefined) return "---";
	if (value === 0 || !value) return i18n.t("common.option.no");
	if (value === 1 || value) return i18n.t("common.option.yes");
};

export const useGetFullLocation = () => {
	let locationBreadcrumb = [];
	let tenants = [];
	const getFullLocation = async (zone) => {
		locationBreadcrumb = [];
		tenants = [];
		if (zone) await getLocationBreadcrumb(zone);

		const breadcrumbLabel = locationBreadcrumb
			.map((crumb) => crumb.label)
			.reverse()
			.join("|");
		const breadcrumbKey = locationBreadcrumb
			.map((crumb) => crumb.id)
			.reverse()
			.join("|");

		return { locationBreadcrumb, breadcrumbLabel, breadcrumbKey, tenants: _.flattenDeep(tenants) };
	};

	const getLocationBreadcrumb = async (zone) => {
		if (!zone.parent) {
			if (zone.tenant?.length)
				tenants.push({ tenantIds: zone.tenant, zone: { label: zone.label, type: zone.type } });
			locationBreadcrumb.push({ id: zone.id || zone.key, label: zone.label, type: zone.type });
			return locationBreadcrumb;
		} else {
			if (zone.tenant?.length)
				tenants.push({ tenantIds: zone.tenant, zone: { label: zone.label, type: zone.type } });
			locationBreadcrumb.push({ id: zone.id || zone.key, label: zone.label, type: zone.type });
			const {
				data: { getZone: parentZone },
			} = await API.graphql(graphqlOperation(getZone, { id: zone.parent }));
			await getLocationBreadcrumb(parentZone);
		}

		// return locationBreadcrumb;
	};

	return getFullLocation;
};
