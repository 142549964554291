import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { getUiDateTime } from "../helpers/DateUtils";
import i18n from "../i18n";
import _ from "lodash";
import { Username } from "../components/helpers";

export const filterApplyTemplate = (options) => {
	return (
		<Button
			type="button"
			label="Filter"
			icon="pi pi-check"
			onClick={options.filterApplyCallback}
			className="feature"
		/>
	);
};

export const datetimeRowTemplate = (rowData, columnName) => {
	return getUiDateTime(rowData[columnName]);
};

export const usernameRowTemplate = (rowData, columnName) => {
	const sub = _.get(rowData, columnName);
	return <Username sub={sub} />;
};

export const dateFilterTemplate = (options) => {
	return (
		<Calendar
			value={options.value}
			onChange={(e) => options.filterCallback(e.value, options.index)}
			placeholder={i18n.t("common.table.dateSelect")}
			dateFormat="dd M yy"
			readOnlyInput
			showButtonBar
			showTime
		/>
	);
};

export const numberMatchModeOptions = [
	{ label: i18n.t("datatable.matchMode", { context: "EQUALS" }), value: "eq" },
	{ label: i18n.t("datatable.matchMode", { context: "NOT_EQUALS" }), value: "neq" },
	{ label: i18n.t("datatable.matchMode", { context: "GREATER" }), value: "gt" },
	{ label: i18n.t("datatable.matchMode", { context: "GREATER_EQUALS" }), value: "gte" },
	{ label: i18n.t("datatable.matchMode", { context: "LESS" }), value: "lt" },
	{ label: i18n.t("datatable.matchMode", { context: "LESS_EQUALS" }), value: "lte" },
];

export const timePeriodTypeOptions = [
	{ label: i18n.t("datatable.periodType", { context: "SEC" }), value: "sec" },
	{ label: i18n.t("datatable.periodType", { context: "MIN" }), value: "min" },
	{ label: i18n.t("datatable.periodType", { context: "HOUR" }), value: "hour" },
	{ label: i18n.t("datatable.periodType", { context: "DAY" }), value: "day" },
];
