//Node Modules
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";

//GraphQL

//BinaryForge Components
import FeatureItem from "./FeatureItem";

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

//Atoms
import { devicesListAtom, selectedDeviceAtom } from "../../atoms/devices";

//Helpers
import { exportDate } from "../../helpers/DateUtils";
import { filterApplyTemplate } from "../../config/datatable";
import { inputDeviceOptions, outputDeviceOptions } from "../../config/device";

//Other

const DevicesTable = ({ devicesData }) => {
	//Hooks
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dt = useRef(null);

	//Recoil
	const [devices, setDevices] = useRecoilState(devicesListAtom);
	const [selectedDevice, setSelectedDevice] = useRecoilState(selectedDeviceAtom);

	// Local State
	const [dtFilters, setDtFilters] = useState(null);
	const [flexiGroupOptions, setFlexiGroupOptions] = useState([]);
	const [flowGroupOptions, setFlowGroupOptions] = useState([]);

	//On Page Load
	useEffect(() => {
		if (devicesData) {
			setDevices(devicesData);
			// setGroupFilterOptions(devicesData);
			initFilters();
		}
	}, [devicesData]);

	// const setGroupFilterOptions = async (devices) => {
	// 	for await (const device of devices) {
	// 		setFlexiGroupOptions((prevState) => _.union(prevState, device.alarmGroupsFlexi));
	// 		setFlowGroupOptions((prevState) => _.union(prevState, device.alarmGroupsFlow));
	// 	}
	// };

	const handleNavDevice = () => {
		navigate(`/device/${selectedDevice.id}`);
	};

	//Datatable Functions
	const initFilters = () => {
		setDtFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			id: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			"zoneBreadcrumb.label": {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			// alarmGroupsFlexi: {
			// 	value: null,
			// 	matchMode: FilterMatchMode.CUSTOM,
			// },
			// alarmGroupsFlow: {
			// 	value: null,
			// 	matchMode: FilterMatchMode.CUSTOM,
			// },
			inputDevice: { value: null, matchMode: FilterMatchMode.CUSTOM },
			outputDevice: { value: null, matchMode: FilterMatchMode.CUSTOM },
		});
	};

	const exportCSV = (selectionOnly) => {
		dt.current.exportCSV({ selectionOnly });
	};

	// const alarmGroupFlexiRowTempate = (rowData) => {
	// 	const groupCount = rowData.alarmGroupsFlexi.length;
	// 	return <span>{t("devices.table.groupCount", { count: groupCount })}</span>;
	// };

	// const alarmGroupFlowRowTempate = (rowData) => {
	// 	const groupCount = rowData.alarmGroupsFlow.length;
	// 	return <span>{t("devices.table.groupCount", { count: groupCount })}</span>;
	// };

	// const flexiFilterTemplate = (options) => {
	// 	return (
	// 		<MultiSelect
	// 			showClear
	// 			value={options.value}
	// 			options={flexiGroupOptions}
	// 			// itemTemplate={featureFilterItemTemplate}
	// 			// selectedItemTemplate={featureFilterSelectedItemTemplate}
	// 			onChange={(e) => options.filterCallback(e.value)}
	// 			placeholder={t("common.form.multiselectPlaceholder")}
	// 		/>
	// 	);
	// };

	// const flexiFilterFunction = (value, filter) => {
	// 	let include = true;
	// 	if (filter) {
	// 		const checkFilter = _.intersection(value, filter);
	// 		if (!checkFilter.length) include = false;
	// 	}
	// 	return include;
	// };

	// const flowFilterTemplate = (options) => {
	// 	return (
	// 		<MultiSelect
	// 			showClear
	// 			value={options.value}
	// 			options={flowGroupOptions}
	// 			// itemTemplate={featureFilterItemTemplate}
	// 			// selectedItemTemplate={featureFilterSelectedItemTemplate}
	// 			onChange={(e) => options.filterCallback(e.value)}
	// 			placeholder={t("common.form.multiselectPlaceholder")}
	// 		/>
	// 	);
	// };

	// const flowFilterFunction = (value, filter) => {
	// 	let include = true;
	// 	if (filter) {
	// 		const checkFilter = _.intersection(value, filter);
	// 		if (!checkFilter.length) include = false;
	// 	}
	// 	return include;
	// };

	const idSortFunction = (a) => {
		console.log(a);
	};

	const inputRowTemplate = (rowData) => {
		let deviceArray = [];

		if (rowData.inputDevice)
			deviceArray = rowData.inputDevice.map((d) => {
				return <FeatureItem key={uuid()} value={d} type="input" />;
			});

		return <div className="flex gapCol-medium gapRow-small">{deviceArray}</div>;
	};

	const inputFilterItemTemplate = (option) => {
		return <FeatureItem value={option.value} type="input" />;
	};
	const inputFilterSelectedItemTemplate = (option) => {
		if (option !== undefined) return <FeatureItem value={option} type="input" />;
	};

	const inputFilterTemplate = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={inputDeviceOptions}
				itemTemplate={inputFilterItemTemplate}
				selectedItemTemplate={inputFilterSelectedItemTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.form.multiselectPlaceholder")}
			/>
		);
	};

	const deviceFilterFunction = (value, filter) => {
		// console.log("Filter");
		let include = true;
		if (filter?.length) {
			if (filter.includes(null) && value === null) {
				include = true;
			} else {
				const checkFilter = _.intersection(value, filter);
				if (!checkFilter.length) include = false;
			}
		}
		return include;
	};

	const outputRowTemplate = (rowData) => {
		let deviceArray = [];

		if (rowData.outputDevice)
			deviceArray = rowData.outputDevice.map((d) => {
				return <FeatureItem key={uuid()} value={d} type="output" />;
			});

		return <div className="flex gapCol-medium gapRow-small">{deviceArray}</div>;
	};

	const outputFilterItemTemplate = (option) => {
		return <FeatureItem value={option.value} type="output" />;
	};
	const outputFilterSelectedItemTemplate = (option) => {
		if (option !== undefined) return <FeatureItem value={option} type="output" />;
	};

	const outputFilterTemplate = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={outputDeviceOptions}
				itemTemplate={outputFilterItemTemplate}
				selectedItemTemplate={outputFilterSelectedItemTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.form.multiselectPlaceholder")}
			/>
		);
	};

	const locationRowTemplate = (rowData) => {
		const unique = uuid();
		const locationBreadcrumb = rowData.zoneBreadcrumb?.label?.replaceAll("|", " > ");

		return (
			<>
				<Tooltip target={`.customTooltip-${unique}`} />
				<span
					className={`customTooltip-${unique}`}
					data-pr-tooltip={locationBreadcrumb}
					data-pr-at="right+10 center">
					{rowData.zone?.label}
				</span>
			</>
		);
	};

	return (
		<>
			<div className="flex jContent-end gap-small">
				<Button onClick={() => exportCSV(false)}>{t("common.table.export")}</Button>
				<Button onClick={() => handleNavDevice()} className="feature" disabled={!selectedDevice}>
					{t("devices.actions.deviceDetails")}
				</Button>
			</div>
			<div className="marginTop-large">
				<DataTable
					ref={dt}
					value={devices}
					emptyMessage={t("common.table.noData")}
					selectionMode="single"
					selection={selectedDevice}
					onSelectionChange={(e) => setSelectedDevice(e.value)}
					sortMode="multiple"
					removableSort
					filters={dtFilters}
					filterDisplay="menu"
					autoLayout={true}
					paginator
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
					currentPageReportTemplate={t("common.table.paginatorTemplate")}
					rows={10}
					dataKey="id"
					exportFilename={t("common.table.exportFilename", { filename: "Devices", date: exportDate })}>
					<Column
						field="name"
						header={t("devices.table.name")}
						sortable
						filter
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="id"
						header={t("devices.table.id")}
						dataType="text"
						sortable
						filter
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="zone.label"
						header={t("devices.table.zone")}
						body={locationRowTemplate}
						sortable
						filter
						filterField="zoneBreadcrumb.label"
						filterApply={filterApplyTemplate}
					/>
					{/* <Column
					field="alarmGroupsInput"
					header={t("devices.table.alarmGroupFlexi")}
					body={alarmGroupFlexiRowTempate}
					sortable
					sortField="alarmGroupsFlexiCount"
					filter
					showFilterMatchModes={false}
					filterElement={flexiFilterTemplate}
					filterApply={filterApplyTemplate}
					filterFunction={flexiFilterFunction}
				/>
				<Column
					field="alarmGroupsOutput"
					header={t("devices.table.alarmGroupFlow")}
					body={alarmGroupFlowRowTempate}
					sortable
					sortField="alarmGroupsFlowCount"
					filter
					showFilterMatchModes={false}
					filterElement={flowFilterTemplate}
					filterApply={filterApplyTemplate}
					filterFunction={flowFilterFunction}
				/> */}
					<Column
						field="inputDevice"
						header={t("devices.table.input")}
						body={inputRowTemplate}
						sortable
						filter
						showFilterMatchModes={false}
						filterElement={inputFilterTemplate}
						filterFunction={deviceFilterFunction}
						filterApply={filterApplyTemplate}
					/>
					<Column
						field="outputDevice"
						header={t("devices.table.output")}
						body={outputRowTemplate}
						sortable
						filter
						showFilterMatchModes={false}
						filterElement={outputFilterTemplate}
						filterFunction={deviceFilterFunction}
						filterApply={filterApplyTemplate}
					/>
				</DataTable>
				{/* <pre>{JSON.stringify(devicesData, null, 2)}</pre> */}
			</div>
		</>
	);
};

export default DevicesTable;
