//Node Modules
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Navigate, Outlet, useLocation, useSearchParams } from "react-router-dom";

//Helpers
import { useIsValidSession, useLogout } from "../../helpers/Auth";

//Atoms
import { selectedUserSchemeAtom, userSchemeOptionsAtom } from "../../atoms/user";

//Other
import { nav, adminRoutes, ownerRoutes } from "../../config/navigation";

const AuthRoute = () => {
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const isValidSession = useIsValidSession();
	const handleLogout = useLogout();

	//Recoil State
	const [scheme, setScheme] = useRecoilState(selectedUserSchemeAtom);
	const schemeOptions = useRecoilValue(userSchemeOptionsAtom);

	// Local State
	const [returnRoute, setReturnRoute] = useState();

	useEffect(() => {
		// console.log("Auth Route ::", location.pathname);
		getReturnRoute();
	}, [location]);

	const getReturnRoute = async () => {
		if ((await isValidUser()) === false) {
			console.warn("No valid user. Navigate back to the login page.");
			setReturnRoute(<Navigate to={nav.guest.login} state={{ from: location }} replace />);
		} else {
			if (!scheme) {
				console.warn("Scheme is null so signout!");
				await doLogout();
				setReturnRoute(<Navigate to={nav.guest.login} state={{ from: location }} replace />);
			} else {
				const rootLocation = `/${location.pathname.split("/")[1]}`;
				// console.log(rootLocation);

				let validRole = true;
				if (scheme.role === "ADMINISTRATOR" && !adminRoutes.includes(rootLocation)) validRole = false;
				if (scheme.role === "OWNER" && !ownerRoutes.includes(rootLocation)) validRole = false;
				// console.log("Valid Role ::", validRole);

				if (validRole) {
					// console.log("Everything is valid, return the requested page.");
					// Check if we need to change scheme
					const urlScheme = searchParams.get("schemeId");
					if (urlScheme && scheme.id !== urlScheme) {
						// console.log("Scheme needs to be updated");
						const updatedScheme = schemeOptions.find((s) => s.id === urlScheme);
						setScheme(updatedScheme);
					}

					setReturnRoute(<Outlet />);
				} else {
					//TODO: We should navigate to somewhere more appropriate and show an error message
					console.warn("Navigate to user route location...");
					const userRoleBaseRoute = adminRoutes[0];
					// console.log(userRoleBaseRoute);
					// setReturnRoute(null);
					setReturnRoute(<Navigate to={userRoleBaseRoute} replace />);
				}
			}
		}
	};

	const isValidUser = async () => {
		const value = await isValidSession();
		return value;
	};

	const doLogout = async () => {
		await handleLogout();
		return;
	};

	if (returnRoute === undefined) return null;
	return returnRoute;
};

export default AuthRoute;
