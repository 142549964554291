//Node Modules
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { listTenantsByScheme, getZoneBasic } from "../../graphql/queries-custom";
import { createZone, updateScheme, updateDevice, updateZone } from "../../graphql/mutations";

//BinaryForge Components
import { Username, Note } from "../helpers";

//3rd Party Components
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";

//Atoms
import { zoneNodesAtom, selectedZoneKeyAtom, selectedZoneAtom, selectedZoneSelector } from "../../atoms/zones";
import { dialogAtomFamily, loggedInUserAtom, toastAtom } from "../../atoms";
import { selectedUserSchemeAtom } from "../../atoms/user";
import { validationAtomFamily } from "../../atoms/validation";

//Helpers
import { useApiRequest } from "../../helpers/Api";
import { useGetFullLocation } from "../../helpers/Device";
import { zoneTypeOptions } from "../../config/zones";
import {
	findTreeNodeByKey,
	getAllChildZoneProperty,
	getAllParentZoneProperty,
	validateUniqueZoneName,
	getAllChildZonesFull,
} from "../../helpers/Zones";

//Other
const appElement = document.getElementsByClassName("appWrapper")[0];

const ZoneForm = ({ type, availableDevices }, ref) => {
	//Hooks
	const { t } = useTranslation();
	const apiRequest = useApiRequest();
	const getFullLocation = useGetFullLocation();

	//Recoil
	const [toast, setToast] = useRecoilState(toastAtom);
	const setLoading = useSetRecoilState(dialogAtomFamily("loadingDialog"));
	const loggedInUser = useRecoilValue(loggedInUserAtom);
	const selectedUserScheme = useRecoilValue(selectedUserSchemeAtom);
	const [nodes, setNodes] = useRecoilState(zoneNodesAtom);
	const selectedNodeKey = useRecoilValue(selectedZoneKeyAtom);
	const selectedZone = useRecoilValue(selectedZoneAtom);
	const refreshZoneCache = useRecoilRefresher_UNSTABLE(selectedZoneSelector);
	const setShowCreateDialog = useSetRecoilState(dialogAtomFamily("zoneCreateDialog"));
	const setShowEditDialog = useSetRecoilState(dialogAtomFamily("zoneEditDialog"));
	const zoneValidation = useRecoilValue(validationAtomFamily("zone"));

	//Local
	const [tenants, setTenants] = useState([]);

	// Form Default Values
	const defaultValues = {
		name: "",
		type: "",
		devices: [],
		tenants: [],
	};

	// Form Init
	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ defaultValues: defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	useImperativeHandle(ref, () => ({
		submitZoneForm() {
			handleSubmit(onSubmit)();
		},
	}));

	const onSubmit = async (data) => {
		if (type === "create") createNode(data);
		else if (type === "edit") editNode(data);
	};

	//Create Actions
	const createNode = async (data) => {
		try {
			setLoading({ visible: true, message: t("zones.create.loading") });

			let cloneNodes = [];
			let zoneBreadcrumb = null;
			if (nodes) cloneNodes = _.cloneDeep(nodes);

			let updatedNodes;
			const newZone = {
				key: uuidv4(),
				label: data.name,
				type: data.type,
				tenant: data.tenants,
				parent: null,
			};

			const validName = await validateUniqueZoneName(selectedNodeKey, cloneNodes, data.name, "create");
			if (!validName) throw Error(t("zones.create.error.nameUnique"));

			if (selectedNodeKey) {
				newZone.parent = selectedNodeKey;
				const updated = await addChild(cloneNodes, selectedNodeKey, newZone, true);
				updatedNodes = updated;
			} else {
				updatedNodes = [...cloneNodes, newZone];
			}

			await API.graphql(
				graphqlOperation(createZone, {
					input: {
						id: newZone.key,
						schemeId: selectedUserScheme.id,
						label: newZone.label,
						type: newZone.type,
						parent: newZone.parent,
						tenant: newZone.tenant,
						createdBy: loggedInUser.sub,
					},
				})
			);

			if (data.devices) {
				const { breadcrumbLabel, breadcrumbKey } = await getFullLocation(newZone);
				zoneBreadcrumb = { label: breadcrumbLabel, key: breadcrumbKey };
			}

			for await (const device of data.devices) {
				await API.graphql(
					graphqlOperation(updateDevice, {
						input: { id: device, zoneId: newZone.key, zoneBreadcrumb: JSON.stringify(zoneBreadcrumb) },
					})
				);
			}

			await updateNodes(updatedNodes);

			setToast({
				...toast,
				severity: "success",
				summary: t(`zones.create.toast.successSummary`),
				detail: t(`zones.create.toast.successDetail`),
			});

			setShowCreateDialog(false);
		} catch (err) {
			console.error("Create Zone Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t(`zones.create.toast.errorSummary`),
				detail: t(`zones.create.toast.errorDetail`, { error: err.message }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const addChild = async (items, key, newZone, root) => {
		if (!items) {
			return;
		}

		for await (const item of items) {
			// Test current object
			if (item.key === key) {
				if (item.children) item.children.push(newZone);
				else item.children = [newZone];
			}

			// Test children recursively
			const child = await addChild(item.children, key, newZone, false);
			if (child) return child;
		}

		if (root) return items;
	};

	//On Load
	useEffect(() => {
		const listTenants = async () => {
			let availableTenants = [];
			const cognitoUsers = await apiRequest("get", "/user", null, null);
			const {
				data: {
					userSchemeConfigBySchemeId: { items: tenantData },
				},
			} = await API.graphql(
				graphqlOperation(listTenantsByScheme, {
					schemeId: selectedUserScheme.id,
					filter: { role: { eq: "TENANT" } },
				})
			);
			const combinedCognitoDynamoUsers = _.intersectionBy(cognitoUsers, tenantData, "id");

			if (selectedZone) {
				// console.log("List Tenants - Zone Data ::", selectedZone);
				let zoneTenants = [];
				let parentTenants = [];
				let childTenants = [];

				//Get any tenants assigned to parent zones in the node branch
				parentTenants = _.flattenDeep(await getAllParentZoneProperty(selectedZone, "tenant")).map((t) => ({
					id: t,
				}));

				if (type === "create") {
					zoneTenants = selectedZone.tenant ? selectedZone.tenant.map((t) => ({ id: t })) : [];
				}

				if (type === "edit") {
					//Get any tenants assigned to child zones in the node branch
					const childZone = await findTreeNodeByKey(nodes, selectedZone.id);
					childTenants = _.flattenDeep(await getAllChildZoneProperty(childZone, "tenant")).map((t) => ({
						id: t,
					}));
				}

				//Remove any tenants assigned to parent or children zones
				const removeParentsAndChildren = _.differenceBy(
					combinedCognitoDynamoUsers,
					[...zoneTenants, ...parentTenants, ...childTenants],
					"id"
				);
				availableTenants = removeParentsAndChildren;
			} else {
				availableTenants = combinedCognitoDynamoUsers;
			}

			setTenants(availableTenants);
		};

		listTenants();
	}, []);

	//Edit Actions
	useEffect(() => {
		if (type === "edit" && selectedZone) {
			const selectedDevices = selectedZone.devices.items.map((d) => d.id);

			reset({
				name: selectedZone.label,
				type: selectedZone.type,
				devices: selectedDevices,
				tenants: selectedZone.tenant,
			});
		}
	}, [selectedZone]);

	const editNode = async (data) => {
		let zoneBreadcrumb = null;
		let updatedZone = null;

		try {
			setLoading({ visible: true, message: t("zones.edit.loading") });
			const validName = await validateUniqueZoneName(selectedNodeKey, nodes, data.name, "edit");
			if (!validName) throw Error(t("zones.create.error.nameUnique"));

			await API.graphql(
				graphqlOperation(updateZone, {
					input: {
						id: selectedNodeKey,
						label: data.name,
						type: data.type,
						tenant: data.tenants,
						updatedBy: loggedInUser.sub,
					},
				})
			);

			const updateDetails = { label: data.name, type: data.type };
			let cloneNodes = _.cloneDeep(nodes);
			await updateNodeDetails(cloneNodes, selectedNodeKey, updateDetails);
			const updatedZoneConfig = await updateNodes(cloneNodes);

			if (data.devices) {
				updatedZone = await findTreeNodeByKey(updatedZoneConfig, selectedNodeKey);
				const { breadcrumbLabel, breadcrumbKey } = await getFullLocation(updatedZone);
				zoneBreadcrumb = { label: breadcrumbLabel, key: breadcrumbKey };
			}

			const devicesObj = data.devices.map((d) => ({ id: d }));
			const addDevices = _.differenceBy(devicesObj, selectedZone.devices.items, "id");
			const removeDevices = _.differenceBy(selectedZone.devices.items, devicesObj, "id");

			//If the zone name has changed we need to update existing devices breadcrumbs
			if (selectedZone.label !== data.name) {
				for await (const device of devicesObj) {
					await API.graphql(
						graphqlOperation(updateDevice, {
							input: { id: device.id, zoneBreadcrumb: JSON.stringify(zoneBreadcrumb) },
						})
					);
				}

				const childZones = await getAllChildZonesFull(updatedZone);
				for await (const childZone of childZones) {
					const {
						data: { getZone: zone },
					} = await API.graphql(graphqlOperation(getZoneBasic, { id: childZone.key }));

					if (zone.devices?.items) {
						for await (const zoneDevice of zone.devices.items) {
							const selectedZone = await findTreeNodeByKey(updatedZoneConfig, zone.id);
							const { breadcrumbLabel, breadcrumbKey } = await getFullLocation(selectedZone);
							const deviceZoneBreadcrumb = { label: breadcrumbLabel, key: breadcrumbKey };

							await API.graphql(
								graphqlOperation(updateDevice, {
									input: { id: zoneDevice.id, zoneBreadcrumb: JSON.stringify(deviceZoneBreadcrumb) },
								})
							);
						}
					}
				}
			}

			for await (const device of addDevices) {
				await API.graphql(
					graphqlOperation(updateDevice, {
						input: {
							id: device.id,
							zoneId: selectedNodeKey,
							zoneBreadcrumb: JSON.stringify(zoneBreadcrumb),
						},
					})
				);
			}
			for await (const device of removeDevices) {
				await API.graphql(
					graphqlOperation(updateDevice, { input: { id: device.id, zoneId: null, zoneBreadcrumb: null } })
				);
			}

			refreshZoneCache();

			setToast({
				...toast,
				severity: "success",
				summary: t(`zones.edit.toast.successSummary`),
				detail: t(`zones.edit.toast.successDetail`),
			});

			setShowEditDialog(false);
		} catch (err) {
			console.error("Edit Zone Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t(`zones.edit.toast.errorSummary`),
				detail: t(`zones.edit.toast.errorDetail`, { error: err.message }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const updateNodeDetails = async (items, key, newDetails) => {
		if (!items) {
			return;
		}

		for await (const item of items) {
			// Test current object
			if (item.key === key) {
				item.label = newDetails.label;
				item.type = newDetails.type;
			}

			// Test children recursively
			const child = await updateNodeDetails(item.children, key, newDetails);
			if (child) return child;
		}
	};

	//General Actions
	const updateNodes = async (updatedNodes) => {
		setNodes(updatedNodes);
		const {
			data: {
				updateScheme: { zoneConfig },
			},
		} = await API.graphql(
			graphqlOperation(updateScheme, {
				input: { id: selectedUserScheme.id, zoneConfig: JSON.stringify(updatedNodes) },
			})
		);

		return JSON.parse(zoneConfig);
	};

	const selectedTenantTemplate = (item) => {
		if (item) return <Username sub={item} />;
	};

	return (
		<form>
			<div className="formField">
				<label htmlFor="name">{t("zones.form.name.label")}</label>
				<Controller
					name="name"
					control={control}
					rules={{
						required: t("common.form.required"),
						maxLength: {
							value: zoneValidation["maxLength"],
							message: t("validation.zone.maxLength", {
								length: zoneValidation["maxLength"],
							}),
						},
						pattern: {
							value: RegExp(zoneValidation["pattern"]),
							message: t("validation.zone.pattern"),
						},
					}}
					render={({ field, fieldState }) => (
						<InputText {...field} className={classNames({ "p-error": fieldState.error })} />
					)}
				/>
				{getFormErrorMessage("name")}
			</div>
			<div className="formField">
				<label htmlFor="type">{t("zones.form.type.label")}</label>
				<Controller
					name="type"
					control={control}
					rules={{ required: t("common.form.required") }}
					render={({ field: { ref, ...newField }, fieldState }) => (
						<Dropdown
							appendTo={appElement}
							{...newField}
							inputRef={ref}
							options={zoneTypeOptions}
							placeholder={t("zones.form.type.placeholder")}
							onChange={(e) => newField.onChange(e.value)}
							className={classNames({ "p-error": fieldState.error })}
						/>
					)}
				/>
				{getFormErrorMessage("type")}
			</div>
			<div className="formField">
				<label htmlFor="devices">{t("zones.form.devices.label")}</label>
				<Controller
					name="devices"
					control={control}
					render={({ field: { ref, ...newField }, fieldState }) => (
						<MultiSelect
							appendTo={appElement}
							{...newField}
							inputRef={ref}
							filter
							options={availableDevices}
							optionValue="id"
							optionLabel="name"
							filterBy="name"
							placeholder={t("zones.form.devices.placeholder")}
							onChange={(e) => newField.onChange(e.value)}
							className={classNames({ "p-error": fieldState.error })}
						/>
					)}
				/>
				{getFormErrorMessage("devices")}
			</div>
			<div className="formField">
				<label htmlFor="tenants">{t("zones.form.tenants.label")}</label>
				<Controller
					name="tenants"
					control={control}
					render={({ field: { ref, ...newField }, fieldState }) => (
						<MultiSelect
							appendTo={appElement}
							{...newField}
							inputRef={ref}
							filter
							options={tenants}
							optionValue="id"
							optionLabel="fullname"
							filterBy="fullname"
							selectedItemTemplate={selectedTenantTemplate}
							placeholder={t("zones.form.tenants.placeholder")}
							onChange={(e) => newField.onChange(e.value)}
							className={classNames({ "p-error": fieldState.error })}
						/>
					)}
				/>
				{getFormErrorMessage("tenants")}
				<Note
					messageKey={t("zones.form.tenants.note")}
					messageStyle="fontColour-light fontSize-small"
					icon="pi pi-info-circle fontColour-info fontSize-small"
					wrapperStyle="marginTop-xsmall"
				/>
			</div>
		</form>
	);
};

export default forwardRef(ZoneForm);
