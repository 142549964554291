import i18n from "../i18n";

export const zoneConfig = {
	BUILDING: { icon: "pi pi-building", colour: "blue1" },
	FLOOR: { icon: "icon-floor", colour: "blue1" },
	ROOM: { icon: "icon-room", colour: "blue1" },
	AREA: { icon: "icon-zone", colour: "blue1" },
};

export const zoneTypeOptions = [
	{ value: "BUILDING", label: i18n.t("zones.type.BUILDING") },
	{ value: "FLOOR", label: i18n.t("zones.type.FLOOR") },
	{ value: "ROOM", label: i18n.t("zones.type.ROOM") },
	{ value: "AREA", label: i18n.t("zones.type.AREA") },
];
