export const delay = (delayInMillis) => {
	return new Promise((resolve) => setTimeout(resolve, delayInMillis));
};

export const getVersionNumber = (version) => {
	const splits = version.split(".");
	const major = (Number(splits[0]) || 0) * 10000;
	const minor = (Number(splits[1]) || 0) * 100;
	const revision = Number(splits[2]) || 0;
	const versionNumber = major + minor + revision;
	return versionNumber;
};
