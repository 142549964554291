//React
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

//Recoil
import { RecoilRoot } from "recoil";

//React Router
import { RouterProvider } from "react-router-dom";
import { router } from "./config/navigation";

//App

// Amplify
import { Amplify, Auth } from "aws-amplify";
import awsExports from "./aws-exports";

//Sentry
import * as Sentry from "@sentry/react";

//Amplify Configure
Amplify.configure({
	...awsExports,
	API: {
		endpoints: [
			{
				name: "andelRestApi",
				endpoint: process.env.REACT_APP_API_AWS_BASE + process.env.REACT_APP_API_AWS_STAGE,
				custom_header: async () => {
					return {
						Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
						"Content-Type": "application/json",
					};
				},
			},
		],
	},
});

Sentry.init({
	dsn: "https://fa0fa39b96e34e4d9b133f56dbf6c657@o1334838.ingest.sentry.io/4505329125031936",
	environment: process.env.REACT_APP_API_AWS_STAGE,
	tunnel:
		process.env.REACT_APP_API_AWS_STAGE != "dev" &&
		`https://api.andel-cloud.com/${process.env.REACT_APP_API_AWS_STAGE}/sentry-tunnel/${process.env.REACT_APP_API_AWS_STAGE}`,
	integrations: [
		new Sentry.BrowserTracing({
			// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: [/^https:\/\/*\.andel-cloud\.com\//],
		}),
	],
	tracesSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")).render(
	<RecoilRoot>
		<RouterProvider router={router} />
	</RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
