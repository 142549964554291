//Node Modules
import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useLoaderData, Link, useNavigate } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import _ from "lodash";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { getDevice } from "../../../graphql/queries";
import { updateDevice } from "../../../graphql/mutations";
import { listAlarmGroupsInputIds, listAlarmGroupsOutputIds } from "../../../graphql/queries-custom";
import { onUpdateDeviceDetails } from "../../../graphql/subscriptions-custom";

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { Breadcrumb, Note, PillItem, Username } from "../../../components/helpers";
import { Field } from "../../../components";
import {
	PendingUpdate,
	ConfigUpdateDialog,
	AttributesUpdateDialog,
	DeviceAlarmGroupDialog,
	DeleteDisabledDialog,
} from "../../../components/device";

//3rd Party Components
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

//Atoms
import { dialogAtomFamily, loggedInUserAtom } from "../../../atoms";
import { useApiRequest } from "../../../helpers/Api";

//Helpers
import { getUiDateTime } from "../../../helpers/DateUtils";
import {
	getSensorString,
	getRelayString,
	getDcStatusString,
	getBatteryChargingString,
	getYesNoString,
	useGetFullLocation,
} from "../../../helpers/Device";
import { inputDeviceOptions } from "../../../config/device";
import AssignedTenantDialog from "../../../components/device/AssignedTenantDialog";

//Other
import { nav } from "../../../config/navigation";

const DeviceDetails = () => {
	const { t } = useTranslation();
	const apiRequest = useApiRequest();
	const { deviceId } = useLoaderData();
	const getFullLocation = useGetFullLocation();
	const navigate = useNavigate();

	// Recoil
	const loggedInUser = useRecoilValue(loggedInUserAtom);
	const setLoading = useSetRecoilState(dialogAtomFamily("loadingDialog"));
	const setShowConfigUpdateDialog = useSetRecoilState(dialogAtomFamily("configUpdateDialog"));
	const setShowAttributesUpdateDialog = useSetRecoilState(dialogAtomFamily("deviceAttributesUpdateDialog"));
	const setShowDeviceAlarmGroupDialog = useSetRecoilState(dialogAtomFamily("deviceAlarmGroupDialog"));
	const setShowAssignedTenantDialog = useSetRecoilState(dialogAtomFamily("assignedTenantDialog"));
	const setShowDeleteDisabledDialog = useSetRecoilState(dialogAtomFamily("deleteDisabledDialog"));

	//Local State
	const [deviceInfo, setDeviceInfo] = useState(null);
	const [reportedState, setReportedState] = useState(null);
	const [modbusState, setModbusState] = useState(null);
	const [desiredState, setDesiredState] = useState(null);
	const [desiredStateInfo, setDesiredStateInfo] = useState(null);
	const [inAlarm, setInAlarm] = useState(null);
	const [selectedAlarmGroups, setSelectedAlarmGroups] = useState({ type: "input", data: [] });
	const [deviceTsFormat, setDeviceTsFormat] = useState(true);
	const [deleteDisabledProps, setDeleteDisabledProps] = useState();

	//On Page Load
	useEffect(() => {
		handleGetDevice();
		const updateDeviceSubscription = subscribeDeviceState();

		return () => {
			updateDeviceSubscription.unsubscribe();
		};
	}, []);

	//Get the initial device data
	const handleGetDevice = async () => {
		setLoading({ visible: true, message: t("device.loading") });
		const {
			data: { getDevice: deviceData },
		} = await API.graphql(graphqlOperation(getDevice, { id: deviceId }));

		processDeviceData(deviceData);
		setLoading({ visible: false, message: "" });
	};

	const subscribeDeviceState = () => {
		console.log("Subscribe to Device Updates ::", deviceId);
		const sub = API.graphql(
			graphqlOperation(onUpdateDeviceDetails, {
				id: deviceId,
			})
		).subscribe({
			next: ({ provider, value }) => {
				// console.log("Sub Update ::", provider, value);
				processDeviceData(value.data.onUpdateDeviceById);
			},
			error: (error) => console.warn(error),
		});

		return sub;
	};

	const processDeviceData = async (deviceData) => {
		console.log("Device Data ::", deviceData);
		const reportedStateObj = JSON.parse(deviceData.reported_state);
		const modbusStateObj = JSON.parse(deviceData.modbus_state || "[]")[0] || [{}];
		const desiredStateObj = JSON.parse(deviceData.desired_state);
		const desiredStateInfoObj = JSON.parse(deviceData.desired_state_info);

		const { locationBreadcrumb, tenants } = await getFullLocation(deviceData.zone);

		const alarmGroups = {};
		alarmGroups.input = deviceData.alarmGroupsInput.items.map((input) => {
			return {
				name: input.alarmGroup.name,
				id: input.alarmGroup.id,
				otherDevices: input.alarmGroup.input.items.length,
			};
		});
		alarmGroups.output = deviceData.alarmGroupsOutput.items.map((output) => {
			return {
				name: output.alarmGroup.name,
				id: output.alarmGroup.id,
				otherDevices: output.alarmGroup.output.items.length,
			};
		});

		const leakDistance = modbusStateObj.registers?.find((register) => register.name === "leakDistance")?.value;
		if (leakDistance === 65535 || leakDistance === 0) modbusStateObj.leakDistance = "No Leak";
		else if (leakDistance < 65535) modbusStateObj.leakDistance = leakDistance / 10;
		else modbusStateObj.leakDistance = null;

		setDeviceInfo({
			name: deviceData.name,
			createdAt: deviceData.createdAt,
			updatedAt: deviceData.updatedAt,
			inputDevice: deviceData.inputDevice,
			outputDevice: deviceData.outputDevice,
			alarmGroups: alarmGroups,
			zone: deviceData.zone,
			zoneBreadcrumb: locationBreadcrumb,
			tenants: tenants,
			tenantsCount: tenants.reduce((acc, tenant) => acc + tenant.tenantIds.length, 0),
			liveEventCount: deviceData.liveEvent?.items?.length,
			liveEventIds: deviceData.liveEvent?.items?.map((liveEvent) => liveEvent.id),
			archiveEventCount: deviceData.archiveEvent?.items?.length,
		});
		setReportedState(reportedStateObj);
		setModbusState(modbusStateObj);
		setDesiredState(desiredStateObj);
		setDesiredStateInfo(desiredStateInfoObj);
		setInAlarm(deviceData.alert?.sensor_state > 0);
	};

	const updateDeviceInfo = async (deviceInfo) => {
		setDeviceInfo(deviceInfo);
	};

	const handleShowAlarmGroupDialog = (type) => {
		setSelectedAlarmGroups({
			type: type,
			data: type === "input" ? deviceInfo.alarmGroups.input : deviceInfo.alarmGroups.output,
		});
		setShowDeviceAlarmGroupDialog(true);
	};

	const confirmToggleRelay = () => {
		confirmDialog({
			header: t("device.dialog.toggleRelay.header"),
			message: (
				<>
					<p>
						{
							<Trans
								i18nKey="device.dialog.toggleRelay.message"
								values={{
									from: getRelayString(Boolean(reportedState.relay.status)),
									to: getRelayString(Boolean(!reportedState.relay.status)),
									device: deviceId,
								}}
								components={{ b: <b /> }}
							/>
						}
					</p>
				</>
			),
			acceptLabel: t("device.dialog.toggleRelay.accept"),
			rejectLabel: t("common.action.cancel"),
			acceptIcon: "pi pi-check",
			acceptClassName: "success feature",
			rejectClassName: "",
			accept: () => doToggleRelay(),
			reject: () => {}, //Do Nothing,
		});
	};

	const doToggleRelay = async () => {
		const desiredState = {
			thingName: deviceId,
			desiredState: {
				relay: {
					status: Boolean(!reportedState.relay.status),
				},
			},
		};

		await apiRequest("post", "/shadow/desired/update", desiredState, t("device.dialog.toggleRelay.loading"));

		const updatedDesiredStateInfo = {
			...desiredStateInfo,
			relay: {
				value: Boolean(!reportedState.relay.status),
				createdAt: new Date().getTime(),
				createdBy: loggedInUser.username,
			},
		};

		await API.graphql(
			graphqlOperation(updateDevice, {
				input: {
					id: deviceId,
					desired_state_info: JSON.stringify(updatedDesiredStateInfo),
				},
			})
		);
	};

	const confirmDeleteDevice = () => {
		const lf = new Intl.ListFormat("en");
		const lastInputs = [];
		const lastOutputs = [];
		if (deviceInfo.alarmGroups.input.length > 0) {
			for (const input of deviceInfo.alarmGroups.input) {
				if (input.otherDevices <= 1) lastInputs.push(input.name);
			}
		}
		if (deviceInfo.alarmGroups.output.length > 0) {
			for (const output of deviceInfo.alarmGroups.output) {
				if (output.otherDevices <= 1) lastOutputs.push(output.name);
			}
		}

		if (lastInputs.length > 0) {
			setDeleteDisabledProps({ type: "INPUT", groups: lf.format(lastInputs) });
			setShowDeleteDisabledDialog(true);
		} else if (lastOutputs.length > 0) {
			setDeleteDisabledProps({ type: "OUTPUT", groups: lf.format(lastOutputs) });
			setShowDeleteDisabledDialog(true);
		} else {
			confirmDialog({
				header: t("device.dialog.delete.header"),
				message: (
					<div className="flexVert gapRow-medium">
						<p>
							{
								<Trans
									i18nKey="device.dialog.delete.message"
									values={{
										deviceId: deviceId,
										deviceName: deviceInfo.name,
									}}
									components={{ b: <b /> }}
								/>
							}
						</p>
						{inAlarm && (
							<Note
								messageKey={t("device.dialog.delete.inAlarmWarn")}
								icon="pi pi-exclamation-triangle fontColour-warn"
							/>
						)}
						{deviceInfo.alarmGroups.input.length > 0 && (
							<Note
								messageKey={t("device.dialog.delete.alarmGroupWarn", {
									count: deviceInfo.alarmGroups.input.length,
									type: t("device.dialog.attributes.form.inputDevice.label"),
								})}
								icon="pi pi-exclamation-triangle fontColour-warn"
							/>
						)}
						{deviceInfo.alarmGroups.output.length > 0 && (
							<Note
								messageKey={t("device.dialog.delete.alarmGroupWarn", {
									count: deviceInfo.alarmGroups.output.length,
									type: t("device.dialog.attributes.form.outputDevice.label"),
								})}
								icon="pi pi-exclamation-triangle fontColour-warn"
							/>
						)}
					</div>
				),
				acceptLabel: t("common.action.delete"),
				rejectLabel: t("common.action.cancel"),
				acceptIcon: "pi pi-trash",
				acceptClassName: "error feature",
				rejectClassName: "",
				accept: () => doDeleteDevice(),
				reject: () => {}, //Do Nothing,
			});
		}
	};

	const doDeleteDevice = async () => {
		try {
			setLoading({ visible: true, message: "Deleting Device" });

			const {
				data: {
					listAlarmGroupsInputs: { items: alarmInputs },
				},
			} = await API.graphql(
				graphqlOperation(listAlarmGroupsInputIds, { filter: { deviceID: { eq: deviceId } } })
			);

			const {
				data: {
					listAlarmGroupsOutputs: { items: alarmOutputs },
				},
			} = await API.graphql(
				graphqlOperation(listAlarmGroupsOutputIds, { filter: { deviceID: { eq: deviceId } } })
			);

			const liveEvents = deviceInfo.liveEventIds;

			await apiRequest("del", `/device/${deviceId}`, { data: { alarmInputs, alarmOutputs, liveEvents } }, null);

			navigate(`${nav.device.base}/${nav.device.management}`);
		} catch (err) {
			console.error("Delete Device Error ::", err);
		} finally {
			setLoading({ visible: false });
		}
	};

	return (
		<>
			<PageHeader
				title={t("device.pageTitle", { deviceId: deviceInfo?.name })}
				subtitle={t("device.pageSubtitle")}
				enableBack
			/>
			<main className="appMain">
				<div className="appMain-content">
					<div className="flex jContent-end gap-small">
						{/* <Button onClick={() => doClearDesiredState()} className="error">
							Clear Desired
						</Button> */}
						<Button
							onClick={() => confirmDeleteDevice()}
							visible={process.env.REACT_APP_ENABLE_DEVICE_DELETE === "true"}
							className="error">
							{t("device.actions.delete")}
						</Button>
						<Button
							onClick={() => confirmToggleRelay()}
							visible={
								deviceInfo?.outputDevice?.includes("FLOWSTOP") &&
								process.env.REACT_APP_ENABLE_DEVICE_RELAY === "true"
							}>
							{t("device.actions.valveControl")}
						</Button>
						<Button onClick={() => setShowConfigUpdateDialog(true)}>{t("device.actions.config")}</Button>
						<Button onClick={() => setShowAttributesUpdateDialog(true)}>
							{t("device.actions.attributes")}
						</Button>
					</div>

					{/* {deviceInfo?.inAlarm && (
						<Note
							icon="pi pi-exclamation-triangle fontColour-warn"
							messageKey={t("alarm.group.edit.inAlarm")}
							messageStyle="fontWeight-bold"
						/>
					)} */}

					{desiredState && desiredStateInfo && (
						<div className="flexVert gap-small">
							{/* {desiredState.valve !== undefined && (
								<PendingUpdate
									label={t("device.state.valve")}
									value={getValveString(desiredState.valve.value)}
									createdBy={desiredState.valve.createdBy}
									createdAt={desiredState.valve.createdAt}
								/>
							)} */}
							{desiredState.relay !== undefined && (
								<PendingUpdate
									label={t("device.state.relay")}
									value={getRelayString(desiredStateInfo?.relay.status) || "Unknown"}
									createdBy={desiredStateInfo?.relay?.createdBy || "Unknown"}
									createdAt={desiredStateInfo?.relay?.createdAt || "Unknown"}
								/>
							)}
							{desiredState.wake_interval !== undefined && (
								<PendingUpdate
									label={t("device.config.interval.wakeInterval")}
									value={`${desiredStateInfo?.wake_interval?.value} seconds` || "Unknown"}
									createdBy={desiredStateInfo?.wake_interval?.createdBy || "Unknown"}
									createdAt={desiredStateInfo?.wake_interval?.createdAt || "Unknown"}
								/>
							)}
							{desiredState.keepalive_interval !== undefined && (
								<PendingUpdate
									label={t("device.config.interval.keepInterval")}
									value={`${desiredStateInfo?.keepalive_interval?.value} seconds` || "Unknown"}
									createdBy={desiredStateInfo?.keepalive_interval?.createdBy || "Unknown"}
									createdAt={desiredStateInfo?.keepalive_interval?.createdAt || "Unknown"}
								/>
							)}
						</div>
					)}
					{reportedState && (
						<>
							<div className="card">
								<div className="grid columns-2-FreeAuto gapCol-large gapRow-medium aItems-start">
									<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
										<Field label={t("device.state.id")} response={deviceId} />
									</div>
									<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
										<Field
											label={t("device.state.location")}
											// labelClass="aSelf-center"
											highlight
											response={
												<Breadcrumb
													items={deviceInfo.zoneBreadcrumb}
													reverse
													highlightIndex={deviceInfo.zoneBreadcrumb.length}
												/>
											}
										/>
										{deviceInfo.tenantsCount > 0 && (
											<Field
												label={t("device.state.tenants")}
												highlight
												response={
													deviceInfo.tenantsCount > 1 ? (
														<Button
															className="p-link text-left"
															onClick={() => setShowAssignedTenantDialog(true)}
															label={t("device.state.tenants", {
																count: deviceInfo.tenantsCount,
															})}
														/>
													) : (
														<Username sub={deviceInfo.tenants[0].tenantIds[0]} link />
													)
												}
											/>
										)}
									</div>
									<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
										<Field
											label={t("device.state.inputDevice", {
												count: deviceInfo.inputDevice?.length,
											})}
											labelClass="aSelf-center"
											highlight
											response={
												deviceInfo.inputDevice ? (
													<div className="flex" style={{ gap: "4px" }}>
														{deviceInfo.inputDevice.map((device) => {
															const deviceObj = _.find(inputDeviceOptions, {
																value: device,
															});
															return (
																<PillItem
																	key={device}
																	value={deviceObj.label}
																	icon={deviceObj.icon}
																	wrapperStyle="blue1"
																/>
															);
														})}
													</div>
												) : (
													t("device.feature.null")
												)
											}
										/>
										<Field
											label={t("device.state.outputDevice", {
												count: deviceInfo.outputDevice?.length,
											})}
											labelClass="aSelf-center"
											highlight
											response={
												deviceInfo.outputDevice ? (
													<div className="flex" style={{ gap: "4px" }}>
														{deviceInfo.outputDevice.map((device) => {
															const deviceObj = _.find(inputDeviceOptions, {
																value: device,
															});
															return (
																<PillItem
																	key={device}
																	value={deviceObj.label}
																	icon={deviceObj.icon}
																	wrapperStyle="blue1"
																/>
															);
														})}
													</div>
												) : (
													t("device.feature.null")
												)
											}
										/>
										<Field
											label="Alarm Groups"
											highlight
											response={
												<div className="flexVert" style={{ gap: "4px" }}>
													{deviceInfo.inputDevice?.length > 0 && (
														<Button
															className="p-link text-left"
															onClick={() => handleShowAlarmGroupDialog("input")}
															label={t("device.state.alarmGroupsCount", {
																count: deviceInfo.alarmGroups.input.length,
																type: "input",
															})}
														/>
													)}
													{deviceInfo.outputDevice?.length > 0 && (
														<Button
															className="p-link text-left"
															onClick={() => handleShowAlarmGroupDialog("output")}
															label={t("device.state.alarmGroupsCount", {
																count: deviceInfo.alarmGroups.output.length,
																type: "output",
															})}
														/>
													)}
												</div>
											}
										/>
										<Field
											label="Events"
											highlight
											response={
												<div className="flex gapCol-xsmall">
													<Link
														to={`${nav.event.base}/${nav.event.management}?tab=live&device=${deviceInfo.name}`}>
														{deviceInfo.liveEventCount} Live
													</Link>
													<span>/</span>
													<Link
														to={`${nav.event.base}/${nav.event.management}?tab=archive&device=${deviceInfo.name}`}>
														{deviceInfo.archiveEventCount} Archive
													</Link>
												</div>
											}
										/>
									</div>
									<div className="grid columns-2-Auto gapCol-large gapRow-xsmall aItems-start">
										<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
											<Field
												label={t("device.state.firmware")}
												response={reportedState.firmware_version}
												highlight
											/>
											<Field
												label={t("device.state.hardware")}
												response={reportedState.hardware_version}
												highlight
											/>
											<Field
												label={t("device.state.timestamp")}
												highlight
												response={
													<div onClick={() => setDeviceTsFormat(!deviceTsFormat)}>
														{deviceTsFormat
															? getUiDateTime(
																	reportedState.device_timestamp,
																	"d MMM yyyy, HH:mm:ss"
															  )
															: reportedState.device_timestamp}
													</div>
												}
												responseUnit={!deviceTsFormat && " secs"}
												responseClass={["flex"]}
											/>
										</div>
										<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
											<Field
												label={t("common.updatedAt")}
												response={getUiDateTime(deviceInfo.updatedAt)}
												highlight
											/>
											<Field
												label={t("common.createdAt")}
												response={getUiDateTime(deviceInfo.createdAt)}
												highlight
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="card">
								<header className="span-2">
									<h3>{t("device.state.header")}</h3>
								</header>

								<div className="grid columns-3 gap-medium aItems-start">
									<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
										{deviceInfo?.outputDevice?.length > 0 && (
											<Field
												label={t("device.state.relay")}
												highlight
												response={getRelayString(reportedState.relay.status)}
												responseClass={[
													"fieldBoolean",
													`fieldBoolean-${!Boolean(reportedState.relay.status)}`,
												]}
											/>
										)}
										{deviceInfo?.inputDevice?.length > 0 && (
											<>
												<Field
													label={t("device.state.sensorState")}
													highlight
													response={getSensorString(
														reportedState.sensor1?.status | reportedState.sensor2?.status
													)}
													responseClass={[
														"fieldBoolean",
														`fieldBoolean-${!Boolean(
															reportedState.sensor1?.status |
																reportedState.sensor2?.status
														)}`,
													]}
												/>
												<Field
													label={t("device.state.sensorResistance")}
													highlight
													response={reportedState.sensor1?.resistance}
												/>
												{deviceInfo?.inputDevice?.includes("RANGER") && (
													<Field
														// label={t("device.state.leakDistance")}
														label="Leak Distance"
														highlight
														response={modbusState?.leakDistance || "---"}
														responseUnit={!isNaN(modbusState?.leakDistance) && "meters"}
													/>
												)}
											</>
										)}
									</div>
									<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
										<Field
											label={t("device.state.wifiSsid")}
											highlight
											response={reportedState.wifi?.ssid}
										/>
										<Field
											label={t("device.state.wifiRssi")}
											highlight
											response={reportedState.wifi?.rssi}
											responseUnit="dBm"
										/>
									</div>
									<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
										{reportedState.dc_power?.enabled && (
											<>
												<Field
													label={t("device.state.dcStatus")}
													highlight
													response={getDcStatusString(reportedState.dc_power.status)}
													responseClass={[
														"fieldBoolean",
														`fieldBoolean-${Boolean(reportedState.dc_power.status)}`,
													]}
												/>
												<Field
													label={t("device.state.dcVoltage")}
													highlight
													response={reportedState.dc_power.voltage}
													responseUnit="mV"
												/>
											</>
										)}
										{reportedState.battery?.enabled && (
											<>
												<Field
													label={t("device.state.battCharging")}
													highlight
													response={getBatteryChargingString(reportedState.battery.charging)}
													responseClass={[
														"fieldBoolean",
														`fieldBoolean-${Boolean(reportedState.battery.charging)}`,
													]}
												/>
												<Field
													label={t("device.state.battPercentage")}
													highlight
													response={reportedState.battery.percentage}
													responseUnit="%"
												/>
											</>
										)}
									</div>
								</div>
							</div>

							<div className="card">
								<header>
									<h3>{t("device.config.header")}</h3>
								</header>
								<div className="grid columns-3 gap-medium aItems-start">
									<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
										<Field
											label={t("device.config.interval.wakeInterval")}
											highlight
											response={reportedState.wake_interval}
											responseUnit={t("device.unit.seconds")}
										/>
										<Field
											label={t("device.config.interval.keepInterval")}
											highlight
											response={reportedState.keepalive_interval}
											responseUnit={t("device.unit.seconds")}
										/>
									</div>
									<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
										<Field
											label={t("device.config.batt")}
											highlight
											response={getYesNoString(reportedState.battery?.enabled)}
											responseClass={[
												"fieldBoolean",
												`fieldBoolean-${
													reportedState.battery?.enabled
														? Boolean(reportedState.battery?.enabled)
														: "undefined"
												}`,
											]}
										/>
										<Field
											label={t("device.config.vdc")}
											response={getYesNoString(reportedState.dc_power?.enabled)}
											responseClass={[
												"fieldBoolean",
												`fieldBoolean-${
													reportedState.dc_power?.enabled
														? Boolean(reportedState.dc_power?.enabled)
														: "undefined"
												}`,
											]}
										/>
									</div>
									<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
										<Field
											label={t("device.config.modbus")}
											highlight
											response={getYesNoString(reportedState.modbus?.enabled)}
											responseClass={[
												"fieldBoolean",
												`fieldBoolean-${
													reportedState.modbus?.enabled
														? Boolean(reportedState.modbus?.enabled)
														: "undefined"
												}`,
											]}
										/>
									</div>
								</div>
							</div>

							{/* <div className="card">
								<header>
									<h3>{t("device.modbus.header")}</h3>
								</header>
								<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
									<Field label={t("device.modbus.status")} response={reportedState.modbus.status} />
									<Field
										label={t("device.modbus.distance")}
										highlight
										response={reportedState.modbus.distance}
									/>
									<Field
										label={t("device.modbus.timestamp")}
										highlight
										response={reportedState.modbus.timestamp}
									/>
									<Field
										label={t("device.modbus.events")}
										highlight
										response={reportedState.modbus.n_events}
									/>
								</div>
							</div> */}
						</>
					)}
					{/* <pre>{JSON.stringify(device, null, 2)}</pre>
					<pre>{JSON.stringify(reportedState, null, 2)}</pre>
					<pre>{JSON.stringify(desiredState, null, 2)}</pre> */}
				</div>
			</main>

			{reportedState && (
				<ConfigUpdateDialog
					deviceId={deviceId}
					deviceData={reportedState}
					desiredStateInfo={desiredStateInfo}
				/>
			)}
			{deleteDisabledProps && (
				<DeleteDisabledDialog type={deleteDisabledProps.type} groups={deleteDisabledProps.groups} />
			)}
			{deviceInfo && (
				<AttributesUpdateDialog
					deviceId={deviceId}
					deviceData={deviceInfo}
					updateDeviceData={updateDeviceInfo}
				/>
			)}
			{deviceInfo && (
				<DeviceAlarmGroupDialog type={selectedAlarmGroups.type} alarmGroups={selectedAlarmGroups.data} />
			)}
			{deviceInfo?.tenants?.length > 0 && <AssignedTenantDialog tenants={deviceInfo.tenants} />}
		</>
	);
};

export default DeviceDetails;
