//Node Modules
import React, { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router";
import { useRecoilValue, useResetRecoilState } from "recoil";

//Pages

//BinaryForge Components
import { AppFooter, AppHeader } from "./components/layout";
import { LoadingDialog } from "./components/helpers";
import AlarmSubscriber from "./components/helpers/AlarmSubscriber";

//3rd Party Components
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";

//Atoms
import { themeAtom, toastAtom } from "./atoms";

//Helpers
import { useGetValidationConfig } from "./helpers/Validation";

//Nav
import { guestRoutes, comboRoutes } from "./config/navigation";

//Messages & Languages
import "./i18n";

//Styles
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./assets/css/main.css";
import { loggedInUserAtom, selectedUserSchemeAtom } from "./atoms/user";

const App = () => {
	//Hooks & Util
	const location = useLocation();
	const getValidationConfig = useGetValidationConfig();
	const toastRef = useRef(null);
	const pageType = guestRoutes.includes(location.pathname)
		? "guest"
		: comboRoutes.includes(location.pathname)
		? "combo"
		: "auth";

	//Recoil State
	const theme = useRecoilValue(themeAtom);
	const toast = useRecoilValue(toastAtom);
	const clearToast = useResetRecoilState(toastAtom);
	const selectedScheme = useRecoilValue(selectedUserSchemeAtom);
	const loggedInUser = useRecoilValue(loggedInUserAtom);

	//Local State

	//Make sure the correct theme is set
	useEffect(() => {
		document.body.setAttribute("data-theme", theme);
	}, [theme]);

	//Load any app config needed
	useEffect(() => {
		if (loggedInUser) {
			getValidationConfig();
		}
	}, [loggedInUser]);

	//Generic show toast message
	useEffect(() => {
		if (toast.detail != null) {
			toastRef.current.show({
				severity: toast.severity,
				summary: toast.summary,
				detail: toast.detail,
				life: toast.life,
				sticky: toast.sticky,
			});
			clearToast();
		}
	}, [toast, clearToast]);

	return (
		<div className={`app ${pageType}`}>
			{/* General Util Components */}
			<Toast ref={toastRef} />
			<LoadingDialog />
			<ConfirmDialog />

			{/* Show Header Component if logged in */}
			{/* {location.pathname !== "/" && ( */}
			{selectedScheme && (
				<>
					{pageType === "auth" && <AppHeader />}
					{pageType === "auth" && <AlarmSubscriber />}
				</>
			)}

			{/* Outlet shows page content */}
			<Outlet />

			{/* App Footer on every page */}
			<AppFooter />
		</div>
	);
};

export default App;
