import { t } from "i18next";

const BooleanTemplate = ({ value, type, options }) => {
	let response = null;

	switch (options) {
		case "yes-no":
			response = value ? t("common.boolean.yes") : t("common.boolean.no");
			break;
		case "true-false":
			response = value ? t("common.boolean.true") : t("common.boolean.false");
			break;
		case "active-inactive":
			response = value ? t("common.boolean.active") : t("common.boolean.inactive");
			break;
		default:
			response = value ? t("common.boolean.true") : t("common.boolean.false");
			break;
	}

	return (
		<div className={`flex gap-xsmall ${value ? "fontColour-success" : "fontColour-error"}`}>
			{type !== "text" && <i className={`pi ${value ? "pi-check-circle" : "pi-times-circle"}`} />}
			{type !== "icon" && <span>{response}</span>}
		</div>
	);
};

export default BooleanTemplate;
