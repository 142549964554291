//Node Modules
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useRecoilRefresher_UNSTABLE, useResetRecoilState } from "recoil";
import { useNavigate, useSearchParams } from "react-router-dom";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import {
	onCreateArchiveEvent,
	onCreateLiveEvent,
	onUpdateLiveEvent,
	onDeleteLiveEvent,
} from "../../../graphql/subscriptions";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { ArchiveEventsLoader, LiveEventsLoader } from "../../../components/events";

//3rd Party Components
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";

//Atoms
import { liveEventsListSelector, archiveEventsListSelector, eventTableFiltersAtom } from "../../../atoms/events";

//Helpers

//Other

const EventManagement = () => {
	//Hooks
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();

	//Recoil
	const refreshLiveEventsCache = useRecoilRefresher_UNSTABLE(liveEventsListSelector);
	const refreshArchiveEventsCache = useRecoilRefresher_UNSTABLE(archiveEventsListSelector);
	const resetEventTableFilters = useResetRecoilState(eventTableFiltersAtom);

	//Local State
	const [activeIndex, setActiveIndex] = useState(0);
	const [liveUpdateAvailable, setLiveUpdateAvailable] = useState(false);
	const [archiveUpdateAvailable, setArchiveUpdateAvailable] = useState(false);

	//On Page Load
	useEffect(() => {
		refreshLiveEventsCache();
		refreshArchiveEventsCache();
		const activeTab = searchParams.get("tab");
		if (activeTab === "live") setActiveIndex(0);
		if (activeTab === "archive") setActiveIndex(1);

		const { liveDeleteSub, liveCreateSub, liveSub, archiveSub } = subscribeToEvents();

		return () => {
			resetEventTableFilters();
			liveDeleteSub.unsubscribe();
			liveCreateSub.unsubscribe();
			liveSub.unsubscribe();
			archiveSub.unsubscribe();
		};
	}, []);

	const subscribeToEvents = () => {
		console.log("Subscribe to Event updates...");
		const liveDeleteSub = API.graphql(graphqlOperation(onDeleteLiveEvent)).subscribe({
			next: () => {
				setLiveUpdateAvailable(true);
			},
			error: (error) => console.error(error),
		});
		const liveCreateSub = API.graphql(graphqlOperation(onCreateLiveEvent)).subscribe({
			next: () => {
				setLiveUpdateAvailable(true);
			},
			error: (error) => console.error(error),
		});
		const liveSub = API.graphql(graphqlOperation(onUpdateLiveEvent)).subscribe({
			next: () => {
				setLiveUpdateAvailable(true);
			},
			error: (error) => console.error(error),
		});
		const archiveSub = API.graphql(graphqlOperation(onCreateArchiveEvent)).subscribe({
			next: () => {
				setArchiveUpdateAvailable(true);
			},
			error: (error) => console.error(error),
		});
		return { liveDeleteSub, liveCreateSub, liveSub, archiveSub };
	};

	const updateLiveEvents = () => {
		refreshLiveEventsCache();
		setLiveUpdateAvailable(false);
	};

	const updateArchiveEvents = () => {
		refreshArchiveEventsCache();
		setArchiveUpdateAvailable(false);
	};

	const tabLiveHeaderTemplate = (options) => {
		return (
			<div className="flex gap-small aItems-center tabItem-header" onClick={options.onClick}>
				<Button
					className="noBorder inline"
					icon="pi pi-refresh"
					visible={liveUpdateAvailable}
					onClick={() => updateLiveEvents()}
				/>
				{!liveUpdateAvailable && (
					<i className="pi pi-exclamation-triangle fontColour-lighter fontSize-medium" />
				)}
				<span>{options.titleElement}</span>
			</div>
		);
	};

	const tabArchiveHeaderTemplate = (options) => {
		return (
			<div className="flex gap-small aItems-center tabItem-header" onClick={options.onClick}>
				<Button
					className="noBorder inline"
					icon="pi pi-refresh"
					visible={archiveUpdateAvailable}
					onClick={() => updateArchiveEvents()}
				/>
				{!archiveUpdateAvailable && <i className="icon-archive fontColour-lighter fontSize-medium" />}
				<span>{options.titleElement}</span>
			</div>
		);
	};

	return (
		<>
			<PageHeader title={t("events.management.pageTitle")} subtitle="" />
			<main className="appMain">
				<div className="appMain-content">
					<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
						<TabPanel
							header={t("events.management.liveEvents")}
							headerTemplate={tabLiveHeaderTemplate}
							contentClassName="paddingBlock-large">
							<LiveEventsLoader />
						</TabPanel>
						<TabPanel
							header={t("events.management.archiveEvents")}
							headerTemplate={tabArchiveHeaderTemplate}
							contentClassName="paddingBlock-large">
							<ArchiveEventsLoader />
						</TabPanel>
					</TabView>
				</div>
			</main>
		</>
	);
};

export default EventManagement;
