export const events = {
	eventType: {
		eventType_BATTERY_LOW: "Low Battery",
		eventType_MAINS_POWER_FAIL: "Mains Power Failure",
		eventType_SENSOR_INPUT_FLEXIPAD: "Flexi-Pad Sensor Alarm",
		eventType_SENSOR_INPUT_FLOWSTOP: "FlowStop Sensor Alarm",
		eventType_SENSOR_INPUT_RANGER: "Ranger Sensor Alarm",
		eventType_RELAY: "FlowStop Valve",
		eventType_OUT_OF_COMMS: "Potential Comms Issue",
	},
	management: {
		pageTitle: "Events",
		liveEvents: "Live Events",
		archiveEvents: "Archived Events",
		table: {
			type: "Event Type",
			deviceName: "Device",
			duration: "Duration",
			location: "Location",
		},
	},
};
