//Node Modules
import { useTranslation } from "react-i18next";

//BinaryForge Components
import { BfDialog } from "../helpers";

//3rd Party Components

//Atoms
import { SchemeSelector } from ".";

//Helpers

//Other

const SchemeSelectDialog = ({ refresh }) => {
	//Hooks
	const { t } = useTranslation();

	return (
		<BfDialog id="schemeSelectDialog" header={t("schemeSelect.dialog.header")}>
			<SchemeSelector refresh={refresh} />
		</BfDialog>
	);
};

export default SchemeSelectDialog;
