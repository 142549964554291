//Node Modules
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

//GraphQL
import { API as graphAPI, graphqlOperation } from "aws-amplify";
import { userSchemeConfigByCognitoUserId } from "../../../graphql/queries";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import {
	UserProfileAttributes,
	UserProfilePassword,
	UserProfileMFA,
	UserNotificationConfig,
} from "../../../components/user/profile";

//3rd Party Components

//Atoms
import { selectedUserSchemeAtom } from "../../../atoms/user";

//Helpers
import { useGetCurrentCognitoUser } from "../../../helpers/Auth";

//Other

const Profile = () => {
	//Hooks
	const { t } = useTranslation();
	const getCognitoUser = useGetCurrentCognitoUser();

	//Recoil State
	const scheme = useRecoilValue(selectedUserSchemeAtom);

	//Local State
	const [currentUser, setCurrentUser] = useState();

	//On Page Load
	useEffect(() => {
		const getCurrentUser = async () => {
			const cognitoUser = await getCognitoUser();
			const isAdmin = cognitoUser.signInUserSession.idToken.payload["cognito:groups"].includes("administrator");
			let role = isAdmin ? "ADMINISTRATOR" : null;

			if (!isAdmin) {
				const {
					data: {
						userSchemeConfigByCognitoUserId: { items: userSchemeConfigs },
					},
				} = await graphAPI.graphql(
					graphqlOperation(userSchemeConfigByCognitoUserId, {
						cognitoUserId: cognitoUser.attributes.sub,
						filter: {
							schemeId: { eq: scheme.id },
						},
					})
				);

				const userSchemeConfig = userSchemeConfigs[0];
				role = userSchemeConfig.role;
			}

			setCurrentUser({ cognitoUser: cognitoUser, role: role });
		};

		getCurrentUser();
	}, []);

	return (
		currentUser && (
			<>
				<PageHeader
					title={t("profile.pageTitle")}
					subtitle={t("profile.pageSubtitle", { username: currentUser.cognitoUser.username })}
				/>
				<main className="appMain">
					<div className="appMain-content">
						<UserProfileAttributes currentUser={currentUser} />
						<UserProfilePassword currentUser={currentUser} />
						{currentUser.role !== "INSTALLER" && <UserNotificationConfig currentUser={currentUser} />}
						<UserProfileMFA currentUser={currentUser} />
					</div>
				</main>
			</>
		)
	);
};

export default Profile;
