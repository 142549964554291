//Node Modules
import { useState, useEffect, useId, useRef } from "react";
import { v4 as uuid } from "uuid";
import _ from "lodash";

//BinaryForge Components
import FlowstopItem from "./FlowstopItem";

//3rd Party Components
import { Tooltip } from "primereact/tooltip";

//Atoms

//Helpers
import { getUiDateTime } from "../../helpers/DateUtils";
import { inputDeviceOptions } from "../../config/device";

//Other

const AlarmCard = ({ alarmData }) => {
	const tooltipId = useRef(uuid());
	const errorId = useId();
	const { device: input, outputDevices, updatedAt } = alarmData;

	const [errors, setErrors] = useState([]);
	const [icon, setIcon] = useState();
	const [locationBreadcrumb, setLocationBreadcrumb] = useState();

	useEffect(() => {
		if (!input.inputDevice) {
			setErrors((prevState) => [...prevState, "Unexpected Alarm - No Input Devices"]);
			setIcon("pi pi-exclamation-triangle warnText");
		} else {
			const deviceObj = _.find(inputDeviceOptions, {
				value: input.inputDevice[0],
			});
			setIcon(deviceObj.icon);
		}

		const formattedBreadcrumb = JSON.parse(input.zoneBreadcrumb || null)?.label?.replaceAll("|", " > ");
		setLocationBreadcrumb(formattedBreadcrumb);
	}, [alarmData]);

	return (
		<div className={`card ${errors.length > 0 && "warnBorder"}`}>
			<Tooltip target={`.customTooltip-${tooltipId.current}`} />

			<header className="flex aItems-center gap-xsmall no-divider marginBottom-xsmall">
				<i className={`${icon} fontColour-error`} />
				<h3>{input.name}</h3>
			</header>

			<div className="flex aItems-center gap-xsmall">
				<i className="pi pi-map-marker" />
				<span
					className={`customTooltip-${tooltipId.current}`}
					data-pr-tooltip={locationBreadcrumb}
					data-pr-at="right+10 center">
					{input.zone?.label || "N/A"}
				</span>
			</div>

			<div className="flex aItems-center gap-xsmall">
				<i className="pi pi-clock" />
				<span>{getUiDateTime(updatedAt)}</span>
			</div>

			{errors.length > 0 ? (
				<div className="marginTop-small">
					{errors.map((error) => (
						<span key={errorId}>{error}</span>
					))}
				</div>
			) : (
				<div className="flexVert gapRow-xsmall marginTop-small">
					{outputDevices.map((outputDevice) => (
						<div key={uuid()}>
							<FlowstopItem flowstopData={outputDevice} />
							<span className="fontSize-small">
								{outputDevice.alarmGroupsOutput?.items
									?.map((group) => group.alarmGroup.name)
									.join(", ")}
							</span>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default AlarmCard;
