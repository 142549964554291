//Node Modules
import { useState, useRef, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//Pages

//BinaryForge Components
import { OtaJobCreateDialog } from "./";

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

//Atoms
import { dialogAtomFamily } from "../../../atoms";

//Helpers
import { filterApplyTemplate, datetimeRowTemplate } from "../../../config/datatable";

//Other
import { nav } from "../../../config/navigation";

const JobsList = ({ data }) => {
	//Hooks
	const { t } = useTranslation();
	const dt = useRef(null);

	//Recoil
	const setShowJobCreateUpload = useSetRecoilState(dialogAtomFamily("otaJobCreateDialog"));

	//Local State
	const [selected, setSelected] = useState();

	// Local State
	const [dtFilters, setDtFilters] = useState(null);

	const initFilters = () => {
		setDtFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			version: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			type: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			status: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			createdAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			updatedAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
		});
	};

	useEffect(() => {
		if (data) {
			initFilters();
		}
	}, [data]);

	const typeRowTemplate = (rowData) => {
		return t(`admin.ota.type.${rowData.type}`);
	};

	const exportCSV = (selectionOnly) => {
		dt.current.exportCSV({ selectionOnly });
	};

	const statusRowTemplate = (rowData) => {
		return t(`admin.ota.job.status.${rowData.status}`);
	};

	return (
		<>
			<div className="flex jContent-end gap-small marginBottom-medium">
				<Button onClick={() => exportCSV(false)}>{t("common.table.export")}</Button>
				<Link
					to={`${nav.admin.base}/${nav.admin.ota.base}/${selected?.id}`}
					className="button"
					disabled={!selected}>
					{t("admin.ota.job.actions.details")}
				</Link>
				<Button
					label={t("common.action.create")}
					className="feature"
					onClick={() => {
						setShowJobCreateUpload(true);
					}}
				/>
			</div>
			<DataTable
				ref={dt}
				value={data}
				emptyMessage={t("common.table.noData")}
				selectionMode="single"
				selection={selected}
				onSelectionChange={(e) => setSelected(e.value)}
				sortMode="multiple"
				removableSort
				filters={dtFilters}
				filterDisplay="menu"
				paginator
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
				currentPageReportTemplate={t("common.table.paginatorTemplate")}
				rows={10}
				dataKey="id">
				<Column
					field="name"
					header={t("admin.ota.job.table.name")}
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="version"
					header={t("admin.ota.job.table.version")}
					sortable
					sortField="versionCompare"
					filter
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="type"
					header={t("admin.ota.job.table.type")}
					body={typeRowTemplate}
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="status"
					header={t("admin.ota.job.table.status")}
					body={statusRowTemplate}
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="createdAt"
					header={t("common.table.createdAt")}
					body={(rowData) => datetimeRowTemplate(rowData, "createdAt")}
					dataType="date"
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="updatedAt"
					header={t("common.table.updatedAt")}
					body={(rowData) => datetimeRowTemplate(rowData, "updatedAt")}
					dataType="date"
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
			</DataTable>

			<OtaJobCreateDialog />
		</>
	);
};

export default JobsList;
