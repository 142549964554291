import { Button } from "primereact/button";
import { getUiDate } from "../../helpers/DateUtils";

const ItemTemplate = ({ file, uploadProps, iconType }) => {
	return (
		<div className="flex jContent-spaceBetween">
			<div className="flex">
				<i className={`pi ${iconType} fontSize-large fontColour-success marginRight-small`} />
				<div className="flexVert">
					{file.name}
					<span className="fontSize-small fontColour-light">{getUiDate(file.lastModified)}</span>
				</div>
			</div>
			<Button type="button" icon="pi pi-times" className="rounded error" onClick={() => uploadProps.onRemove()} />
		</div>
	);
};

const EmptyTemplate = ({ iconType, message }) => {
	return (
		<div className="flex aItems-center">
			<i className={`pi ${iconType} fontSize-large fontColour-light marginRight-small`}></i>
			<span className="fontColour-light">{message}</span>
		</div>
	);
};

export { ItemTemplate, EmptyTemplate };
