import { atom, selector } from "recoil";
import _ from "lodash";

import { API, graphqlOperation } from "aws-amplify";
import { selectedUserSchemeAtom } from "./user";
import { listDevicesTable, listDeviceProvisioningsTable } from "../graphql/queries-custom";

export const devicesListAtom = atom({
	key: "devicesListAtom",
	default: null,
});

export const selectedDeviceAtom = atom({
	key: "selectedDeviceAtom",
	default: null,
});

export const devicesListSelector = selector({
	key: "devicesListSelector",
	get: async ({ get }) => {
		const { id: schemeId } = get(selectedUserSchemeAtom);
		const {
			data: {
				deviceByScheme: { items: devicesData },
			},
		} = await API.graphql(
			graphqlOperation(listDevicesTable, {
				schemeId: schemeId,
			})
		);

		for (const device of devicesData) {
			device.zoneBreadcrumb = JSON.parse(device.zoneBreadcrumb);
		}

		// devicesData.map((deviceData) => {
		// 	const alarmGroupsInput = [];
		// 	const alarmGroupsOutput = [];
		// 	deviceData.alarmGroupsInput.items?.map((input) => {
		// 		alarmGroupsInput.push(input.alarmGroup.name);
		// 	});
		// 	deviceData.alarmGroupsOutput.items?.map((output) => {
		// 		alarmGroupsOutput.push(output.alarmGroup.name);
		// 	});
		// 	deviceData.alarmGroupsInputCount = alarmGroupsInput.length;
		// 	deviceData.alarmGroupsOutputCount = alarmGroupsOutput.length;
		// 	deviceData.alarmGroupsInput = _.sortBy(alarmGroupsInput);
		// 	deviceData.alarmGroupsOutput = _.sortBy(alarmGroupsOutput);
		// });

		return devicesData;
	},
	set: ({ set }, newValue) => {
		set(devicesListAtom, newValue);
	},
});

export const devicesProvisionListAtom = atom({
	key: "devicesProvisionListAtom",
	default: null,
});

export const devicesProvisionListSelector = selector({
	key: "devicesProvisionListSelector",
	get: async ({ get }) => {
		const { id: schemeId } = get(selectedUserSchemeAtom);
		const {
			data: {
				deviceProvisioningByScheme: { items: devicesData },
			},
		} = await API.graphql(
			graphqlOperation(listDeviceProvisioningsTable, {
				schemeId: schemeId,
				sortDirection: "DESC",
			})
		);

		devicesData.map((d) => (d.createdAt = new Date(d.createdAt)));

		return devicesData;
	},
	set: ({ set }, newValue) => {
		set(devicesListAtom, newValue);
	},
});
