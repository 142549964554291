//Node Modules
import { useRecoilValueLoadable } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { Loading, Note } from "../helpers";
import { DoughnutChart } from "../report";

//3rd Party Components

//Atoms
import { eventSummarySelector } from "../../atoms/dashboard";

//Helpers

//Other

const EventSummaryLoader = () => {
	//Recoil
	const { t } = useTranslation();
	const { state, contents } = useRecoilValueLoadable(eventSummarySelector);

	switch (state) {
		case "hasError":
			return (
				<Note
					messageKey={`Error: ${contents.message ? contents.message : contents}`}
					icon="pi-exclamation-circle fontColour-error"
				/>
			);
		case "loading":
			return <Loading />;
		case "hasValue":
			const liveData = {
				BATTERY_LOW: contents.liveCounts.BATTERY_LOW,
				OUT_OF_COMMS: contents.liveCounts.OUT_OF_COMMS,
				MAINS_POWER_FAIL: contents.liveCounts.MAINS_POWER_FAIL,
				SENSOR_INPUT_FLEXIPAD: contents.liveCounts.SENSOR_INPUT_FLEXIPAD,
				SENSOR_INPUT_RANGER: contents.liveCounts.SENSOR_INPUT_RANGER,
				SENSOR_INPUT_FLOWSTOP: contents.liveCounts.SENSOR_INPUT_FLOWSTOP,
				RELAY: contents.liveCounts.RELAY,
			};
			const archiveData = {
				BATTERY_LOW: contents.archiveCounts.BATTERY_LOW,
				OUT_OF_COMMS: contents.archiveCounts.OUT_OF_COMMS,
				MAINS_POWER_FAIL: contents.archiveCounts.MAINS_POWER_FAIL,
				SENSOR_INPUT_FLEXIPAD: contents.archiveCounts.SENSOR_INPUT_FLEXIPAD,
				SENSOR_INPUT_RANGER: contents.archiveCounts.SENSOR_INPUT_RANGER,
				SENSOR_INPUT_FLOWSTOP: contents.archiveCounts.SENSOR_INPUT_FLOWSTOP,
				RELAY: contents.archiveCounts.RELAY,
			};
			const labels = Object.keys(liveData).map((label) => t(`events.eventType.eventType`, { context: label }));
			return (
				<div className="summaryChart-wrapper">
					<div className="summaryChart-center">
						<div className="flexVert text-center">
							<span>{contents.total.live}</span>
							<span>{contents.total.archive}</span>
						</div>
					</div>
					<DoughnutChart
						labels={labels}
						chartData={[liveData, archiveData]}
						measureName={["Live Count", "Past Month Count"]}
						legendId="eventSummaryLegend"
					/>
				</div>
			);
		default:
			return null;
	}
};

export default EventSummaryLoader;
