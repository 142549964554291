export const login = {
	submit: "Login",
	submitting: "Logging In...",
	completeRegistration: "Complete Registration",
	completingRegistration: "Registering User...",
	forgotPassword: "Forgot Password",
	username: {
		label: "Username",
		required: "A username is required to login",
	},
	password: {
		label: "Password",
		required: "A password is required to login",
	},
	passwordCurrent: {
		label: "Current Password",
	},
	passwordNew: {
		label: "New Password",
		match: "New password cannot match the current/temporary password",
	},
	passwordConfirm: {
		label: "Confirm New Password",
		match: "Value must match the password entered previously",
	},
	mfaCode: {
		label: "MFA Code",
	},
	error: {
		suspended: "Account Suspended. Please contact support to reinstate your account",
		noGroup: "Your user is not assigned a role. Please contact support.",
		roleNoAccess: "Your user role of {{role}}, is not authorised to access the CloudConnect Portal",
		alreadyRegistered:
			"Registration for {{username}} is already complete. If you think there is an error please contact support.",
	},
	toast: {
		loginFail: {
			summary: "Login Failed",
			detail: "{{error}}",
		},
		completeRegistration: {
			summary: "Registration Success",
			detail: "Registration is complete, please make sure you store your password securely and do not share it.",
		},
	},
};
