//Node Modules
import React from "react";
import { useRecoilState } from "recoil";

//Pages

//BinaryForgeComponenets

//3rd Party Components
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

//Atoms
import { dialogAtomFamily } from "../../atoms/dialog";

//Services

//Helpers

//Other

const LoadingDialog = () => {
	const [show, setShow] = useRecoilState(dialogAtomFamily("loadingDialog"));

	return (
		<Dialog
			visible={show.visible}
			closable={false}
			closeOnEscape={false}
			resizable={false}
			showHeader={false}
			className="loadingDialog"
			onHide={() => setShow({ visible: false })}>
			<ProgressSpinner strokeWidth="4" />
			<p className="message">
				{show.message}
				<span className="loadingDot">.</span>
				<span className="loadingDot">.</span>
				<span className="loadingDot">.</span>
			</p>
		</Dialog>
	);
};

export default LoadingDialog;
