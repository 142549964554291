//Node Modules
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLoaderData } from "react-router-dom";
import { useRecoilValue, useRecoilState, useRecoilValueLoadable, useRecoilRefresher_UNSTABLE } from "recoil";

//GraphQL

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { UserDetailsSkeleton } from "../../../components/helpers/skeleton";
import { Note } from "../../../components/helpers";
import { UserDetails as UserData } from "../../../components/user";
import { TenantDevices } from "../../../components/user/tenant";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { loggedInUserAtom, selectedUserAtom, selectedUserSelector } from "../../../atoms/user";

//Helpers
import { nav } from "../../../config/navigation";
import { useConfirmUserAction } from "../../../helpers/User";

const UserDetails = () => {
	//Hooks
	const { t } = useTranslation();
	const { username, isAdmin } = useLoaderData();
	const { confirmDeleteUser, confirmResetPassword, confirmResendInvite } = useConfirmUserAction();

	//Recoil
	const loggedInUser = useRecoilValue(loggedInUserAtom);
	const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
	const { state: selectedUserState, contents: selectedUserData } = useRecoilValueLoadable(selectedUserSelector);
	const refreshUserCache = useRecoilRefresher_UNSTABLE(selectedUserSelector);

	//On Page Load
	useEffect(() => {
		refreshUserCache();
		if (selectedUser?.username !== username) {
			setSelectedUser({ username: username });
		}
	}, []);

	//Render the User Details
	const UserDetailsLoader = () => {
		switch (selectedUserState) {
			case "hasError":
				return (
					<Note
						messageKey={`Error: ${selectedUserData.message ? selectedUserData.message : selectedUserData}`}
						icon="pi-exclamation-circle fontColour-error"
					/>
				);
			case "loading":
				return <UserDetailsSkeleton />;
			case "hasValue":
				return <UserData userData={selectedUserData} />;
			default:
				return null;
		}
	};

	return (
		<>
			<PageHeader
				title={isAdmin ? t("admin.user.details.pageTitle") : t("user.details.pageTitle")}
				subtitle={t("user.details.pageSubtitle", { username: username })}
				enableBack
			/>
			<main className="appMain">
				<div className="appMain-content">
					<div className="flex jContent-end gap-small">
						<Button
							onClick={() => confirmResendInvite()}
							label={t("user.details.actions.resend")}
							disabled={
								username === loggedInUser.username ||
								selectedUserData.status !== "FORCE_CHANGE_PASSWORD"
							}
						/>
						<Button
							onClick={() => confirmResetPassword()}
							label={t("user.details.actions.reset")}
							disabled={
								username === loggedInUser.username ||
								selectedUserData.status !== "CONFIRMED" ||
								!selectedUserData.active ||
								selectedUserData.email_verified === "false"
							}
						/>
						<Button
							onClick={() => confirmDeleteUser(selectedUserData.id, isAdmin)}
							label={t("user.details.actions.delete")}
							className="error"
							disabled={username === loggedInUser.username}
						/>
						<Link
							to={
								username === loggedInUser.username
									? nav.profile
									: isAdmin
									? `${nav.admin.base}/${nav.admin.user.base}/${nav.admin.user.edit}/${selectedUser?.username}`
									: `${nav.user.base}/${nav.user.edit}/${selectedUser?.username}`
							}
							className="button feature">
							{isAdmin ? t("admin.user.details.actions.edit") : t("user.details.actions.edit")}
						</Link>
					</div>

					<div className="card">
						<UserDetailsLoader />
					</div>

					{selectedUserData?.role === "TENANT" && <TenantDevices tenantId={selectedUserData.id} />}
				</div>
			</main>
		</>
	);
};

export default UserDetails;
