//Node Modules
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../../components/layout";
import { SchemeList } from "../../../../components/admin/scheme";

//3rd Party Components

//Atoms
import { schemesSelector, selectedSchemeAtom } from "../../../../atoms/scheme";

//Helpers

//Other
import { nav } from "../../../../config/navigation";

const SchemeManagement = () => {
	//Hooks
	const { t } = useTranslation();

	//Recoil State
	const refreshSchemeListCache = useRecoilRefresher_UNSTABLE(schemesSelector);
	const selectedScheme = useRecoilValue(selectedSchemeAtom);

	//On Page Load
	useEffect(() => {
		refreshSchemeListCache();
	}, []);

	return (
		<>
			<PageHeader title={t("admin.scheme.management.pageTitle")} />
			<main className="appMain">
				<div className="appMain-content">
					<div className="actions flex jContent-end gap-small">
						<Link
							to={`${nav.admin.base}/${nav.admin.scheme.base}/${nav.admin.scheme.create}`}
							className="button">
							{t("admin.scheme.management.actions.create")}
						</Link>
						<Link
							to={`${nav.admin.base}/${nav.admin.scheme.base}/${selectedScheme?.id}`}
							className="button feature"
							disabled={!selectedScheme}>
							{t("admin.scheme.management.actions.details")}
						</Link>
					</div>
					<SchemeList />
				</div>
			</main>
		</>
	);
};

export default SchemeManagement;
