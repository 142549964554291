//Node Modules
import { useRecoilValueLoadable } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { Note } from "../../helpers";
import { DatatableSkeleton } from "../../helpers/skeleton";
import { FileList } from "./";

//3rd Party Components

//Atoms
import { otaFilesSelector } from "../../../atoms/ota";

//Helpers

//Other

const FileLoader = () => {
	//Recoil
	const { t } = useTranslation();
	const { state, contents } = useRecoilValueLoadable(otaFilesSelector);

	switch (state) {
		case "hasError":
			console.error(contents);
			return (
				<Note
					messageKey={`Error: ${contents.message ? contents.message : contents}`}
					icon="pi-exclamation-circle fontColour-error"
				/>
			);
		case "loading":
			return (
				<DatatableSkeleton
					columns={[
						t("admin.ota.file.table.filename"),
						t("admin.ota.file.table.firmware"),
						t("admin.ota.file.table.modified"),
						t("admin.ota.file.table.uploadedBy"),
					]}
				/>
			);
		case "hasValue":
			return <FileList data={contents} />;
		default:
			return null;
	}
};

export default FileLoader;
