//Node Modules
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Storage } from "aws-amplify";

//BinaryForge Components
import { BfDialog, Note } from "../../helpers";
import { ItemTemplate, EmptyTemplate } from "../../helpers/UploadTemplate";

//3rd Party Components
import { FileUpload } from "primereact/fileupload";

//Atoms
import { dialogAtomFamily, loggedInUserAtom, toastAtom } from "../../../atoms";

//Helpers

//Other
const filenamePattern = /^cloudconnect_v[\d]+.[\d]+.[\d.]*bin$/;
const allowedFiles = ["binary", "octet-stream"];

const OtaFileUploadDialog = ({ existingFiles }) => {
	//Hooks
	const { t } = useTranslation();
	const fileUploadRef = useRef(null);

	//Local State
	const [errorMessages, setErrorMessages] = useState([]);

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("otaFileUploadDialog"));
	const setLoading = useSetRecoilState(dialogAtomFamily("loadingDialog"));
	const [toast, setToast] = useRecoilState(toastAtom);
	const user = useRecoilValue(loggedInUserAtom);

	//Validate the file which is uploaded
	const validateFile = (event) => {
		const file = event.files[0];
		const existingFilenames = existingFiles.map((existingFile) => existingFile.filename);
		if (existingFilenames.includes(file.name))
			setErrorMessages((prevState) => [
				...prevState,
				{
					message:
						"A file with that name already exists, uploading this file will overwrite the current file.",
					type: "info",
				},
			]);
		if (!allowedFiles.some((allowed) => file.type.includes(allowed)))
			setErrorMessages((prevState) => [
				...prevState,
				{ message: "File type in incorrect, file must be a .bin binary file.", type: "error" },
			]);
		if (!RegExp(filenamePattern).test(file.name))
			setErrorMessages((prevState) => [
				...prevState,
				{
					message: "Filename must be in the format cloudconnect_v{x}.{y}.{z}.bin (where z is optional).",
					type: "error",
				},
			]);
	};

	const handleUpload = async (event) => {
		try {
			setLoading({ visible: true, message: t("admin.ota.upload.loading") });

			const file = event.files[0];
			const filename = file.name;
			const version = file.name.split("_")[1].slice(1, -4);
			Storage.put(`ota/${filename}`, file, {
				level: "public",
				contentType: file.type,
				metadata: {
					firmwareVersion: version,
					uploadedBy: user.sub,
				},
			});

			fileUploadRef.current.clear();
			setShow(false);

			setToast({
				...toast,
				life: 6000,
				severity: "success",
				summary: t("admin.ota.upload.toast.successSummary"),
				detail: t("admin.ota.upload.toast.successDetail"),
			});
		} catch (err) {
			setToast({
				...toast,
				life: 6000,
				severity: "error",
				summary: t("admin.ota.upload.toast.errorSummary"),
				detail: t("admin.ota.upload.toast.errorDetail", { error: err.message }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const itemTemplate = (file, props) => {
		return <ItemTemplate file={file} uploadProps={props} iconType="pi-file" />;
	};

	const emptyTemplate = () => {
		return <EmptyTemplate iconType="pi-file" message={t("admin.ota.upload.empty")} />;
	};

	return (
		<BfDialog id="otaFileUploadDialog" header={t("admin.ota.upload.header")}>
			<div className="flexVert gapRow-xsmall marginBottom-small">
				{errorMessages.map((msg, idx) => {
					const icon =
						msg.type === "error"
							? "pi pi-exclamation-triangle fontColour-error"
							: "pi pi-info-circle fontColour-info";
					return (
						<Note
							key={`errorMsg-${idx}`}
							messageKey={msg.message}
							icon={icon}
							wrapperStyle="aItems-center "
						/>
					);
				})}
			</div>
			<FileUpload
				ref={fileUploadRef}
				accept=".bin"
				onSelect={validateFile}
				onRemove={() => setErrorMessages([])}
				customUpload
				uploadHandler={handleUpload}
				itemTemplate={itemTemplate}
				emptyTemplate={emptyTemplate}
				cancelOptions={{ className: "error" }}
				uploadOptions={{
					className:
						errorMessages.filter((msg) => msg.type === "error").length > 0
							? "p-disabled feature"
							: "feature",
				}}
			/>
		</BfDialog>
	);
};

export default OtaFileUploadDialog;
