//Node Modules
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";

//Pages

//BinaryForge Components

//3rd Party Components
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { Menu } from "primereact/menu";

//Atoms
import { dialogAtomFamily } from "../../atoms";

//Helpers
import { nav } from "../../config/navigation";
import { useLogout } from "../../helpers/Auth";
import { deviceSensorAlarmCounterSelector } from "../../atoms/alarm";
import { loggedInUserAtom, themeAtom } from "../../atoms";
import { selectedUserSchemeAtom, userSchemeOptionsAtom } from "../../atoms/user";
import { SchemeSelectDialog } from "../scheme";

//Other
const appElement = document.getElementsByClassName("app")[0];

const AppNav = () => {
	//Hooks
	const { t } = useTranslation();
	const handleLogout = useLogout();
	const userMenu = useRef(null);
	const adminMenu = useRef(null);
	const schemeMenu = useRef(null);

	//Recoil State
	const [theme, setTheme] = useRecoilState(themeAtom);
	const loggedInUser = useRecoilValue(loggedInUserAtom);
	const selectedScheme = useRecoilValue(selectedUserSchemeAtom);
	const userSchemeOptions = useRecoilValue(userSchemeOptionsAtom);
	const alarmCount = useRecoilValue(deviceSensorAlarmCounterSelector);
	const setShowSchemeSelectDialog = useSetRecoilState(dialogAtomFamily("schemeSelectDialog"));

	//Nav Menu Items - Scheme
	const schemeMenuItems = [
		{
			label: t("nav.home"),
			icon: "pi pi-chart-pie",
			url: `${nav.home}`,
		},
		{
			label: t("nav.alarms", { count: alarmCount }),
			icon: "pi pi-exclamation-triangle",
			url: `${nav.alarm.base}/${nav.alarm.alerts}`,
		},
		{
			label: t("nav.alarmGroups"),
			icon: "pi pi-sitemap",
			url: `${nav.alarm.base}/${nav.alarm.group.base}/${nav.alarm.group.management}`,
		},
		{
			label: t("nav.events"),
			icon: "pi pi-calendar",
			url: `${nav.event.base}/${nav.event.management}`,
		},
		{
			label: t("nav.devices"),
			icon: "pi pi-th-large",
			url: `${nav.device.base}/${nav.device.management}`,
		},
		{
			label: t("nav.zones"),
			icon: "pi pi-building",
			url: `${nav.zones.base}/${nav.zones.management}`,
		},
		// {
		// 	label: t("nav.groups"),
		// 	icon: "pi pi-sitemap",
		// 	url: nav.groups,
		// },
		{
			label: t("nav.reports"),
			icon: "pi pi-chart-bar",
			url: nav.report,
		},
		// {
		// 	label: t("nav.zones"),
		// 	icon: "pi pi-building",
		// 	url: `${nav.zones.base}/${nav.zones.management}`,
		// },
		{
			label: t("nav.users"),
			icon: "pi pi-users",
			url: `${nav.user.base}/${nav.user.management}`,
		},
	];

	//Nav Menu Item - User
	const userMenuItems = [
		{
			template: (item, options) => {
				return (
					<div className="flexVert paddingInline-small paddingBottom-xsmall">
						<span className="fontWeight-bold">{loggedInUser?.fullname}</span>
						<span className="fontWeight-bold">{t(`user.role.${selectedScheme?.role}`)}</span>
						<span className="fontWeight-bold fontSize-small">{selectedScheme?.name}</span>
					</div>
				);
			},
		},
		{ separator: true },
		{ label: t("nav.profile"), icon: "pi pi-user-edit", url: nav.profile },
		{
			label: t("nav.switchScheme"),
			icon: "pi pi-arrow-right-arrow-left",
			visible: userSchemeOptions !== null,
			command: () => setShowSchemeSelectDialog(true),
		},
		{
			label: t("nav.theme"),
			icon: `pi ${theme === "light" ? "pi-sun" : "pi-moon"}`,
			command: () => handleToggleTheme(),
		},
		{ label: t("nav.logout"), icon: "pi pi-sign-out", command: () => handleLogout() },
	];

	//Nav Menu Item - Admin
	const adminMenuItems = [
		{
			label: t("nav.adminUsers"),
			icon: "pi pi-users",
			url: `${nav.admin.base}/${nav.admin.user.base}/${nav.admin.user.management}`,
		},
		{
			label: t("nav.adminScheme"),
			icon: "icon-scheme",
			url: `${nav.admin.base}/${nav.admin.scheme.base}/${nav.admin.scheme.management}`,
		},
		{
			label: t("nav.adminOta"),
			icon: "pi pi-cloud-upload",
			url: `${nav.admin.base}/${nav.admin.ota.base}/${nav.admin.ota.management}`,
		},
	];

	const handleSchemeMenuToggle = (e) => {
		// console.log("Scheme Menu Clicked");
		schemeMenu.current.toggle(e);
	};

	const handleUserMenuToggle = (e) => {
		// console.log("User Menu Clicked");
		userMenu.current.toggle(e);
	};

	const handleAdminMenuToggle = (e) => {
		// console.log("Admin Menu Clicked");
		adminMenu.current.toggle(e);
	};

	const handleToggleTheme = () => {
		const newTheme = theme === "light" ? "dark" : "light";
		setTheme(newTheme);
	};

	return (
		<>
			<nav className="appNav" role="navigation">
				<Button
					onClick={(e) => handleSchemeMenuToggle(e)}
					icon="pi pi-bars"
					label="Menu"
					className="navItem menu-badge">
					<span className="p-overlay-badge">
						{alarmCount > 0 && <Badge value={alarmCount} severity="danger" />}
					</span>
				</Button>
				{loggedInUser.role === "ADMINISTRATOR" && (
					<>
						<span className="fontColour-andel-blue2 fontWeight-bold">/</span>
						<Button
							onClick={(e) => handleAdminMenuToggle(e)}
							icon="icon-protectedMenu"
							label="Admin"
							className="navItem"
						/>
					</>
				)}
				<span className="fontColour-andel-blue2 fontWeight-bold">/</span>
				<Button onClick={(e) => handleUserMenuToggle(e)} icon="pi pi-user" className="navItem" />
			</nav>
			<Menu model={schemeMenuItems} ref={schemeMenu} popup appendTo={appElement} />
			<Menu model={userMenuItems} ref={userMenu} popup appendTo={appElement} />
			<Menu model={adminMenuItems} ref={adminMenu} popup appendTo={appElement} />

			<SchemeSelectDialog refresh={true} />
		</>
	);
};

export default AppNav;
