import i18n from "../i18n";

export const devicesOptions = ["Andel_348518515ec8"];

export const measureOptions = [
	{
		value: "sensor1_status",
		label: i18n.t("report.measure.sensor1_status"),
		aggTypes: ["SUM", "COUNT", "AVG"],
		isBoolean: true,
	},
	{
		value: "sensor1_resistance",
		label: i18n.t("report.measure.sensor1_resistance"),
		aggTypes: ["SUM", "AVG", "MIN", "MAX"],
		isBoolean: false,
	},
	{
		value: "relay_status",
		label: i18n.t("report.measure.relay_status"),
		aggTypes: ["SUM", "COUNT", "AVG"],
		isBoolean: true,
	},
	{
		value: "battery_percentage",
		label: i18n.t("report.measure.battery_percentage"),
		aggTypes: ["SUM", "AVG", "MIN", "MAX"],
		isBoolean: false,
	},
	{
		value: "battery_charging",
		label: i18n.t("report.measure.battery_charging"),
		aggTypes: ["SUM", "AVG"],
		isBoolean: true,
	},
	{
		value: "dcPower_status",
		label: i18n.t("report.measure.dcPower_status"),
		aggTypes: ["SUM", "AVG"],
		isBoolean: true,
	},
	{
		value: "dcPower_voltage",
		label: i18n.t("report.measure.dcPower_voltage"),
		aggTypes: ["SUM", "AVG", "MIN", "MAX"],
		isBoolean: false,
	},
	{
		value: "wifi_rssi",
		label: i18n.t("report.measure.wifi_rssi"),
		aggTypes: ["SUM", "AVG", "MIN", "MAX"],
		isBoolean: false,
	},
];

export const measureOptionsInstant = [
	{
		value: "sensor1_status",
		label: i18n.t("report.measure.sensor1_status"),
		type: "INSTANT",
		isBoolean: true,
	},
	{
		value: "sensor1_resistance",
		label: i18n.t("report.measure.sensor1_resistance"),
		type: "INSTANT",
		isBoolean: false,
	},
	{
		value: "relay_status",
		label: i18n.t("report.measure.relay_status"),
		type: "INSTANT",
		isBoolean: true,
	},
	{
		value: "battery_percentage",
		label: i18n.t("report.measure.battery_percentage"),
		type: "INSTANT",
		isBoolean: false,
	},
	{
		value: "battery_charging",
		label: i18n.t("report.measure.battery_charging"),
		type: "INSTANT",
		isBoolean: true,
	},
	{
		value: "dcPower_status",
		label: i18n.t("report.measure.dcPower_status"),
		type: "INSTANT",
		isBoolean: true,
	},
	{
		value: "dcPower_voltage",
		label: i18n.t("report.measure.dcPower_voltage"),
		type: "INSTANT",
		isBoolean: false,
	},
	{
		value: "wifi_rssi",
		label: i18n.t("report.measure.wifi_rssi"),
		type: "INSTANT",
		isBoolean: false,
	},
	{
		value: "firmware_version",
		label: i18n.t("report.measure.firmware_version"),
		type: "INSTANT_DIMENSION",
		isBoolean: false,
	},
	{
		value: "hardware_version",
		label: i18n.t("report.measure.hardware_version"),
		type: "INSTANT_DIMENSION",
		isBoolean: false,
	},
	{
		value: "inputDevice",
		label: i18n.t("report.measure.inputDevice"),
		type: "INSTANT_DIMENSION",
		isBoolean: false,
	},
	{
		value: "outputDevice",
		label: i18n.t("report.measure.outputDevice"),
		type: "INSTANT_DIMENSION",
		isBoolean: false,
	},
];

export const aggregateOptions = [
	// { value: "SUM", label: "Sum" },
	// { value: "COUNT", label: "Count" },
	{ value: "AVG", label: i18n.t("report.aggregate.AVG") },
	{ value: "MIN", label: i18n.t("report.aggregate.MIN") },
	{ value: "MAX", label: i18n.t("report.aggregate.MAX") },
];

export const intervalOptions = [
	{ value: 0, label: "Custom" },
	{ value: -1, label: "All" },
	{ value: 60, label: "1 min" },
	{ value: 600, label: "10 mins" },
	{ value: 1800, label: "30 mins" },
	{ value: 3600, label: "1 hour" },
	{ value: 10800, label: "3 hours" },
	{ value: 21600, label: "6 hours" },
	{ value: 43200, label: "12 hours" },
	{ value: 86400, label: "24 hours" },
];
