//Node Modules
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLoaderData } from "react-router-dom";
import { useRecoilState, useRecoilValueLoadable } from "recoil";

//GraphQL

//Pages

//BinaryForge Components
import { PageHeader } from "../../../../components/layout";
import { Note } from "../../../../components/helpers";
import { SchemeDetailsSkeleton } from "../../../../components/helpers/skeleton";
import { SchemeDetails as SchemeData } from "../../../../components/admin/scheme";

//3rd Party Components

//Atoms
import { selectedSchemeAtom, selectedSchemeSelector } from "../../../../atoms/scheme";

//Helpers
import { nav } from "../../../../config/navigation";

//GraphQL

const SchemeDetails = () => {
	//Hooks
	const { t } = useTranslation();
	const { schemeId } = useLoaderData();

	//Recoil
	const [selectedScheme, setSelectedScheme] = useRecoilState(selectedSchemeAtom);
	const { state, contents } = useRecoilValueLoadable(selectedSchemeSelector);

	//On Page Load
	useEffect(() => {
		if (!selectedScheme) {
			setSelectedScheme({ id: schemeId });
		}
	}, []);

	// Render the User Details
	const PageBody = () => {
		switch (state) {
			case "hasError":
				return (
					<Note
						messageKey={`Error: ${contents.message ? contents.message : contents}`}
						icon="pi-exclamation-circle fontColour-error"
					/>
				);
			case "loading":
				return <SchemeDetailsSkeleton />;
			case "hasValue":
				return <SchemeData schemeData={contents} />;
			default:
				return null;
		}
	};

	return (
		<>
			<PageHeader
				title={t("admin.scheme.details.pageTitle")}
				subtitle={t("admin.scheme.details.pageSubtitle", { scheme: contents?.name })}
				enableBack
			/>
			<main className="appMain">
				<div className="appMain-content">
					<div className="flex jContent-end gap-small">
						{/* <Button
							onClick={() => confirmDeleteUser()}
							label={t("user.details.actions.delete")}
							className="error"
							disabled={username === loggedInUser.username}
						/> */}
						<Link
							to={`${nav.admin.base}/${nav.admin.scheme.base}/${nav.admin.scheme.edit}/${selectedScheme?.id}`}
							className="button feature">
							{t("admin.scheme.details.actions.edit")}
						</Link>
					</div>
					<PageBody />
				</div>
			</main>
		</>
	);
};

export default SchemeDetails;
