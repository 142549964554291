import { atom, selector } from "recoil";

import { API, graphqlOperation } from "aws-amplify";
import { getZoneBasic } from "../graphql/queries-custom";

export const zoneNodesAtom = atom({
	key: "zoneNodesAtom",
	default: [],
});

export const selectedZoneKeyAtom = atom({
	key: "selectedZoneKeyAtom",
	default: null,
});

export const selectedZoneAtom = atom({
	key: "selectedZoneAtom",
	default: null,
});

export const selectedZoneSelector = selector({
	key: "selectedZoneSelector",
	get: async ({ get }) => {
		let data = null;
		const zoneId = get(selectedZoneKeyAtom);
		if (zoneId) {
			const resp = await API.graphql(graphqlOperation(getZoneBasic, { id: zoneId }));
			data = resp.data.getZone;
		}
		return data;
	},
});
