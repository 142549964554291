//Node Modules
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

//BinaryForge Components
import { BfDialog } from "../../helpers";
import { Note } from "../../helpers";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { dialogAtomFamily } from "../../../atoms";

//Helpers

//Other

const DupeAdminDialog = ({ email, role }) => {
	//Hooks
	const { t } = useTranslation();

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("dupeAdminDialog"));

	// Footer
	const footer = (
		<>
			<Button label={t("common.action.ok")} onClick={() => setShow(false)} />
		</>
	);

	return (
		<BfDialog id="dupeAdminDialog" header={t("admin.user.dupeAdmin.dialog.header")} footer={footer}>
			<Note
				messageKey={t("admin.user.dupeAdmin.dialog.message", { email, role })}
				icon="pi-exclamation-circle fontColour-error"
			/>
		</BfDialog>
	);
};

export default DupeAdminDialog;
