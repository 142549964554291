//Node Modules
import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import _ from "lodash";

//Pages

//BinaryForge Components

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

//Atoms
import { reportInstantParamsAtom } from "../../../atoms/report";

//Helpers
import { exportDate } from "../../../helpers/DateUtils";
import { filterApplyTemplate, datetimeRowTemplate, dateFilterTemplate } from "../../../config/datatable";
import { measureOptionsInstant } from "../../../config/report";

//Other

const ReportResultTable = ({ reportResultsData }, ref) => {
	//Hooks
	const { t } = useTranslation();
	const dt = useRef(null);

	//Local State
	const [results, setResults] = useState();

	//Recoil
	const reportParams = useRecoilValue(reportInstantParamsAtom);

	// Local State
	const [dtFilters, setDtFilters] = useState(null);

	//On Page/Data Load
	useEffect(() => {
		if (reportResultsData) {
			setResults(reportResultsData);
			initFilters();
		}
	}, [reportResultsData]);

	//Datatable Functions
	const initFilters = () => {
		setDtFilters({
			timestamp: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			device_id: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			last_measure: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
		});
	};

	const measureValueRowTemplate = (rowData) => {
		const { measureName } = reportParams;
		const isBoolean = measureOptionsInstant.find((opt) => opt.value === measureName).isBoolean;

		if (isBoolean) {
			return rowData.last_measure === 1 ? "true" : "false";
		} else {
			return rowData.last_measure;
		}
	};

	useImperativeHandle(ref, () => ({
		exportCSV(selectionOnly) {
			dt.current.exportCSV({ selectionOnly });
		},
	}));

	return (
		<DataTable
			ref={dt}
			value={results}
			emptyMessage={t("common.table.noData")}
			sortMode="multiple"
			removableSort
			filters={dtFilters}
			filterDisplay="menu"
			autoLayout={true}
			paginator
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
			currentPageReportTemplate={t("common.table.paginatorTemplate")}
			rows={10}
			dataKey="id"
			exportFilename={t("common.table.exportFilename", {
				tableName: `Instant ${t(`report.measure.${reportParams.measureName}`)}`,
				date: exportDate,
			})}>
			<Column
				field="device_id"
				header={t("report.table.device")}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
			<Column
				field="last_measure"
				header={t(`report.measure.${reportParams.measureName}`)}
				body={measureValueRowTemplate}
				dataType="numeric"
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
			<Column
				field="timestamp"
				header={t("report.table.timestamp")}
				body={(rowData) => datetimeRowTemplate(rowData, "timestamp")}
				dataType="date"
				sortable
				filter
				filterApply={filterApplyTemplate}
				filterElement={dateFilterTemplate}
			/>
		</DataTable>
	);
};

export default forwardRef(ReportResultTable);
