//Node Modules
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { getAlarmGroup } from "../../../graphql/queries";

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { AlarmGroupForm } from "../../../components/alarm/groups";

//3rd Party Components

//Atoms

//Helpers

//Other

const AlarmGroupEdit = () => {
	//Hooks
	const { t } = useTranslation();
	const { alarmGroupId } = useLoaderData();

	//Local State
	const [groupInfo, setGroupInfo] = useState(null);

	//On Page Load
	useEffect(() => {
		handleGetGroup();
	}, []);

	//Get the initial group data
	const handleGetGroup = async () => {
		const {
			data: { getAlarmGroup: groupData },
		} = await API.graphql(graphqlOperation(getAlarmGroup, { id: alarmGroupId }));

		await processGroupData(groupData);
	};

	const processGroupData = async (groupData) => {
		const inAlarm = groupData.input.items.filter((groupItem) => groupItem.device.alert.sensor_state === 1);
		const groupId = groupData.id;
		const groupName = groupData.name;
		const inputDevices = groupData.input.items.map((input) => ({
			...input.device,
			relId: input.id,
		}));
		const outputDevices = groupData.output.items.map((output) => ({
			...output.device,
			relId: output.id,
		}));

		setGroupInfo({ id: groupId, name: groupName, inputDevices, outputDevices, inAlarm });
	};

	return (
		<>
			<PageHeader
				title={t("alarm.group.edit.pageTitle", { groupId: groupInfo?.name ? groupInfo.name : alarmGroupId })}
				subtitle={t("alarm.group.edit.pageSubtitle")}
				enableBack
			/>
			<main className="appMain">
				<div className="appMain-content">
					<AlarmGroupForm type="edit" initialData={groupInfo} groupId={alarmGroupId} />
				</div>
			</main>
		</>
	);
};

export default AlarmGroupEdit;
