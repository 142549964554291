//Node Modules
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

//BinaryForge Components
import { LineChartDate } from "../";

//3rd Party Components

//Atoms
import { reportParamsAtom } from "../../../atoms/report";

//Helpers

//Other

const ReportResultsChart = ({ reportResultsData, timestamps }) => {
	//Hooks
	const { t } = useTranslation();

	//Recoil
	const reportParams = useRecoilValue(reportParamsAtom);

	return (
		<LineChartDate
			data={reportResultsData}
			timestamps={timestamps}
			measureName={t(`report.measure.${reportParams.measureName}`)}
		/>
	);
};

export default ReportResultsChart;
