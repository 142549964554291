//Node Modules
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../../components/layout";
import { FileLoader, JobsLoader } from "../../../../components/admin/ota";

//3rd Party Components
import { TabView, TabPanel } from "primereact/tabview";

//Atoms

//Helpers

//Other

const OtaManagement = () => {
	//Hooks
	const { t } = useTranslation();

	return (
		<>
			<PageHeader title={t("admin.ota.management.pageTitle")} />
			<main className="appMain">
				<div className="appMain-content">
					<TabView>
						<TabPanel header={t("admin.ota.management.tab.manage")} contentClassName="paddingBlock-medium">
							<JobsLoader />
						</TabPanel>
						<TabPanel header={t("admin.ota.management.tab.file")} contentClassName="paddingBlock-medium">
							<FileLoader />
						</TabPanel>
					</TabView>
				</div>
			</main>
		</>
	);
};

export default OtaManagement;
