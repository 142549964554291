//Node Modules
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";

//GraphQL

//Pages

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { UserList } from "../../../components/user";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { selectedUserAtom, usersSelector } from "../../../atoms/user";

//Helpers
import { nav } from "../../../config/navigation";
import { datatableAtomFamily } from "../../../atoms/datatable";

const UserManagement = () => {
	const { t } = useTranslation();

	//Recoil State
	const refreshUserListCache = useRecoilRefresher_UNSTABLE(usersSelector);
	const selectedUser = useRecoilValue(selectedUserAtom);
	const userTable = useRecoilValue(datatableAtomFamily("users"));

	//On Page Load
	useEffect(() => {
		refreshUserListCache();
	}, []);

	const exportCSV = (selectionOnly) => {
		userTable.exportCSV({ selectionOnly });
	};

	return (
		<>
			<PageHeader title={t("user.management.pageTitle")} />
			<main className="appMain">
				<div className="appMain-content">
					<div className="actions flex jContent-end gap-small">
						<Button onClick={() => exportCSV(false)}>{t("common.table.export")}</Button>
						<Link to={`${nav.user.base}/create`} className="button">
							{t("user.management.actions.create")}
						</Link>
						<Link
							to={`${nav.user.base}/${selectedUser?.username}`}
							className="button feature"
							disabled={!selectedUser}>
							{t("user.management.actions.details")}
						</Link>
					</div>
					<UserList />
				</div>
			</main>
		</>
	);
};

export default UserManagement;
