import { handleSetTickType } from "../helpers/chart";

export const chartStyleConfig = {
	axisColour: "hsl(213, 20%, 48%)",
	gridColour: "hsl(213, 20%, 48%, 0.2)",
	bar: {
		barThickness: 16,
		heightMultiplyer: 22,
		borderWidth: 1,
		borderColours: ["hsl(194, 73%, 60%)", "hsl(68, 64%, 52%)", "hsl(209, 34%, 59%)", "hsl(196, 66%, 75%)"],
		backgroundColours: [
			"hsl(194, 73%, 60%, 0.1)",
			"hsl(68, 64%, 52%, 0.1)",
			"hsl(209, 34%, 59%, 0.1)",
			"hsl(196, 66%, 75%, 0.1)",
		],
	},
	doughnut: {
		borderWidth: 2,
		cutout: ["54%", "50%"],
		radius: ["100%", "96%"],
		backgroundColours: {
			default: [
				"hsl(68, 64%, 52%)",
				"hsl(194, 73%, 60%)",
				"hsl(209, 34%, 59%)",
				"hsl(196, 66%, 75%)",
				"hsl(68, 64%, 76%)",
				"hsl(209, 34%, 80%)",
				"hsl(194, 73%, 80%)",
			],
			ota: [
				"hsl(68, 64%, 52%)",
				"hsl(194, 73%, 60%)",
				"hsl(209, 34%, 59%)",
				"hsl(345, 100%, 39%)",
				"hsl(345, 100%, 59%)",
				"hsl(209, 34%, 80%)",
				"hsl(194, 73%, 80%)",
				"hsl(44, 100%, 50%)",
			],
		},
	},
	line: [
		{
			borderColour: "hsl(194, 73%, 60%)",
			backgroundColour: "hsl(194, 73%, 60%, 0.1)",
		},
		{
			borderColour: "hsl(68, 64%, 52%)",
			backgroundColour: "hsl(68, 64%, 52%, 0.1)",
		},
		{
			borderColour: "hsl(209, 34%, 59%)",
			backgroundColour: "hsl(209, 34%, 59%, 0.1)",
		},
		{
			borderColour: "hsl(196, 66%, 75%)",
			backgroundColour: "hsl(196, 66%, 75%, 0.1)",
		},
		{
			borderColour: "hsl(194, 73%, 60%)",
			backgroundColour: "hsl(194, 73%, 60%, 0.1)",
		},
		{
			borderColour: "hsl(200, 90%, 88%)",
			backgroundColour: "hsl(200, 90%, 88%, 0.1)",
		},
		{
			borderColour: "hsl(68, 64%, 52%)",
			backgroundColour: "hsl(68, 64%, 52%, 0.1)",
		},
	],
	tooltip: {
		padding: 16,
		titleFont: { family: "Quicksand" },
		titleMarginBottom: 8,
		bodyFont: { family: "Quicksand" },
		backgroundColor: "hsl(213, 20%, 48%)",
		displayColors: false,
	},
};

export const historialLineChartConfig = (title, timestamps) => {
	return {
		responsive: true,
		maintainAspectRatio: false,
		parsing: {
			xAxisKey: "timestamp",
			yAxisKey: "measure_value",
		},
		scales: {
			x: {
				type: "time",
				time: {
					// Luxon format string
					tooltipFormat: "d MMM yy T",
					// unit: handleSetTickType(timestamps.min, timestamps.max),
					displayFormats: {
						minute: "d MMM HH:mm",
						hour: "d MMM HH:mm",
						day: "d MMM",
						week: "d MMM",
					},
				},
				grid: {
					display: true,
					drawOnChartArea: false,
					color: chartStyleConfig.gridColour,
					borderColor: chartStyleConfig.axisColour,
					borderWidth: 3,
				},
				ticks: {
					source: "auto",
					color: chartStyleConfig.axisColour,
					font: {
						family: "Quicksand",
					},
				},
			},
			y: {
				title: {
					display: true,
					text: title,
					font: {
						family: "Quicksand",
						weight: 700,
						size: 16,
					},
				},
				type: "linear",
				position: "left",
				min: 0,
				grid: {
					color: chartStyleConfig.gridColour,
					StyleborderColor: chartStyleConfig.axisColour,
					borderWidth: 3,
				},
				ticks: {
					color: chartStyleConfig.axisColour,
					font: {
						family: "Quicksand",
					},
				},
			},
		},
		plugins: {
			legend: {
				display: true,
				labels: {
					usePointStyle: true,
					padding: 30,
				},
			},
			zoom: {
				limits: {
					y: { min: 0 },
				},
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: "alt",
					},
					pinch: {
						enabled: true,
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					modifierKey: "alt",
					mode: "x",
				},
			},
		},
	};
};

export const countBarChartConfig = (title) => {
	return {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: "Count",
					font: {
						family: "Quicksand",
						weight: 700,
						size: 16,
					},
				},
			},
			x: {
				title: {
					display: true,
					text: title,
					font: {
						family: "Quicksand",
						weight: 700,
						size: 16,
					},
				},
				ticks: {
					color: chartStyleConfig.colour,
					font: {
						family: "Quicksand",
						weight: 700,
					},
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
		},
	};
};

export const doughnutChartConfig = (legendId) => {
	return {
		radius: "100%",
		plugins: {
			legend: {
				display: false,
			},
			htmlLegend: {
				containerID: legendId,
			},
		},
	};
};

const getOrCreateLegendList = (chart, id) => {
	const legendContainer = document.getElementById(id);
	let listContainer = legendContainer.querySelector("ul");

	if (!listContainer) {
		listContainer = document.createElement("ul");
		listContainer.style.display = "flex";
		listContainer.style.flexDirection = "column";
		listContainer.style.margin = 0;
		listContainer.style.padding = 0;

		legendContainer.appendChild(listContainer);
	}

	return listContainer;
};

export const htmlLegendPlugin = {
	id: "htmlLegend",
	afterUpdate(chart, args, options) {
		const ul = getOrCreateLegendList(chart, options.containerID);

		// Remove old legend items
		while (ul.firstChild) {
			ul.firstChild.remove();
		}

		// Reuse the built-in legendItems generator
		const items = chart.options.plugins.legend.labels.generateLabels(chart);

		items.forEach((item) => {
			const li = document.createElement("li");
			li.style.alignItems = "center";
			li.style.cursor = "pointer";
			li.style.display = "flex";
			li.style.flexDirection = "row";
			li.style.marginLeft = "10px";

			li.onclick = () => {
				const { type } = chart.config;
				if (type === "pie" || type === "doughnut") {
					// Pie and doughnut charts only have a single dataset and visibility is per item
					chart.toggleDataVisibility(item.index);
				} else {
					chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
				}
				chart.update();
			};

			// Color box
			const boxSpan = document.createElement("span");
			boxSpan.style.background = item.fillStyle;
			boxSpan.style.borderColor = item.strokeStyle;
			boxSpan.style.borderWidth = item.lineWidth + "px";
			boxSpan.style.display = "inline-block";
			boxSpan.style.flexShrink = 0;
			boxSpan.style.height = "20px";
			boxSpan.style.marginRight = "10px";
			boxSpan.style.width = "20px";

			// Text
			const textContainer = document.createElement("p");
			textContainer.style.color = item.fontColor;
			textContainer.style.margin = 0;
			textContainer.style.padding = 0;
			textContainer.style.textDecoration = item.hidden ? "line-through" : "";

			const text = document.createTextNode(item.text);
			textContainer.appendChild(text);

			li.appendChild(boxSpan);
			li.appendChild(textContainer);
			ul.appendChild(li);
		});
	},
};
