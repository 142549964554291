import _ from "lodash";
import { inputDeviceOptions, outputDeviceOptions } from "../../config/device";
import { PillItem } from "../helpers";

const FeatureItem = ({ value, type, wrapperStyles = [] }) => {
	const options = type === "input" ? inputDeviceOptions : outputDeviceOptions;
	const featureDetails = _.find(options, { value });

	return (
		<PillItem
			value={featureDetails.label}
			icon={featureDetails.icon}
			wrapperStyle={["blue1", ...wrapperStyles].join(" ")}
		/>
	);
};

export default FeatureItem;
