//Node Modules
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { ReactComponent as Logo } from "../../assets/img/logo/logo2023.svg";

//3rd Party Components

//Atoms

//Helpers

//Other

const AppHeaderGuest = () => {
	const { t } = useTranslation();

	return (
		<>
			<Logo className="guest" />
			<div className="appName marginTop-medium marginBottom-xlarge text-center">
				<span className="text-uppercase">{t("common.appName")}</span>
			</div>
		</>
	);
};

export default AppHeaderGuest;
