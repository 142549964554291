//Node Modules
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router";
import { useRecoilState, useRecoilValueLoadable } from "recoil";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../../components/layout";
import { SchemeForm } from "../../../../components/scheme";
import { Note } from "../../../../components/helpers";
import { SchemeFormSkeleton } from "../../../../components/helpers/skeleton";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { selectedSchemeAtom, selectedSchemeSelector } from "../../../../atoms/scheme";

//Helpers

//Other

const SchemeEdit = () => {
	//Hooks
	const { t } = useTranslation();
	const { schemeId } = useLoaderData();
	const formRef = useRef();

	//Recoil
	const [selectedScheme, setSelectedScheme] = useRecoilState(selectedSchemeAtom);
	const { state, contents } = useRecoilValueLoadable(selectedSchemeSelector);

	//On Page Load
	useEffect(() => {
		if (!selectedScheme) {
			setSelectedScheme({ id: schemeId });
		}
	}, []);

	//Render the User Details
	const PageBody = () => {
		switch (state) {
			case "hasError":
				return (
					<Note
						messageKey={`Error: ${contents.message ? contents.message : contents}`}
						icon="pi-exclamation-circle fontColour-error"
					/>
				);
			case "loading":
				return <SchemeFormSkeleton />;
			case "hasValue":
				return <SchemeForm type="edit" schemeData={contents} ref={formRef} />;
			default:
				return null;
		}
	};

	return (
		<>
			<PageHeader
				title={t("admin.scheme.edit.pageTitle")}
				subtitle={t("admin.scheme.edit.pageSubtitle")}
				enableBack
			/>
			<main className="appMain">
				<div className="appMain-content">
					<div className="flex jContent-end gap-small">
						<Button
							className="feature"
							label={t("admin.scheme.edit.actions.submit")}
							icon="pi pi-save"
							onClick={() => formRef.current.submitSchemeForm()}
						/>
					</div>
					<PageBody />
				</div>
			</main>
		</>
	);
};

export default SchemeEdit;
