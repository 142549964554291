//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { ReactComponent as Divider } from "../../assets/img/layout/divider.svg";

//3rd Party Components

//Atoms

//Helpers

//Other

const AppFooter = () => {
	const { t } = useTranslation();

	return (
		<footer className="appFooter paddingBlock-medium" role="contentinfo">
			<div className="fontColour-lighter fontSize-small flexVert gap-xsmall aItems-center appFooter-content">
				<div className="divider">
					<Divider />
				</div>
				<div>
					{t("common.appName")} v{process.env.REACT_APP_VERSION} | &copy; {new Date().getFullYear()}{" "}
					{t("common.company")}
				</div>
			</div>
		</footer>
	);
};

export default AppFooter;
