import { atom, selector } from "recoil";
import { API, graphqlOperation } from "aws-amplify";
import { listReports } from "../graphql/queries";
import { loggedInUserAtom } from "./user";

const restApiName = "andelRestApi";

export const reportTypeAtom = atom({
	key: "reportTypeAtom",
	default: null,
});

export const reportParamsAtom = atom({
	key: "reportParamsAtom",
	default: null,
});

export const reportParamsSelector = selector({
	key: "reportParamsSelector",
	get: async ({ get }) => {
		const params = get(reportParamsAtom);

		if (params.generate) {
			try {
				const resp = await API.post(restApiName, `/analytics/report/get-data`, { body: params });

				if (resp.table) {
					resp.table.map((row) => {
						row.timestamp = new Date(row.timestamp);
					});

					return resp;
				} else if (resp.dataExceeded) {
					return { errors: { dataExceeded: true } };
				}
			} catch (err) {
				console.error(err);
				console.log("RESP ::", err.response);
			}
		}
	},
});

export const reportInstantParamsAtom = atom({
	key: "reportInstantParamsAtom",
	default: null,
});

export const reportInstantParamsSelector = selector({
	key: "reportInstantParamsSelector",
	get: async ({ get }) => {
		const params = get(reportInstantParamsAtom);

		if (params.generate) {
			const resp = await API.post(restApiName, `/analytics/report/get-data`, { body: params });

			resp.table.map((row) => {
				row.timestamp = new Date(row.timestamp);
			});

			return resp;
		}
	},
});

export const combinedReportParamsSelector = selector({
	key: "combinedReportParamsSelector",
	get: ({ get }) => {
		const historicParams = get(reportParamsAtom);
		const instantParams = get(reportInstantParamsAtom);

		return { historical: historicParams, instant: instantParams };
	},
});

export const reportPresetsSelector = selector({
	key: "reportPresetsSelector",
	get: async ({ get }) => {
		const { sub } = get(loggedInUserAtom);
		const {
			data: {
				listReports: { items: reports },
			},
		} = await API.graphql(
			graphqlOperation(listReports, {
				filter: { or: [{ public: { eq: true } }, { public: { eq: false }, createdBy: { eq: sub } }] },
			})
		);

		return reports;
	},
	set: ({ set }, newValue) => {
		//Do nothing but allow this to be reset
	},
	cachePolicy_UNSTABLE: { eviction: "most-recent" },
});
