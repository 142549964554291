//Node Modules
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { Link } from "react-router-dom";

//GraphQL

//Pages

//BinaryForge Components
import { BfDialog } from "../helpers";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { dialogAtomFamily } from "../../atoms";

//Helpers

//Other
import { nav } from "../../config/navigation";

const DeviceAlarmGroupDialog = ({ type, alarmGroups }) => {
	//Hooks
	const { t } = useTranslation();

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("deviceAlarmGroupDialog"));

	// Footer
	const footer = (
		<>
			<Button label={t("common.action.ok")} onClick={() => setShow(false)} />
		</>
	);

	return (
		<BfDialog
			id="deviceAlarmGroupDialog"
			header={t("device.dialog.alarmGroup.header", { type: type })}
			footer={footer}>
			<div className="flexVert gapRow-xsmall">
				{alarmGroups.map((group) => (
					<Link
						to={`${nav.alarm.base}/${nav.alarm.group.base}/${nav.alarm.group.edit}/${group.id}`}
						key={group.id}>
						{group.name}
					</Link>
				))}
			</div>
		</BfDialog>
	);
};

export default DeviceAlarmGroupDialog;
