//Node Modules
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DateTime } from "luxon";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { listDevicesTable } from "../../../graphql/queries-custom";

//BinaryForge Components
import { ReportResultsLoader } from "./";

//3rd Party Components
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";

//Atoms
import { reportParamsAtom, reportTypeAtom } from "../../../atoms/report";
import { devicesListAtom } from "../../../atoms/devices";
import { selectedUserSchemeAtom } from "../../../atoms/user";
import { dialogAtomFamily } from "../../../atoms";

//Helpers
import { now } from "../../../helpers/DateUtils";

//Other
import { measureOptions, aggregateOptions, intervalOptions } from "../../../config/report";
import { device } from "../../../assets/messages/engb";
const appElement = document.getElementsByClassName("app")[0];

const ReportForm = () => {
	//Hooks
	const { t } = useTranslation();

	//Recoil State
	const [reportParams, setReportParams] = useRecoilState(reportParamsAtom);
	const [deviceOptions, setDeviceOptions] = useRecoilState(devicesListAtom);
	const { id: schemeId } = useRecoilValue(selectedUserSchemeAtom);
	const setSaveReportDialogShow = useSetRecoilState(dialogAtomFamily("reportSaveDialog"));
	const setReportType = useSetRecoilState(reportTypeAtom);

	//On Page Load
	useEffect(() => {
		if (!deviceOptions || deviceOptions?.length <= 0) {
			getDeviceOptions();
		}
	}, []);

	const getDeviceOptions = async () => {
		const {
			data: {
				deviceByScheme: { items: devicesData },
			},
		} = await API.graphql(
			graphqlOperation(listDevicesTable, {
				schemeId: schemeId,
			})
		);

		setDeviceOptions(devicesData);
	};

	// Form Default Values
	const defaultValues = {
		allDevices: false,
		devices: [],
		from: DateTime.now().minus({ days: 1 }).toJSDate(),
		to: new Date(),
		measureName: measureOptions[0].value,
		aggregateType: aggregateOptions[0].value,
		interval: intervalOptions[1].value,
		intervalCustom: 1,
	};

	// Form Init
	const {
		control,
		formState: { errors, isValid, isDirty },
		handleSubmit,
		watch,
		getValues,
		trigger,
	} = useForm({ defaultValues: defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	const buildReportParams = (data, generate) => {
		const deviceValue = data.allDevices ? { id: schemeId, name: "All" } : data.devices;

		const interval = data.interval === 0 ? data.intervalCustom * 60 : data.interval;
		const fromString = `from_iso8601_timestamp('${DateTime.fromJSDate(data.from)
			.set({ second: 0, millisecond: 0 })
			.toISO()}')`;
		const toString = `from_iso8601_timestamp('${DateTime.fromJSDate(data.to)
			.set({ second: 0, millisecond: 0 })
			.toISO()}')`;
		const params = {
			...data,
			devices: deviceValue,
			interval: interval,
			aggregateType: data.interval === -1 ? "ALL" : data.aggregateType,
			from: fromString,
			to: toString,
			generate: generate,
		};

		setReportParams(params);
	};

	//Submit the Form
	const onSubmit = async (data) => {
		buildReportParams(data, true);
	};

	const handleShowPresetDialog = () => {
		buildReportParams(getValues(), false);
		setReportType("historical");
		setSaveReportDialogShow(true);
	};

	const allDevicesOpt = watch("allDevices");
	const measureOpt = watch("measureName");

	const isOptionDisabled = (opt) => {
		const measure = measureOptions.find((m) => m.value === measureOpt);

		if (measure.aggTypes.includes(opt.value)) {
			return false;
		} else {
			return true;
		}
	};

	useEffect(() => {
		if (isDirty) trigger("devices");
	}, [allDevicesOpt]);

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className="card">
				<div className="grid columns-2 gapCol-large gapRow-medium">
					<div className="formField">
						<label htmlFor="measureName">{t("report.form.measure.label")}</label>
						<Controller
							name="measureName"
							control={control}
							rules={{ required: t("common.form.required") }}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<Dropdown
									appendto={appElement}
									{...myField}
									inputRef={ref}
									options={measureOptions}
									placeholder={t("report.form.measure.placeholder")}
									onChange={(e) => myField.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("measureName")}
					</div>
					<div className="grid columns-2-AutoFree gapCol-medium">
						<div className="formField">
							<label htmlFor="allDevices">{t("report.form.allDevices.label")}</label>
							<Controller
								name="allDevices"
								control={control}
								render={({ field, fieldState }) => (
									<InputSwitch
										{...field}
										checked={field.value}
										onChange={(e) => field.onChange(e.value)}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("allDevices")}
						</div>
						<div className="formField">
							<label htmlFor="devices">{t("report.form.devices.label")}</label>
							<Controller
								name="devices"
								control={control}
								rules={{ required: { value: !allDevicesOpt, message: t("common.form.required") } }}
								render={({ field: { ref, ...myField }, fieldState }) => (
									<MultiSelect
										appendto={appElement}
										{...myField}
										inputRef={ref}
										options={deviceOptions}
										// optionValue="id"
										optionLabel="name"
										maxSelectedLabels={1}
										disabled={allDevicesOpt}
										filter
										placeholder={t("report.form.devices.placeholder")}
										onChange={(e) => myField.onChange(e.value)}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("devices")}
						</div>
					</div>
					<div className="formField">
						<label htmlFor="from">{t("report.form.from.label")}</label>
						<Controller
							name="from"
							control={control}
							rules={{ required: t("common.form.required") }}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<Calendar
									{...myField}
									inputRef={ref}
									readOnlyInput
									maxDate={watch("to")}
									dateFormat="d M yy"
									showTime
									selectOtherMonths={true}
								/>
							)}
						/>
						{getFormErrorMessage("from")}
					</div>
					<div className="formField">
						<label htmlFor="to">{t("report.form.to.label")}</label>
						<Controller
							name="to"
							control={control}
							rules={{ required: t("common.form.required") }}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<Calendar
									{...myField}
									inputRef={ref}
									readOnlyInput
									minDate={watch("from")}
									maxDate={now.toJSDate()}
									dateFormat="d M yy"
									showTime
									selectOtherMonths={true}
								/>
							)}
						/>
						{getFormErrorMessage("to")}
					</div>
					<div className="grid columns-2 gapCol-small">
						<div className="formField">
							<label htmlFor="interval">{t("report.form.interval.label")}</label>
							<Controller
								name="interval"
								control={control}
								rules={{ required: t("common.form.required") }}
								render={({ field: { ref, ...myField }, fieldState }) => (
									<Dropdown
										appendto={appElement}
										{...myField}
										inputRef={ref}
										options={intervalOptions}
										placeholder={t("report.form.interval.placeholder")}
										onChange={(e) => myField.onChange(e.value)}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("interval")}
						</div>
						<div className="formField">
							<label htmlFor="intervalCustom">Custom Interval</label>
							<Controller
								name="intervalCustom"
								control={control}
								rules={{ required: t("common.form.required") }}
								render={({ field: { ref, ...myField }, fieldState }) => (
									<InputNumber
										appendto={appElement}
										{...myField}
										inputRef={ref}
										onChange={(e) => myField.onChange(e.value)}
										showButtons
										suffix=" minutes"
										min={1}
										disabled={watch("interval") !== 0}
									/>
								)}
							/>
							{getFormErrorMessage("intervalCustom")}
						</div>
					</div>
					<div className="formField">
						<label htmlFor="aggregateType">{t("report.form.aggregate.label")}</label>
						<Controller
							name="aggregateType"
							control={control}
							rules={{ required: t("common.form.required") }}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<Dropdown
									appendto={appElement}
									{...myField}
									inputRef={ref}
									options={aggregateOptions}
									optionDisabled={(opt) => isOptionDisabled(opt)}
									placeholder={t("report.form.aggregate.placeholder")}
									onChange={(e) => myField.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
									disabled={watch("interval") === -1}
								/>
							)}
						/>
						{getFormErrorMessage("aggregateType")}
					</div>
					<Button type="submit" label={t("report.form.submit")} className="feature marginTop-medium" />
					<Button
						type="button"
						label={t("report.form.save")}
						onClick={() => handleShowPresetDialog()}
						disabled={!isValid}
						className="marginTop-medium"
					/>
				</div>
			</form>

			{reportParams?.generate && (
				<ReportResultsLoader allDevices={getValues("allDevices")} selectedDevices={getValues("devices")} />
			)}
		</>
	);
};

export default ReportForm;
