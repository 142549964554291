//Node Modules
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

//BinaryForge Components
import { BfDialog } from "../helpers";
import { Note } from "../helpers";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { dialogAtomFamily } from "../../atoms";

//Helpers

//Other

const DeleteDisabledDialog = ({ type, groups }) => {
	//Hooks
	const { t } = useTranslation();

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("deleteDisabledDialog"));

	// Footer
	const footer = (
		<>
			<Button label={t("common.action.ok")} onClick={() => setShow(false)} />
		</>
	);

	return (
		<BfDialog id="deleteDisabledDialog" header={t("device.dialog.deleteDisabled.header")} footer={footer}>
			<Note
				messageKey={t("device.dialog.deleteDisabled.message", { context: type, groups })}
				icon="pi-exclamation-circle fontColour-error"
			/>
		</BfDialog>
	);
};

export default DeleteDisabledDialog;
