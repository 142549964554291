//Node Modules
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { createUserSchemeConfig } from "../../../graphql/mutations";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { Note } from "../../../components/helpers";
import UserForm from "./UserForm";

//3rd Party Components
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

//Atoms
import { selectedUserSchemeAtom } from "../../../atoms/user";

//Helpers
import { nav } from "../../../config/navigation";
import { useApiRequest } from "../../../helpers/Api";

const UserCreate = () => {
	//Hooks
	const { t } = useTranslation();
	const formRef = useRef();
	const navigate = useNavigate();
	const apiRequest = useApiRequest();
	const { isAdmin } = useLoaderData();

	//Recoil State
	const selectedScheme = useRecoilValue(selectedUserSchemeAtom);

	const addUserToScheme = async (email, role) => {
		try {
			const user = await apiRequest(
				"post",
				"/user/import",
				{ email: email, role: role, scheme: selectedScheme.name },
				t(`user.create.dupeUser.loading`)
			);

			await API.graphql(
				graphqlOperation(createUserSchemeConfig, {
					input: {
						cognitoUserId: user.id,
						schemeId: selectedScheme.id,
						isDefaultScheme: false,
						role: role,
					},
				})
			);

			navigate(`${nav.user.base}/${nav.user.management}`);
		} catch (err) {
			console.error(err);
		}
	};

	const confirmAddUser = (email, role) => {
		confirmDialog({
			header: t("user.create.dupeUser.header"),
			message: (
				<>
					<Note
						messageKey={t("user.create.dupeUser.message", { email: email })}
						icon="pi pi-info-circle fontColour-info"
					/>
					<Note
						wrapperStyle="marginTop-small"
						messageKey={t("user.create.dupeUser.warning")}
						messageStyle="fontSize-small"
						icon="pi pi-exclamation-triangle fontColour-warn"
					/>
				</>
			),
			acceptLabel: t("user.create.dupeUser.accept"),
			rejectLabel: t("user.create.dupeUser.reject"),
			acceptIcon: "pi pi-user-plus",
			acceptClassName: "success feature",
			rejectClassName: "error",
			accept: () => addUserToScheme(email, role),
			reject: () => {}, //Do Nothing,
		});
	};

	return (
		<>
			<PageHeader title={isAdmin ? t("admin.user.create.pageTitle") : t("user.create.pageTitle")} enableBack />
			<main className="appMain">
				<div className="appMain-content">
					<div className="flex jContent-end gap-small">
						<Button
							className="feature"
							label={isAdmin ? t("admin.user.create.actions.submit") : t("user.create.actions.submit")}
							icon="pi pi-save"
							onClick={() => formRef.current.submitUserForm()}
						/>
					</div>
					<UserForm type="create" ref={formRef} dupeUser={confirmAddUser} />
				</div>
			</main>
		</>
	);
};

export default UserCreate;
