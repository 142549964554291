import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PillItem, UsernameAlt } from "../../helpers";
import { getUiDateTime } from "../../../helpers/DateUtils";
import { Username } from "../../helpers";
import Field from "../../Field";
import _ from "lodash";

const SchemeDetails = ({ schemeData }) => {
	const { t } = useTranslation();

	console.log("Scheme Data ::", schemeData);

	//Local State
	const [schemeUsers, setSchemeUsers] = useState([]);

	useEffect(() => {
		const sortUsers = _.groupBy(schemeData.users.items, "role");
		setSchemeUsers(sortUsers);
	}, [schemeData]);

	return (
		<>
			<section className="grid columns-2 gap-large">
				<div className="grid columns-label gapCol-medium gapRow-xsmall aItems-center aContent-start">
					<span>{t("admin.scheme.common.name")}:</span>
					<span>{schemeData.name}</span>
					<span>{t("admin.scheme.common.id")}:</span>
					<span>{schemeData.id}</span>
				</div>
				<div className="grid columns-2 gapCol-medium">
					<div className="grid columns-label gapCol-medium gapRow-xsmall aItems-center aContent-start">
						<span>{t("common.table.createdAt")}:</span>
						<span>{getUiDateTime(schemeData.createdAt)}</span>
						<span>{t("common.table.createdBy")}:</span>
						<span>{schemeData.createdBy && <Username sub={schemeData.createdBy} />}</span>
					</div>
					<div className="grid columns-label gapCol-medium gapRow-xsmall aItems-center aContent-start">
						<span>{t("common.table.updatedAt")}:</span>
						<span>{getUiDateTime(schemeData.updatedAt)}</span>
						<span>{t("common.table.updatedBy")}:</span>
						<span>{schemeData.updatedBy && <Username sub={schemeData.updatedBy} />}</span>
					</div>
				</div>
				<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
					<Field
						label="Contact"
						response={
							schemeData.contact?.name ? (
								<div className="flexVert">
									<span>{schemeData.contact?.name}</span>
									<span>{schemeData.contact?.phone}</span>
									<span>{schemeData.contact?.email}</span>
								</div>
							) : (
								<span>{t("admin.scheme.details.contact.notSet")}</span>
							)
						}
					/>
				</div>
				<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
					<Field
						label="Emergency Contact"
						response={
							schemeData.contactEmergency?.name ? (
								<div className="flexVert">
									<span>{schemeData.contactEmergency?.name}</span>
									<span>{schemeData.contactEmergency?.phone}</span>
									<span>{schemeData.contactEmergency?.email}</span>
								</div>
							) : (
								<span>{t("admin.scheme.details.contact.notSet")}</span>
							)
						}
					/>
				</div>
			</section>
			<section>
				<div className="card">
					<header>
						<h3>{t("admin.scheme.details.users.header")}</h3>
					</header>
					<div className="grid columns-3 gap-medium">
						<div>
							<PillItem type="text" value={t(`user.role.OWNER`)} wrapperStyle="blue1" />
							<div className="flexVert gapRow-xsmall marginTop-xsmall">
								{schemeUsers?.OWNER?.map((u) => (
									<UsernameAlt key={u.cognitoUserId} sub={u.cognitoUserId} link />
								))}
							</div>
						</div>
						<div>
							<PillItem type="text" value={t(`user.role.TENANT`)} wrapperStyle="blue1" />
							<div className="flexVert gapRow-xsmall marginTop-xsmall">
								{schemeUsers?.TENANT?.map((u) => (
									<UsernameAlt key={u.cognitoUserId} sub={u.cognitoUserId} link />
								))}
							</div>
						</div>
						<div>
							<PillItem type="text" value={t(`user.role.INSTALLER`)} wrapperStyle="blue1" />
							<div className="flexVert gapRow-xsmall marginTop-xsmall">
								{schemeUsers?.INSTALLER?.map((u) => (
									<UsernameAlt key={u.cognitoUserId} sub={u.cognitoUserId} link />
								))}
							</div>
						</div>
					</div>
					{/* <div className="grid columns-2-AutoFree gapCol-medium gapRow-small aItems-center">
						{schemeData.users.items.map((u) => (
							<React.Fragment key={u.cognitoUserId}>
								<Username sub={u.cognitoUserId} link />
								<PillItem type="text" value={t(`user.role.${u.role}`)} wrapperStyle="blue1" />
							</React.Fragment>
						))}
					</div> */}
				</div>
			</section>
		</>
	);
};

export default SchemeDetails;
