//Node Modules
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil";
import { useForm, Controller } from "react-hook-form";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { listModbusConfigs } from "../../graphql/queries";
import { getSchemeZoneConfig } from "../../graphql/queries-custom";
import { updateDevice } from "../../graphql/mutations";

//BinaryForge Components
import { BfDialog, PillItem, Note } from "../helpers";

//3rd Party Components
import { Button } from "primereact/button";
import { Tree } from "primereact/tree";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

//Atoms
import { dialogAtomFamily, toastAtom } from "../../atoms";
import { zoneNodesAtom } from "../../atoms/zones";
import { selectedUserSchemeAtom } from "../../atoms/user";
import { validationAtomFamily } from "../../atoms/validation";

//Helpers
import { useApiRequest } from "../../helpers/Api";
import { inputDeviceOptions, outputDeviceOptions } from "../../config/device";
import { zoneConfig } from "../../config/zones";
import { expandLocation, findTreeNodeByKey } from "../../helpers/Zones";
import { useGetFullLocation } from "../../helpers/Device";
import { useValidateDevice } from "../../helpers/Validation";

//Other
const dialogElement = document.getElementById("configUpdateDialog");

const AttributesUpdateDialog = ({ deviceId, deviceData, updateDeviceData }) => {
	//Hooks
	const { t } = useTranslation();
	const apiRequest = useApiRequest();
	const validateDevice = useValidateDevice();
	const getFullLocation = useGetFullLocation();

	//Local State
	const [selectedNodeKey, setSelectedNodeKey] = useState();
	const [expandedKeys, setExpandedKeys] = useState();
	const [disableEdit, setDisableEdit] = useState(false);

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("deviceAttributesUpdateDialog"));
	const setLoading = useSetRecoilState(dialogAtomFamily("loadingDialog"));
	const [toast, setToast] = useRecoilState(toastAtom);
	const [nodes, setNodes] = useRecoilState(zoneNodesAtom);
	const selectedUserScheme = useRecoilValue(selectedUserSchemeAtom);
	const deviceValidation = useRecoilValue(validationAtomFamily("device"));

	//React Hook Form
	const defaultValues = {
		name: deviceData.name,
		inputDevice: deviceData.inputDevice && deviceData.inputDevice[0],
		outputDevice: deviceData.outputDevice && deviceData.outputDevice[0],
	};
	const {
		control,
		formState: { errors, isDirty },
		handleSubmit,
		setValue,
		watch,
		reset,
	} = useForm({
		defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	//Submit the Form
	const onSubmit = async (data) => {
		try {
			setLoading({ visible: true, message: t("device.dialog.attributes.loading") });

			if (data.name !== deviceData.name) await validateDevice(data.name, deviceId);

			let zoneBreadcrumb = deviceData.zoneBreadcrumb;
			let updatedZoneInfo;
			if (deviceData.zoneId !== selectedNodeKey) {
				const updatedZone = await findTreeNodeByKey(nodes, selectedNodeKey);
				const { locationBreadcrumb, tenants, breadcrumbLabel, breadcrumbKey } = await getFullLocation(
					updatedZone
				);
				zoneBreadcrumb = { label: breadcrumbLabel, key: breadcrumbKey };
				updatedZoneInfo = {
					breadcrumb: locationBreadcrumb,
					tenants: tenants,
					tenantsCount: tenants.reduce((acc, tenant) => acc + tenant.tenantIds.length, 0),
				};
			}

			await API.graphql(
				graphqlOperation(updateDevice, {
					input: {
						id: deviceId,
						name: data.name,
						inputDevice: data.inputDevice ? [data.inputDevice] : null,
						outputDevice: data.outputDevice ? [data.outputDevice] : null,
						zoneId: selectedNodeKey,
						zoneBreadcrumb: JSON.stringify(zoneBreadcrumb),
					},
				})
			);

			const updatedDeviceData = {
				...deviceData,
				name: data.name,
				zone: { id: selectedNodeKey } || null,
				zoneBreadcrumb: updatedZoneInfo.breadcrumb || null,
				tenants: updatedZoneInfo.tenants || null,
				tenantsCount: updatedZoneInfo.tenantsCount || null,
				inputDevice: data.inputDevice ? [data.inputDevice] : null,
				outputDevice: data.outputDevice ? [data.outputDevice] : null,
			};
			await updateDeviceData(updatedDeviceData);

			// console.log(
			// 	"Input ::",
			// 	data.inputDevice,
			// 	deviceData.inputDevice,
			// 	deviceData.inputDevice ? deviceData.inputDevice[0] : null,
			// 	data.inputDevice !== (deviceData.inputDevice ? deviceData.inputDevice[0] : null)
			// );
			// console.log(
			// 	"Output ::",
			// 	data.outputDevice,
			// 	deviceData.outputDevice,
			// 	deviceData.outputDevice ? deviceData.outputDevice[0] : null,
			// 	data.outputDevice !== (deviceData.outputDevice ? deviceData.outputDevice[0] : null)
			// );

			if (
				data.inputDevice !== (deviceData.inputDevice ? deviceData.inputDevice[0] : null) ||
				data.outputDevice !== (deviceData.outputDevice ? deviceData.outputDevice[0] : null)
			) {
				// console.log("We need to update the modbus Config...");
				const {
					data: {
						listModbusConfigs: { items: modbusConfigs },
					},
				} = await API.graphql(graphqlOperation(listModbusConfigs));
				// console.log("MB ::", modbusConfigs);
				const newDeviceTypes = [];
				if (data.inputDevice) newDeviceTypes.push(data.inputDevice);
				if (data.outputDevice) newDeviceTypes.push(data.outputDevice);
				// console.log("New Devices ::", newDeviceTypes);
				const newModbusConfigs = modbusConfigs.filter((config) => newDeviceTypes.includes(config.deviceType));
				const modbusDevices = newModbusConfigs.map((config) => JSON.parse(config.modbusTemplate));
				// console.log("New Configs ::", newModbusConfigs, modbusDevices);
				const modbusState = newDeviceTypes.includes("RANGER")
					? { enabled: true, devices: modbusDevices }
					: { enabled: false, devices: [] };
				const modbusDesiredState = {
					thingName: deviceId,
					desiredState: {
						modbus: modbusState,
					},
				};
				await apiRequest(
					"post",
					"/shadow/desired/update",
					modbusDesiredState,
					t("device.dialog.config.loading")
				);
			}

			setToast({
				...toast,
				severity: "success",
				summary: t("device.dialog.attributes.toast.successSummary"),
				detail: t("device.dialog.attributes.toast.successDetail"),
			});

			setShow(false);
		} catch (err) {
			console.error("Update Device Attributes Failed ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("device.dialog.attributes.toast.errorSummary"),
				detail: t("device.dialog.attributes.toast.errorDetail", { error: err.message }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const inputDevice = watch("inputDevice");

	useEffect(() => {
		if (deviceData.alarmGroups.input.length > 0 || deviceData.alarmGroups.output.length > 0) setDisableEdit(true);
	}, []);

	useEffect(() => {
		if (inputDevice === "FLOWSTOP") {
			setValue("outputDevice", "FLOWSTOP", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
		} else if (isDirty) {
			setValue("outputDevice", null, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
		}
	}, [inputDevice]);

	const nodeTemplate = (node, options) => {
		let label = <span>{node.label}</span>;
		let type = (
			<PillItem
				type="full"
				value={t(`zones.type.${node.type}`)}
				icon={zoneConfig[node.type].icon}
				wrapperStyle={zoneConfig[node.type].colour}
			/>
		);

		return (
			<div className={`flex gapCol-xsmall aItems-center ${options.className}`}>
				{type}
				{label}
			</div>
		);
	};

	const loadZoneConfig = async () => {
		const {
			data: {
				getScheme: { zoneConfig },
			},
		} = await API.graphql(graphqlOperation(getSchemeZoneConfig, { id: selectedUserScheme.id }));
		setNodes(JSON.parse(zoneConfig));
		if (deviceData.zone) {
			setSelectedNodeKey(deviceData.zone.id);
			const expandedLocation = expandLocation(deviceData.zoneBreadcrumb);
			setExpandedKeys(expandedLocation);
		}
	};

	useEffect(() => {
		loadZoneConfig();
	}, [deviceData]);

	const handleClose = () => {
		setShow(false);
		reset();
	};

	// Footer
	const footer = (
		<>
			<Button label={t("common.action.cancel")} onClick={() => handleClose()} />
			<Button
				label={t("device.dialog.attributes.save")}
				icon="pi pi-save"
				className="feature"
				// disabled={!isValid}
				onClick={() => handleSubmit(onSubmit)()}
			/>
		</>
	);

	return (
		<BfDialog id="deviceAttributesUpdateDialog" header={t("device.dialog.attributes.header")} footer={footer}>
			<form>
				<div className="formFieldsWrapper">
					<div className="formField">
						<label htmlFor="name">{t("device.dialog.attributes.form.name.label")}</label>
						<Controller
							name="name"
							control={control}
							rules={{
								required: t("common.form.required"),
								maxLength: {
									value: deviceValidation["maxLength"],
									message: t("validation.device.maxLength", {
										length: deviceValidation["maxLength"],
									}),
								},
								pattern: {
									value: RegExp(deviceValidation["pattern"]),
									message: t("validation.device.pattern"),
								},
							}}
							render={({ field, fieldState }) => (
								<InputText
									{...field}
									id={field.name}
									autoComplete="name"
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("name")}
					</div>

					<div className="grid columns-2 gapCol-medium">
						{disableEdit && (
							<Note
								messageKey="Input and Output devices cannot be edited whilst the device is part of an Alarm Group."
								messageStyle="fontColour-light fontSize-small"
								icon="pi pi-info-circle fontColour-info"
								wrapperStyle="span-2 marginBottom-xsmall aItems-center"
							/>
						)}
						<div className="formField">
							<label htmlFor="inputDevice">{t("device.dialog.attributes.form.inputDevice.label")}</label>
							<Controller
								name="inputDevice"
								control={control}
								render={({ field: { ref, ...myField }, fieldState }) => (
									<Dropdown
										appendTo={dialogElement}
										{...myField}
										inputRef={ref}
										options={inputDeviceOptions}
										disabled={
											(watch("outputDevice") && myField.value !== "FLOWSTOP") || disableEdit
										}
										onChange={(e) => myField.onChange(e.value)}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("inputDevice")}
						</div>
						<div className="formField">
							<label htmlFor="outputDevice">
								{t("device.dialog.attributes.form.outputDevice.label")}
							</label>
							<Controller
								name="outputDevice"
								control={control}
								render={({ field: { ref, ...myField }, fieldState }) => (
									<Dropdown
										appendTo={dialogElement}
										{...myField}
										inputRef={ref}
										options={outputDeviceOptions}
										disabled={watch("inputDevice") || disableEdit}
										onChange={(e) => myField.onChange(e.value)}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("outputDevice")}
						</div>
					</div>
					{/* <label>{t("device.dialog.attributes.form.features.label")}</label>
						<div className="flexVert gapRow-xsmall marginTop-xsmall">
							{featuresOptions.map((feature) => (
								<div className="formField" key={feature.value}>
									<div className="flex aItems-center">
										<Controller
											name={feature.value}
											control={control}
											render={({ field, fieldState }) => (
												<Checkbox
													{...field}
													id={field.name}
													inputId={field.id}
													inputRef={field.ref}
													checked={field.value}
													onChange={(e) => field.onChange(e.checked)}
													className={classNames({ "p-error": fieldState.error })}
													disabled={featuresDisabled[field.name].disabled}
												/>
											)}
										/>
										<i className={feature.icon} />
										<label htmlFor={feature.value} className="fontWeight-regular marginLeft-xsmall">
											{feature.label}
										</label>
										{featuresDisabled[feature.value].disabled && (
											<span className="fontColour-error fontSize-small marginLeft-medium">
												{t("device.feature.disabled", {
													count: featuresDisabled[feature.value].count,
													type: feature.value,
												})}
											</span>
										)}
									</div>
								</div>
							))}
						</div>
					</div> */}
				</div>

				<div>
					<header className="withDivider">
						<h3>{t("device.dialog.attributes.location")}</h3>
					</header>
					<Tree
						value={nodes}
						nodeTemplate={nodeTemplate}
						selectionMode="single"
						selectionKeys={selectedNodeKey}
						onSelectionChange={(e) => setSelectedNodeKey(e.value)}
						expandedKeys={expandedKeys}
						onToggle={(e) => setExpandedKeys(e.value)}
					/>
				</div>
			</form>
		</BfDialog>
	);
};

export default AttributesUpdateDialog;
