export const profile = {
	pageTitle: "Profile",
	pageSubtitle: "Username: {{username}}",
	attributes: {
		title: "User Attributes",
		submit: "Update Attributes",
		loading: "Updating Attributes",
		role: {
			label: "$t(user.common.role)",
		},
		fullname: {
			label: "$t(user.common.fullname)",
			pattern: "$t(user.validation.fullname.pattern)",
		},
		email: {
			label: "$t(user.common.email)",
			pattern: "$t(user.validation.email.pattern)",
			verifyPlaceholder: "Verification Code",
			verifySubmit: "Verify",
			verifyWarning:
				"Your email address has recently been changed. You should have received an email with a verification code; please enter the code to verify your new email address.",
			verifyInfo:
				"Until your new email address is verified you will not be able to receive email communication from CloudConnect or further update your email address. If the email above is incorrect please contact an administrator to update your email.",
			toast: {
				successSummary: "Email Verified",
				successDetail: "",
				errorSummary: "Email Verification Failed",
				errorDetail: "Your email could not be verified. {{error}}",
			},
		},
		defaultScheme: {
			label: "Default Scheme",
			placeholder: "Select a default scheme",
			clear: "Clear default scheme",
			tooltip:
				"This will only set the default scheme for logging in via the web portal. If you would also like a default scheme for logging into the mobile app you must configure this within the application.",
		},
		toast: {
			successSummary: "Update Successful",
			successDetail: "Your user details have been successfully updated",
			successSummaryEmail: "Update Request Successful",
			successDetailEmail:
				"Request to update your user details has been successful. Monitor your emails for a confirmation code.",
			errorSummary: "Update Failed",
			errorDetail: "Your user details could not be updated. {{error}}",
		},
	},
	password: {
		title: "Password",
		submit: "Update Password",
		loading: "Updating Password",
		current: {
			label: "Current Password",
		},
		passwordNew: {
			label: "$t(login.passwordNew.label)",
			match: "$t(login.passwordNew.match)",
		},
		passwordConfirm: {
			label: "$t(login.passwordConfirm.label)",
			match: "$t(login.passwordConfirm.match)",
		},
		toast: {
			successSummary: "Update Successful",
			successDetail: "Your password has been successfully updated",
			errorSummary: "Update Failed",
			errorDetail: "Your password could not be updated. {{error}}",
		},
	},
	notifications: {
		title: "Notification Config ({{scheme}})",
		submit: "Update Config",
		push: {
			title: "Push Notifications",
			tooltip:
				"If your mobile device permission settings disallow push notifications, this will override the settings within the $t(common.appName).",
			alarm: {
				label: "Alarms",
			},
			out_of_comms: {
				label: '$t(events.eventType.eventType, {"context": "OUT_OF_COMMS"})',
			},
			battery_low: {
				label: '$t(events.eventType.eventType, {"context": "BATTERY_LOW"})',
			},
			mains_power_fail: {
				label: '$t(events.eventType.eventType, {"context": "MAINS_POWER_FAIL"})',
			},
			sensor_input_flexipad: {
				label: '$t(events.eventType.eventType, {"context": "SENSOR_INPUT_FLEXIPAD"})',
			},
			sensor_input_flowstop: {
				label: '$t(events.eventType.eventType, {"context": "SENSOR_INPUT_FLOWSTOP"})',
			},
			sensor_input_ranger: {
				label: '$t(events.eventType.eventType, {"context": "SENSOR_INPUT_RANGER"})',
			},
			relay: {
				label: '$t(events.eventType.eventType, {"context": "RELAY"})',
			},
			purge: {
				submit: "Purge Devices",
				loading: "Purging Devices",
				tooltip:
					"Removes the push notification link between the $t(common.appName) system and ALL mobile devices where you've logged into the $t(common.appName) app. Logging in to the app on a mobile device will re-establish the link.",
				toast: {
					successSummary: "Devices Purged",
					successDetail: "",
					errorSummary: "Device Purge Failed",
					errorDetails: "{{error}}",
				},
			},
		},
		email: {
			title: "Email Notifications",
			alarm: {
				label: "Alarms",
			},
			out_of_comms: {
				label: '$t(events.eventType.eventType, {"context": "OUT_OF_COMMS"})',
			},
			battery_low: {
				label: '$t(events.eventType.eventType, {"context": "BATTERY_LOW"})',
			},
			mains_power_fail: {
				label: '$t(events.eventType.eventType, {"context": "MAINS_POWER_FAIL"})',
			},
			sensor_input_flexipad: {
				label: '$t(events.eventType.eventType, {"context": "SENSOR_INPUT_FLEXIPAD"})',
			},
			sensor_input_flowstop: {
				label: '$t(events.eventType.eventType, {"context": "SENSOR_INPUT_FLOWSTOP"})',
			},
			sensor_input_ranger: {
				label: '$t(events.eventType.eventType, {"context": "SENSOR_INPUT_RANGER"})',
			},
			relay: {
				label: '$t(events.eventType.eventType, {"context": "RELAY"})',
			},
		},
		allSchemes: {
			confirm: {
				title: "Copy to all Schemes",
				loading: "Updating Notification Config",
				message:
					"Your user is assigned to multiple schemes. Would you like to copy this notification configuration to all of your schemes?",
				accept: "Yes, Copy to All",
				reject: "No, Only this Scheme",
			},
		},
		toast: {
			successSummary: "Notification Config Updated Successfully",
			successDetail: "",
			errorSummary: "Notification Config Update Failed",
			errorDetails: "{{error}}",
		},
	},
	mfa: {
		title: "MFA",
		enable: "MFA enabled",
		message:
			"Enhance your account security by enabling Multi-factor Authentication (MFA). Once enabled, when logging into the platform, you will be required to enter an additional Time-based, One-Time Password generated by an authenticator app.",
		dialog: {
			setup: {
				header: "Enable MFA",
				auth: {
					title: "Authenticator",
					message:
						"Use the code below in your authenticator app to add an account for the $t(common.appName).",
				},
				code: {
					title: "MFA Code",
					message:
						"Enter the code from the authenticator app configured in the previous step to complete MFA setup.",
					label: "MFA Code",
					submit: "Complete Setup",
				},
				toast: {
					successSummary: "MFA Enabled",
					successDetail:
						"Congratulations, your account is now more secure. Future logins will require an MFA code in addition to your username and password.",
					errorSummary: "MFA Failed",
					errorDetail: "MFA could not be enabled on your account. {{error}}",
				},
			},
			remove: {
				header: "Disable MFA",
				message:
					"Are you sure you want to disable MFA for your user? MFA is suggested in order to keep your account more secure.",
				disable: "Disable",
				keep: "No, Stay Secure",
				toast: {
					successSummary: "MFA Disabled",
					successDetail: "",
					errorSummary: "MFA Error",
					errorDetail: "MFA could not be disabled on your account. {{error}}",
				},
			},
		},
	},
};
