//Node Modules
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { userDashboardConfigBySchemeId } from "../../graphql/queries";

//BinaryForge Components
import { PageHeader } from "../../components/layout";
import {
	AnalyticsLoader,
	AnalyticsSelector,
	DeviceSummaryLoader,
	EventSummaryLoader,
	UserSummaryLoader,
} from "../../components/dashboard";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { dialogAtomFamily, loggedInUserAtom } from "../../atoms";
import { analyticsParamsAtom } from "../../atoms/dashboard";
import { selectedUserSchemeAtom } from "../../atoms/user";
import { Note } from "../../components/helpers";

//Helpers

const Home = () => {
	const { t } = useTranslation();

	//Recoil
	const loggedInUser = useRecoilValue(loggedInUserAtom);
	const selectedScheme = useRecoilValue(selectedUserSchemeAtom);
	const [analyticsParams, setAnalyticsParams] = useRecoilState(analyticsParamsAtom);
	const setShowAnalyticsSelector = useSetRecoilState(dialogAtomFamily("analyticsSelectorDialog"));

	useEffect(() => {
		initData();
	}, [selectedScheme]);

	const initData = async () => {
		const {
			data: {
				userDashboardConfigBySchemeId: { items: userDashboardConfig },
			},
		} = await API.graphql(
			graphqlOperation(userDashboardConfigBySchemeId, {
				cognitoUserId: { eq: loggedInUser.sub },
				schemeId: selectedScheme.id,
			})
		);

		if (userDashboardConfig.length > 0) setAnalyticsParams(JSON.parse(userDashboardConfig[0].dashboardAnalytics));
	};

	return (
		<>
			<PageHeader title={t("dashboard.pageTitle")} />
			<main className="appMain">
				<div className="appMain-content">
					<div className="grid columns-3 gap-large">
						<section className="card">
							<header className="marginBottom-medium">
								<h3>{t("dashboard.device.header")}</h3>
							</header>
							<DeviceSummaryLoader />
							<div id="deviceSummaryLegend" className="marginTop-small"></div>
						</section>
						<section className="card">
							<header className="marginBottom-medium">
								<h3>{t("dashboard.event.header")}</h3>
							</header>
							<EventSummaryLoader />
							<div id="eventSummaryLegend" className="marginTop-small"></div>
						</section>
						<section className="card">
							<header className="marginBottom-medium">
								<h3>{t("dashboard.user.header")}</h3>
							</header>
							<UserSummaryLoader />
							<div id="userSummaryLegend" className="marginTop-small"></div>
						</section>
						<section className="card span-full">
							{analyticsParams ? (
								<>
									<div className="flex jContent-spaceBetween">
										<header className="marginBottom-medium">
											<h3>{analyticsParams?.name}</h3>
										</header>
										<Button
											label={t("dashboard.analytics.default.open")}
											onClick={() => setShowAnalyticsSelector(true)}
											className="aSelf-start noStyle fontSize-small fontColour-light"
										/>
									</div>
									<AnalyticsLoader />
								</>
							) : (
								<div className="flexVert aItems-center">
									<Note
										messageKey={t("dashboard.analytics.noConfig")}
										icon="pi pi-info-circle fontColour-info"
										wrapperStyle="aItems-center"
									/>
									<Button
										label={t("dashboard.analytics.default.set")}
										onClick={() => setShowAnalyticsSelector(true)}
										className="marginTop-small"
									/>
								</div>
							)}
						</section>
					</div>
				</div>
			</main>

			<AnalyticsSelector />
		</>
	);
};

export default Home;
