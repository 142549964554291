//Node Modules
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { Link } from "react-router-dom";

//GraphQL

//Pages

//BinaryForge Components
import { BfDialog, Username } from "../helpers";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { dialogAtomFamily } from "../../atoms";

//Helpers
import { zoneConfig } from "../../config/zones";

//Other

const AssignedTenantDialog = ({ tenants }) => {
	//Hooks
	const { t } = useTranslation();

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("assignedTenantDialog"));

	// Footer
	const footer = (
		<>
			<Button label={t("common.action.ok")} onClick={() => setShow(false)} />
		</>
	);

	return (
		<BfDialog id="assignedTenantDialog" header={t("device.dialog.tenants.header")} footer={footer}>
			<div className="flexVert gapRow-small">
				{tenants.map((tenantZone) => (
					<div key={tenantZone.zone} className="flexVert">
						<span className="flex aItems-center fontWeight-bold marginBottom-xsmall">
							<i className={`${zoneConfig[tenantZone.zone.type].icon}`} />
							{tenantZone.zone.label}
						</span>
						{tenantZone.tenantIds.map((tenant) => (
							<Username key={tenant} sub={tenant} link />
						))}
					</div>
				))}
			</div>
		</BfDialog>
	);
};

export default AssignedTenantDialog;
