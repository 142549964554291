import { API, graphqlOperation } from "aws-amplify";
import { getZone } from "../graphql/queries";

export const findTreeNodeByKey = async (nodes, findKey) => {
	let nodeResult = null;
	for await (const node of nodes) {
		if (node.key === findKey) {
			return node;
		}

		if (node.children) {
			const result = await findTreeNodeByKey(node.children, findKey);
			if (result) {
				nodeResult = result;
				break;
			}
		}
	}

	return nodeResult;
};

export const expandLocation = (nodes) => {
	let _expandedKeys = {};

	for (let node of nodes) {
		_expandedKeys[node] = true;
	}

	return _expandedKeys;
};

export const getAllChildZones = async (node, childZones = []) => {
	if (!node.children) {
		return childZones;
	} else {
		for await (const child of node.children) {
			childZones.push(child.id || child.key);
			await getAllChildZones(child, childZones);
		}
		return childZones;
	}
};

export const getAllChildZonesFull = async (node, childZones = []) => {
	if (!node.children) {
		return childZones;
	} else {
		for await (const child of node.children) {
			childZones.push(child);
			await getAllChildZonesFull(child, childZones);
		}
		return childZones;
	}
};

export const getAllParentZoneProperty = async (zone, propertyName, properties = []) => {
	if (zone.parent) {
		const {
			data: { getZone: parent },
		} = await API.graphql(graphqlOperation(getZone, { id: zone.parent }));
		if (parent[propertyName]) properties.push(parent[propertyName]);
		await getAllParentZoneProperty(parent, propertyName, properties);
	}

	return properties;
};

export const getAllChildZoneProperty = async (zone, propertyName, properties = []) => {
	if (zone.children) {
		for await (const child of zone.children) {
			const {
				data: { getZone: childZone },
			} = await API.graphql(graphqlOperation(getZone, { id: child.key }));

			if (childZone[propertyName]) properties.push(childZone[propertyName]);
			await getAllChildZoneProperty(child, propertyName, properties);
		}
	}

	return properties;
};

export const getRootZone = async (zone) => {
	let parentKey = zone.parent;
	let rootNode = zone;

	while (parentKey) {
		const {
			data: { getZone: parent },
		} = await API.graphql(graphqlOperation(getZone, { id: parentKey }));

		parentKey = parent?.parent;
		if (parent) rootNode = parent;
	}

	return rootNode;
};

export const validateUniqueZoneName = async (selectedZoneKey, allZones, newZoneName, type) => {
	let validName = true;

	if (!selectedZoneKey) {
		const rootZoneNames = allZones.filter((zone) => zone.key !== selectedZoneKey).map((rootZone) => rootZone.label);
		validName = !rootZoneNames.some((zoneName) => zoneName === newZoneName);
	} else {
		const zone = await findTreeNodeByKey(allZones, selectedZoneKey);
		const rootZone = await getRootZone(zone);

		if (rootZone.key === selectedZoneKey && type === "edit") {
			const rootZoneNames = allZones
				.filter((zone) => zone.key !== selectedZoneKey)
				.map((rootZone) => rootZone.label);
			validName = !rootZoneNames.some((zoneName) => zoneName === newZoneName);
		} else {
			const rootNode = await findTreeNodeByKey(allZones, rootZone.id || rootZone.key);
			const childZones = await getAllChildZonesFull(rootNode);
			const excludeCurrentNames = childZones
				.filter((childZone) => childZone.key !== selectedZoneKey)
				.map((filtered) => filtered.label);
			const allZonesNames = [rootNode.label, ...excludeCurrentNames];
			validName = !allZonesNames.some((zoneName) => zoneName === newZoneName);
		}
	}

	return validName;
};
