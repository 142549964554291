//Node Modules
import { useRecoilValueLoadable } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { EventsTable } from "./";
import { DatatableSkeleton } from "../helpers/skeleton";
import { Note } from "../helpers";

//3rd Party Components

//Atoms
import { liveEventsListSelector } from "../../atoms/events";

//Helpers

//Other

const LiveEventsLoader = () => {
	//Recoil
	const { t } = useTranslation();
	const { state: liveEventsState, contents: liveEventsContent } = useRecoilValueLoadable(liveEventsListSelector);

	switch (liveEventsState) {
		case "hasError":
			console.error(liveEventsContent);
			return (
				<Note
					messageKey={`Error: ${liveEventsContent.message ? liveEventsContent.message : liveEventsContent}`}
					icon="pi-exclamation-circle fontColour-error"
				/>
			);
		case "loading":
			return (
				<DatatableSkeleton
					columns={[
						t("events.management.table.type"),
						t("events.management.table.deviceName"),
						t("events.management.table.location"),
						t("common.table.createdAt"),
						t("common.table.updatedAt"),
						t("events.management.table.duration"),
					]}
				/>
			);
		case "hasValue":
			return <EventsTable eventsData={liveEventsContent} context="live" />;
		default:
			return null;
	}
};

export default LiveEventsLoader;
