import { useState, useEffect } from "react";
import { PillItem } from "../helpers";

import { API, graphqlOperation } from "aws-amplify";
import { onUpdateFlowstop } from "../../graphql/subscriptions-custom";

import { getValveString } from "../../helpers/Device";

const FlowstopItem = ({ flowstopData }) => {
	const [flowstop, setFlowstop] = useState(null);

	//On Page Load
	useEffect(() => {
		setFlowstop({ ...flowstopData, reported_state: JSON.parse(flowstopData.reported_state) });
		const updateDeviceSubscription = subscribeDeviceState();

		return () => {
			updateDeviceSubscription.unsubscribe();
		};
	}, []);

	const subscribeDeviceState = () => {
		let compareStatus = JSON.parse(flowstopData.reported_state).relay.status;
		const sub = API.graphql(
			graphqlOperation(onUpdateFlowstop, {
				id: flowstopData.id,
			})
		).subscribe({
			next: ({ provider, value }) => {
				const updatedState = JSON.parse(value.data.onUpdateDeviceById.reported_state);

				if (compareStatus !== updatedState.relay.status) {
					compareStatus = updatedState.relay.status;
					setFlowstop({
						...value.data.onUpdateDeviceById,
						reported_state: updatedState,
					});
				}
			},
			error: (error) => console.warn(error),
		});

		return sub;
	};

	return (
		flowstop && (
			<PillItem
				type="full"
				value={`${flowstop.name} - ${getValveString(flowstop.reported_state.relay.status)}`}
				icon="icon-flowstop"
				wrapperStyle={Boolean(flowstop.reported_state.relay.status) ? "success" : "error"}
			/>
		)
	);
};

export default FlowstopItem;
