import i18n from "../i18n";
import { atom, selector } from "recoil";
import { Storage, API } from "aws-amplify";
import _ from "lodash";
import { getVersionNumber } from "../helpers/general";

const restApiName = "andelRestApi";

export const otaFilesAtom = atom({
	key: "otaFilesAtom",
	default: null,
});

export const otaFilesSelector = selector({
	key: "otaFilesSelector",
	get: async ({ get }) => {
		const fileList = await Storage.list(`ota`, { pageSize: 100 });

		const fileResults = [];
		for await (const file of fileList.results) {
			const fileData = await Storage.getProperties(file.key);
			const users = await API.get(
				restApiName,
				`/user/byAttribute?name=sub&type=%3D&value=${fileData.metadata.uploadedby}`
			);
			const username = users.length
				? users[0].fullname
				: `${i18n.t("user.common.deleted")}: ${fileData.metadata.uploadedby.slice(-4)}`;
			fileResults.push({
				filename: file.key.substring(4),
				firmwareNumber: getVersionNumber(fileData.metadata.firmwareversion),
				uploadedByName: username,
				...fileData,
			});
		}

		console.log("R", fileResults);

		return fileResults;
	},
});

export const selectedOtaFileAtom = atom({
	key: "selectedOtaFileAtom",
	default: null,
});

export const otaJobsSelector = selector({
	key: "otaJobsSelector",
	get: async ({ get }) => {
		const otaJobs = await API.get(restApiName, "/ota");

		otaJobs.map((otaJob) => {
			otaJob.createdAt = new Date(otaJob.createdAt);
			otaJob.updatedAt = new Date(otaJob.updatedAt);
		});

		return otaJobs;
	},
});
