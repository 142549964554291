//Node Modules
import { useEffect, forwardRef, useImperativeHandle } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { userSchemeConfigByCognitoUserId, userNotifyConfigByCognitoUserId } from "../../../graphql/queries";
import {
	createUserSchemeConfig,
	updateUserSchemeConfig,
	updateZone,
	deleteUserNotifyConfig,
} from "../../../graphql/mutations";
import { listZonesBasic } from "../../../graphql/queries-custom";

//BinaryForge Components
import { DupeAdminDialog } from "../../../components/admin/user";
import { DupeUserDialog } from "../../../components/user";

//3rd Party Components
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

//Atoms
import { selectedUserSchemeAtom, selectedUserAtom } from "../../../atoms/user";
import { toastAtom } from "../../../atoms/toast";
import { dialogAtomFamily } from "../../../atoms";

//Helpers
import { useApiRequest } from "../../../helpers/Api";
import { getReturnPage, useConfirmUserAction } from "../../../helpers/User";

//Other
import { emailValidation, fullnameValidation, userRoleOptions, usernameValidation } from "../../../config/user";
const appElement = document.getElementsByClassName("appWrapper")[0];

const UserForm = ({ type, userData, dupeUser }, ref) => {
	// Hooks
	const { t } = useTranslation();
	const apiRequest = useApiRequest();
	const navigate = useNavigate();
	const { isAdmin } = useLoaderData();
	const { confirmPromoteUser } = useConfirmUserAction();

	// Recoil
	const setLoading = useSetRecoilState(dialogAtomFamily("loadingDialog"));
	const [toast, setToast] = useRecoilState(toastAtom);
	const setSelectedUser = useSetRecoilState(selectedUserAtom);
	const selectedScheme = useRecoilValue(selectedUserSchemeAtom);
	const setShowDupeAdminDialog = useSetRecoilState(dialogAtomFamily("dupeAdminDialog"));

	// Form Default Values
	const defaultValues = {
		username: "",
		fullname: "",
		email: "",
		emailConfirm: "",
		role: isAdmin ? "ADMINISTRATOR" : null,
		active: true,
	};

	// Form Init
	const {
		control,
		getValues,
		watch,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ defaultValues: defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	// Fetch the user if one is available
	useEffect(() => {
		if (type === "edit" && userData) {
			reset({
				username: userData.username,
				fullname: userData.fullname,
				email: userData.email,
				emailConfirm: userData.email,
				role: userData.role,
				active: userData.active,
			});
		}
	}, [userData, type]);

	useImperativeHandle(ref, () => ({
		submitUserForm() {
			handleSubmit(onSubmit)();
		},
	}));

	const onSubmit = async (data) => {
		try {
			//Set the loading
			setLoading({ visible: true, message: t(`user.${type}.loading`) });

			//Define the variables
			const apiMethod = type === "create" ? "post" : "put";
			const apiEndpoint = type === "create" ? "/user" : `/user/${userData.username}`;
			const navParams = type === "create" ? null : { username: userData.username };
			const navigateTo = getReturnPage(type, isAdmin, navParams);

			//Send the data to the backend
			const user = await apiRequest(apiMethod, apiEndpoint, data, null);

			//If we are creating the user we need to add the schemeConfig to DynamoDB
			if (!isAdmin) {
				if (type === "create") {
					await API.graphql(
						graphqlOperation(createUserSchemeConfig, {
							input: {
								cognitoUserId: user.id,
								schemeId: selectedScheme.id,
								isDefaultScheme: false,
								role: data.role,
							},
						})
					);
				} else if (type === "edit" && data.role !== userData.role) {
					const {
						data: {
							userSchemeConfigByCognitoUserId: { items: userSchemeConfig },
						},
					} = await API.graphql(
						graphqlOperation(userSchemeConfigByCognitoUserId, {
							cognitoUserId: user.id,
							filter: { schemeId: { eq: selectedScheme.id } },
						})
					);

					await API.graphql(
						graphqlOperation(updateUserSchemeConfig, {
							input: {
								id: userSchemeConfig[0].id,
								role: data.role,
							},
						})
					);

					//If the old user role was tenant we need to remove them from any zones
					if (userData.role === "TENANT") {
						const {
							data: {
								zoneBySchemeId: { items: tenantZones },
							},
						} = await API.graphql(
							graphqlOperation(listZonesBasic, {
								schemeId: selectedScheme.id,
								filter: { tenant: { contains: user.id } },
							})
						);

						for await (const zone of tenantZones) {
							const updatedTenants = _.pull(zone.tenant, user.id);
							await API.graphql(
								graphqlOperation(updateZone, { input: { id: zone.id, tenant: updatedTenants } })
							);
						}
					}

					//If the new user role is installer we need to remove their notification config
					if (data.role === "INSTALLER") {
						const {
							data: {
								userNotifyConfigByCognitoUserId: { items: userNotifyConfigs },
							},
						} = await API.graphql(
							graphqlOperation(userNotifyConfigByCognitoUserId, {
								cognitoUserId: user.id,
							})
						);

						const schemeNotifyConfig = userNotifyConfigs.find(
							(config) => config.schemeId === selectedScheme.id
						);

						if (schemeNotifyConfig) {
							await API.graphql(
								graphqlOperation(deleteUserNotifyConfig, { input: { id: schemeNotifyConfig.id } })
							);
						}
					}
				}
			}

			//If we are editing then update the selected user
			type === "edit" && setSelectedUser(user);

			setToast({
				...toast,
				severity: "success",
				summary: t(`user.${type}.toast.successSummary`),
				detail: t(`user.${type}.toast.successDetail`, { username: data.username, email: data.email }),
			});

			navigate(navigateTo, { replace: true });
		} catch (err) {
			console.error("Error ::", err);
			if (type === "create" && err.response.data.message === "user.error.emailExistsAdmin") {
				setShowDupeAdminDialog(true);
			} else if (type === "create" && err.response.data.message === "user.error.emailExistsStandard") {
				confirmPromoteUser(err.response.data.username, err.response.data.userId, data.email);
			} else if (type === "create" && err.response.data.message === "user.error.emailExistsImport") {
				dupeUser(data.email, data.role);
			} else {
				setToast({
					...toast,
					severity: "error",
					summary: t(`user.${type}.toast.errorSummary`),
					detail: t(`user.${type}.toast.errorDetail`, {
						error: err.response.data.message || err.message,
						data: JSON.stringify(data),
					}),
				});
			}
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	return (
		<>
			<form>
				<div className="grid columns-2 gap-medium">
					<div className="formField">
						<label htmlFor="username">{t("user.create.form.username.label")}</label>
						<Controller
							name="username"
							control={control}
							rules={{
								required: t("common.form.required"),
								pattern: { value: usernameValidation, message: t("user.validation.username.pattern") },
							}}
							render={({ field, fieldState }) => (
								<InputText
									{...field}
									readOnly={type === "create" ? false : true}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("username")}
					</div>
					<div className="formField">
						<label htmlFor="fullname">{t("user.create.form.fullname.label")}</label>
						<Controller
							name="fullname"
							control={control}
							rules={{
								required: t("common.form.required"),
								pattern: { value: fullnameValidation, message: t("user.validation.fullname.pattern") },
							}}
							render={({ field, fieldState }) => (
								<InputText {...field} className={classNames({ "p-error": fieldState.error })} />
							)}
						/>
						{getFormErrorMessage("fullname")}
					</div>
					<div className="formField">
						<label htmlFor="email">{t("user.create.form.email.label")}</label>
						<Controller
							name="email"
							control={control}
							rules={{
								required: t("common.form.required"),
								pattern: {
									value: emailValidation,
									message: t("user.validation.email.pattern"),
								},
							}}
							render={({ field, fieldState }) => (
								<InputText {...field} className={classNames({ "p-error": fieldState.error })} />
							)}
						/>
						{getFormErrorMessage("email")}
					</div>
					<div className="formField">
						<label htmlFor="emailConfirm">{t("user.create.form.emailConfirm.label")}</label>
						<Controller
							name="emailConfirm"
							control={control}
							rules={{
								required: t("common.form.required"),
								pattern: {
									value: emailValidation,
									message: t("user.validation.emailConfirm.pattern"),
								},
								validate: {
									match: (value) =>
										getValues("email") === value || t("user.validation.emailConfirm.match"),
								},
							}}
							render={({ field, fieldState }) => (
								<InputText
									{...field}
									disabled={userData && userData.email === watch("email") && type === "edit"}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("emailConfirm")}
					</div>

					<div className="formField">
						<label htmlFor="role">{t("user.create.form.role.label")}</label>
						{isAdmin ? (
							<Controller
								name="role"
								control={control}
								rules={{
									required: t("common.form.required"),
								}}
								render={({ field, fieldState }) => (
									<InputText
										{...field}
										value={t(`user.role.${field.value}`)}
										className={classNames({ "p-error": fieldState.error })}
										readOnly
									/>
								)}
							/>
						) : (
							<Controller
								name="role"
								control={control}
								rules={{ required: t("common.form.required") }}
								render={({ field: { ref, ...newField }, fieldState }) => (
									<Dropdown
										appendTo={appElement}
										{...newField}
										inputRef={ref}
										options={userRoleOptions}
										placeholder={t("user.create.form.role.placeholder")}
										onChange={(e) => newField.onChange(e.value)}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
						)}
						{getFormErrorMessage("role")}
					</div>
					<div className="formField">
						<label htmlFor="active">{t("user.create.form.active.label")}</label>
						<Controller
							name="active"
							control={control}
							render={({ field, fieldState }) => (
								<InputSwitch
									{...field}
									checked={field.value}
									onChange={(e) => field.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("active")}
					</div>
				</div>
			</form>

			<DupeAdminDialog email={getValues("email")} role={getValues("role")} />
			<DupeUserDialog email={getValues("email")} />
		</>
	);
};

export default forwardRef(UserForm);
