//Node Modules

//GraphQL

//BinaryForge Components

//3rd Party Components

//Atoms

//Helpers

//Other
import { nav } from "../config/navigation";

export const getReturnPage = (type, params) => {
	let pageUrl = null;
	type === "create"
		? (pageUrl = `${nav.admin.base}/${nav.admin.scheme.base}/${nav.admin.scheme.management}`)
		: (pageUrl = `${nav.admin.base}/${nav.admin.scheme.base}/${params.id}`);

	return pageUrl;
};
