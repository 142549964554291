//Node Modules
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

//Pages

//BinaryForge Components
import PageHelmet from "../helpers/PageHelmet";
import { ReactComponent as HeaderGraphic } from "../../assets/img/layout/pageHeaderBottom.svg";

//3rd Party Components

//Atoms

//Helpers

//Other

const PageHeader = ({ title, subtitle, enableBack }) => {
	//Hooks
	const location = useLocation();

	//On Load Component
	useEffect(() => {
		const rootLocation = location.pathname.split("/")[1];
		if (rootLocation === "admin") {
			const waveElement = document.getElementsByClassName("pageHeader-graphic")[0];
			if (waveElement) waveElement.style.setProperty("--accentColour", "var(--colour-warn-hsl)");
		}
	}, [location.pathname]);

	return (
		<>
			<PageHelmet title={title} />
			<header className="pageHeader">
				<div className="pageHeader-content">
					{subtitle && <h2 className="subtitle">{subtitle}</h2>}
					<h1>{title}</h1>
					{enableBack && (
						<Link className="goBack" to={-1}>
							<i className="pi pi-angle-double-left" />
						</Link>
					)}
				</div>
			</header>
			<div className="pageHeader-graphic">
				<HeaderGraphic />
			</div>
		</>
	);
};

export default PageHeader;
