export const onUpdateDeviceDetails = /* GraphQL */ `
	subscription onUpdateDeviceById($id: ID!) {
		onUpdateDeviceById(id: $id) {
			id
			name
			reported_state
			modbus_state
			desired_state
			desired_state_info
			inputDevice
			outputDevice
			alert {
				sensor_state
			}
			alarmGroupsInput {
				items {
					alarmGroup {
						id
						name
						input {
							items {
								id
							}
						}
					}
				}
			}
			alarmGroupsOutput {
				items {
					alarmGroup {
						id
						name
						output {
							items {
								id
							}
						}
					}
				}
			}
			zone {
				id
				label
				type
				parent
				tenant
			}
			liveEvent {
				items {
					id
				}
			}
			archiveEvent {
				items {
					id
				}
			}
			createdAt
			updatedAt
		}
	}
`;

export const onProvisiongCompleteByScheme = /* GraphQL */ `
	subscription OnCreateDeviceByInstaller($schemeId: ID, $installerId: String) {
		onCreateDeviceByInstaller(schemeId: $schemeId, installerId: $installerId) {
			id
		}
	}
`;

export const onCreateDeviceProvisioningByScheme = /* GraphQL */ `
	subscription OnCreateDeviceProvisioningByInstaller($schemeId: ID, $installerId: String) {
		onCreateDeviceProvisioningByInstaller(schemeId: $schemeId, installerId: $installerId) {
			id
			name
			inputDevice
			outputDevice
			zone {
				label
			}
			createdAt
			installerId
		}
	}
`;

export const onCreateDeviceByScheme = /* GraphQL */ `
	subscription OnCreateDeviceByInstaller($schemeId: ID, $installerId: String) {
		onCreateDeviceByInstaller(schemeId: $schemeId, installerId: $installerId) {
			id
			name
			zone {
				id
				label
			}
			zoneBreadcrumb
			inputDevice
			outputDevice
		}
	}
`;

export const onUpdateFlowstop = /* GraphQL */ `
	subscription onUpdateDeviceById($id: ID!) {
		onUpdateDeviceById(id: $id) {
			id
			name
			reported_state
		}
	}
`;

export const onUpdateDeviceSensorAlertByScheme = /* GraphQL */ `
	subscription onUpdateDeviceSensorAlertByScheme($schemeId: ID!) {
		onUpdateDeviceSensorAlertByScheme(schemeId: $schemeId) {
			updatedAt
			id
			sensor_state
			device {
				id
				name
				inputDevice
				alarmGroupsInput {
					items {
						alarmGroup {
							output {
								items {
									device {
										id
										name
										reported_state
										alarmGroupsOutput {
											items {
												alarmGroup {
													name
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
