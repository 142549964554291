export const devices = {
	pageTitle: "Devices",
	pageSubtitle: "Devices",
	actions: {
		deviceDetails: "View Details",
		removeProvisioning: "Remove",
	},
	tab: {
		devices: "Devices",
		provision: "Provisioning",
	},
	table: {
		name: "Name",
		id: "Device ID",
		zone: "Location",
		alarmGroupFlexi: "Alarm Groups (as Flexi-Pad)",
		alarmGroupFlow: "Alarm Groups (as FlowStop)",
		input: "Input Devices",
		output: "Output Devices",
		groupCount_one: "{{count}} Group",
		groupCount_other: "{{count}} Groups",
		installer: "Installer",
	},
	provisioning: {
		remove: {
			header: "Confirm Remove Provisioning Device",
			message: "Are you sure you want to remove this device from the provisioning list?",
			toast: {
				successSummary: "Successfully removed provisioning Device",
				successDetail: "",
				errorSummary: "Failed to remove provisioning Device",
				errorDetail: "{{err}}",
			},
		},
	},
};
