import { Link } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { Skeleton } from "primereact/skeleton";
import { usernameSelectorFamily } from "../../atoms/user";
import { nav } from "../../config/navigation";

const Username = ({ sub, link }) => {
	//Recoil
	const { state: usernameState, contents: usernameData } = useRecoilValueLoadable(usernameSelectorFamily(sub));

	switch (usernameState) {
		case "hasError":
			//TODO: Show a proper error component here
			console.error("Username Error ::", usernameData);
			return <div>{usernameData.message ? usernameData.message : usernameData}</div>;
		case "loading":
			return <Skeleton width="75%" />;
		case "hasValue":
			return link ? (
				<Link to={`${nav.user.base}/${usernameData.username}`}>{usernameData.fullname}</Link>
			) : (
				<span>{usernameData.fullname}</span>
			);
		default:
			return null;
	}
};

export default Username;
