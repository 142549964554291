//Node Modules
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import { useForm, Controller } from "react-hook-form";

//BinaryForge Components
import { BfDialog, Copy, Note } from "../../helpers";

//3rd Party Components
import { Steps } from "primereact/steps";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

//Atoms
import { dialogAtomFamily } from "../../../atoms/dialog";
import { toastAtom } from "../../../atoms/toast";

//Other

const UserSetupMFA = ({ currentUser, updateStateOnClose }) => {
	// Hooks
	const { t } = useTranslation();

	const steps = [
		{ label: t("profile.mfa.dialog.setup.auth.title") },
		{ label: t("profile.mfa.dialog.setup.code.title") },
	];

	// Recoil
	const [show, setShow] = useRecoilState(dialogAtomFamily("userSetupMfaDialog"));
	const [toast, setToast] = useRecoilState(toastAtom);

	//LocalState
	const [step, setStep] = useState(0);
	const [authCode, setAuthCode] = useState();

	//React Hook Form
	const defaultValues = {
		mfaCode: "",
	};

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({ defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	useEffect(() => {
		const getTotpCode = async () => {
			if (currentUser && show)
				Auth.setupTOTP(currentUser).then((code) => {
					setAuthCode(code);
				});
		};

		getTotpCode();
	}, [currentUser, show]);

	const onSubmit = async (data) => {
		try {
			await Auth.verifyTotpToken(currentUser, data.mfaCode);
			Auth.setPreferredMFA(currentUser, "TOTP");
			const cognitoUser = await Auth.currentAuthenticatedUser();
			const cognitoSession = await Auth.currentSession(cognitoUser);
			await cognitoUser.refreshSession(cognitoSession.getRefreshToken());
			setToast({
				...toast,
				severity: "success",
				summary: t("profile.mfa.dialog.setup.toast.successSummary"),
				detail: t("profile.mfa.dialog.setup.toast.successDetail"),
			});
			setShow(false);
		} catch (e) {
			// Token is not verified
			console.error("MFA Error ::", e);
			setToast({
				...toast,
				severity: "error",
				summary: t("profile.mfa.dialog.setup.toast.errorSummary"),
				detail: t("profile.mfa.dialog.setup.toast.errorDetail", { error: e.message }),
			});
		}
	};

	const goBack = () => {
		setStep(0);
	};

	const handleHide = () => {
		setStep(0);
		updateStateOnClose();
	};

	return (
		<BfDialog id="userSetupMfaDialog" header={t("profile.mfa.dialog.setup.header")} hideFunction={handleHide}>
			<Steps className="marginBottom-large" model={steps} activeIndex={step} />

			{step === 0 && (
				<>
					<Note
						messageKey={t("profile.mfa.dialog.setup.auth.message")}
						icon="pi pi-info-circle fontColour-info"
						wrapperStyle="marginBottom-medium"
					/>
					<Copy message={authCode} />
					<div className="flex jContent-end marginTop-large paddingRight-small paddingBottom-small">
						<Button
							onClick={() => {
								setStep(1);
							}}
							label={t("common.action.next")}
							className="feature"
						/>
					</div>
				</>
			)}
			{step === 1 && (
				<>
					<Note
						messageKey={t("profile.mfa.dialog.setup.code.message")}
						icon="pi pi-info-circle fontColour-info"
						wrapperStyle="marginBottom-medium"
					/>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="formField">
							<label htmlFor="mfaCode">{t("profile.mfa.dialog.setup.code.label")}</label>
							<Controller
								name="mfaCode"
								control={control}
								rules={{
									required: t("common.form.required"),
								}}
								render={({ field, fieldState }) => (
									<InputText
										id={field.name}
										{...field}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("mfaCode")}
						</div>

						<div className="paddingLeft-small paddingRight-small paddingBottom-small grid columns-2-AutoFree gap-medium">
							<Button type="button" label={t("common.action.back")} onClick={() => goBack()} />
							<Button
								type="submit"
								label={t("profile.mfa.dialog.setup.code.submit")}
								className="feature"
							/>
						</div>
					</form>
				</>
			)}
		</BfDialog>
	);
};

export default UserSetupMFA;
