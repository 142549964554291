//Node Modules
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

//GraphQL

//Pages

//BinaryForge Components
import { ReactComponent as LogoHorizontal } from "../../assets/img/logo/logo-horizontal.svg";
import { Note } from "../../components/helpers";
import { SchemeSelector } from "../../components/scheme";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { loggedInUserAtom, userSchemeOptionsAtom } from "../../atoms/user";

//Helpers
import { nav } from "../../config/navigation";
import { useLogout } from "../../helpers/Auth";

//Other

const SchemeSelect = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleLogout = useLogout();

	// Recoil State
	const loggedInUser = useRecoilValue(loggedInUserAtom);
	const schemeOptions = useRecoilValue(userSchemeOptionsAtom);

	// Local State

	//On Page Load
	useEffect(() => {
		//We need to check if there is a logged in user, if not go to the home page
		if (!loggedInUser) navigate(nav.home);
	}, []);

	return (
		<main className="appMain">
			<div className="appMain-content">
				<LogoHorizontal />
				<div className="appName marginTop-medium marginBottom-xlarge text-center">
					<span className="font-lighter text-uppercase">{t("common.appName")}</span>
				</div>

				{schemeOptions === -1 && (
					<div className="flexVert gapRow-medium">
						<Note
							messageKey={t("schemeSelect.noRoleWarn")}
							icon="pi pi-exclamation-triangle fontColour-warn"
						/>
						<div>
							<Note
								messageKey={t("schemeSelect.appSuggestion")}
								icon="pi pi-info-circle fontColour-info"
							/>
							<ul className="marginLeft-medium paddingLeft-medium">
								<li>
									<b>Android</b> -{" "}
									<a href={process.env.APP_LINK_ANDROID} target="_blank" noreferrer>
										Google Play
									</a>
								</li>
								<li>
									<b>iOS</b> -{" "}
									<a href={process.env.APP_LINK_APPLE} target="_blank" noreferrer>
										Apple App Store
									</a>
								</li>
							</ul>
						</div>
					</div>
				)}
				{schemeOptions !== -1 && <SchemeSelector />}
				<div className="text-center marginTop-large">
					<Button className="p-link" label={t("forgotPassword.back")} onClick={() => handleLogout()} />
				</div>
			</div>
		</main>
	);
};

export default SchemeSelect;
