import { API } from "aws-amplify";
import { atom, selector } from "recoil";
import { API as graphAPI, graphqlOperation } from "aws-amplify";
import { getScheme, listSchemes } from "../graphql/queries";

export const schemesAtom = atom({
	key: "schemesAtom",
	default: null,
});

export const schemesSelector = selector({
	key: "schemesSelector",
	get: async () => {
		const {
			data: {
				listSchemes: { items: schemes },
			},
		} = await graphAPI.graphql(graphqlOperation(listSchemes));

		return schemes;
	},
	set: ({ set }, newValue) => {
		set(schemesAtom, newValue);
	},
});

export const selectedSchemeAtom = atom({
	key: "selectedSchemeAtom",
	default: null,
});

export const selectedSchemeSelector = selector({
	key: "selectedSchemeSelector",
	get: async ({ get }) => {
		let scheme = null;
		const selectedScheme = get(selectedSchemeAtom);

		if (selectedScheme) {
			const {
				data: { getScheme: schemeData },
			} = await API.graphql(
				graphqlOperation(getScheme, {
					id: selectedScheme.id,
				})
			);

			const contact = JSON.parse(schemeData.contact);
			const contactEmergency = JSON.parse(schemeData.contactEmergency);

			scheme = { ...schemeData, contact, contactEmergency };
		}
		return scheme;
	},
});
