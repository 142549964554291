export const forgotPassword = {
	request: "Request Code",
	reset: "Reset Password",
	back: "Back to Login",
	form: {
		code: {
			label: "Code",
			fail: "If you did not receive a code please make sure your profile has a valid and verified email address.",
		},
		password: {
			label: "New Password",
			match: "New password cannot match the current/temporary password",
		},
		passwordConfirm: {
			label: "Confirm New Password",
			match: "Value must match the password entered previously",
		},
	},
	toast: {
		codeSentSummary: "Reset Code Sent",
		codeSentDetail: "Check your emails for a password reset verfication code.",
		codeErrorSummary: "Request Code Error",
		codeErrorDetail: "{{err}} If errors continue please contact your system administrator.",
		resetSuccessSummary: "Password Reset Success",
		resetSuccessDetail: "",
		resetErrorSummary: "Password Reset Error",
		resetErrorDetail: "{{err}}. If errors continue please contact your system administrator.",
		resetErrorCustom:
			"Invalid username or code provided. If errors continue please contact your system administrator.",
	},
};
