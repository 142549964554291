//Node Modules
import { useState, useRef, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PillItem, BooleanItem } from "../helpers";

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";

//Atoms
import { selectedUserAtom } from "../../atoms/user";
import { datatableAtomFamily } from "../../atoms/datatable";

//Helpers
import { exportDate } from "../../helpers/DateUtils";
import { userRoleOptionsFilter } from "../../config/user";

//Other

const UserListTable = ({ userData, admin }) => {
	//Hooks
	const { t } = useTranslation();
	const dt = useRef(null);

	//Recoil
	const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
	const setUserTable = useSetRecoilState(datatableAtomFamily("users"));

	// Local State
	const [dtFilters, setDtFilters] = useState(null);

	const initFilters = () => {
		setDtFilters({
			username: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			fullname: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			email: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			role: { value: null, matchMode: FilterMatchMode.IN },
			active: { value: null, matchMode: FilterMatchMode.IN },
		});
	};

	const filterApplyTemplate = (options) => {
		return (
			<Button
				type="button"
				label="Filter"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="feature"
			/>
		);
	};

	const usernameRowTemplate = (rowData) => {
		let username = rowData.username;
		rowData.status === "FORCE_CHANGE_PASSWORD" &&
			(username = <PillItem value={rowData.username} type="text" wrapperStyle="error" />);
		return username;
	};

	const emailRowTemplate = (rowData) => {
		let email = rowData.email;
		rowData.email_verified === "false" &&
			(email = <PillItem value={rowData.email} type="text" wrapperStyle="error" />);
		return email;
	};

	const roleRowTemplate = (rowData) => {
		return t(`user.role.${rowData.role}`);
	};

	const roleFilterTemplate = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={userRoleOptionsFilter}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.form.multiselectPlaceholder")}
			/>
		);
	};

	const activeRowTemplate = (rowData) => {
		return (
			<PillItem
				type="full"
				value={rowData.active ? t("common.boolean.active") : t("common.boolean.inactive")}
				icon={rowData.active ? "pi-check-circle" : "pi-times-circle"}
				wrapperStyle={rowData.active ? "success" : "error"}
			/>
		);
	};

	const activeFilterItemTemplate = (option) => {
		return <BooleanItem value={option} type="full" options="active-inactive" />;
	};

	const activeFilterSelectedTemplate = (option) => {
		if (option !== undefined) {
			return <BooleanItem value={option} type="full" options="active-inactive" />;
		}
	};

	const activeFilterTemplate = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={[true, false]}
				itemTemplate={activeFilterItemTemplate}
				selectedItemTemplate={activeFilterSelectedTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.form.multiselectPlaceholder")}
			/>
		);
	};

	useEffect(() => {
		if (userData) {
			initFilters();
		}
	}, [userData]);

	const dtValueChange = () => {
		setUserTable(dt.current);
	};

	return (
		<DataTable
			ref={dt}
			value={userData}
			emptyMessage={t("common.table.noData")}
			selectionMode="single"
			selection={selectedUser}
			onSelectionChange={(e) => setSelectedUser(e.value)}
			sortMode="multiple"
			removableSort
			filters={dtFilters}
			filterDisplay="menu"
			paginator
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
			currentPageReportTemplate={t("common.table.paginatorTemplate")}
			rows={10}
			dataKey="username"
			onValueChange={dtValueChange}
			exportFilename={t("common.table.exportFilename", {
				tableName: `Users`,
				date: exportDate,
			})}>
			<Column
				field="username"
				header={t("user.common.username")}
				body={usernameRowTemplate}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
			<Column
				field="fullname"
				header={t("user.common.fullname")}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
			<Column
				field="email"
				header={t("user.common.email")}
				body={emailRowTemplate}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
			{!admin && (
				<Column
					field="role"
					header={t("user.common.role")}
					body={roleRowTemplate}
					sortable
					filter
					showFilterMatchModes={false}
					filterElement={roleFilterTemplate}
					filterApply={filterApplyTemplate}
				/>
			)}
			<Column
				field="active"
				header={t("user.common.active")}
				body={activeRowTemplate}
				sortable
				filter
				showFilterMatchModes={false}
				filterElement={activeFilterTemplate}
				filterApply={filterApplyTemplate}
			/>
		</DataTable>
	);
};

export default UserListTable;
