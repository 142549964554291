import { atom, selector } from "recoil";
import _ from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import { listAlarmAlerts, listAlarmGroupsTable } from "../graphql/queries-custom";
import { selectedUserSchemeAtom } from "./user";

export const selectedAlarmGroupAtom = atom({
	key: "selectedAlarmGroupAtom",
	default: null,
});

export const alarmGroupListAtom = atom({
	key: "alarmGroupListAtom",
	default: null,
});

export const alarmGroupListSelector = selector({
	key: "alarmGroupListSelector",
	get: async ({ get }) => {
		const scheme = get(selectedUserSchemeAtom);
		const {
			data: {
				listAlarmGroups: { items: alarmGroupData },
			},
		} = await API.graphql(graphqlOperation(listAlarmGroupsTable, { filter: { schemeId: { eq: scheme.id } } }));

		alarmGroupData.map((group) => {
			group.inputCount = group.input.items.length;
			group.outputCount = group.output.items.length;

			let espComplete = false;
			const inputDevices = group.input.items;
			const inputDeviceIds = inputDevices.map((input) => input.device.id);
			const outputDevices = group.output.items;
			const outputDeviceIds = outputDevices.map((output) => output.device.id);

			for (const input of inputDevices) {
				espComplete = checkPeerCompletion(input.device, outputDeviceIds);

				//If this isn't true there is no need to continue
				if (!espComplete) break;
			}

			for (const output of outputDevices) {
				espComplete = checkPeerCompletion(output.device, inputDeviceIds);

				//If this isn't true there is no need to continue
				if (!espComplete) break;
			}

			group.espComplete = espComplete;
		});

		return alarmGroupData;
	},
	set: ({ set }, newValue) => {
		set(alarmGroupListAtom, newValue);
	},
});

const checkPeerCompletion = (currentDevice, comparisonIds) => {
	let espComplete = false;
	//Get the devices currently configured peers which are outputs for this group
	const reportedStateObj = JSON.parse(currentDevice.reported_state);
	const espPeers = reportedStateObj.ESPNow.peers.filter((peer) => comparisonIds.includes(`Andel_${peer.id}`));

	//Check there are enough peers
	if (espPeers.length >= comparisonIds.length) {
		//Make sure all the peers are paired
		espComplete = espPeers.every((peer) => peer.paired);
	}

	return espComplete;
};

export const deviceSensorAlarmListAtom = atom({
	key: "deviceSensorAlarmListAtom",
	default: [],
});

export const deviceSensorAlarmListSelector = selector({
	key: "deviceSensorAlarmListSelector",
	get: async ({ get }) => {
		let sensorAlarms;
		const alarmList = get(deviceSensorAlarmListAtom);
		const selectedUserScheme = get(selectedUserSchemeAtom);

		if (alarmList.length === 0) {
			const {
				data: {
					deviceSensorAlertBySensorState: { items: sensorAlarmItems },
				},
			} = await API.graphql(
				graphqlOperation(listAlarmAlerts, {
					sensor_state: 1,
					filter: { schemeId: { eq: selectedUserScheme.id } },
				})
			);

			for await (const sensorAlarmItem of sensorAlarmItems) {
				const outputDevices = [];
				for await (const alarmGroupInput of sensorAlarmItem.device.alarmGroupsInput.items) {
					for await (const outputDevice of alarmGroupInput.alarmGroup.output.items) {
						outputDevices.push(outputDevice.device);
					}
				}
				sensorAlarmItem.outputDevices = _.uniqBy(outputDevices, "id");
			}

			sensorAlarms = sensorAlarmItems;
		} else {
			sensorAlarms = alarmList;
		}

		return sensorAlarms;
	},
});

export const deviceSensorAlarmCounterSelector = selector({
	key: "alarmCounterSelector",
	get: ({ get }) => {
		const alarmList = get(deviceSensorAlarmListAtom);
		return alarmList.length;
	},
});
