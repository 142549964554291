import { Button } from "primereact";
import { useState } from "react";

const Break = () => {
	const [breakState, setBreakState] = useState("");

	const breakObj = {
		title: "This will break",
		desc: "Objects are not allowed as React render components so this will throw error boundary",
	};

	const doBreak = () => {
		setBreakState(breakObj);
	};

	return (
		<div>
			<Button onClick={() => doBreak()}>Click to Break</Button>
			<p>{breakState}</p>
		</div>
	);
};

export default Break;
