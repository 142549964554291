export const datatable = {
	matchMode_EQUALS: "Equals",
	matchMode_NOT_EQUALS: "Not Equals",
	matchMode_GREATER: "Greater Than",
	matchMode_GREATER_EQUALS: "Greater Than or Equals",
	matchMode_LESS: "Less Than",
	matchMode_LESS_EQUALS: "Less Than or Equals",
	periodType_SEC: "Seconds",
	periodType_MIN: "Minutes",
	periodType_HOUR: "Hours",
	periodType_DAY: "Days",
};
