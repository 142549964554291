//Node Modules
import { useState, useRef, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PillItem, BooleanItem } from "../../helpers";

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

//Atoms
import { selectedSchemeAtom } from "../../../atoms/scheme";

//Helpers

//Other

const SchemeListTable = ({ schemeData }) => {
	//Hooks
	const { t } = useTranslation();
	const dt = useRef(null);

	//Recoil
	const [selectedScheme, setSelectedScheme] = useRecoilState(selectedSchemeAtom);

	// Local State
	const [dtFilters, setDtFilters] = useState(null);

	const initFilters = () => {
		setDtFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
		});
	};

	const filterApplyTemplate = (options) => {
		return (
			<Button
				type="button"
				label="Filter"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="feature"
			/>
		);
	};

	useEffect(() => {
		if (schemeData) {
			initFilters();
		}
	}, [schemeData]);

	return (
		<DataTable
			ref={dt}
			value={schemeData}
			emptyMessage={t("common.table.noData")}
			selectionMode="single"
			selection={selectedScheme}
			onSelectionChange={(e) => setSelectedScheme(e.value)}
			sortMode="multiple"
			removableSort
			filters={dtFilters}
			filterDisplay="menu"
			paginator
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
			currentPageReportTemplate={t("common.table.paginatorTemplate")}
			rows={10}
			dataKey="name">
			<Column
				field="name"
				header={t("admin.scheme.common.name")}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
		</DataTable>
	);
};

export default SchemeListTable;
