//Node Modules
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState, useRecoilRefresher_UNSTABLE } from "recoil";
import _ from "lodash";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import {
	onCreateDeviceByScheme,
	onProvisiongCompleteByScheme,
	onCreateDeviceProvisioningByScheme,
} from "../../../graphql/subscriptions-custom";

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { DevicesLoader, DevicesProvisioningLoader } from "../../../components/devices";

//3rd Party Components
import { TabView, TabPanel } from "primereact/tabview";

//Atoms
import {
	devicesListAtom,
	devicesListSelector,
	devicesProvisionListAtom,
	devicesProvisionListSelector,
} from "../../../atoms/devices";
import { selectedUserSchemeAtom } from "../../../atoms/user";

//Helpers

//Other

const DeviceManagement = () => {
	//Hooks
	const { t } = useTranslation();
	const deviceCreateSub = useRef();
	const deviceProvisionSub = useRef();
	const deviceCompleteSub = useRef();

	//Recoil
	const refreshDevicesCache = useRecoilRefresher_UNSTABLE(devicesListSelector);
	const refreshProvisionCache = useRecoilRefresher_UNSTABLE(devicesProvisionListSelector);
	const selectedUserScheme = useRecoilValue(selectedUserSchemeAtom);
	const setDevices = useSetRecoilState(devicesListAtom);
	const [provisionDevices, setProvisionDevices] = useRecoilState(devicesProvisionListAtom);

	//Local State
	const [activeIndex, setActiveIndex] = useState(0);
	const [deviceUpdate, setDeviceUpdate] = useState(false);
	const [deviceProvisionUpdate, setDeviceProvisionUpdate] = useState(false);

	//On Page Load
	useEffect(() => {
		refreshDevicesCache();
		refreshProvisionCache();
		manageSubscriptions();

		return () => {
			deviceCreateSub.current.unsubscribe();
			deviceProvisionSub.current.unsubscribe();
			deviceCompleteSub.current.unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (activeIndex === 0 && deviceUpdate) {
			refreshDevicesCache();
			setDeviceUpdate(false);
		} else if (activeIndex === 1 && deviceProvisionUpdate) {
			refreshProvisionCache();
			setDeviceProvisionUpdate(false);
		}
	}, [activeIndex]);

	const manageSubscriptions = () => {
		deviceCreateSub.current = API.graphql(
			graphqlOperation(onCreateDeviceByScheme, { schemeId: selectedUserScheme.id })
		).subscribe({
			next: ({
				value: {
					data: { onCreateDeviceByInstaller: newDevice },
				},
			}) => {
				setDevices((prevState) => [newDevice, ...prevState]);
				setDeviceUpdate(true);
			},
			error: (error) => console.warn(error),
		});

		deviceProvisionSub.current = API.graphql(
			graphqlOperation(onCreateDeviceProvisioningByScheme, { schemeId: selectedUserScheme.id })
		).subscribe({
			next: ({
				value: {
					data: { onCreateDeviceProvisioningByInstaller: newDevice },
				},
			}) => {
				setProvisionDevices((prevState) => [newDevice, ...prevState]);
				setDeviceProvisionUpdate(true);
			},
			error: (error) => console.warn(error),
		});

		deviceCompleteSub.current = API.graphql(
			graphqlOperation(onProvisiongCompleteByScheme, { schemeId: selectedUserScheme.id })
		).subscribe({
			next: ({
				value: {
					data: { onCreateDeviceByInstaller: newDevice },
				},
			}) => {
				const updatedDevices = _.differenceBy(provisionDevices, [newDevice], "id");
				setProvisionDevices(updatedDevices);
				setDeviceProvisionUpdate(true);
			},
			error: (error) => console.warn(error),
		});
	};

	return (
		<>
			<PageHeader title={t("devices.pageTitle")} subtitle="" />
			<main className="appMain">
				<div className="appMain-content">
					<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
						<TabPanel header={t("devices.tab.devices")} contentClassName="paddingBlock-medium">
							<DevicesLoader />
						</TabPanel>
						<TabPanel header={t("devices.tab.provision")} contentClassName="paddingBlock-medium">
							<DevicesProvisioningLoader />
						</TabPanel>
					</TabView>
				</div>
			</main>
		</>
	);
};

export default DeviceManagement;
