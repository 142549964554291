//Node Modules
import { useRef } from "react";
import { useTranslation } from "react-i18next";

//GraphQL

//BinaryForge Components
import { PageHeader } from "../../../../components/layout";
import { SchemeForm } from "../../../../components/scheme";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms

//Helpers

//Other

const SchemeCreate = () => {
	// Hooks
	const { t } = useTranslation();
	const formRef = useRef();

	return (
		<>
			<PageHeader title={t("admin.scheme.create.pageTitle")} enableBack />
			<main className="appMain">
				<div className="appMain-content">
					<div className="flex jContent-end gap-small">
						<Button
							className="feature"
							label={t("admin.scheme.create.actions.submit")}
							icon="pi pi-save"
							onClick={() => formRef.current.submitSchemeForm()}
						/>
					</div>
					<SchemeForm type="create" ref={formRef} />
				</div>
			</main>
		</>
	);
};

export default SchemeCreate;
