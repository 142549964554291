//Node Modules
import { useRecoilValueLoadable } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { EventsTable } from "./";
import { DatatableSkeleton } from "../helpers/skeleton";
import { Note } from "../helpers";

//3rd Party Components

//Atoms
import { archiveEventsListSelector } from "../../atoms/events";

//Helpers

//Other

const ArchiveEventsLoader = () => {
	//Recoil
	const { t } = useTranslation();
	const { state: archiveEventsState, contents: archiveEventsContent } =
		useRecoilValueLoadable(archiveEventsListSelector);

	switch (archiveEventsState) {
		case "hasError":
			console.error(archiveEventsContent);
			return (
				<Note
					messageKey={`Error: ${
						archiveEventsContent.message ? archiveEventsContent.message : archiveEventsContent
					}`}
					icon="pi-exclamation-circle fontColour-error"
				/>
			);
		case "loading":
			return (
				<DatatableSkeleton
					columns={[
						t("events.management.table.type"),
						t("events.management.table.deviceName"),
						t("events.management.table.location"),
						t("common.table.createdAt"),
						t("common.table.updatedAt"),
						t("events.management.table.duration"),
					]}
				/>
			);
		case "hasValue":
			return <EventsTable eventsData={archiveEventsContent} context="archive" />;
		default:
			return null;
	}
};

export default ArchiveEventsLoader;
