//Node Modules
import { useRecoilValueLoadable } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { Note } from "../../helpers";
import { DatatableSkeleton } from "../../helpers/skeleton";
import { JobsList } from "./";

//3rd Party Components

//Atoms
import { otaJobsSelector } from "../../../atoms/ota";
import { Skeleton } from "primereact/skeleton";

//Helpers

//Other

const FileLoader = () => {
	//Recoil
	const { t } = useTranslation();
	const { state, contents } = useRecoilValueLoadable(otaJobsSelector);

	switch (state) {
		case "hasError":
			console.error(contents);
			return (
				<Note
					messageKey={`Error: ${contents.message ? contents.message : contents}`}
					icon="pi-exclamation-circle fontColour-error"
				/>
			);
		case "loading":
			return (
				<div>
					<div className="flex jContent-end gap-small marginBottom-medium">
						<Skeleton height="3rem" width="14ch" />
						<Skeleton height="3rem" width="10ch" />
					</div>
					<DatatableSkeleton
						columns={[
							t("admin.ota.job.table.name"),
							t("admin.ota.job.table.version"),
							t("admin.ota.job.table.type"),
							t("admin.ota.job.table.status"),
							t("common.table.createdAt"),
							t("common.table.updatedAt"),
						]}
					/>
				</div>
			);
		case "hasValue":
			return <JobsList data={contents} />;
		default:
			return null;
	}
};

export default FileLoader;
