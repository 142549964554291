export const schemeSelect = {
	placeholder: "Select your Scheme",
	submit: "Use Scheme",
	default: "Set as my default scheme",
	noRoleWarn:
		"Your user is not assigned a role which has access to any schemes via the $t(common.appName). If you think there is an error please contact support.",
	appSuggestion:
		"Your user may have a role which can access the $t(common.appNameShort) mobile app. You can download the app via the links for your device below:",
	dialog: {
		header: "Select Scheme",
	},
};
