import { atomFamily, selector } from "recoil";

export const validationAtomFamily = atomFamily({
	key: "validationAtomFamily",
	default: false,
});

export const validationSelector = selector({
	key: "validationSelector",
	get: ({ get }) => get(validationAtomFamily("device")),
	set: ({ set }, values) => {
		values.map((value) => set(validationAtomFamily(value.model), JSON.parse(value.validation)));
	},
});
