//Node Modules
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { DateTime, Duration } from "luxon";
import { useForm, Controller } from "react-hook-form";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { listDevicesBasic } from "../../graphql/queries-custom";

//BinaryForge Components
import { Field } from "../";
import { BfDialog, Note } from "../helpers";

//3rd Party Components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { classNames } from "primereact/utils";

//Atoms
import { dialogAtomFamily, loggedInUserAtom } from "../../atoms";
import { combinedReportParamsSelector, reportPresetsSelector, reportTypeAtom } from "../../atoms/report";

//Helpers
import { getUiDateTime, getIntervalHuman } from "../../helpers/DateUtils";
import { createReport } from "../../graphql/mutations";
import { validationAtomFamily } from "../../atoms/validation";

//Other

const ReportSaveDialog = ({ loadTime }) => {
	//Hooks
	const { t } = useTranslation();

	//Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("reportSaveDialog"));
	const reportType = useRecoilValue(reportTypeAtom);
	const reportParams = useRecoilValue(combinedReportParamsSelector);
	const loggedInUser = useRecoilValue(loggedInUserAtom);
	const resetPresets = useResetRecoilState(reportPresetsSelector);
	const reportValidation = useRecoilValue(validationAtomFamily("report"));

	// Form Default Values
	const defaultValues = {
		name: "",
		desc: "",
		public: true,
		relative: false,
	};

	// Form Init
	const {
		control,
		formState: { errors, isValid },
		handleSubmit,
		reset,
	} = useForm({ defaultValues: defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	//Submit the Form
	const onSubmit = async (data) => {
		try {
			let fromString = reportParams[reportType]?.from;
			let toString = reportParams[reportType]?.to;

			if (data.relative) {
				const fromIso = DateTime.fromISO(fromString?.split("'")[1]);
				const toIso = DateTime.fromISO(toString?.split("'")[1]);

				const fromDuration = loadTime.diff(fromIso, ["seconds"]).toObject();
				const toDuration = loadTime.diff(toIso, ["seconds"]).toObject();

				fromString = `ago(${Math.ceil(fromDuration.seconds)}s)`;
				toString = `ago(${Math.ceil(toDuration.seconds)}s)`;
			}

			const presetParams = {
				type: reportType.toUpperCase(),
				measureName: reportParams[reportType]?.measureName,
				allDevices: reportParams[reportType]?.allDevices,
				devices: JSON.stringify(reportParams[reportType]?.devices),
				from: fromString,
				to: toString,
				aggregateType: reportParams[reportType]?.aggregateType,
				interval: reportParams[reportType]?.interval,
				relative: data.relative,
				public: data.public,
				name: data.name,
				desc: data.desc,
				createdBy: loggedInUser.sub,
			};
			await API.graphql(graphqlOperation(createReport, { input: presetParams }));
			handleHide();
			resetPresets();
		} catch (err) {
			console.error("Create Preset Error ::", err);
		}
	};

	const handleHide = () => {
		setShow(false);
		reset();
	};

	const footer = (
		<>
			<Button label={t("common.action.cancel")} onClick={() => handleHide()} />
			<Button
				label={t("common.action.save")}
				icon="pi pi-save"
				className="feature"
				disabled={!isValid}
				onClick={() => handleSubmit(onSubmit)()}
			/>
		</>
	);

	const getTimestamp = (timeString) => {
		if (timeString) {
			const iso = timeString.split("'")[1];
			return getUiDateTime(iso);
		}
	};

	return (
		<BfDialog id="reportSaveDialog" header={t("report.preset.dialog.header")} footer={footer}>
			<form>
				<div className="grid gapRow-medium">
					<div className="formField">
						<label htmlFor="name">{t("report.preset.dialog.form.name.label")}</label>
						<Controller
							name="name"
							control={control}
							rules={{
								required: t("common.form.required"),
								maxLength: {
									value: reportValidation.name["maxLength"],
									message: t("validation.report.name.maxLength", {
										length: reportValidation.name["maxLength"],
									}),
								},
								pattern: {
									value: RegExp(reportValidation.name["pattern"]),
									message: t("validation.report.name.pattern"),
								},
							}}
							render={({ field, fieldState }) => (
								<InputText {...field} className={classNames({ "p-error": fieldState.error })} />
							)}
						/>
						{getFormErrorMessage("name")}
					</div>
					<div className="formField">
						<label htmlFor="desc">{t("report.preset.dialog.form.desc.label")}</label>
						<Controller
							name="desc"
							control={control}
							rules={{
								required: t("common.form.required"),
								maxLength: {
									value: reportValidation.desc["maxLength"],
									message: t("validation.report.desc.maxLength", {
										length: reportValidation.desc["maxLength"],
									}),
								},
								pattern: {
									value: RegExp(reportValidation.desc["pattern"]),
									message: t("validation.report.desc.pattern"),
								},
							}}
							render={({ field, fieldState }) => (
								<InputTextarea
									{...field}
									rows={3}
									autoResize={true}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("desc")}
					</div>

					<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
						<Field
							label={t("report.form.measure.label")}
							response={t(`report.measure.${reportParams[reportType]?.measureName}`)}
						/>
						<Field
							label={t("report.form.devices.label")}
							response={
								reportParams[reportType]?.allDevices
									? "All Devices"
									: reportParams[reportType]?.devices.length
							}
						/>
						{reportType === "historical" && (
							<Field
								label={t("report.form.aggregate.label")}
								response={t(`report.aggregate.${reportParams[reportType]?.aggregateType}`)}
							/>
						)}
						{reportType === "historical" && (
							<Field
								label={t("report.form.interval.label")}
								response={getIntervalHuman(reportParams[reportType]?.interval)}
							/>
						)}
						{reportType === "historical" && (
							<Field
								label={t("report.form.from.label")}
								response={getTimestamp(reportParams[reportType]?.from)}
							/>
						)}
						<Field
							label={t("report.form.to.label")}
							response={getTimestamp(reportParams[reportType]?.to)}
						/>
					</div>

					<div className="grid columns-2-AutoFree gapCol-large aItems-end">
						<div className="formField">
							<label htmlFor="public">{t("report.preset.dialog.form.public.label")}</label>
							<Controller
								name="public"
								control={control}
								render={({ field, fieldState }) => (
									<InputSwitch
										{...field}
										checked={field.value}
										onChange={(e) => field.onChange(e.value)}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("public")}
						</div>
						<Note
							messageKey={t("report.preset.dialog.form.public.tooltip")}
							messageStyle="fontSize-small fontColour-lighter"
							icon="pi pi-info-circle fontColour-info"
						/>
					</div>
					<div className="grid columns-2-AutoFree gapCol-large aItems-end">
						<div className="formField">
							<label htmlFor="relative">{t("report.preset.dialog.form.relative.label")}</label>
							<Controller
								name="relative"
								control={control}
								render={({ field, fieldState }) => (
									<InputSwitch
										{...field}
										checked={field.value}
										onChange={(e) => field.onChange(e.value)}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("relative")}
						</div>
						<Note
							messageKey={t("report.preset.dialog.form.relative.tooltip")}
							messageStyle="fontSize-small fontColour-lighter"
							icon="pi pi-info-circle fontColour-info"
						/>
					</div>
				</div>
			</form>
		</BfDialog>
	);
};

export default ReportSaveDialog;
