import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { nav, guestRoutes } from "../../config/navigation";
import { useIsValidSession } from "../../helpers/Auth";

const GuestRoute = () => {
	let location = useLocation();
	const isValidSession = useIsValidSession();

	//Local State
	const [returnRoute, setReturnRoute] = useState();

	useEffect(() => {
		// console.log("Guest Route ::", location.pathname);
		getReturnRoute();
	}, [location]);

	const getReturnRoute = async () => {
		if ((await isValidUser()) === true) {
			// console.log("There is a valid user, send to auth section...");
			setReturnRoute(<Navigate to={`${nav.device.base}/${nav.device.management}`} replace />);
		} else {
			// console.log("No valid user so returning request page...");
			setReturnRoute(<Outlet />);
		}
	};

	const isValidUser = async () => {
		const value = await isValidSession();
		return value;
	};

	if (returnRoute === undefined) return null;
	return returnRoute;
};

export default GuestRoute;
