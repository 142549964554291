export const error = {
	pageTitle: "Something went wrong!",
	errorMsg: "Unfortunately there has been an unexpected error; our team have been made aware of the error.",
	supportMsg: "If you continue to experience an error please contact support directly.",
	prevLink: "Back to previous page",
	homeLink: "Go to home page",
	notFound: {
		pageTitle: "404: Page Not Found",
		errorMsg: "Sorry but the page at {{url}} could not be found.",
		supportMsg: "If you continue to experience an error please contact support directly.",
	},
};
