import i18n from "../i18n";
import { API } from "aws-amplify";
import { atom, selector, selectorFamily } from "recoil";
import { recoilPersist } from "recoil-persist";
import { API as graphAPI, graphqlOperation } from "aws-amplify";
import { listUserSchemeConfigs, userSchemeConfigByCognitoUserId } from "../graphql/queries";

const { persistAtom } = recoilPersist();

const restApi = "andelRestApi";

export const loggedInUserAtom = atom({
	key: "loggedInUserAtom",
	default: null,
	effects_UNSTABLE: [persistAtom],
});

export const usersAtom = atom({
	key: "usersAtom",
	default: null,
});

export const usersSelector = selector({
	key: "usersSelector",
	get: async ({ get }) => {
		const scheme = get(selectedUserSchemeAtom);
		const cognitoUsers = await API.get(restApi, `/user`, {
			headers: {
				"X-Scheme-Id": scheme.id,
			},
		});

		const {
			data: {
				listUserSchemeConfigs: { items: schemeUsers },
			},
		} = await graphAPI.graphql(
			graphqlOperation(listUserSchemeConfigs, {
				filter: {
					schemeId: { eq: scheme.id },
				},
			})
		);

		cognitoUsers.map((u) => {
			const userRole = schemeUsers.find((su) => su.cognitoUserId === u.id).role;
			u.role = userRole;
			return userRole;
		});

		return cognitoUsers;
	},
	set: ({ set }, newValue) => {
		set(usersAtom, newValue);
	},
});

export const selectedUserAtom = atom({
	key: "selectedUserAtom",
	default: null,
});

export const selectedUserSelector = selector({
	key: "selectedUserSelector",
	get: async ({ get }) => {
		let user = null;
		let role = "ADMINISTRATOR";
		const selectedUser = get(selectedUserAtom);
		const scheme = get(selectedUserSchemeAtom);

		if (selectedUser) {
			const cognitoUser = await API.get(restApi, `/user/${selectedUser.username}`, {
				headers: {
					"X-Scheme-Id": scheme.id,
				},
			});

			if (!cognitoUser.isAdmin) {
				const {
					data: {
						userSchemeConfigByCognitoUserId: { items: userSchemeConfig },
					},
				} = await API.graphql(
					graphqlOperation(userSchemeConfigByCognitoUserId, {
						cognitoUserId: cognitoUser.id,
						filter: { schemeId: { eq: scheme.id } },
					})
				);

				role = userSchemeConfig[0].role;
			}

			user = { ...cognitoUser, role: role };
		}
		return user;
	},
});

export const selectedUserSchemeAtom = atom({
	key: "selectedUserSchemeAtom",
	default: null,
	effects_UNSTABLE: [persistAtom],
});

export const userSchemeOptionsAtom = atom({
	key: "userSchemeOptionsAtom",
	default: null,
	effects_UNSTABLE: [persistAtom],
});

export const usernameSelectorFamily = selectorFamily({
	key: "usernameSelectorFamily",
	get: (sub) => async () => {
		const users = await API.get(restApi, `/user/byAttribute?name=sub&type=%3D&value=${sub}`);
		return users.length ? users[0] : { fullname: `${i18n.t("user.common.deleted")}: ${sub.slice(-4)}` };
	},
});
