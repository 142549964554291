//Node Modules
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, Link } from "react-router-dom";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { getAlarmGroup } from "../../../graphql/queries";

//3rd Party Components

//Atoms

//Helpers

//Other
import { nav } from "../../../config/navigation";

const AlarmGroupDetails = () => {
	//Hooks
	const { t } = useTranslation();
	const { groupId } = useLoaderData();

	// Recoil

	//Local State
	const [groupInfo, setGroupInfo] = useState(null);

	//On Page Load
	useEffect(() => {
		handleGetGroup();
	}, []);

	//Get the initial group data
	const handleGetGroup = async () => {
		const {
			data: { getAlarmGroup: groupData },
		} = await API.graphql(graphqlOperation(getAlarmGroup, { id: groupId }));

		setGroupInfo(groupData);
	};

	return (
		<>
			<PageHeader
				title={t("alarm.group.details.pageTitle", { groupId: groupInfo?.name ? groupInfo.name : groupId })}
				subtitle={t("alarm.group.details.pageSubtitle")}
				enableBack
			/>
			<main className="appMain">
				<div className="appMain-content">
					<div className="flex jContent-end gap-small">
						<Link
							to={`${nav.alarm.base}/${nav.alarm.group.base}/${nav.alarm.group.edit}/${groupId}`}
							className="button feature">
							{t("alarm.group.details.actions.edit")}
						</Link>
					</div>
					{groupInfo && <pre>{JSON.stringify(groupInfo, null, 2)}</pre>}
				</div>
			</main>
		</>
	);
};

export default AlarmGroupDetails;
