//Node Modules
import { useTranslation } from "react-i18next";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";

//GraphQL

//Pages

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { AlarmCard } from "../../../components/alarm";
import { Note } from "../../../components/helpers";

//3rd Party Components
import { ProgressSpinner } from "primereact/progressspinner";

//Atoms
import { deviceSensorAlarmListSelector } from "../../../atoms/alarm";

//Helpers

//GraphQL

const Alarms = () => {
	const { t } = useTranslation();

	//Recoil State
	const { state, contents: deviceSensorAlarmList } = useRecoilValueLoadable(deviceSensorAlarmListSelector);
	// const setDeviceSensorAlarmList = useSetRecoilState(deviceSensorAlarmListSelector);

	const PageBody = () => {
		switch (state) {
			case "hasError":
				return (
					<Note
						messageKey={`Error: ${
							deviceSensorAlarmList.message ? deviceSensorAlarmList.message : deviceSensorAlarmList
						}`}
						icon="pi-exclamation-circle fontColour-error"
					/>
				);
			case "loading":
				return (
					<div className="aSelf-center marginBottom-xlarge appMain-content">
						<ProgressSpinner strokeWidth="4" />
					</div>
				);
			case "hasValue":
				return deviceSensorAlarmList.length > 0 ? (
					<div className="appMain-content">
						<div className="grid columns-3 gap-medium">
							{deviceSensorAlarmList.map((a) => (
								<AlarmCard key={a.id} alarmData={a} />
							))}
						</div>
					</div>
				) : (
					<div className="aSelf-center appMain-content">
						<div className="flexVert aItems-center marginBottom-xlarge">
							<i className="pi pi-check-circle fontColour-success fontSize-xlarge" />
							<p className="fontSize-large marginTop-medium">{t("alarms.none.message")}</p>
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<PageHeader title={t("alarms.pageTitle")} subtitle={t("alarms.pageSubtitle")} />
			<main className="flex appMain">
				<PageBody />
			</main>
		</>
	);
};

export default Alarms;
