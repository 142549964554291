//Node Modules
import { useRecoilValueLoadable } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { Loading, Note } from "../helpers";
import { DoughnutChart } from "../report";

//3rd Party Components

//Atoms
import { deviceSummarySelector } from "../../atoms/dashboard";

//Helpers

//Other

const DeviceSummaryLoader = () => {
	//Recoil
	const { t } = useTranslation();
	const { state, contents } = useRecoilValueLoadable(deviceSummarySelector);

	switch (state) {
		case "hasError":
			return (
				<Note
					messageKey={`Error: ${contents.message ? contents.message : contents}`}
					icon="pi-exclamation-circle fontColour-error"
				/>
			);
		case "loading":
			return <Loading />;
		case "hasValue":
			const inputData = {
				FLOWSTOP: contents.inputDevices.FLOWSTOP,
				FLEXIPAD: contents.inputDevices.FLEXIPAD,
				RANGER: contents.inputDevices.RANGER,
			};
			const outputData = {
				FLOWSTOP: contents.outputDevices.FLOWSTOP,
			};
			const labels = Object.keys(inputData).map((label) => t(`device.feature.${label.toLowerCase()}`));
			return (
				<div className="summaryChart-wrapper">
					<div className="summaryChart-center">{contents.total}</div>
					<DoughnutChart
						labels={labels}
						chartData={[inputData, outputData]}
						measureName={["Input Count", "Output Count"]}
						legendId="deviceSummaryLegend"
					/>
				</div>
			);
		default:
			return null;
	}
};

export default DeviceSummaryLoader;
