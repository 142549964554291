//Node Modules
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useResetRecoilState, useRecoilValue } from "recoil";
import { DateTime } from "luxon";

//BinaryForge Components
import { PageHeader } from "../../components/layout";
import { ReportForm as ReportFormHistorical } from "../../components/report/historical";
import { ReportForm as ReportFormInstant } from "../../components/report/instant";
import { ReportPresets } from "../../components/report/preset";
import ReportSaveDialog from "../../components/report/ReportSaveDialog";

//3rd Party Components
import { TabView, TabPanel } from "primereact/tabview";

//Atoms
import { combinedReportParamsSelector, reportInstantParamsAtom, reportParamsAtom } from "../../atoms/report";

//Helpers

//Other

const Report = () => {
	//Hooks
	const { t } = useTranslation();

	//Local State
	const [activeIndex, setActiveIndex] = useState(0);
	const [loadTime, setLoadTime] = useState(DateTime.now().set({ second: 0, millisecond: 0 }));

	//Recoil
	const reportParams = useRecoilValue(combinedReportParamsSelector);
	const resetHistroicParams = useResetRecoilState(reportParamsAtom);
	const resetInstantParams = useResetRecoilState(reportInstantParamsAtom);

	const handleTabChange = (activeIndex) => {
		setLoadTime(DateTime.now().set({ second: 0, millisecond: 0 }));
		resetHistroicParams();
		resetInstantParams();
		setActiveIndex(activeIndex);
	};

	return (
		<>
			<PageHeader title={t("report.pageTitle")} subtitle={t("report.pageSubtitle")} />
			<main className="appMain">
				<div className="appMain-content">
					<TabView activeIndex={activeIndex} onTabChange={(e) => handleTabChange(e.index)}>
						<TabPanel header={t("report.historical.tabHeader")} contentClassName="paddingBlock-medium">
							<ReportFormHistorical />
						</TabPanel>
						<TabPanel header={t("report.instant.tabHeader")} contentClassName="paddingBlock-medium">
							<ReportFormInstant />
						</TabPanel>
						<TabPanel header={t("report.preset.tabHeader")} contentClassName="paddingBlock-medium">
							<ReportPresets />
						</TabPanel>
					</TabView>
				</div>
			</main>
			{Object.values(reportParams).some((v) => v !== null) && <ReportSaveDialog loadTime={loadTime} />}
		</>
	);
};

export default Report;
