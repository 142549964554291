import i18n from "../i18n";

export const intervalTypeOptions = [
	{ label: i18n.t("device.config.interval.type.second"), value: "second" },
	{ label: i18n.t("device.config.interval.type.minute"), value: "minute" },
	{ label: i18n.t("device.config.interval.type.hour"), value: "hour" },
];

export const featuresOptions = [
	{ label: i18n.t("device.feature.flexipad"), value: "flexipad", icon: "icon-flexipad" },
	{ label: i18n.t("device.feature.flowstop"), value: "flowstop", icon: "icon-flowstop" },
];

export const inputDeviceOptions = [
	{ value: null, label: i18n.t("device.feature.null"), icon: "pi pi-times" },
	{ value: "FLEXIPAD", label: i18n.t("device.feature.flexipad"), icon: "icon-flexipad" },
	{ value: "FLOWSTOP", label: i18n.t("device.feature.flowstop"), icon: "icon-flowstop" },
	{ value: "RANGER", label: i18n.t("device.feature.ranger"), icon: "icon-ranger" },
];

export const outputDeviceOptions = [
	{ value: null, label: i18n.t("device.feature.null"), icon: "pi pi-times" },
	{ value: "FLOWSTOP", label: i18n.t("device.feature.flowstop"), icon: "icon-flowstop" },
];
