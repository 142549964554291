//Node Modules
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";

//GraphQL

//Pages

//BinaryForge Components
import { PageHeader } from "../../../../components/layout";
import { UserList } from "../../../../components/admin/user";

//3rd Party Components

//Atoms
import { selectedUserAtom } from "../../../../atoms/user";
import { adminUsersSelector } from "../../../../atoms/admin";

//Helpers
import { nav } from "../../../../config/navigation";

const AdminUserManagement = () => {
	const { t } = useTranslation();

	//Recoil State
	const refreshUserListCache = useRecoilRefresher_UNSTABLE(adminUsersSelector);
	const selectedUser = useRecoilValue(selectedUserAtom);

	//On Page Load
	useEffect(() => {
		refreshUserListCache();
	}, []);

	//Get the Initial Data

	return (
		<>
			<PageHeader title={t("admin.user.management.pageTitle")} />
			<main className="appMain">
				<div className="appMain-content">
					<div className="actions flex jContent-end gap-small">
						<Link to={`${nav.admin.base}/${nav.admin.user.base}/create`} className="button">
							{t("admin.user.management.actions.create")}
						</Link>
						<Link
							to={`${nav.admin.base}/${nav.admin.user.base}/${selectedUser?.username}`}
							className="button feature"
							disabled={!selectedUser}>
							{t("user.management.actions.details")}
						</Link>
					</div>
					<UserList />
				</div>
			</main>
		</>
	);
};

export default AdminUserManagement;
