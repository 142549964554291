//Node Modules
import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import _ from "lodash";

//Pages

//BinaryForge Components

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

//Atoms
import { reportParamsAtom } from "../../../atoms/report";

//Helpers
import { exportDate } from "../../../helpers/DateUtils";
import { filterApplyTemplate, datetimeRowTemplate, dateFilterTemplate } from "../../../config/datatable";
import { measureOptions } from "../../../config/report";

//Other

const ReportResultTable = ({ reportResultsData, selectedDevices }, ref) => {
	//Hooks
	const { t } = useTranslation();
	const dt = useRef(null);

	//Local State
	const [results, setResults] = useState();

	//Recoil
	const reportParams = useRecoilValue(reportParamsAtom);

	// Local State
	const [dtFilters, setDtFilters] = useState(null);
	const [deviceColumns, setDeviceColumns] = useState([]);

	//On Page/Data Load
	useEffect(() => {
		if (reportResultsData) {
			setResults(reportResultsData);
			setDeviceColumns(selectedDevices);
			initFilters();
		}
	}, [reportResultsData, selectedDevices]);

	//Datatable Functions
	const initFilters = () => {
		setDtFilters({
			timestamp: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			measure_value: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
		});
	};

	const measureValueRowTemplate = (rowData, columnId) => {
		const { measureName, aggregateType } = reportParams;
		const isBoolean = measureOptions.find((opt) => opt.value === measureName).isBoolean;

		if (aggregateType === "AVG" && isBoolean) {
			const percentage = (100 * rowData[columnId]).toFixed(0);
			if (isNaN(percentage)) return "---";
			else return percentage;
		} else if (isBoolean) {
			let returnValue = "---";
			if (rowData[columnId] === 1) returnValue = "true";
			else if (rowData[columnId] === 0) returnValue = "false";
			return returnValue;
		} else {
			return rowData[columnId];
		}
	};

	useImperativeHandle(ref, () => ({
		exportCSV(selectionOnly) {
			dt.current.exportCSV({ selectionOnly });
		},
	}));

	return (
		<>
			<DataTable
				ref={dt}
				value={results}
				emptyMessage={t("common.table.noData")}
				sortMode="multiple"
				removableSort
				filters={dtFilters}
				filterDisplay="menu"
				autoLayout={true}
				scrollable
				paginator
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
				currentPageReportTemplate={t("common.table.paginatorTemplate")}
				rows={10}
				dataKey="id"
				exportFilename={t("common.table.exportFilename", {
					tableName: `Historical ${t(`report.measure.${reportParams.measureName}`)}`,
					date: exportDate,
				})}>
				<Column
					field="timestamp"
					header={t("report.table.timestamp")}
					body={(rowData) => datetimeRowTemplate(rowData, "timestamp")}
					dataType="date"
					frozen
					sortable
					filter
					filterApply={filterApplyTemplate}
					filterElement={dateFilterTemplate}
					style={{ minWidth: "25ch" }}
				/>
				{deviceColumns.map((column) => {
					return (
						<Column
							key={column.id}
							field={column.id}
							header={column.name}
							body={(rowData) => measureValueRowTemplate(rowData, column.id)}
							dataType="numeric"
							style={{ minWidth: "25ch" }}
							// sortable
							// filter
							// filterElement={basicNumberFilterTemplate}
							// filterApply={filterApplyTemplate}
						/>
					);
				})}
			</DataTable>
		</>
	);
};

export default forwardRef(ReportResultTable);
