import { atom, selector } from "recoil";
import { selectedUserSchemeAtom } from "./user";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { liveEventBySchemeBasic, archiveEventBySchemeBasic } from "../graphql/queries-custom";

export const selectedEventAtom = atom({
	key: "selectedEventAtom",
	default: null,
});

export const eventTableFiltersAtom = atom({
	key: "eventTableFiltersAtom",
	default: null,
});

export const liveEventsListSelector = selector({
	key: "liveEventsListSelector",
	get: async ({ get }) => {
		const { id: schemeId } = get(selectedUserSchemeAtom);
		const {
			data: {
				liveEventByScheme: { items: liveEventData },
			},
		} = await API.graphql(
			graphqlOperation(liveEventBySchemeBasic, {
				schemeId: schemeId,
				sortDirection: "DESC",
			})
		);

		liveEventData.map((event) => {
			event.nameFallback = event.device ? event.device.name : `Deleted - ${event.deviceId}`;
			event.device.zoneBreadcrumb = JSON.parse(event.device.zoneBreadcrumb);
			event.createdAt = new Date(event.createdAt);
			event.updatedAt = new Date(event.updatedAt);
		});

		return liveEventData;
	},
});

export const archiveEventsListSelector = selector({
	key: "archiveEventsListSelector",
	get: async ({ get }) => {
		const { id: schemeId } = get(selectedUserSchemeAtom);
		let archiveEventData = [];
		let lastNextToken = null;

		do {
			const {
				data: {
					archiveEventByScheme: { items: archiveEventItems, nextToken },
				},
			} = await API.graphql(
				graphqlOperation(archiveEventBySchemeBasic, {
					schemeId: schemeId,
					sortDirection: "DESC",
					limit: 500,
					nextToken: lastNextToken,
				})
			);

			lastNextToken = nextToken;
			archiveEventData.push(...archiveEventItems);
		} while (lastNextToken !== null);

		archiveEventData.map((event) => {
			event.nameFallback = event.device ? event.device.name : `Deleted - ${event.deviceId}`;
			event.createdAt = new Date(event.createdAt);
			event.updatedAt = new Date(event.updatedAt);
			if (event.device) event.device.zoneBreadcrumb = JSON.parse(event.device?.zoneBreadcrumb);
		});

		return archiveEventData;
	},
});
