import { useTranslation } from "react-i18next";
import { Skeleton } from "primereact/skeleton";

const SchemeDetailsSkeleton = () => {
	const { t } = useTranslation();

	return (
		<form>
			<div className="formField">
				<label htmlFor="name">{t("admin.scheme.create.form.name.label")}</label>
				<Skeleton width="100%" />
			</div>
		</form>
	);
};

export default SchemeDetailsSkeleton;
