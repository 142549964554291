export const report = {
	pageTitle: "Reports",
	pageSubtitle: "Analytics",
	loading: "Fetching Report Data",
	view: {
		table: "View as Table",
		chart: "View as Chart",
	},
	form: {
		submit: "Generate Report",
		save: "Save As Preset",
		allDevices: {
			label: "All Devices",
		},
		devices: {
			label: "Devices",
			placeholder: "Select Devices for report",
		},
		from: {
			label: "From",
		},
		to: {
			label: "To",
		},
		measure: {
			label: "Measure",
			placeholder: "Select the type of data",
		},
		aggregate: {
			label: "Aggregate",
			placeholder: "Select how to aggregate the data",
		},
		interval: {
			label: "Interval",
			placeholder: "Select the data interval",
		},
	},
	table: {
		timestamp: "Timestamp",
		device: "Device",
		dataExceeded_HISTORICAL:
			"The report parameters generate too much data to be returned. Consider the following options to reduce the data size: <ul><li>Reduce the number of Devices.</li><li>Reduce the timespan between 'from' and 'to' values.</li><li>Use or increase the interval value.</li></ul>",
		dataExceeded_INSTANT:
			"The report parameters generate too much data to be returned. Consider the following options to reduce the data size: <ul><li>Reduce the number of Devices.</li></ul>",
	},
	measure: {
		sensor1_status: "Sensor Status",
		sensor1_resistance: "Sensor Resistance",
		relay_status: "Relay Status",
		battery_percentage: "Battery Percentage",
		battery_charging: "Battery Charging",
		dcPower_status: "DC Power Status",
		dcPower_voltage: "DC Power Voltage",
		wifi_rssi: "WiFi RSSI",
		firmware_version: "Firmware Version",
		hardware_version: "Hardware Version",
		outputDevice: "Output Device",
		inputDevice: "Input Device",
	},
	aggregate: {
		ALL: "N/A",
		AVG: "Average (mean)",
		MIN: "Minimum",
		MAX: "Maximum",
	},
	type: {
		HISTORICAL: "Historical",
		historical: "Histrocial",
		INSTANT: "Instant",
		instant: "Instant",
	},
	historical: {
		tabHeader: "Historical Report Generator",
	},
	instant: {
		tabHeader: "Instant Report Generator",
	},
	preset: {
		tabHeader: "Preset Reports",
		generate: "Generate",
		dataExceeded_HISTORICAL:
			"The preset report parameters generate too much data to be returned; for relative dates the amount of data returned is likely to vary. Consider the following options: <ul><li>Create and save a new preset with a smaller timespan.</li><li>Create and save a new preset with an increased interval value.</li><li>Create and save a new preset with a smaller number of devices.</li><li>Keep the current preset and monitor the data on a different dates.</li></ul>",
		dialog: {
			header: "Save As Preset",
			form: {
				name: {
					label: "Report Name",
				},
				desc: {
					label: "Report Description",
				},
				public: {
					label: "Public",
					tooltip:
						"If set the report will be available to any user otherwise only you will have access to this preset report.",
				},
				relative: {
					label: "Relative",
					tooltip:
						"If set the report times will be relative to when the report preset is saved otherwise the report times will be absolute as set.",
				},
			},
		},
		table: {
			name: "Name",
			desc: "Description",
			measure: "Measure",
			relative: "Relative",
			public: "Public",
			type: "Type",
		},
	},
};
