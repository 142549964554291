//Node Modules
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";

//GraphQL
import { API as graphAPI, graphqlOperation } from "aws-amplify";
import { userNotifyConfigByCognitoUserId, userSchemeConfigByCognitoUserId } from "../../../graphql/queries";
import { createUserNotifyConfig, updateUserNotifyConfig } from "../../../graphql/mutations";

//BinaryForge Components
import { Note } from "../../helpers";

//3rd Party Components
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { confirmDialog } from "primereact/confirmdialog";
import { classNames } from "primereact/utils";

//Atoms
import { selectedUserSchemeAtom, userSchemeOptionsAtom } from "../../../atoms/user";
import { dialogAtomFamily, toastAtom } from "../../../atoms";

//Helpers

//Other
import { defaultNotificationConfig } from "../../../config/user";

const UserNotificationConfig = ({ currentUser }) => {
	const { t } = useTranslation();
	const { cognitoUser } = currentUser;

	//Recoil State
	const [toast, setToast] = useRecoilState(toastAtom);
	const setLoading = useSetRecoilState(dialogAtomFamily("loadingDialog"));
	const scheme = useRecoilValue(selectedUserSchemeAtom);
	const allSchemesAdmin = useRecoilValue(userSchemeOptionsAtom);

	//Local State
	const [currentSchemeNotifyConfig, setCurrentSchemeNotifyConfig] = useState();
	const [allSchemeNotifyConfig, setAllSchemeNotifyConfig] = useState([]);
	const [allSchemesUser, setAllSchemesUser] = useState();

	useEffect(() => {
		getConfigData();
		//Portal Only - We need to get all schemes regardless of role (app should already have all roles)
		if (currentUser.role !== "ADMINISTRATOR") getAllSchemesUser();
	}, []);

	const getConfigData = async () => {
		let currentConfig = null;
		const {
			data: {
				userNotifyConfigByCognitoUserId: { items: userNotifyConfigs },
			},
		} = await graphAPI.graphql(
			graphqlOperation(userNotifyConfigByCognitoUserId, {
				cognitoUserId: cognitoUser.attributes.sub,
			})
		);

		if (userNotifyConfigs.length > 0) {
			setAllSchemeNotifyConfig(userNotifyConfigs);
			currentConfig = userNotifyConfigs.find((config) => config.schemeId === scheme.id);
		}
		if (currentConfig) {
			setCurrentSchemeNotifyConfig({
				id: currentConfig.id,
				config: JSON.parse(currentConfig.config),
			});
		} else {
			const newConfigData = await createBlankConfig();
			const newConfig = {
				id: newConfigData.data.createUserNotifyConfig.id,
				config: JSON.parse(newConfigData.data.createUserNotifyConfig.config),
				schemeId: newConfigData.data.createUserNotifyConfig.schemeId,
			};

			setCurrentSchemeNotifyConfig(newConfig);
			setAllSchemeNotifyConfig((prevState) => [...prevState, newConfig]);
		}
	};

	const getAllSchemesUser = async () => {
		const {
			data: {
				userSchemeConfigByCognitoUserId: { items: graphQlAllSchemes },
			},
		} = await graphAPI.graphql(
			graphqlOperation(userSchemeConfigByCognitoUserId, {
				cognitoUserId: cognitoUser.attributes.sub,
			})
		);

		const allSchemes = graphQlAllSchemes.map((s) => ({
			id: s.scheme.id,
			name: s.scheme.name,
			role: s.role,
			configId: s.id,
		}));
		setAllSchemesUser(allSchemes);
	};

	const createBlankConfig = async (schemeId = scheme.id, config = defaultNotificationConfig) => {
		const newConfig = await graphAPI.graphql(
			graphqlOperation(createUserNotifyConfig, {
				input: {
					cognitoUserId: cognitoUser.attributes.sub,
					schemeId: schemeId,
					config: JSON.stringify(config),
				},
			})
		);

		return newConfig;
	};

	//React Hook Form
	const defaultValues = defaultNotificationConfig;
	const {
		reset,
		control,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm({
		defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	useEffect(() => {
		if (currentSchemeNotifyConfig) {
			const { push, email } = currentSchemeNotifyConfig.config;
			reset({
				push: {
					sensor_input_flexipad: push.sensor_input_flexipad,
					sensor_input_ranger: push.sensor_input_ranger,
					sensor_input_flowstop: push.sensor_input_flowstop,
					relay: push.relay,
					out_of_comms: push.out_of_comms,
					battery_low: push.battery_low,
					mains_power_fail: push.mains_power_fail,
				},
				email: {
					sensor_input_flexipad: email.sensor_input_flexipad,
					sensor_input_ranger: email.sensor_input_ranger,
					sensor_input_flowstop: email.sensor_input_flowstop,
					relay: email.relay,
					out_of_comms: email.out_of_comms,
					battery_low: email.battery_low,
					mains_power_fail: email.mains_power_fail,
				},
			});
		}
	}, [currentSchemeNotifyConfig]);

	const handleUpdateNotifyConfig = async (data) => {
		const configJSON = JSON.stringify(data);

		if ((allSchemesUser && allSchemesUser.length > 1) || (allSchemesAdmin && allSchemesAdmin.length > 1)) {
			confirmNotifyAllSchemes(configJSON);
		} else {
			doNotifySingleScheme(configJSON);
		}
	};

	const confirmNotifyAllSchemes = async (configJSON) => {
		confirmDialog({
			header: t("profile.notifications.allSchemes.confirm.title"),
			message: (
				<Note
					messageKey={t("profile.notifications.allSchemes.confirm.message")}
					icon="pi pi-info-circle fontColour-info"
				/>
			),
			acceptLabel: t("profile.notifications.allSchemes.confirm.accept"),
			rejectLabel: t("profile.notifications.allSchemes.confirm.reject"),
			accept: () => doNotifyAllSchemes(configJSON),
			reject: () => doNotifySingleScheme(configJSON),
		});
	};

	const doNotifyAllSchemes = async (configJSON) => {
		try {
			setLoading({ visible: true, message: t("profile.notifications.allSchemes.confirm.loading") });

			const allSchemes = currentUser.role === "ADMINISTRATOR" ? allSchemesAdmin : allSchemesUser;
			for await (const scheme of allSchemes) {
				const schemeConfig = allSchemeNotifyConfig.find((c) => c.schemeId === scheme.id);
				if (schemeConfig) {
					await graphAPI.graphql(
						graphqlOperation(updateUserNotifyConfig, {
							input: {
								id: schemeConfig.id,
								config: configJSON,
							},
						})
					);
				} else {
					createBlankConfig(scheme.id, JSON.parse(configJSON));
				}
			}

			setToast({
				...toast,
				severity: "success",
				summary: t("profile.notifications.toast.successSummary"),
				detail: t("profile.notifications.toast.successDetail"),
			});
		} catch (err) {
			console.error("Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("profile.notifications.toast.errorSummary"),
				detail: t("profile.notifications.toast.errorDetail", { error: err }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const doNotifySingleScheme = async (configJSON) => {
		try {
			setLoading({ visible: true, message: t("profile.notifications.allSchemes.confirm.loading") });

			await graphAPI.graphql(
				graphqlOperation(updateUserNotifyConfig, {
					input: {
						id: currentSchemeNotifyConfig.id,
						config: configJSON,
					},
				})
			);

			setToast({
				...toast,
				severity: "success",
				summary: t("profile.notifications.toast.successSummary"),
				detail: t("profile.notifications.toast.successDetail"),
			});
		} catch (err) {
			console.error("Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("profile.notifications.toast.errorSummary"),
				detail: t("profile.notifications.toast.errorDetail", { error: err }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const handlePurgeDevices = async () => {
		try {
			setLoading({ visible: true, message: t("profile.notifications.push.purge.loading") });
			await Auth.deleteUserAttributes(cognitoUser, ["custom:expoTokens"]);
			setToast({
				...toast,
				severity: "success",
				summary: t("profile.notifications.push.purge.toast.successSummary"),
				detail: t("profile.notifications.push.purge.toast.successDetail"),
			});
		} catch (err) {
			console.error("Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("profile.notifications.push.purge.toast.errorSummary"),
				detail: t("profile.notifications.push.purge.toast.errorDetail", { error: err }),
			});
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	return (
		<section>
			<Tooltip target=".customTooltip" />
			<div className="card">
				<header>
					<h3>{t("profile.notifications.title", { scheme: scheme.name })}</h3>
				</header>

				<form onSubmit={handleSubmit(handleUpdateNotifyConfig)}>
					<div className="grid columns-2 colGap-medium">
						<div>
							<div className="flex gap-small aItems-center fontWeight-bold marginBottom-small">
								{t("profile.notifications.push.title")}
								<i
									className="pi pi-info-circle fontColour-info customTooltip"
									data-pr-tooltip={t("profile.notifications.push.tooltip")}
								/>
							</div>
							<div className="flexVert gapRow-xsmall">
								{Object.keys(defaultNotificationConfig.push).map((notiItem) => (
									<div key={`pushItem-${notiItem}`} className="flex aItems-center">
										<Controller
											name={`push.${notiItem}`}
											control={control}
											render={({ field, fieldState }) => (
												<Checkbox
													{...field}
													id={field.name}
													inputId={field.id}
													inputRef={field.ref}
													checked={field.value}
													onChange={(e) => field.onChange(e.checked)}
													className={classNames({ "p-error": fieldState.error })}
												/>
											)}
										/>
										<label
											htmlFor={`push.${notiItem}`}
											className="fontWeight-regular marginLeft-xsmall">
											{t(`profile.notifications.push.${notiItem}.label`)}
										</label>
									</div>
								))}
							</div>
							<div className="flex gap-small aItems-center marginTop-small">
								<Button
									type="button"
									onClick={() => handlePurgeDevices()}
									className="error"
									label={t("profile.notifications.push.purge.submit")}
								/>
								<i
									className="pi pi-info-circle fontColour-info customTooltip"
									data-pr-tooltip={t("profile.notifications.push.purge.tooltip")}
								/>
							</div>
						</div>
						<div>
							<div className="fontWeight-bold marginBottom-small">
								{t("profile.notifications.email.title")}
							</div>
							<div className="flexVert gapRow-xsmall">
								{Object.keys(defaultNotificationConfig.email).map((notiItem) => (
									<div key={`emailItem-${notiItem}`} className="flex aItems-center">
										<Controller
											name={`email.${notiItem}`}
											control={control}
											render={({ field, fieldState }) => (
												<Checkbox
													{...field}
													id={field.name}
													inputId={field.id}
													inputRef={field.ref}
													checked={field.value}
													onChange={(e) => field.onChange(e.checked)}
													className={classNames({ "p-error": fieldState.error })}
												/>
											)}
										/>
										<label
											htmlFor={`email.${notiItem}`}
											className="fontWeight-regular marginLeft-xsmall">
											{t(`profile.notifications.email.${notiItem}.label`)}
										</label>
									</div>
								))}
							</div>
						</div>
					</div>
					<Button
						type="submit"
						label={t("profile.notifications.submit")}
						className="feature aSelf-start"
						disabled={isSubmitting}
					/>
				</form>
			</div>
		</section>
	);
};

export default UserNotificationConfig;
