import i18n from "../i18n";

export const usernameValidation = /^[\w@._-]+$/;
export const fullnameValidation = /^[A-zÀ-ÿ\- ]+$/i;
export const emailValidation =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordValidation =
	/^(?=^.{8,}$)(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*?&():;#"^_{}[\]'~|<>,./=`\\+-]+).*$/;
export const phoneValidation = /^[\d()+ -]+$/;

export const allowedRoles = ["administrator", "owner"];

export const userRoleOptions = [
	{ value: "INSTALLER", label: i18n.t("user.role.INSTALLER") },
	{ value: "OWNER", label: i18n.t("user.role.OWNER") },
	{ value: "TENANT", label: i18n.t("user.role.TENANT") },
];

export const userRoleOptionsFilter = [
	{ value: "INSTALLER", label: i18n.t("user.role.INSTALLER") },
	{ value: "OWNER", label: i18n.t("user.role.OWNER") },
	{ value: "TENANT", label: i18n.t("user.role.TENANT") },
	{ value: "none", label: i18n.t("user.role.none") },
];

export const defaultNotificationConfig = {
	push: {
		sensor_input_flexipad: false,
		sensor_input_flowstop: false,
		sensor_input_ranger: false,
		relay: false,
		out_of_comms: false,
		battery_low: false,
		mains_power_fail: false,
	},
	email: {
		sensor_input_flexipad: false,
		sensor_input_flowstop: false,
		sensor_input_ranger: false,
		relay: false,
		out_of_comms: false,
		battery_low: false,
		mains_power_fail: false,
	},
};
