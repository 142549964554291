/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateDeviceById = /* GraphQL */ `
  subscription OnUpdateDeviceById($id: ID!) {
    onUpdateDeviceById(id: $id) {
      id
      name
      reported_state
      desired_state
      desired_state_info
      modbus_state
      alarmGroupsInput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroupsOutput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inputDevice
      outputDevice
      alert {
        id
        sensor_state
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deviceSensorAlertDeviceId
        __typename
      }
      liveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      archiveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneBreadcrumb
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDeviceByScheme = /* GraphQL */ `
  subscription OnDeleteDeviceByScheme($schemeId: ID!) {
    onDeleteDeviceByScheme(schemeId: $schemeId) {
      id
      name
      reported_state
      desired_state
      desired_state_info
      modbus_state
      alarmGroupsInput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroupsOutput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inputDevice
      outputDevice
      alert {
        id
        sensor_state
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deviceSensorAlertDeviceId
        __typename
      }
      liveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      archiveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneBreadcrumb
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDeviceSensorAlertByScheme = /* GraphQL */ `
  subscription OnUpdateDeviceSensorAlertByScheme($schemeId: ID!) {
    onUpdateDeviceSensorAlertByScheme(schemeId: $schemeId) {
      id
      sensor_state
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      deviceSensorAlertDeviceId
      __typename
    }
  }
`;
export const onCreateDeviceByInstaller = /* GraphQL */ `
  subscription OnCreateDeviceByInstaller($schemeId: ID, $installerId: String) {
    onCreateDeviceByInstaller(schemeId: $schemeId, installerId: $installerId) {
      id
      name
      reported_state
      desired_state
      desired_state_info
      modbus_state
      alarmGroupsInput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroupsOutput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inputDevice
      outputDevice
      alert {
        id
        sensor_state
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deviceSensorAlertDeviceId
        __typename
      }
      liveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      archiveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneBreadcrumb
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDeviceProvisioningByInstaller = /* GraphQL */ `
  subscription OnCreateDeviceProvisioningByInstaller(
    $schemeId: ID
    $installerId: String
  ) {
    onCreateDeviceProvisioningByInstaller(
      schemeId: $schemeId
      installerId: $installerId
    ) {
      id
      name
      inputDevice
      outputDevice
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateZoneByScheme = /* GraphQL */ `
  subscription OnUpdateZoneByScheme($schemeId: ID!) {
    onUpdateZoneByScheme(schemeId: $schemeId) {
      id
      schemeId
      label
      type
      parent
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateZoneByScheme = /* GraphQL */ `
  subscription OnCreateZoneByScheme($schemeId: ID!) {
    onCreateZoneByScheme(schemeId: $schemeId) {
      id
      schemeId
      label
      type
      parent
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteZoneByScheme = /* GraphQL */ `
  subscription OnDeleteZoneByScheme($schemeId: ID!) {
    onDeleteZoneByScheme(schemeId: $schemeId) {
      id
      schemeId
      label
      type
      parent
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice($filter: ModelSubscriptionDeviceFilterInput) {
    onCreateDevice(filter: $filter) {
      id
      name
      reported_state
      desired_state
      desired_state_info
      modbus_state
      alarmGroupsInput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroupsOutput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inputDevice
      outputDevice
      alert {
        id
        sensor_state
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deviceSensorAlertDeviceId
        __typename
      }
      liveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      archiveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneBreadcrumb
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice($filter: ModelSubscriptionDeviceFilterInput) {
    onUpdateDevice(filter: $filter) {
      id
      name
      reported_state
      desired_state
      desired_state_info
      modbus_state
      alarmGroupsInput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroupsOutput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inputDevice
      outputDevice
      alert {
        id
        sensor_state
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deviceSensorAlertDeviceId
        __typename
      }
      liveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      archiveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneBreadcrumb
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice($filter: ModelSubscriptionDeviceFilterInput) {
    onDeleteDevice(filter: $filter) {
      id
      name
      reported_state
      desired_state
      desired_state_info
      modbus_state
      alarmGroupsInput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroupsOutput {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      inputDevice
      outputDevice
      alert {
        id
        sensor_state
        device {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deviceSensorAlertDeviceId
        __typename
      }
      liveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      archiveEvent {
        items {
          id
          schemeId
          deviceId
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          eventType
          duration
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneBreadcrumb
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDeviceProvisioning = /* GraphQL */ `
  subscription OnCreateDeviceProvisioning(
    $filter: ModelSubscriptionDeviceProvisioningFilterInput
  ) {
    onCreateDeviceProvisioning(filter: $filter) {
      id
      name
      inputDevice
      outputDevice
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDeviceProvisioning = /* GraphQL */ `
  subscription OnUpdateDeviceProvisioning(
    $filter: ModelSubscriptionDeviceProvisioningFilterInput
  ) {
    onUpdateDeviceProvisioning(filter: $filter) {
      id
      name
      inputDevice
      outputDevice
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDeviceProvisioning = /* GraphQL */ `
  subscription OnDeleteDeviceProvisioning(
    $filter: ModelSubscriptionDeviceProvisioningFilterInput
  ) {
    onDeleteDeviceProvisioning(filter: $filter) {
      id
      name
      inputDevice
      outputDevice
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      zoneId
      zone {
        id
        schemeId
        label
        type
        parent
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      installerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAlarmGroup = /* GraphQL */ `
  subscription OnCreateAlarmGroup(
    $filter: ModelSubscriptionAlarmGroupFilterInput
  ) {
    onCreateAlarmGroup(filter: $filter) {
      id
      name
      input {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      output {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAlarmGroup = /* GraphQL */ `
  subscription OnUpdateAlarmGroup(
    $filter: ModelSubscriptionAlarmGroupFilterInput
  ) {
    onUpdateAlarmGroup(filter: $filter) {
      id
      name
      input {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      output {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAlarmGroup = /* GraphQL */ `
  subscription OnDeleteAlarmGroup(
    $filter: ModelSubscriptionAlarmGroupFilterInput
  ) {
    onDeleteAlarmGroup(filter: $filter) {
      id
      name
      input {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      output {
        items {
          id
          deviceID
          alarmGroupID
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          alarmGroup {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      schemeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDeviceSensorAlert = /* GraphQL */ `
  subscription OnCreateDeviceSensorAlert(
    $filter: ModelSubscriptionDeviceSensorAlertFilterInput
  ) {
    onCreateDeviceSensorAlert(filter: $filter) {
      id
      sensor_state
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      deviceSensorAlertDeviceId
      __typename
    }
  }
`;
export const onUpdateDeviceSensorAlert = /* GraphQL */ `
  subscription OnUpdateDeviceSensorAlert(
    $filter: ModelSubscriptionDeviceSensorAlertFilterInput
  ) {
    onUpdateDeviceSensorAlert(filter: $filter) {
      id
      sensor_state
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      deviceSensorAlertDeviceId
      __typename
    }
  }
`;
export const onDeleteDeviceSensorAlert = /* GraphQL */ `
  subscription OnDeleteDeviceSensorAlert(
    $filter: ModelSubscriptionDeviceSensorAlertFilterInput
  ) {
    onDeleteDeviceSensorAlert(filter: $filter) {
      id
      sensor_state
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      deviceSensorAlertDeviceId
      __typename
    }
  }
`;
export const onCreateNotifyToken = /* GraphQL */ `
  subscription OnCreateNotifyToken(
    $filter: ModelSubscriptionNotifyTokenFilterInput
  ) {
    onCreateNotifyToken(filter: $filter) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNotifyToken = /* GraphQL */ `
  subscription OnUpdateNotifyToken(
    $filter: ModelSubscriptionNotifyTokenFilterInput
  ) {
    onUpdateNotifyToken(filter: $filter) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNotifyToken = /* GraphQL */ `
  subscription OnDeleteNotifyToken(
    $filter: ModelSubscriptionNotifyTokenFilterInput
  ) {
    onDeleteNotifyToken(filter: $filter) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateScheme = /* GraphQL */ `
  subscription OnCreateScheme($filter: ModelSubscriptionSchemeFilterInput) {
    onCreateScheme(filter: $filter) {
      id
      name
      users {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          isDefaultScheme
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroups {
        items {
          id
          name
          input {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          output {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorAlerts {
        items {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        nextToken
        __typename
      }
      userNotifyConfig {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          config
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contact
      contactEmergency
      zoneConfig
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateScheme = /* GraphQL */ `
  subscription OnUpdateScheme($filter: ModelSubscriptionSchemeFilterInput) {
    onUpdateScheme(filter: $filter) {
      id
      name
      users {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          isDefaultScheme
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroups {
        items {
          id
          name
          input {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          output {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorAlerts {
        items {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        nextToken
        __typename
      }
      userNotifyConfig {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          config
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contact
      contactEmergency
      zoneConfig
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteScheme = /* GraphQL */ `
  subscription OnDeleteScheme($filter: ModelSubscriptionSchemeFilterInput) {
    onDeleteScheme(filter: $filter) {
      id
      name
      users {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          isDefaultScheme
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      alarmGroups {
        items {
          id
          name
          input {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          output {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorAlerts {
        items {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        nextToken
        __typename
      }
      userNotifyConfig {
        items {
          id
          cognitoUserId
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          config
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contact
      contactEmergency
      zoneConfig
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserSchemeConfig = /* GraphQL */ `
  subscription OnCreateUserSchemeConfig(
    $filter: ModelSubscriptionUserSchemeConfigFilterInput
  ) {
    onCreateUserSchemeConfig(filter: $filter) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      isDefaultScheme
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserSchemeConfig = /* GraphQL */ `
  subscription OnUpdateUserSchemeConfig(
    $filter: ModelSubscriptionUserSchemeConfigFilterInput
  ) {
    onUpdateUserSchemeConfig(filter: $filter) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      isDefaultScheme
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserSchemeConfig = /* GraphQL */ `
  subscription OnDeleteUserSchemeConfig(
    $filter: ModelSubscriptionUserSchemeConfigFilterInput
  ) {
    onDeleteUserSchemeConfig(filter: $filter) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      isDefaultScheme
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserDashboardConfig = /* GraphQL */ `
  subscription OnCreateUserDashboardConfig(
    $filter: ModelSubscriptionUserDashboardConfigFilterInput
  ) {
    onCreateUserDashboardConfig(filter: $filter) {
      id
      cognitoUserId
      schemeId
      dashboardAnalytics
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserDashboardConfig = /* GraphQL */ `
  subscription OnUpdateUserDashboardConfig(
    $filter: ModelSubscriptionUserDashboardConfigFilterInput
  ) {
    onUpdateUserDashboardConfig(filter: $filter) {
      id
      cognitoUserId
      schemeId
      dashboardAnalytics
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserDashboardConfig = /* GraphQL */ `
  subscription OnDeleteUserDashboardConfig(
    $filter: ModelSubscriptionUserDashboardConfigFilterInput
  ) {
    onDeleteUserDashboardConfig(filter: $filter) {
      id
      cognitoUserId
      schemeId
      dashboardAnalytics
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserNotifyConfig = /* GraphQL */ `
  subscription OnCreateUserNotifyConfig(
    $filter: ModelSubscriptionUserNotifyConfigFilterInput
  ) {
    onCreateUserNotifyConfig(filter: $filter) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      config
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserNotifyConfig = /* GraphQL */ `
  subscription OnUpdateUserNotifyConfig(
    $filter: ModelSubscriptionUserNotifyConfigFilterInput
  ) {
    onUpdateUserNotifyConfig(filter: $filter) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      config
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserNotifyConfig = /* GraphQL */ `
  subscription OnDeleteUserNotifyConfig(
    $filter: ModelSubscriptionUserNotifyConfigFilterInput
  ) {
    onDeleteUserNotifyConfig(filter: $filter) {
      id
      cognitoUserId
      schemeId
      scheme {
        id
        name
        users {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            isDefaultScheme
            role
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devicesProvisioning {
          items {
            id
            name
            inputDevice
            outputDevice
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            installerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroups {
          items {
            id
            name
            input {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            output {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorAlerts {
          items {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          nextToken
          __typename
        }
        userNotifyConfig {
          items {
            id
            cognitoUserId
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            config
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        contact
        contactEmergency
        zoneConfig
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      config
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateZone = /* GraphQL */ `
  subscription OnCreateZone($filter: ModelSubscriptionZoneFilterInput) {
    onCreateZone(filter: $filter) {
      id
      schemeId
      label
      type
      parent
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateZone = /* GraphQL */ `
  subscription OnUpdateZone($filter: ModelSubscriptionZoneFilterInput) {
    onUpdateZone(filter: $filter) {
      id
      schemeId
      label
      type
      parent
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteZone = /* GraphQL */ `
  subscription OnDeleteZone($filter: ModelSubscriptionZoneFilterInput) {
    onDeleteZone(filter: $filter) {
      id
      schemeId
      label
      type
      parent
      devices {
        items {
          id
          name
          reported_state
          desired_state
          desired_state_info
          modbus_state
          alarmGroupsInput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroupsOutput {
            items {
              id
              deviceID
              alarmGroupID
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              alarmGroup {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          inputDevice
          outputDevice
          alert {
            id
            sensor_state
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            deviceSensorAlertDeviceId
            __typename
          }
          liveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          archiveEvent {
            items {
              id
              schemeId
              deviceId
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              eventType
              duration
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneBreadcrumb
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devicesProvisioning {
        items {
          id
          name
          inputDevice
          outputDevice
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          zoneId
          zone {
            id
            schemeId
            label
            type
            parent
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tenant
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          installerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLiveEvent = /* GraphQL */ `
  subscription OnCreateLiveEvent(
    $filter: ModelSubscriptionLiveEventFilterInput
  ) {
    onCreateLiveEvent(filter: $filter) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLiveEvent = /* GraphQL */ `
  subscription OnUpdateLiveEvent(
    $filter: ModelSubscriptionLiveEventFilterInput
  ) {
    onUpdateLiveEvent(filter: $filter) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLiveEvent = /* GraphQL */ `
  subscription OnDeleteLiveEvent(
    $filter: ModelSubscriptionLiveEventFilterInput
  ) {
    onDeleteLiveEvent(filter: $filter) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateArchiveEvent = /* GraphQL */ `
  subscription OnCreateArchiveEvent(
    $filter: ModelSubscriptionArchiveEventFilterInput
  ) {
    onCreateArchiveEvent(filter: $filter) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateArchiveEvent = /* GraphQL */ `
  subscription OnUpdateArchiveEvent(
    $filter: ModelSubscriptionArchiveEventFilterInput
  ) {
    onUpdateArchiveEvent(filter: $filter) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteArchiveEvent = /* GraphQL */ `
  subscription OnDeleteArchiveEvent(
    $filter: ModelSubscriptionArchiveEventFilterInput
  ) {
    onDeleteArchiveEvent(filter: $filter) {
      id
      schemeId
      deviceId
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      eventType
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateValidation = /* GraphQL */ `
  subscription OnCreateValidation(
    $filter: ModelSubscriptionValidationFilterInput
  ) {
    onCreateValidation(filter: $filter) {
      model
      validation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateValidation = /* GraphQL */ `
  subscription OnUpdateValidation(
    $filter: ModelSubscriptionValidationFilterInput
  ) {
    onUpdateValidation(filter: $filter) {
      model
      validation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteValidation = /* GraphQL */ `
  subscription OnDeleteValidation(
    $filter: ModelSubscriptionValidationFilterInput
  ) {
    onDeleteValidation(filter: $filter) {
      model
      validation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateModbusConfig = /* GraphQL */ `
  subscription OnCreateModbusConfig(
    $filter: ModelSubscriptionModbusConfigFilterInput
  ) {
    onCreateModbusConfig(filter: $filter) {
      deviceType
      modbusTemplate
      statusBits
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateModbusConfig = /* GraphQL */ `
  subscription OnUpdateModbusConfig(
    $filter: ModelSubscriptionModbusConfigFilterInput
  ) {
    onUpdateModbusConfig(filter: $filter) {
      deviceType
      modbusTemplate
      statusBits
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteModbusConfig = /* GraphQL */ `
  subscription OnDeleteModbusConfig(
    $filter: ModelSubscriptionModbusConfigFilterInput
  ) {
    onDeleteModbusConfig(filter: $filter) {
      deviceType
      modbusTemplate
      statusBits
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateReport = /* GraphQL */ `
  subscription OnCreateReport($filter: ModelSubscriptionReportFilterInput) {
    onCreateReport(filter: $filter) {
      id
      type
      measureName
      allDevices
      devices
      from
      to
      aggregateType
      interval
      relative
      public
      name
      desc
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateReport = /* GraphQL */ `
  subscription OnUpdateReport($filter: ModelSubscriptionReportFilterInput) {
    onUpdateReport(filter: $filter) {
      id
      type
      measureName
      allDevices
      devices
      from
      to
      aggregateType
      interval
      relative
      public
      name
      desc
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteReport = /* GraphQL */ `
  subscription OnDeleteReport($filter: ModelSubscriptionReportFilterInput) {
    onDeleteReport(filter: $filter) {
      id
      type
      measureName
      allDevices
      devices
      from
      to
      aggregateType
      interval
      relative
      public
      name
      desc
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCommsTracker = /* GraphQL */ `
  subscription OnCreateCommsTracker(
    $filter: ModelSubscriptionCommsTrackerFilterInput
  ) {
    onCreateCommsTracker(filter: $filter) {
      id
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCommsTracker = /* GraphQL */ `
  subscription OnUpdateCommsTracker(
    $filter: ModelSubscriptionCommsTrackerFilterInput
  ) {
    onUpdateCommsTracker(filter: $filter) {
      id
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCommsTracker = /* GraphQL */ `
  subscription OnDeleteCommsTracker(
    $filter: ModelSubscriptionCommsTrackerFilterInput
  ) {
    onDeleteCommsTracker(filter: $filter) {
      id
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAlarmGroupsInput = /* GraphQL */ `
  subscription OnCreateAlarmGroupsInput(
    $filter: ModelSubscriptionAlarmGroupsInputFilterInput
  ) {
    onCreateAlarmGroupsInput(filter: $filter) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAlarmGroupsInput = /* GraphQL */ `
  subscription OnUpdateAlarmGroupsInput(
    $filter: ModelSubscriptionAlarmGroupsInputFilterInput
  ) {
    onUpdateAlarmGroupsInput(filter: $filter) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAlarmGroupsInput = /* GraphQL */ `
  subscription OnDeleteAlarmGroupsInput(
    $filter: ModelSubscriptionAlarmGroupsInputFilterInput
  ) {
    onDeleteAlarmGroupsInput(filter: $filter) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAlarmGroupsOutput = /* GraphQL */ `
  subscription OnCreateAlarmGroupsOutput(
    $filter: ModelSubscriptionAlarmGroupsOutputFilterInput
  ) {
    onCreateAlarmGroupsOutput(filter: $filter) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAlarmGroupsOutput = /* GraphQL */ `
  subscription OnUpdateAlarmGroupsOutput(
    $filter: ModelSubscriptionAlarmGroupsOutputFilterInput
  ) {
    onUpdateAlarmGroupsOutput(filter: $filter) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAlarmGroupsOutput = /* GraphQL */ `
  subscription OnDeleteAlarmGroupsOutput(
    $filter: ModelSubscriptionAlarmGroupsOutputFilterInput
  ) {
    onDeleteAlarmGroupsOutput(filter: $filter) {
      id
      deviceID
      alarmGroupID
      device {
        id
        name
        reported_state
        desired_state
        desired_state_info
        modbus_state
        alarmGroupsInput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        alarmGroupsOutput {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        inputDevice
        outputDevice
        alert {
          id
          sensor_state
          device {
            id
            name
            reported_state
            desired_state
            desired_state_info
            modbus_state
            alarmGroupsInput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroupsOutput {
              items {
                id
                deviceID
                alarmGroupID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            inputDevice
            outputDevice
            alert {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            liveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            archiveEvent {
              items {
                id
                schemeId
                deviceId
                eventType
                duration
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            schemeId
            scheme {
              id
              name
              users {
                nextToken
                __typename
              }
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              alarmGroups {
                nextToken
                __typename
              }
              sensorAlerts {
                nextToken
                __typename
              }
              userNotifyConfig {
                nextToken
                __typename
              }
              contact
              contactEmergency
              zoneConfig
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneId
            zone {
              id
              schemeId
              label
              type
              parent
              devices {
                nextToken
                __typename
              }
              devicesProvisioning {
                nextToken
                __typename
              }
              tenant
              createdBy
              updatedBy
              createdAt
              updatedAt
              __typename
            }
            zoneBreadcrumb
            installerId
            createdAt
            updatedAt
            __typename
          }
          schemeId
          scheme {
            id
            name
            users {
              items {
                id
                cognitoUserId
                schemeId
                isDefaultScheme
                role
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devices {
              items {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            devicesProvisioning {
              items {
                id
                name
                inputDevice
                outputDevice
                schemeId
                zoneId
                installerId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            alarmGroups {
              items {
                id
                name
                schemeId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            sensorAlerts {
              items {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              nextToken
              __typename
            }
            userNotifyConfig {
              items {
                id
                cognitoUserId
                schemeId
                config
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            contact
            contactEmergency
            zoneConfig
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          deviceSensorAlertDeviceId
          __typename
        }
        liveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        archiveEvent {
          items {
            id
            schemeId
            deviceId
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            eventType
            duration
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        scheme {
          id
          name
          users {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              isDefaultScheme
              role
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          alarmGroups {
            items {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          sensorAlerts {
            items {
              id
              sensor_state
              device {
                id
                name
                reported_state
                desired_state
                desired_state_info
                modbus_state
                inputDevice
                outputDevice
                schemeId
                zoneId
                zoneBreadcrumb
                installerId
                createdAt
                updatedAt
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              deviceSensorAlertDeviceId
              __typename
            }
            nextToken
            __typename
          }
          userNotifyConfig {
            items {
              id
              cognitoUserId
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              config
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          contact
          contactEmergency
          zoneConfig
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneId
        zone {
          id
          schemeId
          label
          type
          parent
          devices {
            items {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          devicesProvisioning {
            items {
              id
              name
              inputDevice
              outputDevice
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              installerId
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tenant
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        zoneBreadcrumb
        installerId
        createdAt
        updatedAt
        __typename
      }
      alarmGroup {
        id
        name
        input {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        output {
          items {
            id
            deviceID
            alarmGroupID
            device {
              id
              name
              reported_state
              desired_state
              desired_state_info
              modbus_state
              alarmGroupsInput {
                nextToken
                __typename
              }
              alarmGroupsOutput {
                nextToken
                __typename
              }
              inputDevice
              outputDevice
              alert {
                id
                sensor_state
                schemeId
                createdAt
                updatedAt
                deviceSensorAlertDeviceId
                __typename
              }
              liveEvent {
                nextToken
                __typename
              }
              archiveEvent {
                nextToken
                __typename
              }
              schemeId
              scheme {
                id
                name
                contact
                contactEmergency
                zoneConfig
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneId
              zone {
                id
                schemeId
                label
                type
                parent
                tenant
                createdBy
                updatedBy
                createdAt
                updatedAt
                __typename
              }
              zoneBreadcrumb
              installerId
              createdAt
              updatedAt
              __typename
            }
            alarmGroup {
              id
              name
              input {
                nextToken
                __typename
              }
              output {
                nextToken
                __typename
              }
              schemeId
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        schemeId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
