//Node Modules
import React from "react";
import ChangeHighlight from "react-change-highlight";

//Pages

//BinaryForge Components

//3rd Party Components

//Atoms

//Helpers

//Other

const Field = ({ label, labelClass, response, responseUnit, responseClass = [], highlight }) => {
	return highlight ? (
		<>
			<span className={`fontWeight-bold ${labelClass}`}>{label}:</span>
			<ChangeHighlight highlightClassName="highlight-onChange" containerClassName="field-value">
				<div ref={React.createRef()} className={`${responseClass.join(" ")}`}>
					{response}
					{responseUnit && (
						<span className="fontColour-lighter fontSize-small aSelf-end"> {responseUnit}</span>
					)}
				</div>
			</ChangeHighlight>
		</>
	) : (
		<>
			<span className={`fontWeight-bold ${labelClass}`}>{label}:</span>
			<div className="field-value">
				<div className={`${responseClass.join(" ")}`}>
					{response}
					{responseUnit && (
						<span className="fontColour-lighter fontSize-small aSelf-end"> {responseUnit}</span>
					)}
				</div>
			</div>
		</>
	);
};

export default Field;
