//Node Modules
import { useEffect, forwardRef, useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { listSchemes } from "../../graphql/queries";
import { createScheme, updateScheme } from "../../graphql/mutations";

//BinaryForge Components

//3rd Party Components
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

//Atoms
import { toastAtom } from "../../atoms/toast";
import { selectedSchemeAtom } from "../../atoms/scheme";
import { userSchemeOptionsAtom } from "../../atoms/user";
import { loggedInUserAtom } from "../../atoms";
import { validationAtomFamily } from "../../atoms/validation";

//Helpers
import { useApiRequest } from "../../helpers/Api";
import { getReturnPage } from "../../helpers/Scheme";
import { emailValidation, phoneValidation } from "../../config/user";
import { useValidateScheme } from "../../helpers/Validation";

//Other

const SchemeForm = ({ type, schemeData }, ref) => {
	// Hooks
	const { t } = useTranslation();
	const apiRequest = useApiRequest();
	const navigate = useNavigate();
	const validateScheme = useValidateScheme();

	// Recoil
	const [toast, setToast] = useRecoilState(toastAtom);
	const loggedInUser = useRecoilValue(loggedInUserAtom);
	const [userSchemeOptions, setUserSchemeOptions] = useRecoilState(userSchemeOptionsAtom);
	const setSelectedScheme = useSetRecoilState(selectedSchemeAtom);
	const schemeValidation = useRecoilValue(validationAtomFamily("scheme"));

	// Form Default Values
	const defaultValues = {
		name: "",
		contactName: "",
		contactEmail: "",
		contactPhone: "",
		contactEmName: "",
		contactEmEmail: "",
		contactEmPhone: "",
	};

	// Form Init
	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ defaultValues: defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	// Pre-populate the fields if this is an edit
	useEffect(() => {
		if (type === "edit" && schemeData) {
			const contactInfo = schemeData.contact ? schemeData.contact : {};
			const contactEmInfo = schemeData.contactEmergency ? schemeData.contactEmergency : {};
			const { name = "", email = "", phone = "" } = contactInfo;
			const { name: emName = "", email: emEmail = "", phone: emPhone = "" } = contactEmInfo;
			reset({
				name: schemeData.name,
				contactName: name,
				contactEmail: email,
				contactPhone: phone,
				contactEmName: emName,
				contactEmEmail: emEmail,
				contactEmPhone: emPhone,
			});
		}
	}, [schemeData, type]);

	useImperativeHandle(ref, () => ({
		submitSchemeForm() {
			handleSubmit(onSubmit)();
		},
	}));

	const onSubmit = async (data) => {
		let navigateTo = null;
		const contactJSON = JSON.stringify({
			name: data.contactName,
			email: data.contactEmail,
			phone: data.contactPhone,
		});
		const contactEmJSON = JSON.stringify({
			name: data.contactEmName,
			email: data.contactEmEmail,
			phone: data.contactEmPhone,
		});
		try {
			await validateScheme(data.name, schemeData ? schemeData.id : "");
			if (type === "create") {
				const {
					data: { createScheme: newScheme },
				} = await API.graphql(
					graphqlOperation(createScheme, {
						input: {
							name: data.name,
							contact: contactJSON,
							contactEmergency: contactEmJSON,
							createdBy: loggedInUser.sub,
						},
					})
				);

				//Get the page we should navigate to after complete
				navigateTo = getReturnPage(type, { id: newScheme.id });

				await apiRequest(
					"post",
					"/scheme",
					{ id: newScheme.id, name: data.name },
					t("admin.scheme.create.loading")
				);

				if (!userSchemeOptions && loggedInUser.role === "ADMINISTRATOR") {
					const {
						data: {
							listSchemes: { items: adminSchemes },
						},
					} = await API.graphql(graphqlOperation(listSchemes));
					const userSchemeOptions = adminSchemes.map((s) => ({
						id: s.id,
						name: s.name,
						role: "ADMINISTRATOR",
					}));
					setUserSchemeOptions(userSchemeOptions);
				}
			} else {
				const {
					data: { updateScheme: updatedScheme },
				} = await API.graphql(
					graphqlOperation(updateScheme, {
						input: {
							id: schemeData.id,
							name: data.name,
							contact: contactJSON,
							contactEmergency: contactEmJSON,
							updatedBy: loggedInUser.sub,
						},
					})
				);

				setSelectedScheme(updatedScheme);
				navigateTo = getReturnPage(type, { id: updatedScheme.id });

				await apiRequest(
					"put",
					`/scheme/${updatedScheme.id}`,
					{ name: data.name },
					t("admin.scheme.edit.loading")
				);
			}

			setToast({
				...toast,
				severity: "success",
				summary: t(`admin.scheme.${type}.toast.successSummary`),
				detail: t(`admin.scheme.${type}.toast.successDetail`, { name: data.name }),
			});

			navigate(navigateTo, { replace: true });
		} catch (err) {
			console.error(err);
			const errMsg = err.response ? err.response.data.message : err.message;
			setToast({
				...toast,
				severity: "error",
				summary: t(`admin.scheme.${type}.toast.errorSummary`),
				detail: t(`admin.scheme.${type}.toast.errorDetail`, {
					error: errMsg,
				}),
			});
		}
	};

	return (
		<form>
			<div className="formField">
				<label htmlFor="name">{t("admin.scheme.create.form.name.label")}</label>
				<Controller
					name="name"
					control={control}
					rules={{
						required: t("common.form.required"),
						maxLength: {
							value: schemeValidation["maxLength"],
							message: t("validation.scheme.maxLength", {
								length: schemeValidation["maxLength"],
							}),
						},
						pattern: {
							value: RegExp(schemeValidation["pattern"]),
							message: t("validation.scheme.pattern"),
						},
					}}
					render={({ field, fieldState }) => (
						<InputText {...field} id={field.name} className={classNames({ "p-error": fieldState.error })} />
					)}
				/>
				{getFormErrorMessage("name")}
			</div>
			<div className="grid columns-2 gap-large marginTop-large">
				<section>
					<header>
						<h3>{t("admin.scheme.create.form.contact.header")}</h3>
					</header>
					<div className="formFieldsWrapper">
						<div className="formField">
							<label htmlFor="contactName">{t("admin.scheme.create.form.contact.name.label")}</label>
							<Controller
								name="contactName"
								control={control}
								rules={{
									required: t("common.form.required"),
								}}
								render={({ field, fieldState }) => (
									<InputText
										{...field}
										id={field.name}
										// readOnly={type === "create" ? false : true}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("contactName")}
						</div>
						<div className="formField">
							<label htmlFor="contactEmail">{t("admin.scheme.create.form.contact.email.label")}</label>
							<Controller
								name="contactEmail"
								control={control}
								rules={{
									required: t("common.form.required"),
									pattern: {
										value: emailValidation,
										message: t("admin.scheme.create.form.contact.email.pattern"),
									},
								}}
								render={({ field, fieldState }) => (
									<InputText
										{...field}
										id={field.name}
										// readOnly={type === "create" ? false : true}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("contactEmail")}
						</div>
						<div className="formField">
							<label htmlFor="contactPhone">{t("admin.scheme.create.form.contact.phone.label")}</label>
							<Controller
								name="contactPhone"
								control={control}
								rules={{
									required: t("common.form.required"),
									pattern: {
										value: phoneValidation,
										message: t("admin.scheme.create.form.contact.phone.pattern"),
									},
								}}
								render={({ field, fieldState }) => (
									<InputText
										{...field}
										id={field.name}
										// readOnly={type === "create" ? false : true}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("contactPhone")}
						</div>
					</div>
				</section>
				<section>
					<header>
						<h3>{t("admin.scheme.create.form.contactEm.header")}</h3>
					</header>
					<div className="formFieldsWrapper">
						<div className="formField">
							<label htmlFor="contactEmName">{t("admin.scheme.create.form.contact.name.label")}</label>
							<Controller
								name="contactEmName"
								control={control}
								rules={{
									required: t("common.form.required"),
								}}
								render={({ field, fieldState }) => (
									<InputText
										{...field}
										id={field.name}
										// readOnly={type === "create" ? false : true}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("contactEmName")}
						</div>
						<div className="formField">
							<label htmlFor="contactEmEmail">{t("admin.scheme.create.form.contact.email.label")}</label>
							<Controller
								name="contactEmEmail"
								control={control}
								rules={{
									required: t("common.form.required"),
									pattern: {
										value: emailValidation,
										message: t("admin.scheme.create.form.contact.email.pattern"),
									},
								}}
								render={({ field, fieldState }) => (
									<InputText
										{...field}
										id={field.name}
										// readOnly={type === "create" ? false : true}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("contactEmEmail")}
						</div>
						<div className="formField">
							<label htmlFor="contactEmPhone">{t("admin.scheme.create.form.contact.phone.label")}</label>
							<Controller
								name="contactEmPhone"
								control={control}
								rules={{
									required: t("common.form.required"),
									pattern: {
										value: phoneValidation,
										message: t("admin.scheme.create.form.contact.phone.pattern"),
									},
								}}
								render={({ field, fieldState }) => (
									<InputText
										{...field}
										id={field.name}
										// readOnly={type === "create" ? false : true}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("contactEmPhone")}
						</div>
					</div>
				</section>
			</div>
		</form>
	);
};

export default forwardRef(SchemeForm);
