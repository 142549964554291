//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const PageHelmet = ({ title, desc }) => {
	const { t } = useTranslation();
	const metaDesc = desc ? desc : t("common.metaDesc");

	return (
		<Helmet>
			<title>
				{t("common.appName")} | {title}
			</title>
			<meta name="description" content={metaDesc} />
		</Helmet>
	);
};

export default PageHelmet;
