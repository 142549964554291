//Node Modules
import { useTranslation } from "react-i18next";
import { useRecoilValueLoadable } from "recoil";

//Pages

//BinaryForge Components
import { SchemeListTable } from "./";
import { Note } from "../../helpers";
import { DatatableSkeleton } from "../../helpers/skeleton";

//3rd Party Components

//Atoms
import { schemesSelector } from "../../../atoms/scheme";

//Helpers

//Other

const Scheme = () => {
	//Hooks
	const { t } = useTranslation();

	//Recoil
	const { state, contents } = useRecoilValueLoadable(schemesSelector);

	switch (state) {
		case "hasError":
			return (
				<Note
					messageKey={`Error: ${contents.message ? contents.message : contents}`}
					icon="pi-exclamation-circle fontColour-error"
				/>
			);
		case "loading":
			return <DatatableSkeleton columns={[t("admin.scheme.common.name")]} />;
		case "hasValue":
			return <SchemeListTable schemeData={contents} />;
		default:
			return null;
	}
};

export default Scheme;
