// Node Modules
import { createBrowserRouter } from "react-router-dom";

//Pages
import App from "../App";
import { SchemeSelect, VerifyAttribute, ErrorBoundary } from "../pages/combo";
import { Login, CompleteRegistration, ForgotPassword, MfaChallenge } from "../pages/guest";
import { Home, Report, StyleGuide } from "../pages/auth";
import { User, UserManagement, UserDetails, UserCreate, UserEdit, Profile } from "../pages/auth/user";
import {
	Alarm,
	AlarmAlerts,
	AlarmGroupManagement,
	AlarmGroupCreate,
	AlarmGroupEdit,
	AlarmGroupDetails,
} from "../pages/auth/alarm";
import { Device, DeviceManagement, DeviceDetails } from "../pages/auth/device/index.js";
import { Zones, ZoneManagement } from "../pages/auth/zones";
import { Admin } from "../pages/auth/admin";
import { AdminUser, AdminUserManagement } from "../pages/auth/admin/user";
import { Scheme, SchemeManagement, SchemeCreate, SchemeDetails, SchemeEdit } from "../pages/auth/admin/scheme";
import { Ota, OtaManagement, OtaDetails } from "../pages/auth/admin/ota";

//Helpers
import { AuthRoute, GuestRoute } from "../components/helpers";
import Break from "../components/helpers/Break";
import EventManagement from "../pages/auth/events/EventManagement";

export const nav = {
	guest: {
		login: "/login",
		logout: "/login",
		completeReg: "/completeRegistration",
		forgotPassword: "/forgotPassword",
		mfa: "/mfaChallenge",
	},
	combo: {
		notfound: "/notFound",
		verifyAttribute: "/verifyAttribute",
		schemeSelect: "/schemeSelection",
	},
	home: "/dashboard",
	alarm: {
		base: "/alarms",
		alerts: "alerts",
		group: {
			base: "group",
			management: "management",
			details: ":groupId",
			create: "create",
			edit: "edit",
		},
	},
	device: {
		base: "/device",
		management: "management",
		details: ":deviceId",
	},
	event: {
		base: "/event",
		management: "management",
	},
	report: "/report",
	zones: {
		base: "/zones",
		management: "management",
	},
	user: {
		base: "/user",
		management: "management",
		details: ":username",
		create: "create",
		edit: "edit",
	},
	profile: "/profile",
	admin: {
		base: "/admin",
		user: {
			base: "user",
			management: "management",
			details: ":username",
			create: "create",
			edit: "edit",
		},
		scheme: {
			base: "scheme",
			management: "management",
			details: ":schemeId",
			create: "create",
			edit: "edit",
		},
		ota: {
			base: "ota",
			management: "management",
			details: ":otaId",
		},
	},
	style: "/style",
};

export const guestRoutes = Object.values(nav.guest);
export const comboRoutes = Object.values(nav.combo);

export const adminRoutes = [
	"/",
	nav.home,
	nav.alarm.base,
	nav.device.base,
	nav.event.base,
	nav.report,
	nav.zones.base,
	nav.user.base,
	nav.profile,
	nav.admin.base,
	nav.style,
];
export const ownerRoutes = [
	"/",
	nav.home,
	nav.alarm.base,
	nav.device.base,
	nav.event.base,
	nav.report,
	nav.zones.base,
	nav.user.base,
	nav.profile,
	nav.style,
];

export const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: "/break-0123456789",
				element: <Break />,
			},
			{
				path: nav.combo.verifyAttribute,
				element: <VerifyAttribute />,
			},
			{
				path: nav.combo.schemeSelect,
				element: <SchemeSelect />,
			},
			{
				element: <GuestRoute />,
				children: [
					{
						path: nav.guest.login,
						element: <Login />,
					},
					{
						path: nav.guest.completeReg,
						element: <CompleteRegistration />,
					},
					{
						path: nav.guest.forgotPassword,
						element: <ForgotPassword />,
					},
					{
						path: nav.guest.mfa,
						element: <MfaChallenge />,
					},
				],
			},
			{
				element: <AuthRoute />,
				children: [
					{
						index: true,
						element: <Home />,
					},
					{
						path: nav.home,
						element: <Home />,
					},
					{
						path: nav.alarm.base,
						element: <Alarm />,
						children: [
							{
								path: nav.alarm.alerts,
								element: <AlarmAlerts />,
							},
							{
								path: `${nav.alarm.group.base}/${nav.alarm.group.management}`,
								element: <AlarmGroupManagement />,
							},
							{
								path: `${nav.alarm.group.base}/${nav.alarm.group.details}`,
								element: <AlarmGroupDetails />,
								loader: ({ params }) => {
									return params;
								},
							},
							{
								path: `${nav.alarm.group.base}/${nav.alarm.group.create}`,
								element: <AlarmGroupCreate />,
							},
							{
								path: `${nav.alarm.group.base}/${nav.alarm.group.edit}/:alarmGroupId`,
								element: <AlarmGroupEdit />,
								loader: ({ params }) => {
									return params;
								},
							},
						],
					},
					{
						path: nav.device.base,
						element: <Device />,
						children: [
							{
								path: nav.device.management,
								element: <DeviceManagement />,
							},
							{
								path: nav.device.details,
								element: <DeviceDetails />,
								loader: ({ params }) => {
									return params;
								},
							},
						],
					},
					{
						path: nav.event.base,
						// element: <Event />,
						children: [
							{
								path: nav.event.management,
								element: <EventManagement />,
							},
						],
					},
					{
						path: nav.report,
						element: <Report />,
					},
					{
						path: nav.zones.base,
						element: <Zones />,
						children: [
							{
								path: nav.zones.management,
								element: <ZoneManagement />,
								loader: ({ params }) => {
									return params;
								},
							},
						],
					},
					{
						path: nav.user.base,
						element: <User />,
						children: [
							{
								path: nav.user.management,
								element: <UserManagement />,
							},
							{
								path: nav.user.details,
								element: <UserDetails />,
								loader: ({ params }) => {
									return params;
								},
							},
							{
								path: nav.user.create,
								element: <UserCreate />,
								loader: () => {
									return { isAdmin: false };
								},
							},
							{
								path: `${nav.user.edit}/:username`,
								element: <UserEdit />,
								loader: ({ params }) => {
									return { ...params, isAdmin: false };
								},
							},
						],
					},
					{
						path: nav.profile,
						element: <Profile />,
					},
					{
						path: nav.admin.base,
						element: <Admin />,
						children: [
							{
								path: nav.admin.user.base,
								element: <AdminUser />,
								children: [
									{
										path: nav.admin.user.management,
										element: <AdminUserManagement />,
									},
									{
										path: nav.admin.user.details,
										element: <UserDetails />,
										loader: ({ params }) => {
											return { ...params, isAdmin: true };
										},
									},
									{
										path: nav.admin.user.create,
										element: <UserCreate />,
										loader: () => {
											return { isAdmin: true };
										},
									},
									{
										path: `${nav.admin.user.edit}/:username`,
										element: <UserEdit />,
										loader: ({ params }) => {
											return { ...params, isAdmin: true };
										},
									},
								],
							},
							{
								path: nav.admin.scheme.base,
								element: <Scheme />,
								children: [
									{
										path: nav.admin.scheme.management,
										element: <SchemeManagement />,
									},
									{
										path: nav.admin.scheme.details,
										element: <SchemeDetails />,
										loader: ({ params }) => {
											return { ...params };
										},
									},
									{
										path: nav.admin.scheme.create,
										element: <SchemeCreate />,
									},
									{
										path: `${nav.admin.scheme.edit}/:schemeId`,
										element: <SchemeEdit />,
										loader: ({ params }) => {
											return { ...params };
										},
									},
								],
							},
							{
								path: nav.admin.ota.base,
								element: <Ota />,
								children: [
									{
										path: nav.admin.ota.management,
										element: <OtaManagement />,
									},
									{
										path: nav.admin.ota.details,
										element: <OtaDetails />,
										loader: ({ params }) => {
											return params;
										},
									},
								],
							},
						],
					},
					{
						path: nav.style,
						element: <StyleGuide />,
					},
				],
			},
		],
	},
]);
