import ReportPresetsLoader from "./ReportPresetsLoader";

const ReportPresets = () => {
	return (
		<>
			<ReportPresetsLoader />
		</>
	);
};

export default ReportPresets;
