import { useRef } from "react";
import { Tooltip } from "primereact/tooltip";
import { v4 as uuid } from "uuid";

const DatatableItem = ({ value, type, wrapperStyle = "blue1", icon, tooltip }) => {
	const uniqueId = useRef(uuid());

	return (
		<>
			<Tooltip target={`.customTooltip-${uniqueId.current}`} />
			<div
				className={`flex gap-xsmall fontSize-small ${wrapperStyle} pillItem customTooltip-${uniqueId.current}`}
				data-pr-tooltip={tooltip}>
				{type !== "text" && <i className={`pi ${icon}`} />}
				{type !== "icon" && <span>{value}</span>}
			</div>
		</>
	);
};

export default DatatableItem;
