//Node Modules
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useForm, Controller } from "react-hook-form";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { updateDevice } from "../../graphql/mutations";

//Pages

//BinaryForge Components
import { BfDialog } from "../helpers";

//3rd Party Components
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

//Atoms
import { dialogAtomFamily, loggedInUserAtom } from "../../atoms";

//Helpers
import { useApiRequest } from "../../helpers/Api";
import { intervalTypeOptions } from "../../config/device";

//Other
const dialogElement = document.getElementById("configUpdateDialog");

const ConfigUpdateDialog = ({ deviceId, deviceData, desiredStateInfo }) => {
	//Hooks
	const { t } = useTranslation();
	const apiRequest = useApiRequest();

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("configUpdateDialog"));
	const loggedInUser = useRecoilValue(loggedInUserAtom);

	// Local State
	const [maxWakeInterval, setMaxWakeInterval] = useState(24 * 60 * 60);
	const [maxKeepaliveInterval, setMaxKeepaliveInterval] = useState(24 * 60 * 60);

	// Form Default Values
	const defaultValues = {
		wakeInterval: deviceData.wake_interval,
		wakeIntervalType: intervalTypeOptions[0].value,
		keepaliveInterval: deviceData.keepalive_interval,
		keepaliveIntervalType: intervalTypeOptions[0].value,
	};

	// Form Init
	const {
		control,
		watch,
		formState: { errors },
		handleSubmit,
	} = useForm({ defaultValues: defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	const wakeIntervalTypeWatcher = watch("wakeIntervalType");
	const keepaliveIntervalTypeWatcher = watch("keepaliveIntervalType");

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	//Submit the Form
	const onSubmit = async (data) => {
		let wakeIntervalSeconds = data.wakeInterval;
		let keepaliveIntervalSeconds = data.keepaliveInterval;
		let desiredParams = {};
		let desiredInfo = {};

		try {
			if (data.wakeIntervalType === "minute") wakeIntervalSeconds = data.wakeInterval * 60;
			if (data.wakeIntervalType === "hour") wakeIntervalSeconds = data.wakeInterval * 60 * 60;
			if (data.keepaliveIntervalType === "minute") keepaliveIntervalSeconds = data.keepaliveInterval * 60;
			if (data.keepaliveIntervalType === "hour") keepaliveIntervalSeconds = data.keepaliveInterval * 60 * 60;

			console.log("wake ::", wakeIntervalSeconds, deviceData.wake_interval);

			if (wakeIntervalSeconds !== deviceData.wake_interval) {
				desiredParams.wake_interval = wakeIntervalSeconds;
				desiredInfo.wake_interval = {
					value: wakeIntervalSeconds,
					createdAt: new Date().getTime(),
					createdBy: loggedInUser.username,
				};
			}

			if (keepaliveIntervalSeconds !== deviceData.keepalive_interval) {
				desiredParams.keepalive_interval = keepaliveIntervalSeconds;
				desiredInfo.keepalive_interval = {
					value: keepaliveIntervalSeconds,
					createdAt: new Date().getTime(),
					createdBy: loggedInUser.username,
				};
			}

			if (Object.keys(desiredParams).length > 0) {
				const desiredState = {
					thingName: deviceId,
					desiredState: desiredParams,
				};

				await apiRequest("post", "/shadow/desired/update", desiredState, t("device.dialog.config.loading"));
				// console.log("Desired State ::", desiredState);
				// console.log("Existing Desired Info ::", desiredStateInfo);

				const updatedDesiredStateInfo = {
					...desiredStateInfo,
					...desiredInfo,
				};

				// console.log("Update Info ::", updatedDesiredStateInfo);

				await API.graphql(
					graphqlOperation(updateDevice, {
						input: {
							id: deviceId,
							desired_state_info: JSON.stringify(updatedDesiredStateInfo),
						},
					})
				);
				// console.log("Desired Info ::", updatedDesiredStateInfo);
			}

			setShow(false);
		} catch (err) {
			console.err(err.message);
		}
	};

	useEffect(() => {
		switch (wakeIntervalTypeWatcher) {
			case "second":
				setMaxWakeInterval(24 * 60 * 60);
				break;
			case "minute":
				setMaxWakeInterval(24 * 60);
				break;
			case "hour":
				setMaxWakeInterval(24);
				break;
			default:
				setMaxWakeInterval(24 * 60 * 60);
		}
	}, [wakeIntervalTypeWatcher]);

	useEffect(() => {
		switch (keepaliveIntervalTypeWatcher) {
			case "second":
				setMaxKeepaliveInterval(24 * 60 * 60);
				break;
			case "minute":
				setMaxKeepaliveInterval(24 * 60);
				break;
			case "hour":
				setMaxKeepaliveInterval(24);
				break;
			default:
				setMaxKeepaliveInterval(24 * 60 * 60);
		}
	}, [keepaliveIntervalTypeWatcher]);

	// Footer
	const footer = (
		<>
			<Button label={t("common.action.cancel")} onClick={() => setShow(false)} />
			<Button
				label={t("device.dialog.config.save")}
				icon="pi pi-save"
				className="feature"
				// disabled={!isValid}
				onClick={() => handleSubmit(onSubmit)()}
			/>
		</>
	);

	return (
		<BfDialog id="configUpdateDialog" header={t("device.dialog.config.header")} footer={footer}>
			<form>
				<div className="grid columns-2 gap-medium">
					<div className="formField">
						<label htmlFor="wakeInterval">{t("device.dialog.config.form.wake.label")}</label>
						<Controller
							name="wakeInterval"
							control={control}
							rules={{
								required: t("common.form.required"),
								max: {
									value: maxWakeInterval,
									message: t("device.dialog.config.form.wake.max", {
										type: wakeIntervalTypeWatcher,
										value: maxWakeInterval,
									}),
								},
							}}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<InputNumber
									{...myField}
									inputRef={ref}
									min={1}
									max={maxWakeInterval}
									showButtons={true}
									onChange={(e) => myField.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("wakeInterval")}
					</div>
					<div className="formField">
						<label htmlFor="wakeIntervalType">{t("device.dialog.config.form.intervalType.label")}</label>
						<Controller
							name="wakeIntervalType"
							control={control}
							rules={{ required: t("common.form.required") }}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<Dropdown
									appendTo={dialogElement}
									{...myField}
									inputRef={ref}
									options={intervalTypeOptions}
									onChange={(e) => myField.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("wakeIntervalType")}
					</div>
				</div>

				<div className="grid columns-2 gap-medium">
					<div className="formField">
						<label htmlFor="keepaliveInterval">{t("device.dialog.config.form.keepalive.label")}</label>
						<Controller
							name="keepaliveInterval"
							control={control}
							rules={{
								required: t("common.form.required"),
								max: {
									value: maxKeepaliveInterval,
									message: t("device.dialog.config.form.keepalive.max", {
										type: keepaliveIntervalTypeWatcher,
										value: maxKeepaliveInterval,
									}),
								},
							}}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<InputNumber
									{...myField}
									inputRef={ref}
									min={1}
									max={maxKeepaliveInterval}
									showButtons={true}
									onChange={(e) => myField.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("keepaliveInterval")}
					</div>
					<div className="formField">
						<label htmlFor="keepaliveIntervalType">
							{t("device.dialog.config.form.intervalType.label")}
						</label>
						<Controller
							name="keepaliveIntervalType"
							control={control}
							rules={{ required: t("common.form.required") }}
							render={({ field: { ref, ...myField }, fieldState }) => (
								<Dropdown
									appendTo={dialogElement}
									{...myField}
									inputRef={ref}
									options={intervalTypeOptions}
									onChange={(e) => myField.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("keepaliveIntervalType")}
					</div>
				</div>
			</form>
		</BfDialog>
	);
};

export default ConfigUpdateDialog;
