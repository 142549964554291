export const admin = {
	user: {
		management: {
			pageTitle: "Admin Management",
			actions: {
				create: "Create Admin",
			},
		},
		details: {
			pageTitle: "Admin Details",
			actions: {
				edit: "Edit Admin",
			},
		},
		create: {
			pageTitle: "Create Admin",
			actions: {
				submit: "Create Admin",
			},
		},
		edit: {
			pageTitle: "Edit Admin",
		},
		dupeAdmin: {
			dialog: {
				header: "User Exists as Admin",
				message:
					"A user with the email {{email}} already exists as a global Administrator and cannot be created as an $t(user.role.{{role}}) in this scheme.",
			},
		},
		dupeStandard: {
			dialog: {
				header: "User Exists as Non-Admin",
				message:
					"A user with the email {{email}} already exists as a non-admin user assigned to 1 or more schemes and cannot be created as an Admin. To create Admin the existing user account be deleted from all existing schemes.",
			},
		},
		promote: {
			header: "Promote to Admin",
			message:
				"A user with the email {{email}} already exists as a standard user assigned to one or more schemes. Would you like to promote this user to an Administrator? The user will be promoted with their current username and fullname.",
			warning:
				"If you choose to promote the user they will be removed as a standard user from all their current schemes and have global administrator access to all schemes. This operation can only be reversed by deleting the user as an Administrator and recreating them in each scheme.",
			accept: "Promote",
			reject: "Cancel",
			loading: "Promoting User to Admin",
			toast: {
				successSummary: "User Promoted",
				successDetail: "The user {{username}} has successfully being promoted to an Administrator.",
				errorSummary: "User Promote Error",
				errorDetail: 'Could not promote the user. $t({{error}}, {"user": {{data}} })',
			},
		},
	},
	scheme: {
		common: {
			name: "Scheme Name",
			id: "Scheme ID",
		},
		management: {
			pageTitle: "Scheme Management",
			actions: {
				create: "Create Scheme",
				details: "Scheme Details",
			},
		},
		details: {
			pageTitle: "Scheme Details",
			pageSubtitle: "Scheme Name: {{scheme}}",
			actions: {
				edit: "Edit Scheme",
				delete: "Delete Scheme",
			},
			users: {
				header: "Scheme Users",
				confirmNavigate: {
					header: "Confirm View User",
					message:
						"You are trying to view a user in a different scheme to the one you are currently logged in to. In order to view the user details you will need to switch scheme, would you like to continue?",
					accept: "Yes, Switch Scheme",
					reject: "No, Stay Here",
				},
			},
			contact: {
				notSet: "Not Set",
			},
		},
		create: {
			pageTitle: "Create Scheme",
			loading: "Creating Scheme",
			actions: {
				submit: "Create Scheme",
			},
			form: {
				name: {
					label: "Scheme Name",
				},
				contact: {
					header: "Contact",
					name: {
						label: "Name",
					},
					email: {
						label: "Email",
						pattern: "Please enter a valid email address",
					},
					phone: {
						label: "Phone",
						pattern: "Please enter a valid phone number",
					},
				},
				contactEm: {
					header: "Emergency Contact",
				},
			},
			toast: {
				successSummary: "Scheme Created",
				successDetail: "",
				errorSummary: "Scheme Creation Failed",
				errorDetail: "$t({{error}})",
			},
		},
		edit: {
			pageTitle: "Edit Scheme",
			pageSubtitle: "",
			loading: "Updating Scheme",
			actions: {
				submit: "Save Scheme",
			},
			toast: {
				successSummary: "Scheme Updated",
				successDetail: "",
				errorSummary: "Scheme Update Failed",
				errorDetail: "$t({{error}})",
			},
		},
	},
	ota: {
		management: {
			pageTitle: "OTA Management",
			tab: {
				file: "Files",
				manage: "Manage Jobs",
			},
		},
		file: {
			table: {
				filename: "Filename",
				firmware: "Firmware Version",
				modified: "File Modified",
				uploadedBy: "Uploaded By",
			},
		},
		upload: {
			header: "Upload OTA File",
			upload: "Upload",
			loading: "Uploading File",
			empty: "No OTA file chosen",
			toast: {
				successSummary: "File Uploaded Successfully",
				successDetail: "",
				errorSummary: "File Upload Failed",
				errorDetail: "{{error}}",
			},
		},
		job: {
			table: {
				name: "Name",
				version: "Version",
				type: "Type",
				status: "Status",
			},
			actions: {
				details: "OTA Details",
			},
			create: {
				header: "Create OTA Job",
				loading: "Creating OTA Job",
				toast: {
					successSummary: "OTA Job Created Successfully",
					successDetail: "",
					errorSummary: "Failed to Create OTA Job",
					errorDetail: "{{error}}",
				},
				name: {
					label: "Name",
					pattern: "Name can only contain letters, numbers, and underscore (_)",
				},
				desc: {
					label: "Description",
					pattern: "Description contains disallowed characters.",
					maxLength: "Description cannot be longer than {{length}}",
				},
				version: {
					label: "Firmware Version",
					pattern:
						"Version can only contain numbers and period (.) and must be in the format N.N.N (where the last number is optional)",
				},
				type: {
					label: "OTA Type",
					placeholder: "Select an OTA Type",
				},
				file: {
					label: "OTA File",
				},
				targetType: {
					label: "Target Type",
				},
				target: {
					loading: "Fetching Target Options",
					label: "OTA Targets",
				},
				downgradable: {
					label: "Enable Downgrade",
				},
			},
			status: {
				IN_PROGRESS: "In Progress",
				CANCELED: "Cancelled",
				COMPLETED: "Completed",
				DELETION_IN_PROGRESS: "Delete In Progress",
				SCHEDULED: "Scheduled",
			},
			details: {
				pageTitle: "{{otaName}}",
				pageSubtitle: "OTA Details",
				loading: "Fetching OTA Details",
				description: "Description",
				version: "Version",
				type: "Type",
				status: "Status",
				downgrade: "Allow Downgrade",
				chart: {
					label: {
						numberOfCanceledThings: "Cancelled",
						numberOfFailedThings: "Failed",
						numberOfInProgressThings: "In Progress",
						numberOfQueuedThings: "Queued",
						numberOfRejectedThings: "Rejected",
						numberOfRemovedThings: "Removed",
						numberOfSucceededThings: "Succeeded",
						numberOfTimedOutThings: "Timed Out",
					},
				},
				execution: {
					queuedAt: "Queued At",
					status: {
						QUEUED: "Queued",
						IN_PROGRESS: "In Progress",
						SUCCEEDED: "Succeeded",
						FAILED: "Failed",
						TIMED_OUT: "Timed Out",
						REJECTED: "Rejected",
						REMOVED: "Removed",
						CANCELED: "Cancelled",
					},
				},
				cancel: {
					loading: "Cancelling OTA",
					confirm: {
						header: "Confirm Cancel OTA",
						message:
							"Are you sure you want to cancel {{name}} OTA? Once the cancellation procedure has begun it cannot be undone and any devices that have not yet accepted the OTA will no longer attempt to upgrade.",
						accept: "Cancel",
						reject: "",
					},
					toast: {
						successSummary: "OTA Job Successfully Cancelled",
						successDetail: "",
						errorSummary: "Failed to Cacnel OTA Job",
						errorDetail: "{{error}}",
					},
				},
			},
		},
		type: {
			PUBLIC: "Public",
			INTERNAL: "Internal",
			RETRY: "Retry",
		},
		targetType: {
			GROUP: "Group",
			DEVICE: "Devices",
		},
	},
};
