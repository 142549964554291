//Node Modules
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

//BinaryForge Components
import { BarChartCount } from "../";

//3rd Party Components

//Atoms
import { reportInstantParamsAtom } from "../../../atoms/report";

//Helpers

//Other

const ReportResultsChart = ({ reportResultsData }) => {
	//Hooks
	const { t } = useTranslation();

	//Recoil
	const reportParams = useRecoilValue(reportInstantParamsAtom);

	return reportParams.aggregateType === "INSTANT_DIMENSION" ? (
		<BarChartCount data={reportResultsData} measureName={t(`report.measure.${reportParams.measureName}`)} />
	) : (
		<div>Chart Coming Soon</div>
	);
};

export default ReportResultsChart;
