//Node Modules
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router";
import { useRecoilState } from "recoil";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../../components/layout";
import { ExectuionSummary } from "../../../../components/admin/ota";
import { Field } from "../../../../components";
import { DoughnutChart } from "../../../../components/report";
import { Note } from "../../../../components/helpers";

//3rd Party Components
import { confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";

//Atoms
import { toastAtom } from "../../../../atoms";

//Helpers
import { useApiRequest } from "../../../../helpers/Api";
import { getUiDateTime } from "../../../../helpers/DateUtils";

//Other

const OtaDetails = () => {
	//Hooks
	const { t } = useTranslation();
	const { otaId } = useLoaderData();
	const apiRequest = useApiRequest();

	//Recoil State
	const [toast, setToast] = useRecoilState(toastAtom);

	//Local State
	const [otaData, setOtaData] = useState();
	const [chartData, setChartData] = useState();

	useEffect(() => {
		fetchOtaDetails();
	}, []);

	const fetchOtaDetails = async () => {
		try {
			const otaDetails = await apiRequest("get", `/ota/${otaId}`, null, t("admin.ota.job.details.loading"));
			const state = {
				numberOfSucceededThings: otaDetails.jobProcessDetails.numberOfSucceededThings,
				numberOfInProgressThings: otaDetails.jobProcessDetails.numberOfInProgressThings,
				numberOfQueuedThings: otaDetails.jobProcessDetails.numberOfQueuedThings,
				numberOfFailedThings: otaDetails.jobProcessDetails.numberOfFailedThings,
				numberOfRejectedThings: otaDetails.jobProcessDetails.numberOfRejectedThings,
				numberOfCanceledThings: otaDetails.jobProcessDetails.numberOfCanceledThings,
				numberOfTimedOutThings: otaDetails.jobProcessDetails.numberOfTimedOutThings,
				numberOfRemovedThings: otaDetails.jobProcessDetails.numberOfRemovedThings,
			};
			const labels = Object.keys(state).map((label) => t(`admin.ota.job.details.chart.label.${label}`));
			otaDetails.jobProcessDetails.numberOfFailedThings = 2;
			setOtaData(otaDetails);
			setChartData({ labels, data: state });
		} catch (err) {}
	};

	const confirmCancelOta = () => {
		confirmDialog({
			header: t("admin.ota.job.details.cancel.confirm.header"),
			message: (
				<>
					<Note
						messageKey={t("admin.ota.job.details.cancel.confirm.message", { name: otaId.split("-").pop() })}
						icon="pi pi-info-circle fontColour-info"
					/>
				</>
			),
			acceptLabel: t("admin.ota.job.details.cancel.confirm.accept"),
			rejectLabel: t("admin.ota.job.details.cancel.confirm.reject"),
			acceptIcon: "pi pi-ban",
			acceptClassName: "error feature",
			accept: () => doCancelOta(),
			reject: () => {}, //Do Nothing,
		});
	};

	const doCancelOta = async () => {
		try {
			await apiRequest("post", `/ota/${otaId}/cancel`, null, t("admin.ota.job.details.cancel.loading"));

			setToast({
				...toast,
				life: 6000,
				severity: "success",
				summary: t("admin.ota.job.details.cancel.toast.successSummary"),
				detail: "",
			});
		} catch (err) {
			console.error(err);
			setToast({
				...toast,
				life: 6000,
				severity: "error",
				summary: t("admin.ota.job.details.cancel.toast.errorSummary"),
				detail: t("admin.ota.job.details.cancel.toast.errorSummary", { error: err.message }),
			});
		}
	};

	return (
		<>
			<PageHeader title={otaId.split("-").pop()} subtitle={t("admin.ota.management.pageTitle")} enableBack />
			<main className="appMain">
				<div className="appMain-content">
					<div className="flex jContent-end gap-small">
						<Button
							label={t("common.action.cancel")}
							className="feature error"
							disabled={otaData?.status !== "IN_PROGRESS"}
							onClick={() => confirmCancelOta()}
						/>
					</div>
					{chartData && (
						<>
							<section className="card">
								<Field label={t("admin.ota.job.details.description")} response={otaData.description} />
								<div className="grid columns-3 gapCol-large marginTop-small aItems-start">
									<div className="grid columns-2-AutoFree gap-xsmall">
										<Field
											label={t("admin.ota.job.details.status")}
											response={t(`admin.ota.job.status.${otaData.status}`)}
										/>
									</div>
									<div className="grid columns-2-AutoFree gap-xsmall">
										<Field
											label={t("admin.ota.job.details.version")}
											response={otaData.additionalParameters.version}
										/>
										<Field
											label={t("admin.ota.job.details.type")}
											response={t(`admin.ota.type.${otaData.additionalParameters.type}`)}
										/>
										<Field
											label={t("admin.ota.job.details.downgrade")}
											response={otaData.additionalParameters.downgrade}
										/>
									</div>
									<div className="grid columns-2-AutoFree gap-xsmall">
										<Field
											label={t("common.createdAt")}
											response={getUiDateTime(otaData.createdAt)}
										/>
										<Field
											label={t("common.updatedAt")}
											response={getUiDateTime(otaData.lastUpdatedAt)}
										/>
									</div>
								</div>
							</section>
							<section className="grid columns-2 gap-large">
								<div className="card">
									<header>
										<h3>Execution Summary</h3>
									</header>
									<div className="summaryChart-wrapper">
										<div className="summaryChart-center">
											<div className="flexVert gapRow-xsmall text-center">
												<span>{otaData.executionSummaries.length}</span>
												<span className="fontColour-lightest fontSize-small">of</span>
												<span>{otaData.targetTotal}</span>
											</div>
										</div>
										<DoughnutChart
											labels={chartData.labels}
											chartData={[chartData.data]}
											measureName={["Device Count"]}
											legendId="otaThingLegend"
											colours="ota"
										/>
									</div>
									<div id="otaThingLegend" className="marginTop-small"></div>
								</div>
								<div className="card">
									<header>
										<h3>Execution List</h3>
									</header>
									<div className="flexVert gapRow-small">
										{otaData?.executionSummaries?.map((exe, idx) => (
											<ExectuionSummary key={`${exe.thingArn}-${idx}`} data={exe} />
										))}
									</div>
								</div>
							</section>
						</>
					)}
					{/* <pre>{JSON.stringify(otaData, null, 2)}</pre> */}
				</div>
			</main>
		</>
	);
};

export default OtaDetails;
