//Node Modules
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import _ from "lodash";

//Pages

//BinaryForge Components
import { ReportResultsLoader as ReportHistoricResultsLoader } from "../historical";
import { ReportResultsLoader as ReportInstantResultsLoader } from "../instant";
import { Field } from "../../";

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

//Atoms
import { reportParamsAtom, reportInstantParamsAtom } from "../../../atoms/report";

//Helpers
import { getIntervalHuman, getUiDateTime } from "../../../helpers/DateUtils";
import { filterApplyTemplate } from "../../../config/datatable";
import { PillItem } from "../../helpers";

//Other

const ReportPresetsTable = ({ reportPresetsData }) => {
	//Hooks
	const { t } = useTranslation();
	const dt = useRef(null);

	//Local State
	const [reports, setReports] = useState();
	const [selectedReport, setSelectedReport] = useState();

	//Recoil
	const [historicReportParams, setHistoricReportParams] = useRecoilState(reportParamsAtom);
	const [instantReportParams, setInstantReportParams] = useRecoilState(reportInstantParamsAtom);

	// Local State
	const [dtFilters, setDtFilters] = useState(null);

	//On Page/Data Load
	useEffect(() => {
		if (reportPresetsData) {
			setReports(reportPresetsData);
			initFilters();
		}
	}, [reportPresetsData]);

	//Datatable Functions
	const initFilters = () => {
		setDtFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
		});
	};

	const generateReport = () => {
		if (selectedReport.type === "HISTORICAL") {
			setInstantReportParams(null);
			setHistoricReportParams({ ...selectedReport, devices: JSON.parse(selectedReport.devices), generate: true });
		} else {
			setHistoricReportParams(null);
			setInstantReportParams({ ...selectedReport, devices: JSON.parse(selectedReport.devices), generate: true });
		}
	};

	const getTimestamp = (timeString) => {
		if (timeString) {
			if (timeString.startsWith("from")) {
				const iso = timeString.split("'")[1];
				return getUiDateTime(iso);
			} else {
				const seconds = timeString.substring(timeString.indexOf("(") + 1, timeString.lastIndexOf("s"));
				return `${getIntervalHuman(seconds)} ago`;
			}
		}
	};

	const rowTemplateMessageKey = (rowData, structure, key) => {
		return t(`report.${structure}.${rowData[key]}`);
	};

	return (
		<>
			<DataTable
				ref={dt}
				value={reports}
				emptyMessage={t("common.table.noData")}
				selectionMode="single"
				selection={selectedReport}
				onSelectionChange={(e) => setSelectedReport(e.value)}
				sortMode="multiple"
				removableSort
				filters={dtFilters}
				filterDisplay="menu"
				autoLayout={true}
				paginator
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
				currentPageReportTemplate={t("common.table.paginatorTemplate")}
				rows={10}
				dataKey="id">
				<Column
					field="name"
					header={t("report.preset.table.name")}
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="desc"
					header={t("report.preset.table.desc")}
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="type"
					header={t("report.preset.table.type")}
					body={(rowData) => rowTemplateMessageKey(rowData, "type", "type")}
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="measureName"
					header={t("report.preset.table.measure")}
					body={(rowData) => rowTemplateMessageKey(rowData, "measure", "measureName")}
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
			</DataTable>

			{selectedReport && (
				<section className="marginBlock-medium card">
					<div className="flex jContent-spaceBetween">
						<header>
							<h3>Report Details</h3>
						</header>
						<Button
							type="button"
							label={t("report.preset.generate")}
							className="feature"
							onClick={() => generateReport()}
						/>
					</div>

					<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall aItems-center">
						<Field label={t("report.preset.table.desc")} response={selectedReport?.desc} />
						<Field
							label={t("report.preset.table.type")}
							response={t(`report.type.${selectedReport?.type}`)}
						/>
						<Field
							label={t("report.form.measure.label")}
							response={t(`report.measure.${selectedReport?.measureName}`)}
						/>
						<Field
							label={t("report.form.devices.label")}
							response={
								selectedReport.allDevices ? (
									<PillItem key="all" type="text" value="All Devices" wrapperStyle="blue1" />
								) : (
									<div className="flex gap-xsmall">
										{JSON.parse(selectedReport?.devices).map((d) => (
											<PillItem key={d.id} type="text" value={d.name} wrapperStyle="blue1" />
										))}
									</div>
								)
							}
						/>
						{selectedReport.type === "HISTORICAL" && (
							<Field
								label={t("report.form.aggregate.label")}
								response={t(`report.aggregate.${selectedReport?.aggregateType}`)}
							/>
						)}
						{selectedReport.type === "HISTORICAL" && (
							<Field
								label={t("report.form.interval.label")}
								response={getIntervalHuman(selectedReport?.interval)}
							/>
						)}
						{selectedReport.type === "HISTORICAL" && (
							<Field label={t("report.form.from.label")} response={getTimestamp(selectedReport?.from)} />
						)}
						<Field label={t("report.form.to.label")} response={getTimestamp(selectedReport?.to)} />
						<Field
							label={t("report.preset.table.relative")}
							response={
								<PillItem
									type="text"
									value={t(`common.boolean.${selectedReport?.relative ? "yes" : "no"}`)}
									wrapperStyle={selectedReport?.relative ? "success" : "error"}
								/>
							}
						/>
						<Field
							label={t("report.preset.table.public")}
							response={
								<PillItem
									type="text"
									value={t(`common.boolean.${selectedReport?.public ? "yes" : "no"}`)}
									wrapperStyle={selectedReport?.public ? "success" : "error"}
								/>
							}
						/>
					</div>
				</section>
			)}

			{historicReportParams?.generate && (
				<ReportHistoricResultsLoader
					allDevices={selectedReport.allDevices}
					selectedDevices={JSON.parse(selectedReport.devices)}
					preset={true}
				/>
			)}
			{instantReportParams?.generate && <ReportInstantResultsLoader preset={true} />}
		</>
	);
};

export default ReportPresetsTable;
