//Node Modules
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useNavigate, Link } from "react-router-dom";
import { useRecoilState } from "recoil";

//Pages

//BinaryForge Components
import { ReactComponent as LogoHorizontal } from "../../assets/img/logo/logo-horizontal.svg";

//3rd Party Components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";

//Atoms
import { toastAtom } from "../../atoms/toast";

//Helpers
import { nav } from "../../config/navigation";

//Other

function VerifyAttribute() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	// Recoil State
	const [toast, setToast] = useRecoilState(toastAtom);

	// Local State
	const [user, setUser] = useState();

	//React Hook Form
	const defaultValues = {
		code: "",
		username: "",
		password: "",
	};
	const {
		control,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm({
		defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	useEffect(() => {
		const getCurrentUser = async () => {
			try {
				const currentUser = await Auth.currentAuthenticatedUser();
				setUser(currentUser);
			} catch (err) {
				console.warn("No Current User");
			}
		};

		getCurrentUser();
	}, []);

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	// Login Form Submit
	const doVerifyAttribute = async (data) => {
		try {
			if (user) {
				await Auth.verifyCurrentUserAttributeSubmit("email", data.code);
				await Auth.currentAuthenticatedUser({ bypassCache: true });
			} else {
				const newUser = await Auth.signIn(data.username, data.password);
				await Auth.verifyUserAttributeSubmit(newUser, "email", data.code);
				await Auth.signOut();
			}

			setToast({
				...toast,
				severity: "success",
				summary: t("profile.attributes.email.toast.successSummary"),
				detail: t("profile.attributes.email.toast.successDetail"),
			});

			navigate(nav.home);
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t("profile.attributes.email.toast.errorSummary"),
				detail: t("profile.attributes.email.toast.errorDetail", { error: err.message }),
			});
		}
	};

	return (
		<main className="appMain">
			<div className="appMain-content">
				<LogoHorizontal />
				<div className="appName marginTop-medium marginBottom-xlarge text-center">
					<span className="font-lighter text-uppercase">{t("common.appName")}</span>
				</div>

				<form onSubmit={handleSubmit(doVerifyAttribute)}>
					<div className="formFieldsWrapper">
						{!user && (
							<>
								<div className="formField">
									<label htmlFor="username">{t("user.common.username")}</label>
									<Controller
										name="username"
										control={control}
										rules={{ required: t("common.form.required") }}
										render={({ field, fieldState }) => (
											<InputText
												{...field}
												id={field.name}
												className={classNames({ "p-error": fieldState.error })}
											/>
										)}
									/>
									{getFormErrorMessage("username")}
								</div>
								<div className="formField">
									<label htmlFor="password">{t("user.common.password")}</label>
									<Controller
										name="password"
										control={control}
										rules={{ required: t("common.form.required") }}
										render={({ field, fieldState }) => (
											<Password
												{...field}
												inputId={field.name}
												feedback={false}
												toggleMask={true}
												className={classNames({ "p-error": fieldState.error })}
											/>
										)}
									/>
									{getFormErrorMessage("password")}
								</div>
							</>
						)}
						<div className="formField">
							<label htmlFor="code">{t("verifyAttribute.code")}</label>
							<Controller
								name="code"
								control={control}
								rules={{ required: t("common.form.required") }}
								render={({ field, fieldState }) => (
									<InputText
										{...field}
										id={field.name}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("code")}
						</div>
					</div>

					<Button
						label={isSubmitting ? t("verifyAttribute.submitting") : t("verifyAttribute.submit")}
						type="submit"
						className="feature"
						disabled={isSubmitting}
					/>
				</form>
				<div className="marginTop-small text-center">
					<Link to={-1} className="fontSize-small">
						{t("nav.backLong")}
					</Link>
				</div>
			</div>
		</main>
	);
}

export default VerifyAttribute;
