import {
	common,
	validation,
	nav,
	login,
	forgotPassword,
	verifyAttribute,
	schemeSelect,
	dashboard,
	alarm,
	devices,
	device,
	zones,
	user,
	profile,
	admin,
	error,
	events,
	report,
	datatable,
} from "./engb";

export const engb = {
	common: common,
	validation: validation,
	nav: nav,
	login: login,
	forgotPassword: forgotPassword,
	verifyAttribute: verifyAttribute,
	schemeSelect: schemeSelect,
	error: error,
	datatable: datatable,
	home: {
		pageTitle: "Home Page Title",
		pageSubtitle: "Home Page Subtitle",
	},
	dashboard: dashboard,
	alarms: {
		pageTitle: "Alarms",
		pageSubtitle: "",
		none: {
			message: "There are no devices in an alarm state.",
		},
	},
	alarm: alarm,
	devices: devices,
	device: device,
	events: events,
	report: report,
	groups: {
		pageTitle: "Groups",
		pageSubtitle: "Groups",
		actions: {
			groupDetails: "Group Details",
		},
		table: {
			name: "Name",
		},
	},
	group: {
		pageTitle: "{{groupName}}",
		pageSubtitle: "Group",
	},
	zones: zones,
	user: user,
	profile: profile,
	admin: admin,
	styles: {
		pageTitle: "Styles",
		pageSubtitle: "",
	},
};
