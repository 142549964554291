//Node Modules
import React, { useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

//Pages

//BinaryForge Components

//3rd Party Components
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-luxon";
import zoomPlugin from "chartjs-plugin-zoom";

//Atoms
import { reportParamsAtom } from "../../atoms/report";

//Services

//Helpers
import { chartStyleConfig, countBarChartConfig } from "../../config/chart";

//Other

const BarChartCount = ({ data, measureName }) => {
	//Hooks
	const { t } = useTranslation();
	const chart = useRef(null);
	Chart.register(...registerables, zoomPlugin);

	const getUpdatedOptions = useCallback(() => {
		let options = countBarChartConfig(measureName);
		return options;
	}, [data]);

	const createChart = useCallback(() => {
		const ctx = chart.current.getContext("2d");

		return new Chart(ctx, {
			type: "bar",
			data: {
				labels: Object.keys(data),
				datasets: [
					{
						label: measureName,
						data: Object.values(data),
						borderWidth: chartStyleConfig.bar.borderWidth,
						borderColor: chartStyleConfig.bar.borderColours,
						backgroundColor: chartStyleConfig.bar.backgroundColours,
					},
				],
			},
			options: getUpdatedOptions(),
		});
	}, [data, getUpdatedOptions]);

	useEffect(() => {
		// process.env.NODE_ENV === "development" && console.log("Graph Data:", data);
		let barChart;
		if (Object.keys(data).length > 0) {
			barChart = createChart();
		}

		return () => {
			if (barChart) barChart.destroy();
		};
	}, [createChart, data]);

	return (
		<div className="chartContainer">
			<canvas id="myChart" ref={chart}></canvas>
		</div>
	);
};

export default BarChartCount;
