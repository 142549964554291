//Node Modules
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

//GraphQL
import { API, graphqlOperation } from "aws-amplify";
import { getZoneBasic } from "../../graphql/queries-custom";

//BinaryForge Components
import { Field } from "../";
import Username from "../helpers/Username";

//3rd Party Components
import { Accordion, AccordionTab } from "primereact/accordion";

//Atoms
import { selectedZoneAtom, zoneNodesAtom } from "../../atoms/zones";

//Helpers
import { getUiDateTime } from "../../helpers/DateUtils";
import { findTreeNodeByKey, getAllChildZones } from "../../helpers/Zones";

//Other
import { nav } from "../../config/navigation";
import { zoneConfig } from "../../config/zones";

const ZoneDetails = ({ zoneData }) => {
	const { t } = useTranslation();

	//Recoil
	const nodes = useRecoilValue(zoneNodesAtom);
	const [selectedZone, setSelectedZone] = useRecoilState(selectedZoneAtom);

	//Local State
	const [childZoneDevices, setChildZoneDevices] = useState([]);
	const [childZoneDevicesCount, setChildZoneDevicesCount] = useState();

	useEffect(() => {
		if (zoneData) {
			setSelectedZone(zoneData);
			getNodeData(zoneData);
		}
	}, [zoneData]);

	const getNodeData = async (zoneData) => {
		const treeNode = await findTreeNodeByKey(nodes, zoneData.id);

		const childZones = [];
		await getAllChildZones(treeNode, childZones);

		const zoneDeviceArray = [];
		let deviceCount = 0;
		for await (const zone of childZones) {
			const {
				data: { getZone: zoneResp },
			} = await API.graphql(graphqlOperation(getZoneBasic, { id: zone }));
			const zoneDevices = zoneResp.devices?.items;
			if (zoneDevices.length) {
				deviceCount = deviceCount + zoneDevices.length;
				zoneDeviceArray.push({ zoneName: zoneResp.label, zoneType: zoneResp.type, zoneDevices: zoneDevices });
			}
		}

		setChildZoneDevicesCount(deviceCount);
		setChildZoneDevices(zoneDeviceArray);
	};

	return (
		selectedZone && (
			<div>
				<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall marginBottom-small">
					<Field label={t("zones.detail.name.label")} response={selectedZone?.label} />
					<Field
						label={t("zones.detail.type.label")}
						response={
							<div className="flex aItems-center gap-xsmall">
								<i className={`${zoneConfig[selectedZone.type].icon}`} />
								<span>{t(`zones.type.${selectedZone.type}`)}</span>
							</div>
						}
					/>
				</div>
				<div className="grid columns-2 gapCol-medium aItems-start">
					<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
						<Field label={t("common.createdBy")} response={<Username sub={selectedZone?.createdBy} />} />
						<Field label={t("common.createdAt")} response={getUiDateTime(selectedZone?.createdAt)} />
					</div>
					{selectedZone?.updatedBy && (
						<div className="grid columns-2-AutoFree gapCol-small gapRow-xsmall">
							<Field
								label={t("common.updatedBy")}
								response={<Username sub={selectedZone?.updatedBy} />}
							/>
							<Field label={t("common.updatedAt")} response={getUiDateTime(selectedZone?.updatedAt)} />
						</div>
					)}
				</div>

				<Accordion activeIndex={0} multiple className="marginTop-medium">
					<AccordionTab
						header={t("zones.detail.devices.title", { count: selectedZone?.devices?.items.length })}>
						<div className="flexVert gapRow-xsmall">
							{selectedZone?.devices?.items?.map((device) => (
								<Link key={device.id} to={`${nav.device.base}/${device.id}`}>
									{device.name}
								</Link>
							))}
						</div>
					</AccordionTab>
					<AccordionTab
						header={t("zones.detail.childDevices.title", { count: childZoneDevicesCount })}
						disabled={childZoneDevicesCount <= 0}>
						<div className="flexVert gapRow-xsmall">
							{childZoneDevices.length > 0 &&
								childZoneDevices.map((childZone) => (
									<div key={childZone.zoneName} className="flexVert">
										<span className="flex aItems-center gap-xsmall fontWeight-bold">
											<i className={`${zoneConfig[childZone.zoneType].icon}`} />
											{childZone.zoneName}
										</span>
										{childZone.zoneDevices.map((device) => (
											<Link key={device.id} to={`${nav.device.base}/${device.id}`}>
												{device.name}
											</Link>
										))}
									</div>
								))}
						</div>
					</AccordionTab>
					<AccordionTab
						header={t("zones.detail.tenants.title", {
							count: selectedZone.tenant ? selectedZone.tenant.length : 0,
						})}
						disabled={!selectedZone.tenant}>
						<div className="flexVert gapRow-xsmall">
							{selectedZone?.tenant?.length > 0 &&
								selectedZone.tenant.map((tenant) => <Username key={tenant} sub={tenant} link />)}
						</div>
					</AccordionTab>
				</Accordion>
			</div>
		)
	);
};

export default ZoneDetails;
