//Node Modules
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//3rd Party Componenets
import { Tooltip } from "primereact/tooltip";

//Atoms
import { toastAtom } from "../../atoms/toast";

const Copy = ({ message, json }) => {
	const { t } = useTranslation();

	const [toast, setToast] = useRecoilState(toastAtom);

	const copy = () => {
		navigator.clipboard.writeText(json ? JSON.stringify(message, null, 2) : message);
		setToast({
			...toast,
			severity: "success",
			summary: t("common.copy.successSummary"),
			detail: t("common.copy.successDetail"),
		});
	};

	return (
		<>
			<Tooltip target=".customTooltip" />
			<div
				className="text-copy customTooltip"
				onClick={() => copy()}
				data-pr-tooltip="Click to Copy"
				data-pr-my="left+8 center"
				data-pr-at="right center">
				<span>{json ? <pre>{JSON.stringify(message, null, 2)}</pre> : message}</span>
				<i className="pi pi-clone" />
			</div>
		</>
	);
};

export default Copy;
