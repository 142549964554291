//Node Modules
import { useState, useRef, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//BinaryForge Components
import { OtaFileUploadDialog } from "./";

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

//Atoms
import { dialogAtomFamily } from "../../../atoms";
import { selectedOtaFileAtom } from "../../../atoms/ota";

//Helpers
import {
	filterApplyTemplate,
	datetimeRowTemplate,
	dateFilterTemplate,
	numberMatchModeOptions,
} from "../../../config/datatable";
import { getVersionNumber } from "../../../helpers/general";

//Other

const FileList = ({ data }) => {
	//Hooks
	const { t } = useTranslation();
	const dt = useRef(null);

	//Recoil
	const [selected, setSelected] = useRecoilState(selectedOtaFileAtom);
	const setShowOtaFileUpload = useSetRecoilState(dialogAtomFamily("otaFileUploadDialog"));

	// Local State
	const [dtFilters, setDtFilters] = useState(null);

	const initFilters = () => {
		setDtFilters({
			filename: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			firmwareNumber: { value: null, matchMode: FilterMatchMode.CUSTOM },
			lastModified: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			uploadedByName: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
		});
	};

	useEffect(() => {
		if (data) {
			initFilters();
		}
	}, [data]);

	const versionFilterTemplate = (options) => {
		const [type, version] = options.value ?? [numberMatchModeOptions[0].value, ""];
		return (
			<div className="flexVert gapRow-small">
				<div className="formField">
					<label htmlFor="versionFilterType">Match Type</label>
					<Dropdown
						name="versionFilterType"
						value={type}
						options={numberMatchModeOptions}
						optionLabel="label"
						onChange={(e) => options.filterCallback([e.value, version])}
					/>
				</div>
				<div className="formField">
					<label htmlFor="versionFilterVersion">Firmware Version</label>
					<InputText
						name="versionFilterVersion"
						value={version}
						keyfilter={/^[\d.]*$/}
						onChange={(e) => options.filterCallback([type, e.target.value])}
					/>
				</div>
			</div>
		);
	};

	const versionFilterFunction = (value, filter) => {
		const [type, filterValue] = filter ?? [null, null];
		let include = true;
		if (type && filterValue) {
			const filterNumber = getVersionNumber(filterValue);

			if (type === "eq" && value !== filterNumber) include = false;
			if (type === "neq" && value === filterNumber) include = false;
			if (type === "gt" && value <= filterNumber) include = false;
			if (type === "gte" && value < filterNumber) include = false;
			if (type === "lt" && value >= filterNumber) include = false;
			if (type === "lte" && value > filterNumber) include = false;
		}

		return include;
	};

	return (
		<>
			<div className="flex jContent-end gap-small marginBottom-medium">
				<Button
					label={t("admin.ota.upload.upload")}
					className="feature"
					onClick={() => {
						setShowOtaFileUpload(true);
					}}
				/>
			</div>
			<DataTable
				ref={dt}
				value={data}
				emptyMessage={t("common.table.noData")}
				selectionMode="single"
				selection={selected}
				onSelectionChange={(e) => setSelected(e.value)}
				sortMode="multiple"
				removableSort
				filters={dtFilters}
				filterDisplay="menu"
				paginator
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
				currentPageReportTemplate={t("common.table.paginatorTemplate")}
				rows={10}
				dataKey="eTag">
				<Column
					field="filename"
					header={t("admin.ota.file.table.filename")}
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
				<Column
					field="metadata.firmwareversion"
					header={t("admin.ota.file.table.firmware")}
					sortable
					sortField="firmwareNumber"
					filter
					filterField="firmwareNumber"
					showFilterMatchModes={false}
					showFilterOperator={false}
					filterApply={filterApplyTemplate}
					filterElement={versionFilterTemplate}
					filterFunction={versionFilterFunction}
				/>
				<Column
					field="lastModified"
					header={t("admin.ota.file.table.modified")}
					body={(rowData) => datetimeRowTemplate(rowData, "lastModified")}
					dataType="date"
					sortable
					filter
					filterApply={filterApplyTemplate}
					filterElement={dateFilterTemplate}
				/>
				<Column
					field="uploadedByName"
					header={t("admin.ota.file.table.uploadedBy")}
					sortable
					filter
					filterApply={filterApplyTemplate}
				/>
			</DataTable>

			<OtaFileUploadDialog existingFiles={data} />
		</>
	);
};

export default FileList;
