//Node Modules
import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Graph QL
import { API, graphqlOperation } from "aws-amplify";
import { listValidations } from "../graphql/queries";
import { validateDeviceName, validateSchemeName, listAlarmGroupsTable } from "../graphql/queries-custom";

//Atoms
import { validationSelector } from "../atoms/validation";
import { validationAtomFamily } from "../atoms/validation";
import { selectedUserSchemeAtom } from "../atoms/user";

export const useGetValidationConfig = () => {
	const setValidationConfig = useSetRecoilState(validationSelector);

	const getValidationConfig = async () => {
		const {
			data: {
				listValidations: { items: validations },
			},
		} = await API.graphql(graphqlOperation(listValidations));

		setValidationConfig(validations);
	};

	return getValidationConfig;
};

const validateLength = async (data, validation, type, trans) => {
	const { minLength = 5, maxLength = 1000 } = validation;
	if (data.length < minLength) throw Error(trans(`validation.${type}.minLength`, { length: minLength }));
	if (data.length > maxLength) throw Error(trans(`validation.${type}.maxLength`, { length: maxLength }));

	return;
};

const validatePattern = async (data, validation, type, trans) => {
	const { pattern } = validation;
	if (!RegExp(pattern).test(data)) throw Error(trans(`validation.${type}.pattern`, { name: data }));

	return;
};

export const useValidateDevice = () => {
	const { t } = useTranslation();

	const validation = useRecoilValue(validationAtomFamily("device"));
	const selectedUserScheme = useRecoilValue(selectedUserSchemeAtom);

	const validateDevice = useCallback(async (deviceData, currentDeviceId) => {
		//Check Name Unique
		const {
			data: {
				deviceByName: { items: existingDevices },
			},
		} = await API.graphql(
			graphqlOperation(validateDeviceName, {
				name: deviceData,
				schemeId: { eq: selectedUserScheme.id },
				filter: { id: { ne: currentDeviceId } },
			})
		);
		if (existingDevices.length > 0) throw Error(t("validation.device.dupe", { name: deviceData }));

		//Check Name Length
		await validateLength(deviceData, validation, "device", t);

		//Check Name Format
		if (validation.pattern) await validatePattern(deviceData, validation, "device", t);
	}, []);

	return validateDevice;
};

export const useValidateGroup = () => {
	const { t } = useTranslation();

	const selectedUserScheme = useRecoilValue(selectedUserSchemeAtom);

	const validateGroup = useCallback(async (groupName, currentGroupId) => {
		//Check Name Unique
		const {
			data: {
				listAlarmGroups: { items: alarmGroupData },
			},
		} = await API.graphql(
			graphqlOperation(listAlarmGroupsTable, {
				filter: {
					schemeId: { eq: selectedUserScheme.id },
					id: { ne: currentGroupId },
					name: { eq: groupName },
				},
			})
		);

		if (alarmGroupData.length > 0) throw Error(t("validation.alarmGroup.dupe", { name: groupName }));
	}, []);

	return validateGroup;
};

export const useValidateScheme = () => {
	const { t } = useTranslation();

	const validation = useRecoilValue(validationAtomFamily("scheme"));

	const validateScheme = useCallback(async (schemeName, currentSchemeId) => {
		//Check Name Unique
		const {
			data: {
				schemeByName: { items: existingSchemes },
			},
		} = await API.graphql(
			graphqlOperation(validateSchemeName, { name: schemeName, filter: { id: { ne: currentSchemeId } } })
		);
		if (existingSchemes.length > 0) throw Error(t("validation.scheme.dupe", { name: schemeName }));

		//Check Name Length
		await validateLength(schemeName, validation, "scheme", t);

		//Check Name Format
		if (validation.pattern) await validatePattern(schemeName, validation, "scheme", t);
	}, []);

	return validateScheme;
};
